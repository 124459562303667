<template>
	<div>
		<section>
			<div class="container">
				<div class="row">
					<div class="col-lg">
						<div class="header-content text-center mb-3">
							<router-link to="/" exact><h1>Market Scope</h1></router-link>
						</div>
						<div class="card text-center border-0">
							<div class="card-header text-center">
								<img v-if="campaign.landing_logo" class="img-thumbnail p-3 mb-3" :src="'/files/campaigns/logos/' + campaign.id + '/' + campaign.landing_logo" :style="{ backgroundColor: '#' + campaign.landing_logo_bg_color }" alt="Campaign Logo">
								<h3 class="card-title mb-0">{{ campaign.landing_header || 'Register A Clinical Account' }}</h3>
							</div>
							<div class="card-body">
								<p :class="{ 'card-text': true, ['text-' + campaign.landing_subheader_align]: true }">{{ campaign.landing_subheader || 'A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard.' }}</p>
								<form v-promise-btn="{ action: 'submit' }" @submit.prevent="register" accept-charset="utf-8" novalidate role="form" class="text-left">
									<div class="form-row">
										<div class="col">
											<div class="form-group text required">
												<input type="text" v-model.trim="$v.form.full_name.$model" name="full_name" placeholder="Full Name" id="clinical-full-name" required maxlength="50" :class="{ 'form-control': true, 'is-invalid': $v.form.full_name.$dirty && $v.form.full_name.$error }" />
												<div class="invalid-feedback" v-if="$v.form.full_name.$dirty && !$v.form.full_name.required">Full name is required.</div>
												<div class="invalid-feedback" v-if="$v.form.full_name.$dirty && !$v.form.full_name.minLength">Full name has a minimum of 3 characters.</div>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="col">
											<div class="form-group email required">
												<input type="email" v-model.trim="$v.form.email.$model" name="email" placeholder="Email" id="clinical-email" required maxlength="254" :class="{ 'form-control': true, 'is-invalid': $v.form.email.$dirty && $v.form.email.$error }" />
												<div class="invalid-feedback" v-if="$v.form.email.$dirty && !$v.form.email.required">Email address is required.</div>
												<div class="invalid-feedback" v-if="$v.form.email.$dirty && !$v.form.email.email">Email address has to be valid.</div>
												<div class="invalid-feedback" v-if="$v.form.email.$dirty && !$v.form.email.isUnique">Sorry, that email address is already in use.</div>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="col">
											<div class="form-group select required">
												<label for="user-attribute-practice-location-id" class="control-label">Country</label>
												<multiselect
													v-model.trim="selectedCountry"
													:options="countryOptions"
													placeholder="Select your country..."
													label="alias"
													track-by="id"
													deselect-label=""
													aria-invalid="false"
													id="user-attribute-practice-location-id"
													required
													:class="{ 'is-invalid': $v.form.user_attribute.practice.location_id.$dirty && $v.form.user_attribute.practice.location_id.$error }"
												>
													<span slot="noResult">No matching countries found.</span>
												</multiselect>
												<input type="hidden" name="user_attribute[practice][location_id]" :value="form.user_attribute.practice.location_id" />
												<div class="invalid-feedback" v-if="$v.form.user_attribute.practice.location_id.$dirty && !$v.form.user_attribute.practice.location_id.required">Country is required.</div>
											</div>
										</div>
									</div>
									<div class="text-center">
										<button type="submit" class="btn btn-large btn-success" :disabled="!isMounted">Register</button>
									</div>
								</form>
							</div>
							<div class="card-footer text-center">
								<h5>Already Have an Account?</h5>
								<button @click="$modal.show('campaignLoginModal', {})" type="button" class="btn btn-sm btn-primary"><i class="fa fa-lock"></i> Sign In</button>
							</div>
							<div v-if="campaign.landing_show_qr_code && campaign.app_share_url" class="d-none d-md-block card-footer text-center">
								<h6>Prefer to use your phone/tablet? Try our mobile app <span class="text-nowrap">Market Scope+</span> for an improved experience.</h6>
								<img :src="'/files/campaigns/qr/' + campaign.slug + '-app-qr-sm.png'" alt="MS+ QR Code" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<footer-section />
		<campaign-login />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { email, minLength, required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

import FooterSection from './FooterSection'
import CampaignLogin from './modals/CampaignLogin'

export default {
	name: 'CampaignRegister',
	metaInfo() {
		return {
			title: 'Register',
			bodyAttrs: {
				class: 'campaign-register'
			},
			meta: [
				{
					property: 'og:url',
					content: '//www.market-scope.com/register/' + this.$route.params.access_code,
					vmid: 'og:url'
				},
				{
					name: 'image',
					property: 'og:image',
					content: 'https://www.market-scope.com/static/landing/img/landing-medical-share.jpg',
					vmid: 'og:image'
				}
			],
			link: [
				{
					rel: 'canonical',
					content: '//www.market-scope.com/register/' + this.$route.params.access_code,
					vmid: 'canonical'
				}
			]
		}
	},
	asyncData({ store, route }) {
		if (!store.state.campaign || store.state.campaign.access_code !== route.params.access_code) {
			return store.dispatch('fetchCampaign', route.params.access_code)
		}
	},
	data() {
		return {
			countryOptions: [],
			form: {
				email: '',
				full_name: '',
				user_attribute: {
					practice: {
						location_id: null
					}
				}
			},
			gRecaptchaToken: '',
			validationErrors: {},
			csrfToken: '',
			isMounted: false,
			unsubscribe: null
		}
	},
	computed: {
		...mapState({
			campaign: state => state.campaign
		}),
		selectedCountry: {
			get() {
				return this.countryOptions.find(countryOption => countryOption.id === this.form.user_attribute.practice.location_id)
			},
			set(v) {
				if (v) {
					this.$set(this.form.user_attribute.practice, 'location_id', v.id)
				}
			}
		}
	},
	validations() {
		const validations = {
			form: {
				email: {
					required,
					email,
					isUnique(val) {
						if (val === '') return true
						
						return new Promise((resolve, reject) => {
							resolve(!('email.unique' in this.validationErrors))
						})
					}
				},
				full_name: {
					required,
					minLength: minLength(3)
				},
				user_attribute: {
					practice: {
						location_id: {
							required
						}
					}
				}
			}
		}
		
		return validations
	},
	mounted() {
		this.isMounted = true
		
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'SET_INVISIBLE_RECAPTCHA' && state.invisibleRecaptcha.originComponent === this.$options.name) {
				this.recaptchaCallback(state.invisibleRecaptcha.token);
				
				this.unsubscribe()
			}
		})
		
		this.$http.get('funcs/countries')
			.then(response => {
				this.countryOptions = response.data.result.countries
			})
	},
	beforeDestroy() {
		typeof this.unsubscribe === 'function' && this.unsubscribe()
	},
	watch: {
		'$v.form.email.$model': function (newVal, oldVal) {
			this.validationErrors = {}
		}
	},
	methods: {
		register() {
			this.$v.$touch()
			
			if (!this.$v.$invalid) {
				this.$root.$children[0].$refs.invisibleRecaptcha.execute(this.$options.name)
			}
		},
		recaptchaCallback(token) {
			const gRecaptchaToken = token
			
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						const csrfToken = response.data.result.csrfToken
						
						return this.$http.post('account/campaign-register', {
							...this.form,
							_csrfToken: csrfToken,
							g_recaptcha_response: gRecaptchaToken,
							access_code: this.campaign.access_code
						})
							.then(() => {
								this.$modal.show('campaignLoginModal', {
									email: this.form.email
								})
							})
							.catch(error => {
								if (error.response && error.response.status === 422) {
									this.validationErrors = error.response.data.result.message
								}
							})
					})
			})
		}
	},
	components: {
		Multiselect,
		FooterSection,
		CampaignLogin
	}
}
</script>

<style lang="scss">
body.campaign-register {
	/* background: url('~@assets/img/SignUp-BG-Image.jpg'); */
	background: url('~@assets/img/MarketScope-Home-Header-medical.jpg') no-repeat top left fixed;
	background-size: cover;
	
	footer {
		box-shadow: 0 50vh 0 50vh $gray-darker;
	}
	
	/*
	@media (min-width: 500px) {
		footer {
			position: fixed;
			width: 100%;
			bottom: 0;
		}
	}
	*/
}
</style>

<style lang="scss" scoped>
section {
	width: 100%;
	
	.header-content a {
		&:hover,
		&:focus {
			color: #f3fbfd;
			text-decoration: none;
		}
		
		h1 {
			text-shadow: 0 1px 0 #555;
			font-size: 54px;
			font-family: MistralStd;
			letter-spacing: normal;
		}
	}
	
	.card {
		max-width: 500px;
		margin: 0 auto;
		
		.card-header {
			border-bottom-color: rgba(0, 0, 0, 0.05);
			
			h3.card-title {
				font-weight: 400;
			}
		}
		
		.card-body {
			.card-text {
				font-size: 16px;
			}
		}
		
		.card-footer {
			border-top-color: rgba(0, 0, 0, 0.05);
			
			h5 {
				font-size: 18px;
			}
			
			h6 {
				font-size: 16px;
			}
		}
	}
}
</style>