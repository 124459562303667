var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "news-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.articlesSectionHeader) + " News")]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "news", attrs: { id: "news" } }, [
        _c("div", { staticClass: "container header-row" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "mx-auto" },
              [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/icons/MarketScope-Icons-08.svg"),
                      expression:
                        "require('@assets/img/icons/MarketScope-Icons-08.svg')",
                    },
                  ],
                  staticClass: "d-block mx-auto",
                  staticStyle: {
                    "max-width": "70px",
                    width: "auto",
                    height: "auto",
                  },
                  attrs: { width: "70", height: "70" },
                }),
                _vm._v(" "),
                _vm.$route.name === "News"
                  ? _c("h1", { staticClass: "section-heading text-center" }, [
                      _vm._v(_vm._s(_vm.pageTitle)),
                    ])
                  : _c("h2", { staticClass: "section-heading text-center" }, [
                      _vm._v(_vm._s(_vm.pageTitle)),
                    ]),
                _vm._v(" "),
                _vm.tagPageDescription
                  ? [
                      _c("div", {
                        staticClass: "overflow-hidden",
                        domProps: { innerHTML: _vm._s(_vm.tagPageDescription) },
                      }),
                    ]
                  : _c(
                      "p",
                      { staticClass: "text-center" },
                      [
                        _vm._v(
                          "Market Scope provides comprehensive coverage of the ophthalmic market. Our news stories are a combination of public and proprietary content ranging from coverage of international meetings, surgeon survey results and trends, analysis of company financial reports and public medical registries, press releases, and interviews with executives. Register today to receive free weekly email updates, our top data chart, surgeon poll results, and much more. Locked content can be accessed through a subscription to our "
                        ),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Reports",
                                params: { tag: "newsletter" },
                                query: { page: 1 },
                              },
                            },
                          },
                          [_vm._v("ophthalmic")]
                        ),
                        _vm._v(" industry newsletters."),
                      ],
                      1
                    ),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "transition-group",
                {
                  staticClass: "mx-auto text-center tags",
                  attrs: { name: "list-complete", tag: "div" },
                },
                [
                  _vm.activeTag &&
                  _vm.tags.some(
                    (tag) =>
                      tag.slug === _vm.activeTag &&
                      (tag.parent_tags.length || tag.child_tags.length)
                  )
                    ? _c(
                        "button",
                        {
                          key: 0,
                          staticClass:
                            "btn btn-sm btn-level-up list-complete-item m-1",
                          attrs: { type: "button" },
                          on: { click: _vm.filterByParentTag },
                        },
                        [
                          _vm._v(" "),
                          _c("i", { staticClass: "fas fa-level-up-alt" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.filteredTags, function (tag) {
                    return _c(
                      "router-link",
                      {
                        key: tag.id,
                        class: {
                          btn: true,
                          "btn-primary": true,
                          "btn-sm": true,
                          "list-complete-item": true,
                          "m-1": true,
                          active: _vm.activeTag === tag.slug,
                        },
                        attrs: {
                          to: {
                            params: {
                              tag: _vm.activeTag !== tag.slug ? tag.slug : null,
                            },
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(tag.label)),
                        _vm.activeTag === tag.slug
                          ? _c("i", { staticClass: "fas fa-times-circle ml-1" })
                          : _vm._e(),
                        _vm.loadingTag === tag.slug &&
                        _vm.activeTag !== tag.slug
                          ? _c("i", {
                              staticClass: "fas fa-spinner fa-spin ml-1",
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "recent" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("div", { staticClass: "mx-auto text-center" }, [
                _c("h3", { staticClass: "section-heading" }, [
                  _vm._v(_vm._s(_vm.articlesSectionHeader) + " News"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.articles.length
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.recentArticles, function (article) {
                    return _c(
                      "div",
                      { staticClass: "col-12 col-md-6 col-lg-3" },
                      [
                        _c("div", { staticClass: "card" }, [
                          _c("div", { staticClass: "card-header" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  new Date(
                                    article.publish_date
                                  ).toLocaleDateString("en-US", {
                                    timeZone: "UTC",
                                  })
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                            _c(
                              "div",
                              { staticClass: "float-right text-right" },
                              [
                                _vm._l(article.landing_tags, function (tag) {
                                  return [
                                    tag.id
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "badge badge-pill",
                                            style: {
                                              backgroundColor: tag.color,
                                            },
                                          },
                                          [_vm._v(_vm._s(tag.label))]
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body" }, [
                            _c("h5", { staticClass: "card-title" }, [
                              _vm._v(_vm._s(article.title)),
                            ]),
                            _vm._v(" "),
                            article.preview_img_url
                              ? _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: article.preview_img_url,
                                      expression: "article.preview_img_url",
                                    },
                                  ],
                                  staticClass: "img-thumbnail d-block mx-auto",
                                  attrs: { alt: article.title.trim() },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "card-text",
                              domProps: {
                                innerHTML: _vm._s(article.content_preview),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-footer text-center" },
                            [
                              article.content
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "stretched-link",
                                      attrs: {
                                        to: {
                                          name: "Article",
                                          params: {
                                            id: article.id,
                                            slug: article.slug,
                                          },
                                        },
                                      },
                                    },
                                    [_vm._v("Read Article")]
                                  )
                                : _c(
                                    "router-link",
                                    {
                                      staticClass: "stretched-link",
                                      attrs: {
                                        to: {
                                          name: "Article",
                                          params: {
                                            id: article.id,
                                            slug: article.slug,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-lock" }),
                                      _vm._v(" Read Article"),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "row" }, [_vm._m(0)]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "past" }, [
          _c(
            "div",
            { staticClass: "container" },
            [
              _vm.pastArticles.length
                ? [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.pastArticles, function (article) {
                        return _c(
                          "div",
                          { staticClass: "col-12 col-md-6 col-lg-3" },
                          [
                            _c("div", { staticClass: "card" }, [
                              _c("div", { staticClass: "card-header" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      new Date(
                                        article.publish_date
                                      ).toLocaleDateString("en-US", {
                                        timeZone: "UTC",
                                      })
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                ),
                                _c(
                                  "div",
                                  { staticClass: "float-right text-right" },
                                  [
                                    _vm._l(
                                      article.landing_tags,
                                      function (tag) {
                                        return [
                                          tag.id
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-pill",
                                                  style: {
                                                    backgroundColor: tag.color,
                                                  },
                                                },
                                                [_vm._v(_vm._s(tag.label))]
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "card-body" }, [
                                _c("h5", { staticClass: "card-title" }, [
                                  _vm._v(_vm._s(article.title)),
                                ]),
                                _vm._v(" "),
                                article.preview_img_url
                                  ? _c("img", {
                                      directives: [
                                        {
                                          name: "lazy",
                                          rawName: "v-lazy",
                                          value: article.preview_img_url,
                                          expression: "article.preview_img_url",
                                        },
                                      ],
                                      staticClass:
                                        "img-thumbnail d-block mx-auto",
                                      attrs: { alt: article.title.trim() },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", {
                                  staticClass: "card-text",
                                  domProps: {
                                    innerHTML: _vm._s(article.content_preview),
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "card-footer text-center" },
                                [
                                  article.content
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass: "stretched-link",
                                          attrs: {
                                            to: {
                                              name: "Article",
                                              params: {
                                                id: article.id,
                                                slug: article.slug,
                                              },
                                            },
                                          },
                                        },
                                        [_vm._v("Read Article")]
                                      )
                                    : _c(
                                        "router-link",
                                        {
                                          staticClass: "stretched-link",
                                          attrs: {
                                            to: {
                                              name: "Article",
                                              params: {
                                                id: article.id,
                                                slug: article.slug,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-lock",
                                          }),
                                          _vm._v(" Read Article"),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mt-3 mb-5" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { to: "/pages/news-archive" },
                    },
                    [_vm._v("More News")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("register", {
        attrs: { headerText: "Want to Read the Full Article?" },
      }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-auto" }, [
      _c("h4", { staticClass: "mb-0 mt-2 text-muted" }, [_vm._v("No results")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("div", { staticClass: "mx-auto text-center" }, [
        _c("h3", [_vm._v("Past News Stories")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }