var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "campaignLoginModal",
        height: "auto",
        reset: true,
        clickToClose: _vm.clickToClose,
        adaptive: true,
        maxWidth: 350,
        transition: "nice-modal-fade",
      },
      on: {
        "before-open": _vm.beforeOpen,
        opened: _vm.opened,
        closed: _vm.closed,
      },
    },
    [
      _c("div", { staticClass: "modal-header" }, [
        _c("h5", { staticClass: "modal-title" }, [_vm._v("Sign In")]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$modal.hide("campaignLoginModal")
              },
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        !_vm.registeredEmail
          ? _c(
              "form",
              {
                directives: [
                  {
                    name: "promise-btn",
                    rawName: "v-promise-btn",
                    value: { action: "submit" },
                    expression: "{ action: 'submit' }",
                  },
                ],
                attrs: {
                  "accept-charset": "utf-8",
                  novalidate: "novalidate",
                  role: "form",
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.requestOtp.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group text required" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email",
                      },
                    ],
                    ref: "loginEmail",
                    class: {
                      "form-control": true,
                      "is-invalid": !!_vm.emailErr,
                    },
                    attrs: {
                      type: "text",
                      name: "login_email",
                      inputmode: "email",
                      placeholder: "Your email address",
                    },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "email", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  !!_vm.emailErr
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.emailErr)),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group mb-0" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary-outline form-control",
                      attrs: {
                        type: "submit",
                        disabled: _vm.form.email.length < 1,
                      },
                    },
                    [_vm._v("Continue")]
                  ),
                ]),
              ]
            )
          : _c(
              "form",
              {
                ref: "campaignLoginForm",
                attrs: {
                  method: "post",
                  "accept-charset": "utf-8",
                  novalidate: "novalidate",
                  action: "/users/login",
                  role: "form",
                },
              },
              [
                _c("div", { staticStyle: { display: "none" } }, [
                  _c("input", {
                    attrs: { type: "hidden", name: "_method", value: "POST" },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      type: "hidden",
                      name: "_csrfToken",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.form.csrfToken },
                  }),
                  _vm._v(" "),
                  _vm.campaign
                    ? _c("input", {
                        attrs: { type: "hidden", name: "access_code" },
                        domProps: { value: _vm.campaign.access_code },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    staticStyle: { display: "none" },
                    attrs: {
                      type: "text",
                      name: "login_email",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.registeredEmail },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      type: "hidden",
                      name: "verification_code",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.form.verification_code },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-center" },
                  [
                    _c("h3", [_vm._v("Check Your Email")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-3", staticStyle: { width: "100px" } },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/icons/lock-check.png"),
                              expression:
                                "require('@assets/img/icons/lock-check.png')",
                            },
                          ],
                          staticClass: "img-fluid p-3",
                          staticStyle: {
                            "background-color": "#2fbbcc",
                            "border-radius": "50%",
                            overflow: "hidden",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3 text-center" }, [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { "font-size": "16px" },
                        },
                        [_vm._v("We've sent a verification code to")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 font-weight-bold",
                          staticStyle: { "font-size": "16px" },
                        },
                        [_vm._v(_vm._s(_vm.registeredEmail))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group w-100 mb-0" },
                      [
                        _c("otp-input", {
                          ref: "otpInput",
                          staticClass: "justify-content-between",
                          attrs: {
                            "input-classes": "otp-input",
                            separator: "",
                            "num-inputs": 6,
                            "is-input-num": true,
                            "should-auto-focus": true,
                          },
                          on: { "on-complete": _vm.otpCompleted },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }