var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", {
        attrs: { subpage: true, requestedProductId: _vm.requestedProductId },
      }),
      _vm._v(" "),
      _c("header", { staticClass: "omp-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "omp", attrs: { id: "omp" } }, [
        _c("div", { staticClass: "container header-row" }, [
          _c("div", { staticClass: "mx-auto text-center" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: require("@assets/img/icons/MarketScope-Icons-08.svg"),
                  expression:
                    "require('@assets/img/icons/MarketScope-Icons-08.svg')",
                },
              ],
              staticClass: "d-block mx-auto",
              staticStyle: {
                "max-width": "70px",
                width: "auto",
                height: "auto",
              },
              attrs: { width: "70", height: "70" },
            }),
            _vm._v(" "),
            _c("h1", { staticClass: "section-heading font-weight-bold" }, [
              _vm._v("Ophthalmic Market Perspectives Newsletter"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "For the past 25 years, Market Scope has published the Ophthalmic Market Perspectives newsletter to keep clients informed with third-party perspectives on the industry in an ad-free environment. This monthly newsletter explores new technologies, product trends, and company developments; features conference previews and recaps; and presents proprietary data, analysis, and forecasting."
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-deck w-100 mx-0" }, [
              _c("div", { staticClass: "card border-0 text-center" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", {
                    ref: "flipYears",
                    staticClass: "card-title mb-0 font-weight-bold",
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-weight-bold mb-0" }, [
                    _vm._v("Monthly Issues Spanning 25 Years"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card border-0 text-center" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", {
                    ref: "flipCirculation",
                    staticClass: "card-title mb-0 font-weight-bold",
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-weight-bold mb-0" }, [
                    _vm._v("Monthly Industry and Clinical Circulation"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card border-0 text-center" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", {
                    ref: "flipTypes",
                    staticClass: "card-title mb-0 font-weight-bold",
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-weight-bold mb-0" }, [
                    _vm._v("Subscribing Companies of All Sizes & Types"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3 pt-4 monthly-content" }, [
          _c("div", { staticClass: "container" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "card-deck w-100 mx-0 mb-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-business-conversation.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-business-conversation.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Meeting Coverage"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "Comprehensive coverage from the AAO, ESCRS, ASCRS, ARVO, and many more"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-web-analytics.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-web-analytics.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Procedure Volumes"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "Quarterly analysis of cataract, retina, glaucoma, and refractive procedures"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-competitive-analysis.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-competitive-analysis.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Company Revenue"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "Quarterly analysis of public and private ophthalmic company revenue"
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-deck mx-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-search-company.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-search-company.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Emerging Companies"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "Identification of new companies pursuing ophthalmic indications"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-profit-target.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-profit-target.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Clinical Trial Updates"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "Regular updates on clinical trials and milestone achievements"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-agreement.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-agreement.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Business Transactions"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "Mergers and acquisitions, financing, and business/technology sales"
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container mt-3 contributors" },
          [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "swiper",
              {
                ref: "contributorSwiper",
                staticClass: "swiper contributor-thumbs px-3",
                attrs: { options: _vm.swiperOption },
                on: {
                  "slide-change-transition-end": _vm.handleSlideChange,
                  "click-slide": _vm.handleClickSlide,
                },
              },
              [
                _vm._l(_vm.slides, function (slide, i) {
                  return _c(
                    "swiper-slide",
                    { key: i, class: slide.className },
                    [
                      _c("div", { staticClass: "overlay" }),
                      _vm._v(" "),
                      _c("i", { staticClass: "fas fa-expand" }),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "swiper-button-prev",
                  attrs: { slot: "button-prev" },
                  slot: "button-prev",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "swiper-button-next",
                  attrs: { slot: "button-next" },
                  slot: "button-next",
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "contributor-content mt-2" }, [
              _c("h5", [
                _vm._v(
                  _vm._s(_vm.slides[_vm.activeSlideIndex].contributorName)
                ),
                _c("br"),
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.slides[_vm.activeSlideIndex].contributorTitle)
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3 pt-4 features" }, [
          _c("div", { staticClass: "container" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "card-deck w-100 mx-0 mb-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-calendar.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-calendar.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Access to Historical Issues Back to 2010"),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-search-report.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-search-report.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Searchable Archive of News Stories"),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card bg-transparent border-0 text-white text-center",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/omp/icons/omp-business-people.png"),
                          expression:
                            "require('@assets/img/omp/icons/omp-business-people.png')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "50px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "50", height: "50" },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "card-title mb-0 mt-1" }, [
                      _vm._v("Scalable License for Large Teams"),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3 pt-4 purchase" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _vm.products.length > 0 &&
              (_vm.products[0].document_cover_filename ||
                _vm.products[0].document_sample_page_images.length)
                ? _c("div", { staticClass: "col-md-4 pb-3 report-sample" }, [
                    _c("div", { staticClass: "report-sample-img" }, [
                      _vm.products[0].document_cover_filename
                        ? _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value:
                                  "/files/products/covers/" +
                                  _vm.products[0].id +
                                  "/" +
                                  _vm.products[0].document_cover_filename,
                                expression:
                                  "'/files/products/covers/' + products[0].id + '/' + products[0].document_cover_filename",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: _vm.products[0].cover_img_alt
                                ? _vm.products[0].cover_img_alt
                                : _vm.products[0].label,
                            },
                          })
                        : _vm.products[0].document_sample_page_images.length
                        ? _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value:
                                  "/files/products/samples/" +
                                  _vm.products[0].id +
                                  "/" +
                                  _vm.products[0]
                                    .document_sample_page_images[0],
                                expression:
                                  "'/files/products/samples/' + products[0].id + '/' + products[0].document_sample_page_images[0]",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: _vm.products[0].cover_img_alt
                                ? _vm.products[0].cover_img_alt
                                : _vm.products[0].label,
                            },
                          })
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-md-8 d-flex flex-column justify-content-between",
                },
                [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center my-auto" }, [
                    _c("div", [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#register",
                              expression: "'#register'",
                            },
                          ],
                          staticClass:
                            "btn btn-warning text-white font-weight-bold mb-3",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.requestSubscription(_vm.products[0])
                            },
                          },
                        },
                        [_vm._v("Purchase Subscription")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary font-weight-bold mb-3",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.showRequestSampleModal(
                                _vm.products[0].product_type.id
                              )
                            },
                          },
                        },
                        [_vm._v("Request a Sample")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary font-weight-bold mb-3",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.showRequestCustomQuoteModal(
                                "Request Team Quote"
                              )
                            },
                          },
                        },
                        [_vm._v("Request Team Quote")]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("register", { attrs: { requestedProductId: _vm.requestedProductId } }),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("request-sample"),
      _vm._v(" "),
      _c("request-custom-quote"),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "bioModal",
            height: "auto",
            scrollable: true,
            reset: true,
            adaptive: true,
            transition: "nice-modal-fade",
          },
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.slides[_vm.activeSlideIndex].contributorName)),
              _c("br"),
              _c("small", [
                _vm._v(
                  _vm._s(_vm.slides[_vm.activeSlideIndex].contributorTitle)
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$modal.hide("bioModal")
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.slides[_vm.activeSlideIndex].bio)),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Ophthalmic Newsletter")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "font-weight-bold text-white" }, [
          _vm._v("Curated Monthly Content"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "mx-auto text-center" }, [
        _c("h3", { staticClass: "font-weight-bold" }, [_vm._v("Contributors")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "This newsletter is written and edited by our in-house team of ophthalmic experts, who have a collective 150 years of experience analyzing markets and providing informed perspective."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "font-weight-bold text-white" }, [
          _vm._v("Subscription Features"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "font-weight-bold" }, [
        _vm._v("Purchase Subscription"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "The Ophthalmic Market Perspectives newsletter is licensed as a 12-month subscription, with prices starting at $1,700 for a single user license. Market Scope also offers scalable licenses for large teams, with the price per user decreasing significantly as more users are added."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Join 20,000 other industry and clinical professionals by subscribing today."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }