import Vue from 'vue'
import Vuex from 'vuex'
import { createFlashStore } from 'vuex-flash'

Vue.use(Vuex)

export function createStore() {
	return new Vuex.Store({
		state: {
			companyNames: [],
			articles: [],
			article: null,
			articleTags: [],
			articlesTagLoading: null,
			products: [],
			product: null,
			productTags: [],
			productPageCount: 0,
			productsTagLoading: null,
			searchQuery: '',
			invite: null,
			campaign: null,
			invisibleRecaptcha: {
				token: null,
				originComponent: null
			}
		},
		actions: {
			fetchCompanyNames({ commit }) {
				return Vue.axios.get('companies/names')
					.then(response => {
						commit('SET_COMPANY_NAMES', response.data.result.names)
					})
			},
			fetchArticles({ commit }, options) {
				let defaults = {
					limit: null,
					start_pos: 0,
					tag: null
				}
				let params = Object.assign({}, defaults, options)
				
				commit('SET_ARTICLES_TAG_LOADING', params.tag)
				
				return Vue.axios.get('articles/latest', {
					params: params
				})
					.then(response => {
						commit('SET_ARTICLES', response.data.result.articles)
					})
			},
			fetchArticle({ commit }, id) {
				return Vue.axios.get('articles/latest', {
					params: {
						id: id
					}
				})
					.then(response => {
						if (response.data.result.articles.length) {
							commit('SET_ARTICLE', response.data.result.articles[0])
						} else {
							commit('SET_ARTICLE', null)
						}
					})
			},
			fetchArticleTags({ commit }) {
				return Vue.axios.get('articles/tags')
					.then(response => {
						commit('SET_ARTICLE_TAGS', response.data.result.tags)
					})
			},
			fetchProducts({ commit }, options) {
				let defaults = {
					limit: null,
					tag: null,
					page: 1,
					sort_type: 'newest',
					product_type_id: null
				}
				let params = Object.assign({}, defaults, options)
				
				commit('SET_PRODUCTS_TAG_LOADING', params.tag)
				
				return Vue.axios.get('products/latest', {
					params: params
				})
					.then(response => {
						let products = response.data.result.products.map(product => {
							let coverImg = null // @todo: add stock image
							let coverImgThumb = null
							
							if (product.document_cover_filename) {
								coverImg = '/files/products/covers/' + product.id + '/' + product.document_cover_filename
								coverImgThumb = '/files/products/covers/' + product.id + '/' + product.document_cover_filename.substr(0, product.document_cover_filename.lastIndexOf('.')) + '.thumb.' + product.document_cover_filename.split('.').pop()
							} else if (product.document_sample_page_images.length) {
								coverImg = '/files/products/samples/' + product.id + '/' + product.document_sample_page_images[0]
							}
							
							product.cover_img = coverImg
							product.cover_img_thumb = coverImgThumb
							
							return product
						})
						
						commit('SET_PRODUCTS', products)
						commit('SET_PRODUCT_PAGE_COUNT', response.data.result.pageCount)
					})
			},
			fetchProduct({ commit }, id) {
				return Vue.axios.get('products/latest', {
					params: {
						id: id
					}
				})
					.then(response => {
						if (response.data.result.products.length) {
							let product = response.data.result.products[0]
							
							let coverImg = null // @todo: add stock image
							let coverImgThumb = null
							
							if (product.document_cover_filename) {
								coverImg = '/files/products/covers/' + product.id + '/' + product.document_cover_filename
								coverImgThumb = '/files/products/covers/' + product.id + '/' + product.document_cover_filename.substr(0, product.document_cover_filename.lastIndexOf('.')) + '.thumb.' + product.document_cover_filename.split('.').pop()
							} else if (product.document_sample_page_images.length) {
								coverImg = '/files/products/samples/' + product.id + '/' + product.document_sample_page_images[0]
							}
							
							product.cover_img = coverImg
							product.cover_img_thumb = coverImgThumb
							
							commit('SET_PRODUCT', product)
						} else {
							if (response.data.result.redirectUrl) {
								return Promise.reject({
									status: 301,
									redirectUrl: response.data.result.redirectUrl
								})
							} else {
								commit('SET_PRODUCT', null)
							}
						}
					})
			},
			fetchProductTags({ commit }) {
				return Vue.axios.get('products/tags')
					.then(response => {
						commit('SET_PRODUCT_TAGS', response.data.result.tags)
					})
			},
			fetchCampaign({ commit }, accessCode) {
				return Vue.axios.get('funcs/active-campaign/' + accessCode)
					.then(response => {
						if (response.data.result.active_campaign) {
							commit('SET_CAMPAIGN', response.data.result.active_campaign)
						} else {
							if (response.data.result.redirectUrl) {
								return Promise.reject({
									status: 301,
									redirectUrl: response.data.result.redirectUrl
								})
							} else {
								commit('SET_CAMPAIGN', null)
							}
						}
					})
			}
		},
		mutations: {
			SET_COMPANY_NAMES(state, companyNames) {
				state.companyNames = companyNames
			},
			SET_ARTICLES(state, articles) {
				state.articles = articles
				state.articlesTagLoading = null
			},
			SET_ARTICLE(state, article) {
				state.article = article
			},
			SET_ARTICLE_TAGS(state, articleTags) {
				state.articleTags = articleTags
			},
			SET_ARTICLES_TAG_LOADING(state, tag) {
				state.articlesTagLoading = tag
			},
			SET_PRODUCTS(state, products) {
				state.products = products
				state.productsTagLoading = null
			},
			SET_PRODUCT(state, product) {
				state.product = product
			},
			SET_PRODUCT_TAGS(state, productTags) {
				state.productTags = productTags
			},
			SET_PRODUCT_PAGE_COUNT(state, productPageCount) {
				state.productPageCount = productPageCount
			},
			SET_PRODUCTS_TAG_LOADING(state, tag) {
				state.productsTagLoading = tag
			},
			SET_SEARCH_QUERY(state, searchQuery) {
				state.searchQuery = searchQuery
			},
			SET_INVITE(state, invite) {
				state.invite = invite
			},
			SET_CAMPAIGN(state, campaign) {
				state.campaign = campaign
			},
			SET_INVISIBLE_RECAPTCHA(state, invisibleRecaptcha) {
				state.invisibleRecaptcha = invisibleRecaptcha
			}
		},
		plugins: [
			createFlashStore()
		]
	})
}