var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "bio-masthead w-100" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-center p-5 bio-info",
            },
            [
              _c("div", [
                _c("h1", [_vm._v("Bill Freeman")]),
                _vm._v(" "),
                _c(
                  "h3",
                  { staticClass: "my-4 font-weight-bold text-uppercase" },
                  [_vm._v("Executive Vice President")]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/in/william-freeman-1365a12a/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/LinkedIn-Icon.svg"),
                          expression:
                            "require('@assets/img/about/bios/LinkedIn-Icon.svg')",
                        },
                      ],
                      staticClass: "d-block",
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-end bio-headshot",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/about/headshot-13.jpg"),
                    expression: "require('@assets/img/about/headshot-13.jpg')",
                  },
                ],
                staticClass: "img-fluid",
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c("router-link", { attrs: { to: { name: "About" }, exact: "" } }, [
              _vm._v("About"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("authored-reports", { attrs: { authorId: 1187 } }),
      _vm._v(" "),
      _c("combined-registration", { attrs: { origin: "bio" } }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Phone: "),
      _c("a", { attrs: { href: "tel:+13148350600" } }, [
        _vm._v("314-835-0600"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Email: "),
      _c("a", { attrs: { href: "mailto:bill@market-scope.com" } }, [
        _vm._v("bill@market-scope.com"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Bill Freeman")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bio content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row px-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mx-md-5" }, [
              _c("h4", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "Bill Freeman is a founding father in ophthalmology, with more than 50 years of experience developing, manufacturing, and marketing cataract surgery products—including the world’s first phacoemulsifier system."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Bill held key executive positions at Cavitron Surgical Systems, CooperVision, Alcon, and Mentor Ophthalmics before joining Market Scope in 2000. Since then, Bill has expanded Market Scope’s report coverage further into surgical instruments, glaucoma surgical devices, retinal surgical devices, cataract equipment, and the premium cataract space."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "When he’s not schooling us all with his ophthalmic knowledge, Bill enjoys spending time in Southern California with his family—who also share his passion for ophthalmology, as both his son and granddaughter now also work in the industry."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }