<template>
	<div>
		<navbar :subpage="true" />
		<header class="bio-masthead w-100">
			<div class="row no-gutters">
				<div class="col-md-6 d-flex align-items-center justify-content-center p-5 bio-info">
					<div>
						<h1>Bill Freeman</h1>
						<h3 class="my-4 font-weight-bold text-uppercase">Executive Vice President</h3>
						<h4>Phone: <a href="tel:+13148350600">314-835-0600</a></h4>
						<h4>Email: <a href="mailto:bill@market-scope.com">bill@market-scope.com</a></h4>
						<a href="https://www.linkedin.com/in/william-freeman-1365a12a/" target="_blank"><img v-lazy="require('@assets/img/about/bios/LinkedIn-Icon.svg')" class="d-block" /></a>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-center justify-content-end bio-headshot">
					<img v-lazy="require('@assets/img/about/headshot-13.jpg')" class="img-fluid" />
				</div>
			</div>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'About' }" exact>About</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Bill Freeman</span>
			</li>
		</ol>
		<section class="bio content">
			<div class="container">
				<div class="row px-5">
					<div class="col">
						<div class="mx-md-5">
							<h4 class="mt-3 mb-4">Bill Freeman is a founding father in ophthalmology, with more than 50 years of experience developing, manufacturing, and marketing cataract surgery products—including the world’s first phacoemulsifier system.</h4>
							<p>Bill held key executive positions at Cavitron Surgical Systems, CooperVision, Alcon, and Mentor Ophthalmics before joining Market Scope in 2000. Since then, Bill has expanded Market Scope’s report coverage further into surgical instruments, glaucoma surgical devices, retinal surgical devices, cataract equipment, and the premium cataract space.</p>
							<p>When he’s not schooling us all with his ophthalmic knowledge, Bill enjoys spending time in Southern California with his family—who also share his passion for ophthalmology, as both his son and granddaughter now also work in the industry.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<authored-reports :authorId="1187" />
		<combined-registration origin="bio" />
		<footer-section />
	</div>
</template>

<script>
import Navbar from '../Navbar'
import AuthoredReports from '../AuthoredReports'
import CombinedRegistration from '../forms/CombinedRegistration'
import FooterSection from '../FooterSection'

export default {
	name: 'BillFreeman',
	metaInfo: {
		title: 'About: Bill Freeman',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/about/bill-freeman',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/headshot-13.jpg',
				vmid: 'og:image'
			},
			{
				name: 'title',
				property: 'og:title',
				content: 'About: Bill Freeman',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/about/bill-freeman',
				vmid: 'canonical'
			}
		]
	},
	components: {
		Navbar,
		AuthoredReports,
		CombinedRegistration,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.bio-masthead {
	position: relative;
	overflow: hidden;
	
	.row {
		padding-top: 81px; /* change to auto-adjust based on navbar height */
		
		.bio-info {
			background-color: #0a5384;
			padding-top: 4rem !important;
			
			> div {
				h1,
				h3,
				h4 {
					color: #fff;
				}
				
				h1 {
					font-size: 50px;
					font-weight: 400;
				}
				
				h3 {
					font-size: 18px;
				}
				
				h4 {
					font-size: 20px;
				}
			}
		}
		
		.bio-headshot {
			background-color: #0a5384;
		}
	}
	
	@media (min-width: 482px) {
		.row .bio-info {
			padding-top: 3rem !important;
		}
	}
}

section.bio.content {
	h4 {
		font-size: 30px;
		color: #0a5384;
		font-weight: 500;
	}
	
	p {
		font-size: 18px;
		color: #777;
	}
}
</style>