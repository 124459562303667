<script>
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import startCase from 'lodash/startCase'

import BillFreeman from './bios/BillFreeman'
import ChelseaJones from './bios/ChelseaJones'
import DaveHarmon from './bios/DaveHarmon'
import EthanWallace from './bios/EthanWallace'
import JennieCrabbe from './bios/JennieCrabbe'
import JoanMckenna from './bios/JoanMckenna'
import KristenHarmonIngenito from './bios/KristenHarmonIngenito'
import MatthewDouty from './bios/MatthewDouty'
import NathanJones from './bios/NathanJones'
import PeterDowns from './bios/PeterDowns'
import TonyIngenito from './bios/TonyIngenito'
import KristaDavidson from './bios/KristaDavidson'
import KatherineBrouillet from './bios/KatherineBrouillet'
import TannerRay from './bios/TannerRay'
import SharonGotter from './bios/SharonGotter'

const components = {
	BillFreeman,
	ChelseaJones,
	DaveHarmon,
	EthanWallace,
	JennieCrabbe,
	JoanMckenna,
	KristenHarmonIngenito,
	MatthewDouty,
	NathanJones,
	PeterDowns,
	TonyIngenito,
	KristaDavidson,
	KatherineBrouillet,
	TannerRay,
	SharonGotter
}

export default {
	functional: true,
	props: ['slug'],
	beforeRouteEnter(to, from, next) {
		next(vm => {
			const _paq = window._paq = window._paq || []
			if (vm.prevRoute && vm.prevRoute.fullPath) {
				_paq.push(['setReferrerUrl', window.location.origin + vm.$router.resolve(vm.prevRoute.fullPath).href])
			}
			_paq.push(['setCustomUrl', window.location.origin + vm.$router.resolve(vm.$route.fullPath).href])
			_paq.push(['trackPageView', 'About: ' + startCase(vm.$route.params.slug) + ' | Market Scope'])
		})
	},
	asyncData({ store }) {
		return store.dispatch('fetchCompanyNames')
	},
	render(h, ctx) {
		return h(components[upperFirst(camelCase(ctx.props.slug))], ctx.data, ctx.children)
	}
}
</script>