<template>
	<div>
		<navbar :subpage="true" />
		<header class="disease-models-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Ophthalmic Disease Prevalence Models</span>
			</li>
		</ol>
		<section class="disease-models" id="disease-models">
			<div class="container header-row">
				<div>
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-08.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h1 class="section-heading">Ophthalmic Disease Prevalence Models</h1>
						<p>Market Scope’s ophthalmic disease models offer extensive country-level prevalence data and five-year forecasts by age group for all countries with a population over a million.</p>
						<p>These proprietary models take into consideration the latest clinical research and demographic projections on a global scale and can be customized by geography and disease category to meet clients’ diverse needs. Disease models are often used to evaluate target markets for clinical-stage and commercial-stage products and gain insights into the volume and demographics of diseased populations.</p>
						<div class="card-deck w-100 mx-0">
							<div class="card border-0 text-center">
								<div class="card-body">
									<h5 ref="flipStudies" class="card-title mb-0 font-weight-bold"></h5>
									<p class="font-weight-bold mb-0">Clinical Studies Researched</p>
								</div>
							</div>
							<div class="card border-0 text-center">
								<div class="card-body">
									<h5 ref="flipDiseases" class="card-title mb-0 font-weight-bold"></h5>
									<p class="font-weight-bold mb-0">Ophthalmic Diseases/Conditions Modeled</p>
								</div>
							</div>
							<div class="card border-0 text-center">
								<div class="card-body">
									<h5 ref="flipCountries" class="card-title mb-0 font-weight-bold"></h5>
									<p class="font-weight-bold mb-0">Countries Forecasted</p>
								</div>
							</div>
						</div>
						<div class="mt-5">
							<div id="map"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="container mt-5 disease-cards">
				<div class="row">
					<div class="col text-center mb-3">
						<h3 class="font-weight-bold">Diseases Modeled</h3>
					</div>
				</div>
				<div class="card-deck w-100 mx-0">
					<div v-for="card in diseaseCards" class="card text-white shadow mb-4" :style="{ 'background-color': card.color }">
						<div class="card-body d-flex align-items-center justify-content-center">
							<h5 class="card-title text-center mb-0">{{ card.title }}</h5>
							<div class="card-overlay d-flex align-items-center justify-content-center">
								<div>
									<h5 class="card-title font-weight-bold text-center mb-0" :style="{ color: card.color }">{{ card.title }}</h5>
									<ul class="card-text">
										<li v-for="line in card.content">{{ line }}</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container mt-3">
				<div class="row mb-3">
					<div class="col-md-6 my-auto">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Use cases</h3>
							</div>
							<div class="card-body">
								<ul>
									<li>Evaluation of target markets</li>
									<li>Country-level market sizing and potential</li>
									<li>Clinical trial planning</li>
									<li>Product and company valuation</li>
									<li>Allocation of resources</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-body">
								<LazyYoutube src="https://youtu.be/8pAqVyI_DYI" :showTitle="false" customThumbnail="https://img.youtube.com/vi/8pAqVyI_DYI/sddefault.jpg" />
							</div>
						</div>
					</div>
				</div>
				<div v-if="products.length" class="row mb-3">
					<div class="col">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Available Models</h3>
							</div>
							<div class="card-body pt-2">
								<div class="container">
									<div class="row">
										<div class="card-group">
											<div class="card col p-1 bg-white" v-for="product in products">
												<div class="card-body p-0">
													<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug } }" :title="product.label">
														<img v-if="product.document_original_filename || product.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Learn More</h3>
							</div>
							<div class="card-body pt-2">
								<p class="mb-0">Contact us using the button below to request more information about these models, request a sample, or schedule a time to discuss live with our team.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="text-center mt-3">
				<button @click="showDiseaseModelsContactUsModal" type="button" class="btn btn-success">Contact Us</button>
			</div>
		</section>
		<register />
		<footer-section />
		<disease-models-contact-us />
	</div>
</template>

<script>
import { LazyYoutube } from 'vue-lazytube'

import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'
import DiseaseModelsContactUs from './modals/DiseaseModelsContactUs'

export default {
	name: 'DiseaseModels',
	metaInfo() {
		return {
			title: 'Ophthalmic Disease Prevalence Models',
			meta: [
				{
					property: 'og:url',
					content: '//www.market-scope.com/disease-models',
					vmid: 'og:url'
				},
				{
					name: 'image',
					property: 'og:image',
					content: 'https://www.market-scope.com/static/landing/img/disease-models-header.jpg',
					vmid: 'og:image'
				}
			],
			link: [
				{
					rel: 'canonical',
					href: 'https://www.market-scope.com/disease-models',
					vmid: 'canonical'
				}
			],
			script: [
				{
					src: '/js/mapdata.min.js',
					defer: true,
					async: true,
					callback: () => {
						this.$parent.mapdataLoaded = true
					},
					vmid: 'mapdata'
				},
				{
					src: '/js/worldmap.js',
					defer: true,
					async: true,
					skip: !this.$parent.mapdataLoaded,
					callback: () => simplemaps_worldmap.load(),
					vmid: 'worldmap'
				}
			]
		}
	},
	asyncData({ store }) {
		return store.dispatch('fetchProducts', {
			limit: 5,
			tag: 'disease-model'
		})
	},
	data() {
		return {
			diseaseCards: [
				{
					title: 'Glaucoma',
					content: ['Angle-closure glaucoma', 'Open-angle glaucoma', 'Secondary glaucoma', 'Ocular hypertension'],
					color: '#00597b'
				},
				{
					title: 'Retina',
					content: ['Early AMD', 'Late AMD', 'Wet AMD', 'Geographic Atrophy', 'Retinal Vein Occlusion', 'Diabetic Retinopathy'],
					color: '#58595b'
				},
				{
					title: 'Dry Eye',
					content: ['Preclinical dry eye', 'Dry eye', 'Those at risk for dry eye', 'Meibomian gland dysfunction with dry eye', 'Sjogren\'s with dry eye', 'Seven others'],
					color: '#545788'
				},
				{
					title: 'Refractive Error',
					content: ['Myopia (by diopter)', 'Hyperopia (by diopter)', 'Presbyopia', 'Plano-presbyopes', 'Astigmatism'],
					color: '#9fc645'
				},
				{
					title: 'Cornea',
					content: ['Keratoconus', 'Other Ectasias', 'Fuchs\' Dystrophy', 'Other Dystrophies', 'Acquired & Infectious', 'Congenital', 'Other Keratitis'],
					color: '#2f9daa'
				}
			]
		}
	},
	computed: {
		flipStudies() {
			return this.$refs.flipStudies
		},
		flipDiseases() {
			return this.$refs.flipDiseases
		},
		flipCountries() {
			return this.$refs.flipCountries
		},
		products() {
			return this.$store.state.products
		}
	},
	mounted() {
		const { Flip } = require('number-flip')
		
		new Flip({
			node: $(this.flipStudies)[0],
			to: 587,
			duration: 3,
			delay: 1.5,
			direct: false
		})
		new Flip({
			node: $(this.flipDiseases)[0],
			to: 111,
			duration: 3,
			delay: 2,
			direct: false
		})
		new Flip({
			node: $(this.flipCountries)[0],
			to: 161,
			duration: 3,
			delay: 2.5,
			direct: false
		})
	},
	methods: {
		showDiseaseModelsContactUsModal() {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Ophthalmic Disease Prevalence Models', 'Opened Modal', '"Contact Us" Modal'])
			
			this.$modal.show('diseaseModelsContactUsModal')
		}
	},
	components: {
		LazyYoutube,
		Navbar,
		Register,
		FooterSection,
		DiseaseModelsContactUs
	}
}
</script>

<style lang="scss" scoped>
header.disease-models-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/disease-models-header.jpg'), $theme-secondary;
	background: url('~@assets/img/disease-models-header.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: center;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position-y: 50%;
	}
}

section.disease-models {
	.section-heading {
		color: #0a5384;
		margin-bottom: 8px;
	}
	
	.header-row {
		.card {
			h5,
			p {
				color: #0a5384;
			}
			
			h5 {
				font-size: 3.2rem;
			}
			
			p {
				font-size: 1.2rem;
			}
		}
		
		p {
			color: $gray-light;
		}
	}
	
	.card {
		p,
		li {
			font-size: 1.1rem;
		}
		
		.card-header h3.card-title {
			color: #0a5384;
		}
	}
	
	.disease-cards {
		h3 {
			color: #0a5384;
		}
		
		.card-deck {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
		}
		
		.card {
			height: 192px;
			border-radius: 25px !important;
			
			h5.card-title {
				font-size: 2rem;
				font-weight: 700;
			}
			
			.card-overlay {
				position: absolute;
				top: 4px;
				left: 4px;
				background-color: #fff;
				border-radius: 20px !important;
				overflow: hidden;
				width: calc(100% - 8px);
				height: 0;
				transition: .3s ease;
				
				h5.card-title {
					padding-top: 0;
					padding-bottom: 0.5rem;
					font-size: 1.4rem;
				}
				
				ul.card-text {
					color: #555;
					padding-left: 1.3rem;
					line-height: 1.4;
					
					li {
						font-size: .85rem;
					}
				}
			}
			
			.card-body:hover .card-overlay {
				height: calc(100% - 8px);
			}
		}
	}
	
	.card-group .card {
		min-width: 180px;
	}
}
</style>