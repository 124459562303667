<template>
	<div>
		<navbar :subpage="true" />
		<header class="bio-masthead w-100">
			<div class="row no-gutters">
				<div class="col-md-6 d-flex align-items-center justify-content-center p-5 bio-info">
					<div>
						<h1>Ethan Wallace</h1>
						<h3 class="my-4 font-weight-bold text-uppercase">Senior Data Analyst</h3>
						<h4>Phone: <a href="tel:+13148350600">314-835-0600</a></h4>
						<h4>Email: <a href="mailto:ethan@market-scope.com">ethan@market-scope.com</a></h4>
						<a href="https://www.linkedin.com/in/ethan-wallace-ab9981170/" target="_blank"><img v-lazy="require('@assets/img/about/bios/LinkedIn-Icon.svg')" class="d-block" /></a>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-center justify-content-end bio-headshot">
					<img v-lazy="require('@assets/img/about/headshot-17.jpg')" class="img-fluid" />
				</div>
			</div>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'About' }" exact>About</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Ethan Wallace</span>
			</li>
		</ol>
		<section class="bio content">
			<div class="container">
				<div class="row px-5">
					<div class="col">
						<div class="mx-md-5">
							<h4 class="mt-3 mb-4">Ethan Wallace’s expertise includes data verification, helping to create and examine data models, and translating numbers into plain English.</h4>
							<p>Ethan joined Market Scope in February 2019 as a data analyst. He has a bachelor’s degree in political science with a minor in business administration from Truman State University. Prior to joining Market Scope, Ethan was in a financial analysis role for Citi in its mortgage subsidiary for nearly two years. Outside of work, Ethan enjoys hiking and snowboarding.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<authored-reports :authorId="4826" />
		<combined-registration origin="bio" />
		<footer-section />
	</div>
</template>

<script>
import Navbar from '../Navbar'
import AuthoredReports from '../AuthoredReports'
import CombinedRegistration from '../forms/CombinedRegistration'
import FooterSection from '../FooterSection'

export default {
	name: 'EthanWallace',
	metaInfo: {
		title: 'About: Ethan Wallace',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/about/ethan-wallace',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/headshot-17.jpg',
				vmid: 'og:image'
			},
			{
				name: 'title',
				property: 'og:title',
				content: 'About: Ethan Wallace',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/about/ethan-wallace',
				vmid: 'canonical'
			}
		]
	},
	components: {
		Navbar,
		AuthoredReports,
		CombinedRegistration,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.bio-masthead {
	position: relative;
	overflow: hidden;
	
	.row {
		padding-top: 81px; /* change to auto-adjust based on navbar height */
		
		.bio-info {
			background-color: #0a5384;
			padding-top: 4rem !important;
			
			> div {
				h1,
				h3,
				h4 {
					color: #fff;
				}
				
				h1 {
					font-size: 50px;
					font-weight: 400;
				}
				
				h3 {
					font-size: 18px;
				}
				
				h4 {
					font-size: 20px;
				}
			}
		}
		
		.bio-headshot {
			background-color: #0a5384;
		}
	}
	
	@media (min-width: 482px) {
		.row .bio-info {
			padding-top: 3rem !important;
		}
	}
}

section.bio.content {
	h4 {
		font-size: 30px;
		color: #0a5384;
		font-weight: 500;
	}
	
	p {
		font-size: 18px;
		color: #777;
	}
}
</style>