<template>
	<div
		:id="elementId"
		class="g-recaptcha"
		:class="styleClassObject"
		:data-sitekey="sitekey"
	></div>
</template>

<script>
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha'

export default {
	name: 'vue-programmatic-invisible-google-recaptcha-custom',
	mixins: [VueProgrammaticInvisibleGoogleRecaptcha],
	data() {
		return {
			originComponent: null
		}
	},
	methods: {
		execute(component) {
			this.originComponent = component
			window.grecaptcha.execute(this.gAssignedId)
		},
		reset() {
			this.originComponent = null
			window.grecaptcha.reset(this.gAssignedId)
		},
		callback(recaptchaToken) {
			this.$emit('recaptcha-callback', {
				token: recaptchaToken,
				originComponent: this.originComponent
			})
			
			// Reset the recaptcha widget so you can execute it again
			this.reset()
		}
	}
}
</script>