<template>
	<section class="register" id="register">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 mb-3 mb-lg-0">
					<div class="header-row">
						<h2>{{ headerText }}</h2>
					</div>
					<div>
						<button type="button" :class="{ btn: true, 'btn-outline': true, 'mb-3': true, active: activeForm === 'corporate' }" @click="activeForm = 'corporate'">I am an Industry Professional</button>
						<button type="button" :class="{ btn: true, 'btn-outline': true, 'mb-3': true, active: activeForm === 'clinical' }" @click="activeForm = 'clinical'">I am a Medical Professional</button>
					</div>
					<div class="mt-5">
						<h3 class="text-white mb-3 login-header">Already Have an Account?</h3>
						<button @click="campaign ? $modal.show('campaignLoginModal', {}) : $modal.show('loginModal')" type="button" class="btn btn-outline active"><i class="fa fa-lock"></i> Sign In</button>
					</div>
				</div>
				<div class="col-lg-6">
					<transition name="fade" mode="out-in">
						<template v-if="activeForm === 'clinical'">
							<campaign-clinical-registration v-if="campaign && campaign.fields_list.length" containerId="clinical-container" />
							<clinical-registration v-else containerId="clinical-container" />
						</template>
						<corporate-registration v-if="activeForm === 'corporate'" containerId="corporate-container" :requestedProductId="requestedProductId" />
					</transition>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapState } from 'vuex'

import CorporateRegistration from './forms/CorporateRegistration'
import ClinicalRegistration from './forms/ClinicalRegistration'
import CampaignClinicalRegistration from './forms/CampaignClinicalRegistration'

export default {
	name: 'Register',
	props: {
		headerText: {
			type: String,
			default: 'Join Market Scope Today'
		},
		defaultForm: {
			type: String,
			default: 'corporate'
		},
		requestedProductId: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			activeForm: 'corporate'
		}
	},
	computed: {
		...mapState({
			campaign: state => state.campaign
		})
	},
	mounted() {
		this.activeForm = this.defaultForm
	},
	components: {
		CorporateRegistration,
		ClinicalRegistration,
		CampaignClinicalRegistration
	}
}
</script>

<style lang="scss" scoped>
section.register {
	.card .card-header.card-left,
	.card .card-header.card-right {
		background-position: 0 -50px;
		
		&:hover {
			background-position: 0 -65px;
		}
	}
	
	.login-header {
		font-size: 2.5rem;
	}
}
</style>