var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "bio-masthead w-100" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-center p-5 bio-info",
            },
            [
              _c("div", [
                _c("h1", [_vm._v("Peter Downs")]),
                _vm._v(" "),
                _c(
                  "h3",
                  { staticClass: "my-4 font-weight-bold text-uppercase" },
                  [_vm._v("Writer & Analyst")]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/in/peterdowns/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/LinkedIn-Icon.svg"),
                          expression:
                            "require('@assets/img/about/bios/LinkedIn-Icon.svg')",
                        },
                      ],
                      staticClass: "d-block",
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-end bio-headshot",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/about/headshot-7.jpg"),
                    expression: "require('@assets/img/about/headshot-7.jpg')",
                  },
                ],
                staticClass: "img-fluid",
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c("router-link", { attrs: { to: { name: "About" }, exact: "" } }, [
              _vm._v("About"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("authored-reports", { attrs: { authorId: 1303 } }),
      _vm._v(" "),
      _c("section", { staticClass: "bio extra" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "row px-5" }, [
            _c("div", { staticClass: "col-md-6 pb-4 pb-md-0" }, [
              _c("div", { staticClass: "position-relative" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/about/bios/peter-downs/Video-Presentation-Peter-Downs-1.png"),
                      expression:
                        "require('@assets/img/about/bios/peter-downs/Video-Presentation-Peter-Downs-1.png')",
                    },
                  ],
                  staticClass: "img-fluid",
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "position-absolute",
                    staticStyle: {
                      left: "calc(50% - 31.5px)",
                      bottom: "-31.5px",
                    },
                    attrs: {
                      href: "https://vimeo.com/637624644",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/play-icon.png"),
                          expression:
                            "require('@assets/img/about/bios/play-icon.png')",
                        },
                      ],
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "text-center mx-5 mt-5" }, [
                _vm._v("Peter Downs - OIS Glaucoma Innovation Showcase 2021"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "position-relative" },
                [
                  _vm.playPresentationVideo2
                    ? [
                        _c("LazyYoutube", {
                          attrs: {
                            src: "https://youtu.be/9WIlNPtI6iw",
                            showTitle: false,
                            autoplay: true,
                            customThumbnail:
                              "https://img.youtube.com/vi/9WIlNPtI6iw/sddefault.jpg",
                          },
                        }),
                      ]
                    : [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/about/bios/peter-downs/Video-Presentation-Peter-Downs-2.png"),
                              expression:
                                "require('@assets/img/about/bios/peter-downs/Video-Presentation-Peter-Downs-2.png')",
                            },
                          ],
                          staticClass: "img-fluid",
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "position-absolute",
                            staticStyle: {
                              left: "calc(50% - 31.5px)",
                              bottom: "-31.5px",
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.playPresentationVideo2 = true
                              },
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: require("@assets/img/about/bios/play-icon.png"),
                                  expression:
                                    "require('@assets/img/about/bios/play-icon.png')",
                                },
                              ],
                            }),
                          ]
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("h5", { staticClass: "text-center mx-5 mt-5" }, [
                _vm._v("Peter Downs - All India Ophthalmological Society 2018"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("combined-registration", { attrs: { origin: "bio" } }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Phone: "),
      _c("a", { attrs: { href: "tel:+13148350600" } }, [
        _vm._v("314-835-0600"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Email: "),
      _c("a", { attrs: { href: "mailto:peter@market-scope.com" } }, [
        _vm._v("peter@market-scope.com"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Peter Downs")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bio content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row px-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mx-md-5" }, [
              _c("h4", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "Peter Downs writes about pharmaceutical, retina, and dry eye topics for Market Scope and covers OUS markets."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Peter is a member of the Society of Professional Journalists. Before joining Market Scope in 2015, he had 30 years of experience in business, health, and science reporting."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Since joining Market Scope, he has earned a reputation as the team’s “pharma expert” for his deep dives into glaucoma, retina, cataract, and dry eye pharmaceuticals. He’s also our “fearless traveler,” venturing to countries around the world to study ophthalmology at the local level and report back to other members of our team and in our comprehensive reports and monthly newsletter."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "When he’s not buried in reporting on ophthalmology, you might find Peter enjoying a brisk walk at a local St. Louis park, cycling one of the region’s many bike trails, or writing about local history."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "mt-4 mb-5" }, [_vm._v("Presentation Videos")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }