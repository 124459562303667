var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "shareArticleModal",
        height: "auto",
        scrollable: true,
        reset: true,
        clickToClose: _vm.clickToClose,
        adaptive: true,
        transition: "nice-modal-fade",
      },
      on: {
        "before-open": _vm.beforeOpen,
        opened: _vm.opened,
        closed: _vm.closed,
      },
    },
    [
      _c(
        "form",
        {
          directives: [
            {
              name: "promise-btn",
              rawName: "v-promise-btn",
              value: { action: "submit" },
              expression: "{ action: 'submit' }",
            },
          ],
          attrs: { novalidate: "novalidate", role: "form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.share.apply(null, arguments)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.$modal.hide("shareArticleModal")
            },
          },
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v("Share Article Sample"),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$modal.hide("shareArticleModal")
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body pb-2" }, [
            _c("div", { staticClass: "form-row bg-light" }, [
              _c("div", { staticClass: "col-md-12 mb-1" }, [_vm._v("To:")]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6 mb-1" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.toName,
                      expression: "form.toName",
                    },
                  ],
                  ref: "toName",
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "to-name",
                    placeholder: "Enter recipient's name",
                  },
                  domProps: { value: _vm.form.toName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "toName", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6 mb-1" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.toEmail,
                      expression: "form.toEmail",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "to-email",
                    placeholder: "Enter recipient's email",
                  },
                  domProps: { value: _vm.form.toEmail },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "toEmail", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row bg-light pb-1 mt-3" }, [
              _c("div", { staticClass: "col-md-12 mb-1" }, [_vm._v("From:")]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6 mb-0" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.fromName,
                      expression: "form.fromName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "from-name",
                    placeholder: "Enter your name",
                  },
                  domProps: { value: _vm.form.fromName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "fromName", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6 mb-0" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.fromEmail,
                      expression: "form.fromEmail",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "from-email",
                    placeholder: "Enter your email",
                  },
                  domProps: { value: _vm.form.fromEmail },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "fromEmail", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-12" }, [
                _c("small", { staticClass: "form-text text-muted" }, [
                  _vm._v("The email will be sent by us, on behalf of you."),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mt-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.message,
                    expression: "form.message",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  name: "message",
                  placeholder: "Add a message (optional)",
                  id: "message",
                },
                domProps: { value: _vm.form.message },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "message", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-0" }, [
              _c("small", { staticClass: "form-notice" }, [
                _vm._v("This site is protected by reCAPTCHA and the Google "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://policies.google.com/privacy",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Privacy Policy")]
                ),
                _vm._v(" and "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://policies.google.com/terms",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Terms of Service")]
                ),
                _vm._v(" apply."),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm.form.status
                ? _c("div", {
                    staticClass: "mx-auto",
                    domProps: { innerHTML: _vm._s(_vm.form.status) },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.shared
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$modal.hide("shareArticleModal")
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("Share")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }