var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "reportVideoModal",
        height: "auto",
        scrollable: true,
        reset: true,
        clickToClose: true,
        adaptive: true,
        transition: "nice-modal-fade",
      },
      on: { "before-open": _vm.beforeOpen, closed: _vm.closed },
    },
    [
      _c("div", { staticClass: "modal-header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.header)),
          _c("br"),
          _c("strong", [_vm._v(_vm._s(_vm.subheader))]),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$modal.hide("reportVideoModal")
              },
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "embed-responsive embed-responsive-16by9" }, [
          _vm.videoId
            ? _c("iframe", {
                staticClass: "embed-responsive-item",
                attrs: {
                  src:
                    "https://www.youtube.com/embed/" +
                    _vm.videoId +
                    "?autoplay=1&modestbranding=1&controls=0",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: "",
                },
              })
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }