var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("h4", [_vm._v("Market Scope")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "h6",
      [
        _c("router-link", { attrs: { to: { name: "Careers" } } }, [
          _c("strong", [_vm._v("Careers")]),
          _vm._v(" at Market Scope"),
        ]),
        _vm._v(" | "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.market-scope.com/pages/privacy-policy",
              target: "_blank",
            },
          },
          [_vm._v("Privacy Policy")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "©" +
          _vm._s(new Date().getFullYear()) +
          " Market Scope, LLC. All Rights reserved."
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("address", { staticClass: "mb-2" }, [
      _vm._v(
        "13421 Manchester Road, Suite 101 | St. Louis, MO 63131 | Phone: "
      ),
      _c("strong", [
        _c("a", { attrs: { href: "tel:+13148350600" } }, [
          _vm._v("314-835-0600"),
        ]),
      ]),
      _vm._v(" | Email: "),
      _c("strong", [
        _c("a", { attrs: { href: "mailto:info@market-scope.com" } }, [
          _vm._v("info@market-scope.com"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }