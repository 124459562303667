<template>
	<div>
		<navbar :subpage="true" />
		<header class="surveys-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Survey Opportunities</span>
			</li>
		</ol>
		<section class="surveys">
			<div class="container header-row">
				<div>
					<div class="mx-auto text-center mb-5">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-05.svg')" width="70" height="70" class="d-block mx-auto" style="max-width: 70px; width: auto; height: auto;" />
						<h1 class="section-heading">Complete Surveys. Gain Insights.</h1>
					</div>
				</div>
			</div>
			<div class="container mt-3">
				<div class="row mb-3">
					<div class="col">
						<p>Since 2000, Market Scope has been a confidential and unbiased third party that provides ophthalmologists with real-time trends curated by a robust team of data and industry experts through surveys on practice of medicine techniques, procedure volumes, preferences for new technology, marketing strategies, and much more.</p>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Why participate?</h3>
							</div>
							<div class="card-body pt-2">
								<ul class="list-unstyled">
									<li class="media">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-01.svg')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">Industry Insights</h5>
											Gain access to and stay up to date on industry insights and market trends.
										</div>
									</li>
									<li class="media my-4">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-02.svg')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">Make Your Voice Heard</h5>
											Companies rely on Market Scope to provide guidance on the needs of ophthalmologists, so your participation can often help guide product development, messaging, pricing, and access.
										</div>
									</li>
									<li class="media">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-03.svg')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">Incentives</h5>
											Most surveys include a donation to a charitable organization or an e-gift card.
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">What makes Market Scope unique?</h3>
							</div>
							<div class="card-body pt-2">
								<ul class="list-unstyled">
									<li class="media">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-04.svg')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">Analysis</h5>
											We take careful steps to cross-analyze and uncover trends beyond the individual data points.
										</div>
									</li>
									<li class="media my-4">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-05.svg')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">History &amp; Repetition</h5>
											Many of our data points have extensive historical analysis. Our quarterly survey process also ensures that we are actively capturing market shifts.
										</div>
									</li>
									<li class="media">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-06.svg')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">Content</h5>
											We actively update our questions to ensure that we are asking about topics of highest interest and relevance to the business of ophthalmology.
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-11 offset-md-1">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Will your data be shared with other colleagues/companies?</h3>
							</div>
							<div class="card-body pt-2">
								<div class="media">
									<img class="align-self-start mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-07.png')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;">
									<div class="media-body">
										<h5 class="my-0">Your Data is Confidential</h5>
										<p>Market Scope only reports aggregated data in our survey reports that are shared with participants or included in the market reports that we publish for purchase.</p>
										<p>Your individual responses will always be de-identified before summary analysis to safeguard your anonymity.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col text-center">
						<h3 class="font-weight-bold">Ready to get started?</h3>
					</div>
				</div>
				<div class="row my-3 align-items-center">
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-body">
								<ul class="list-unstyled">
									<li class="media mb-5">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-08.svg')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">Register a Free Account</h5>
											A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard.
										</div>
									</li>
									<li class="media my-5">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-09.svg')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">Contact Us</h5>
											Start a chart using the chat box at the bottom of this page or email us at <a href="mailto:info@market-scope.com">info@market-scope.com</a>.
										</div>
									</li>
									<li class="media mt-5">
										<img class="mr-3" v-lazy="require('@assets/img/icons/CareCredit-Icons-10.png')" width="80" height="80" style="max-width: 80px; width: auto; height: auto;" />
										<div class="media-body">
											<h5 class="my-0">Need More Data?</h5>
											Click the below button to learn about metro-level opportunity analyses available to CareCredit enrolled providers.
										</div>
									</li>
								</ul>
								<div class="text-center mt-4">
									<router-link :to="{ name: 'CarecreditAtlases' }" class="btn btn-primary btn-sm">Learn More</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card bg-light">
							<div class="card-body">
								<form method="post" v-promise-btn="{ action: 'submit' }" @submit.prevent="submit" accept-charset="utf-8" novalidate id="carecredit-registration-form" ref="carecreditRegistrationForm" role="form" action="/clinical-groups/register-landing/carecredit">
									<div style="display: none;">
										<input type="hidden" name="_method" value="POST" class="form-control" />
										<input type="hidden" name="_csrfToken" autocomplete="off" :value="csrfToken" />
										<input type="hidden" name="g_recaptcha_response" autocomplete="off" :value="gRecaptchaToken" />
									</div>
									<div class="form-row">
										<div class="col">
											<div class="form-group text required">
												<input type="text" v-model.trim="$v.full_name.$model" name="full_name" placeholder="Full Name" id="clinical-full-name" required maxlength="50" :class="{ 'form-control': true, 'is-invalid': $v.full_name.$dirty && $v.full_name.$error }" />
												<div class="invalid-feedback" v-if="$v.full_name.$dirty && !$v.full_name.required">Full name is required.</div>
												<div class="invalid-feedback" v-if="$v.full_name.$dirty && !$v.full_name.minLength">Full name has a minimum of 3 characters.</div>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="col">
											<div class="form-group email required">
												<input type="email" v-model.trim="$v.email.$model" name="email" placeholder="Email" id="clinical-email" required maxlength="254" :class="{ 'form-control': true, 'is-invalid': $v.email.$dirty && $v.email.$error }" />
												<div class="invalid-feedback" v-if="$v.email.$dirty && !$v.email.required">Email address is required.</div>
												<div class="invalid-feedback" v-if="$v.email.$dirty && !$v.email.email">Email address has to be valid.</div>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="col">
											<div class="form-group text required">
												<input type="text" v-model.trim="$v.user_attribute.practice.name.$model" name="user_attribute[practice][name]" placeholder="Practice Name" id="clinical-practice-name" required maxlength="255" :class="{ 'form-control': true, 'is-invalid': $v.user_attribute.practice.name.$dirty && $v.user_attribute.practice.name.$error }" />
												<div class="invalid-feedback" v-if="$v.user_attribute.practice.name.$dirty && !$v.user_attribute.practice.name.required">Practice name is required.</div>
												<div class="invalid-feedback" v-if="$v.user_attribute.practice.name.$dirty && !$v.user_attribute.practice.name.minLength">Practice name has a minimum of 3 characters.</div>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="col">
											<div class="form-group select required">
												<label for="user-attribute-practice-location-id" class="control-label">Country</label>
												<multiselect
													v-model.trim="selectedCountry"
													:options="countryOptions"
													placeholder="Select your country..."
													label="alias"
													track-by="id"
													deselect-label=""
													aria-invalid="false"
													id="user-attribute-practice-location-id"
													required
													:class="{ 'is-invalid': $v.user_attribute.practice.location_id.$dirty && $v.user_attribute.practice.location_id.$error }"
												>
													<span slot="noResult">No matching countries found.</span>
												</multiselect>
												<input type="hidden" name="user_attribute[practice][location_id]" :value="user_attribute.practice.location_id" />
												<div class="invalid-feedback" v-if="$v.user_attribute.practice.location_id.$dirty && !$v.user_attribute.practice.location_id.required">Country is required.</div>
											</div>
										</div>
									</div>
									<div v-if="user_attribute.practice.location_id === 1" class="form-row">
										<div class="col">
											<div class="form-group select required">
												<label for="user-attribute-practice-state" class="control-label">State</label>
												<select v-model="user_attribute.practice.state" :class="{ 'form-control': true, 'is-invalid': $v.user_attribute.practice.state.$dirty && $v.user_attribute.practice.state.$error }" name="user_attribute[practice][state]" id="user-attribute-practice-state">
													<option value="AL">Alabama</option>
													<option value="AK">Alaska</option>
													<option value="AZ">Arizona</option>
													<option value="AR">Arkansas</option>
													<option value="CA">California</option>
													<option value="CO">Colorado</option>
													<option value="CT">Connecticut</option>
													<option value="DE">Delaware</option>
													<option value="FL">Florida</option>
													<option value="GA">Georgia</option>
													<option value="HI">Hawaii</option>
													<option value="ID">Idaho</option>
													<option value="IL">Illinois</option>
													<option value="IN">Indiana</option>
													<option value="IA">Iowa</option>
													<option value="KS">Kansas</option>
													<option value="KY">Kentucky</option>
													<option value="LA">Louisiana</option>
													<option value="ME">Maine</option>
													<option value="MD">Maryland</option>
													<option value="MA">Massachusetts</option>
													<option value="MI">Michigan</option>
													<option value="MN">Minnesota</option>
													<option value="MS">Mississippi</option>
													<option value="MO">Missouri</option>
													<option value="MT">Montana</option>
													<option value="NE">Nebraska</option>
													<option value="NV">Nevada</option>
													<option value="NH">New Hampshire</option>
													<option value="NJ">New Jersey</option>
													<option value="NM">New Mexico</option>
													<option value="NY">New York</option>
													<option value="NC">North Carolina</option>
													<option value="ND">North Dakota</option>
													<option value="OH">Ohio</option>
													<option value="OK">Oklahoma</option>
													<option value="OR">Oregon</option>
													<option value="PA">Pennsylvania</option>
													<option value="RI">Rhode Island</option>
													<option value="SC">South Carolina</option>
													<option value="SD">South Dakota</option>
													<option value="TN">Tennessee</option>
													<option value="TX">Texas</option>
													<option value="UT">Utah</option>
													<option value="VT">Vermont</option>
													<option value="VA">Virginia</option>
													<option value="WA">Washington</option>
													<option value="WV">West Virginia</option>
													<option value="WI">Wisconsin</option>
													<option value="WY">Wyoming</option>
												</select>
												<div class="invalid-feedback" v-if="$v.user_attribute.practice.state.$dirty && (!$v.user_attribute.practice.state.required || !$v.user_attribute.practice.state.minLength || !$v.user_attribute.practice.state.maxLength)">State is required.</div>
											</div>
										</div>
									</div>
									<div v-if="user_attribute.practice.location_id === 1" class="row mb-3">
										<div class="col">
											<div class="card bg-white">
												<div class="card-body pt-3 pb-1">
													<div class="required">
														<label class="control-label">Practice Role</label>
														<div class="form-group select">
															<select v-model="user_attribute.practice_role_id" @change="onChangePracticeRole" :class="{ 'form-control': true, 'is-invalid': $v.user_attribute.practice_role_id.$dirty && $v.user_attribute.practice_role_id.$error }" name="user_attribute[practice_role_id]" id="user-attribute-practice-role-id">
																<option v-for="practiceRoleOption in practiceRoleOptions" :value="practiceRoleOption.id">{{ practiceRoleOption.label }}</option>
															</select>
															<div class="invalid-feedback" v-if="$v.user_attribute.practice_role_id.$dirty && (!$v.user_attribute.practice_role_id.required || !$v.user_attribute.practice_role_id.numeric)">Practice role is required.</div>
														</div>
													</div>
													<div v-if="npiRequired" class="form-group text">
														<input type="text" v-model.trim="$v.user_attribute.npi.$model" name="user_attribute[npi]" placeholder="NPI" id="npi" maxlength="10" :class="{ 'form-control': true, 'is-invalid': $v.user_attribute.npi.$dirty && $v.user_attribute.npi.$error }" />
														<div class="invalid-feedback" v-if="$v.user_attribute.npi.$dirty && !$v.user_attribute.npi.required">NPI is required.</div>
														<div class="invalid-feedback" v-if="$v.user_attribute.npi.$dirty && !$v.user_attribute.npi.numeric">NPI can only contain numbers.</div>
														<div class="invalid-feedback" v-if="$v.user_attribute.npi.$dirty && $v.user_attribute.npi.numeric && (!$v.user_attribute.npi.minLength || !$v.user_attribute.npi.maxLength)">Invalid NPI.</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="col-6">
											<div class="form-group password required">
												<input type="password" v-model.trim="$v.passwd.$model" name="passwd" placeholder="Enter a password" id="clinical-password" required :class="{ 'form-control': true, 'is-invalid': $v.passwd.$dirty && $v.passwd.$error }" />
												<div class="invalid-feedback" v-if="$v.passwd.$dirty && !$v.passwd.required">Password is required.</div>
												<div class="invalid-feedback" v-if="$v.passwd.$dirty && !$v.passwd.minLength">Password has a minimum of 6 characters.</div>
											</div>
										</div>
										<div class="col-6">
											<div class="form-group password">
												<input type="password" v-model.trim="$v.passwd_confirm.$model" name="passwd_confirm" placeholder="Re-enter your password" id="clinical-password-confirm" :class="{ 'form-control': true, 'is-invalid': $v.passwd_confirm.$dirty && $v.passwd_confirm.$error }" />
												<div class="invalid-feedback" v-if="$v.passwd_confirm.$dirty && !$v.passwd_confirm.sameAsPasswd">Password re-entry has to match.</div>
											</div>
										</div>
									</div>
									<div>
										<button type="submit" class="btn btn-large btn-success">Register</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<footer-section />
	</div>
</template>

<script>
import { email, minLength, maxLength, numeric, required, sameAs } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

import Navbar from './Navbar'
import FooterSection from './FooterSection'

export default {
	name: 'CarecreditSurveys',
	metaInfo: {
		title: 'Survey Opportunities',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/carecredit-surveys',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/Header-Atlases.jpg',
				vmid: 'og:image'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/carecredit-surveys',
				vmid: 'canonical'
			}
		]
	},
	data() {
		return {
			countryOptions: [],
			practiceRoleOptions: [],
			email: '',
			full_name: '',
			passwd: '',
			passwd_confirm: '',
			user_attribute: {
				practice_role_id: null,
				npi: null,
				practice: {
					name: '',
					location_id: 1,
					state: ''
				}
			},
			csrfToken: '',
			gRecaptchaToken: '',
			isSubmitting: false,
			unsubscribe: null
		}
	},
	computed: {
		selectedCountry: {
			get() {
				return this.countryOptions.find(countryOption => countryOption.id === this.user_attribute.practice.location_id)
			},
			set(v) {
				if (v) {
					this.$set(this.user_attribute.practice, 'location_id', v.id)
				}
			}
		},
		npiRequired() {
			const selectedPracticeRole = this.practiceRoleOptions.find(practiceRoleOption => practiceRoleOption.id === this.user_attribute.practice_role_id)
			return selectedPracticeRole && selectedPracticeRole.rules && !!parseInt(selectedPracticeRole.rules.npi_required, 10);
		}
	},
	validations() {
		const validations = {
			email: {
				required,
				email
			},
			full_name: {
				required,
				minLength: minLength(3)
			},
			passwd: {
				required,
				minLength: minLength(6)
			},
			passwd_confirm: {
				sameAsPasswd: sameAs('passwd')
			},
			user_attribute: {
				practice_role_id: {},
				npi: {},
				practice: {
					name: {
						required,
						minLength: minLength(3)
					},
					location_id: {
						required
					},
					state: {}
				}
			}
		}
		
		if (this.user_attribute.practice.location_id === 1) {
			validations.user_attribute.practice.state = {
				required,
				minLength: minLength(2),
				maxLength: maxLength(2)
			}
			validations.user_attribute.practice_role_id = {
				required,
				numeric
			}
			
			const selectedPracticeRole = this.practiceRoleOptions.find(practiceRoleOption => practiceRoleOption.id === this.user_attribute.practice_role_id)
			if (selectedPracticeRole && selectedPracticeRole.rules && !!parseInt(selectedPracticeRole.rules.npi_required, 10)) {
				validations.user_attribute.npi = {
					required,
					numeric,
					minLength: minLength(10),
					maxLength: maxLength(10)
				}
			}
		}
		
		return validations
	},
	mounted() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'SET_INVISIBLE_RECAPTCHA' && state.invisibleRecaptcha.originComponent === this.$options.name) {
				this.recaptchaCallback(state.invisibleRecaptcha.token);
				
				this.unsubscribe()
			}
		})
		
		this.$http.get('funcs/countries')
			.then(response => {
				this.countryOptions = response.data.result.countries
			})
		this.$http.get('funcs/specialties')
			.then(response => {
				this.practiceRoleOptions = response.data.result.specialties
			})
	},
	beforeDestroy() {
		typeof this.unsubscribe === 'function' && this.unsubscribe()
	},
	watch: {
		'user_attribute.practice.location_id': function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$set(this.user_attribute.practice, 'state', '')
			}
		}
	},
	methods: {
		submit() {
			this.$v.$touch()
			
			if (!this.$v.$invalid) {
				this.isSubmitting = true
				
				this.$root.$children[0].$refs.invisibleRecaptcha.execute(this.$options.name)
			}
		},
		recaptchaCallback(token) {
			this.gRecaptchaToken = token
			
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.carecreditRegistrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		}
	},
	components: {
		Multiselect,
		Navbar,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.surveys-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/Header-Atlases.jpg'), $theme-secondary;
	background: url('~@assets/img/Header-Atlases.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: center;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position: 0 60%;
	}
}

section.surveys {
	.section-heading {
		color: #0a5384;
		margin-bottom: 0;
	}
	
	.header-row p {
		color: $gray-light;
	}
	
	h3 {
		color: #0a5384;
	}
	
	.card {
		p,
		li {
			font-size: 1rem;
		}
		
		a:not(.btn) {
			color: #2fbbcc;
		}
		
		.media-body h5 {
			color: #0a5384;
			font-weight: bold;
			font-size: 1.1rem;
		}
	}
}
</style>