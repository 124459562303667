var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "requestSampleModal",
        height: "auto",
        scrollable: true,
        reset: true,
        clickToClose: _vm.clickToClose,
        adaptive: true,
        transition: "nice-modal-fade",
      },
      on: {
        "before-open": _vm.beforeOpen,
        opened: _vm.opened,
        closed: _vm.closed,
      },
    },
    [
      _c(
        "form",
        {
          directives: [
            {
              name: "promise-btn",
              rawName: "v-promise-btn",
              value: { action: "submit" },
              expression: "{ action: 'submit' }",
            },
          ],
          attrs: { novalidate: "novalidate", role: "form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.request.apply(null, arguments)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.$modal.hide("requestSampleModal")
            },
          },
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v("Request a Sample"),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$modal.hide("requestSampleModal")
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body pb-2" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "to-name" } },
                [_vm._v("Name:")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.toName,
                    expression: "form.toName",
                  },
                ],
                ref: "toName",
                staticClass: "form-control",
                attrs: { type: "text", name: "to-name" },
                domProps: { value: _vm.form.toName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "toName", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "to-name" } },
                [_vm._v("Company:")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.toCompany,
                    expression: "form.toCompany",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "to-company" },
                domProps: { value: _vm.form.toCompany },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "toCompany", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "to-email" } },
                [_vm._v("Email:")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.toEmail,
                    expression: "form.toEmail",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "to-email" },
                domProps: { value: _vm.form.toEmail },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "toEmail", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "to-phone" } },
                [
                  _vm._v("Phone # "),
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v("(optional)"),
                  ]),
                  _vm._v(":"),
                ]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.toPhone,
                    expression: "form.toPhone",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "tel", name: "to-phone" },
                domProps: { value: _vm.form.toPhone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "toPhone", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-0" }, [
              _c("small", { staticClass: "form-notice" }, [
                _vm._v(
                  "Any submitted contact information is confidential and will not be given to any third parties."
                ),
                _c("br"),
                _vm._v("This site is protected by reCAPTCHA and the Google "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://policies.google.com/privacy",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Privacy Policy")]
                ),
                _vm._v(" and "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://policies.google.com/terms",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Terms of Service")]
                ),
                _vm._v(" apply."),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm.form.status
                ? _c("div", {
                    staticClass: "mx-auto",
                    domProps: { innerHTML: _vm._s(_vm.form.status) },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.requested
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$modal.hide("requestSampleModal")
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("Request")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }