var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "register" }, [
    _c("div", { staticClass: "container", attrs: { id: "register" } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col text-center mb-3" }, [
          _c("h3", [_vm._v("Join Market Scope Today")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center align-items-end mt-3 mb-4",
            },
            [
              _c(
                "div",
                {
                  class: {
                    "position-relative": true,
                    "mr-3": true,
                    selected: _vm.registerType === "corporate",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-register py-2 px-3",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.registerType = "corporate"
                        },
                      },
                    },
                    [_vm._v("I am an Industry Professional")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn-bottom-arrow" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "position-relative": true,
                    selected: _vm.registerType === "clinical",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-register py-2 px-3",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.registerType = "clinical"
                        },
                      },
                    },
                    [_vm._v("I am a Medical Professional")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn-bottom-arrow" }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.registerType === "corporate"
            ? _c("p", [
                _vm._v(
                  "A corporate account gives you access to licensed reports and subscriptions, the latest news, a personalized dashboard, and weekly emails with news and data."
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.registerType === "clinical"
            ? _c("p", [
                _vm._v(
                  "A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard."
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card shadow-lg mx-sm-0 mx-md-5" }, [
        _c("div", { staticClass: "card-body" }, [
          _c(
            "form",
            {
              directives: [
                {
                  name: "promise-btn",
                  rawName: "v-promise-btn",
                  value: {
                    action:
                      _vm.registerMethod === "methodRegister"
                        ? "submit"
                        : "login",
                  },
                  expression:
                    "{ action: registerMethod === 'methodRegister' ? 'submit' : 'login' }",
                },
              ],
              ref: "registrationForm",
              attrs: {
                method: "post",
                "accept-charset": "utf-8",
                novalidate: "",
                id: "registration-form",
                role: "form",
                action:
                  _vm.registerMethod === "methodRegister"
                    ? "/users/" +
                      (_vm.registerType === "corporate" ? "corporate-" : "") +
                      "register"
                    : "/users/login",
              },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.registerMethod === "methodRegister"
                    ? _vm.submit()
                    : _vm.login()
                },
              },
            },
            [
              _c("div", { staticStyle: { display: "none" } }, [
                _c("input", {
                  staticClass: "form-control",
                  attrs: { type: "hidden", name: "_method", value: "POST" },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "_csrfToken",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.csrfToken },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "g_recaptcha_response",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.gRecaptchaToken },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "origin" },
                  domProps: { value: _vm.origin },
                }),
                _vm._v(" "),
                _vm.registerType === "clinical"
                  ? _c(
                      "select",
                      { attrs: { multiple: "", name: "tag_list[]" } },
                      _vm._l(_vm.tag_list, function (tag) {
                        return _c(
                          "option",
                          { attrs: { selected: "" }, domProps: { value: tag } },
                          [_vm._v(_vm._s(tag))]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col text-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "custom-control custom-radio custom-control-inline",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerMethod,
                            expression: "registerMethod",
                          },
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "radio",
                          value: "methodRegister",
                          id: "methodRegister",
                        },
                        domProps: {
                          checked: _vm._q(_vm.registerMethod, "methodRegister"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.registerMethod = "methodRegister"
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "methodRegister" },
                        },
                        [_vm._v("Register an account")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "custom-control custom-radio custom-control-inline",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerMethod,
                            expression: "registerMethod",
                          },
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "radio",
                          value: "methodSignIn",
                          id: "methodSignIn",
                        },
                        domProps: {
                          checked: _vm._q(_vm.registerMethod, "methodSignIn"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.registerMethod = "methodSignIn"
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "methodSignIn" },
                        },
                        [_vm._v("Sign in")]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.registerMethod === "methodRegister"
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("label", { attrs: { for: "inputFullName" } }, [
                        _vm._v("Full Name*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.$v.registerForm.full_name.$model,
                            expression: "$v.registerForm.full_name.$model",
                            modifiers: { trim: true },
                          },
                        ],
                        class: {
                          "form-control": true,
                          "is-invalid":
                            _vm.$v.registerForm.full_name.$dirty &&
                            _vm.$v.registerForm.full_name.$error,
                        },
                        attrs: {
                          type: "text",
                          name: "full_name",
                          placeholder: "Full Name",
                          id: "inputFullName",
                          required: "",
                          maxlength: "50",
                        },
                        domProps: {
                          value: _vm.$v.registerForm.full_name.$model,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.registerForm.full_name,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.registerForm.full_name.$dirty &&
                      !_vm.$v.registerForm.full_name.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Full name is required."),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$v.registerForm.full_name.$dirty &&
                      !_vm.$v.registerForm.full_name.minLength
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Full name has a minimum of 3 characters."),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.registerType === "corporate"
                      ? _c(
                          "div",
                          { staticClass: "form-group col-md-3" },
                          [
                            _c("label", { attrs: { for: "inputCompany" } }, [
                              _vm._v("Company*"),
                            ]),
                            _vm._v(" "),
                            _c("vue-bootstrap-typeahead", {
                              attrs: {
                                placeholder: "Company Name",
                                data: _vm.companyNames,
                                serializer: (v) => v.name,
                                id: "inputCompany",
                                inputClass:
                                  _vm.$v.companyName.$dirty &&
                                  _vm.$v.companyName.$error
                                    ? "is-invalid"
                                    : "",
                              },
                              on: {
                                hit: _vm.selectCompany,
                                input: _vm.deselectCompany,
                              },
                              model: {
                                value: _vm.$v.companyName.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.companyName,
                                    "$model",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "$v.companyName.$model",
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              ref: "corporate_company_name",
                              attrs: {
                                type: "hidden",
                                name: "companies[0][name]",
                                value: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              ref: "corporate_company_id",
                              attrs: {
                                type: "hidden",
                                name: "companies[0][id]",
                                value: "",
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.companyName.$dirty &&
                            !_vm.$v.companyName.required
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "invalid-feedback",
                                    staticStyle: { display: "block" },
                                  },
                                  [_vm._v("Company name is required.")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.registerType === "corporate"
                      ? _c("div", { staticClass: "form-group col-md-3" }, [
                          _c("label", { attrs: { for: "inputJobTitle" } }, [
                            _vm._v("Job Title"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "user_attribute[title]",
                              id: "inputJobTitle",
                              placeholder: "Job Title",
                              maxlength: "255",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.registerType === "clinical"
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("label", { attrs: { for: "inputPracticeName" } }, [
                            _vm._v("Practice Name*"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value:
                                  _vm.$v.registerForm.user_attribute.practice
                                    .name.$model,
                                expression:
                                  "$v.registerForm.user_attribute.practice.name.$model",
                                modifiers: { trim: true },
                              },
                            ],
                            class: {
                              "form-control": true,
                              "is-invalid":
                                _vm.$v.registerForm.user_attribute.practice.name
                                  .$dirty &&
                                _vm.$v.registerForm.user_attribute.practice.name
                                  .$error,
                            },
                            attrs: {
                              type: "text",
                              name: "user_attribute[practice][name]",
                              id: "inputPracticeName",
                              placeholder: "Practice Name",
                              required: "",
                              maxlength: "255",
                            },
                            domProps: {
                              value:
                                _vm.$v.registerForm.user_attribute.practice.name
                                  .$model,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.registerForm.user_attribute.practice
                                    .name,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.registerForm.user_attribute.practice.name
                            .$dirty &&
                          !_vm.$v.registerForm.user_attribute.practice.name
                            .required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Practice name is required."),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$v.registerForm.user_attribute.practice.name
                            .$dirty &&
                          !_vm.$v.registerForm.user_attribute.practice.name
                            .minLength
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "Practice name has a minimum of 3 characters."
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("label", { attrs: { for: "inputEmail" } }, [
                    _vm._v("Email*"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.registerForm.email.$model,
                        expression: "$v.registerForm.email.$model",
                        modifiers: { trim: true },
                      },
                    ],
                    class: {
                      "form-control": true,
                      "is-invalid":
                        _vm.$v.registerForm.email.$dirty &&
                        _vm.$v.registerForm.email.$error,
                    },
                    attrs: {
                      type: "email",
                      name:
                        _vm.registerMethod === "methodRegister"
                          ? "email"
                          : "login_email",
                      placeholder: "Email",
                      id: "inputEmail",
                      required: "",
                      maxlength: "254",
                    },
                    domProps: { value: _vm.$v.registerForm.email.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$v.registerForm.email,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.registerForm.email.$dirty &&
                  !_vm.$v.registerForm.email.required
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("Email address is required."),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$v.registerForm.email.$dirty &&
                  !_vm.$v.registerForm.email.email
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("Email address has to be valid."),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "form-group": true,
                      "col-md-3": _vm.registerMethod === "methodRegister",
                      "col-md-6": _vm.registerMethod === "methodSignIn",
                    },
                  },
                  [
                    _c("label", { attrs: { for: "inputPassword" } }, [
                      _vm._v("Password*"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.$v.registerForm.passwd.$model,
                          expression: "$v.registerForm.passwd.$model",
                          modifiers: { trim: true },
                        },
                      ],
                      class: {
                        "form-control": true,
                        "is-invalid":
                          _vm.$v.registerForm.passwd.$dirty &&
                          _vm.$v.registerForm.passwd.$error,
                      },
                      attrs: {
                        type: "password",
                        name:
                          _vm.registerMethod === "methodRegister"
                            ? "passwd"
                            : "password",
                        placeholder: "Enter a password",
                        id: "inputPassword",
                        required: "",
                      },
                      domProps: { value: _vm.$v.registerForm.passwd.$model },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.$v.registerForm.passwd,
                            "$model",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.registerForm.passwd.$dirty &&
                    !_vm.$v.registerForm.passwd.required
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("Password is required."),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$v.registerForm.passwd.$dirty &&
                    !_vm.$v.registerForm.passwd.minLength
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("Password has a minimum of 6 characters."),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.registerMethod === "methodRegister"
                  ? _c("div", { staticClass: "form-group col-md-3" }, [
                      _c("label", { attrs: { for: "inputReenterPassword" } }, [
                        _vm._v("Re-enter Password*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.$v.registerForm.passwd_confirm.$model,
                            expression: "$v.registerForm.passwd_confirm.$model",
                            modifiers: { trim: true },
                          },
                        ],
                        class: {
                          "form-control": true,
                          "is-invalid":
                            _vm.$v.registerForm.passwd_confirm.$dirty &&
                            _vm.$v.registerForm.passwd_confirm.$error,
                        },
                        attrs: {
                          type: "password",
                          name: "passwd_confirm",
                          placeholder: "Re-enter your password",
                          id: "inputReenterPassword",
                        },
                        domProps: {
                          value: _vm.$v.registerForm.passwd_confirm.$model,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.registerForm.passwd_confirm,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.registerForm.passwd_confirm.$dirty &&
                      !_vm.$v.registerForm.passwd_confirm.sameAsPasswd
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Password re-entry has to match."),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.registerMethod === "methodRegister" &&
              _vm.registerType === "clinical"
                ? _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      {
                        class: {
                          "form-group": true,
                          "col-md-6":
                            _vm.registerForm.user_attribute.practice
                              .location_id === 1,
                          col:
                            _vm.registerForm.user_attribute.practice
                              .location_id !== 1,
                        },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "inputPracticeLocationId" },
                          },
                          [_vm._v("Country*")]
                        ),
                        _vm._v(" "),
                        _c(
                          "multiselect",
                          {
                            class: {
                              "is-invalid":
                                _vm.$v.registerForm.user_attribute.practice
                                  .location_id.$dirty &&
                                _vm.$v.registerForm.user_attribute.practice
                                  .location_id.$error,
                            },
                            attrs: {
                              options: _vm.countryOptions,
                              placeholder: "Select your country...",
                              label: "alias",
                              "track-by": "id",
                              "deselect-label": "",
                              "aria-invalid": "false",
                              id: "inputPracticeLocationId",
                              required: "",
                            },
                            model: {
                              value: _vm.selectedCountry,
                              callback: function ($$v) {
                                _vm.selectedCountry =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "selectedCountry",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "noResult" }, slot: "noResult" },
                              [_vm._v("No matching countries found.")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "user_attribute[practice][location_id]",
                          },
                          domProps: {
                            value:
                              _vm.registerForm.user_attribute.practice
                                .location_id,
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.registerForm.user_attribute.practice.location_id
                          .$dirty &&
                        !_vm.$v.registerForm.user_attribute.practice.location_id
                          .required
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("Country is required."),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.registerForm.user_attribute.practice.location_id === 1
                      ? _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "inputPracticeState" },
                            },
                            [_vm._v("State")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.registerForm.user_attribute.practice
                                      .state,
                                  expression:
                                    "registerForm.user_attribute.practice.state",
                                },
                              ],
                              class: {
                                "form-control": true,
                                "is-invalid":
                                  _vm.$v.registerForm.user_attribute.practice
                                    .state.$dirty &&
                                  _vm.$v.registerForm.user_attribute.practice
                                    .state.$error,
                              },
                              attrs: {
                                name: "user_attribute[practice][state]",
                                id: "inputPracticeState",
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.registerForm.user_attribute.practice,
                                    "state",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "AL" } }, [
                                _vm._v("Alabama"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "AK" } }, [
                                _vm._v("Alaska"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "AZ" } }, [
                                _vm._v("Arizona"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "AR" } }, [
                                _vm._v("Arkansas"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "CA" } }, [
                                _vm._v("California"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "CO" } }, [
                                _vm._v("Colorado"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "CT" } }, [
                                _vm._v("Connecticut"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "DE" } }, [
                                _vm._v("Delaware"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "FL" } }, [
                                _vm._v("Florida"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "GA" } }, [
                                _vm._v("Georgia"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "HI" } }, [
                                _vm._v("Hawaii"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "ID" } }, [
                                _vm._v("Idaho"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "IL" } }, [
                                _vm._v("Illinois"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "IN" } }, [
                                _vm._v("Indiana"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "IA" } }, [
                                _vm._v("Iowa"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "KS" } }, [
                                _vm._v("Kansas"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "KY" } }, [
                                _vm._v("Kentucky"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "LA" } }, [
                                _vm._v("Louisiana"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "ME" } }, [
                                _vm._v("Maine"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MD" } }, [
                                _vm._v("Maryland"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MA" } }, [
                                _vm._v("Massachusetts"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MI" } }, [
                                _vm._v("Michigan"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MN" } }, [
                                _vm._v("Minnesota"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MS" } }, [
                                _vm._v("Mississippi"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MO" } }, [
                                _vm._v("Missouri"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MT" } }, [
                                _vm._v("Montana"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "NE" } }, [
                                _vm._v("Nebraska"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "NV" } }, [
                                _vm._v("Nevada"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "NH" } }, [
                                _vm._v("New Hampshire"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "NJ" } }, [
                                _vm._v("New Jersey"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "NM" } }, [
                                _vm._v("New Mexico"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "NY" } }, [
                                _vm._v("New York"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "NC" } }, [
                                _vm._v("North Carolina"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "ND" } }, [
                                _vm._v("North Dakota"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "OH" } }, [
                                _vm._v("Ohio"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "OK" } }, [
                                _vm._v("Oklahoma"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "OR" } }, [
                                _vm._v("Oregon"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "PA" } }, [
                                _vm._v("Pennsylvania"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "RI" } }, [
                                _vm._v("Rhode Island"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "SC" } }, [
                                _vm._v("South Carolina"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "SD" } }, [
                                _vm._v("South Dakota"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "TN" } }, [
                                _vm._v("Tennessee"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "TX" } }, [
                                _vm._v("Texas"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "UT" } }, [
                                _vm._v("Utah"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "VT" } }, [
                                _vm._v("Vermont"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "VA" } }, [
                                _vm._v("Virginia"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "WA" } }, [
                                _vm._v("Washington"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "WV" } }, [
                                _vm._v("West Virginia"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "WI" } }, [
                                _vm._v("Wisconsin"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "WY" } }, [
                                _vm._v("Wyoming"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.$v.registerForm.user_attribute.practice.state
                            .$dirty &&
                          (!_vm.$v.registerForm.user_attribute.practice.state
                            .required ||
                            !_vm.$v.registerForm.user_attribute.practice.state
                              .minLength ||
                            !_vm.$v.registerForm.user_attribute.practice.state
                              .maxLength)
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("State is required."),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.registerMethod === "methodRegister" &&
              _vm.registerType === "clinical"
                ? _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      {
                        class: {
                          "col-md-6":
                            _vm.registerForm.user_attribute.practice
                              .location_id !== null &&
                            _vm.specialtyOptions.length > 1,
                          col:
                            _vm.registerForm.user_attribute.practice
                              .location_id === null ||
                            _vm.specialtyOptions.length <= 1,
                        },
                      },
                      [
                        _c("div", { staticClass: "card bg-light" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("div", { staticClass: "required" }, [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Practice Role"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.registerForm.user_attribute
                                            .practice_role_id,
                                        expression:
                                          "registerForm.user_attribute.practice_role_id",
                                      },
                                    ],
                                    class: {
                                      "form-control": true,
                                      "is-invalid":
                                        _vm.$v.registerForm.user_attribute
                                          .practice_role_id.$dirty &&
                                        _vm.$v.registerForm.user_attribute
                                          .practice_role_id.$error,
                                    },
                                    attrs: {
                                      name: "user_attribute[practice_role_id]",
                                      id: "user-attribute-practice-role-id",
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.registerForm.user_attribute,
                                            "practice_role_id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        _vm.onChangePracticeRole,
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.practiceRoleOptions,
                                    function (practiceRoleOption) {
                                      return _c(
                                        "option",
                                        {
                                          domProps: {
                                            value: practiceRoleOption.id,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(practiceRoleOption.label)
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _vm.$v.registerForm.user_attribute
                                  .practice_role_id.$dirty &&
                                (!_vm.$v.registerForm.user_attribute
                                  .practice_role_id.required ||
                                  !_vm.$v.registerForm.user_attribute
                                    .practice_role_id.numeric)
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v("Practice role is required.")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.npiRequired
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value:
                                          _vm.$v.registerForm.user_attribute.npi
                                            .$model,
                                        expression:
                                          "$v.registerForm.user_attribute.npi.$model",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    class: {
                                      "form-control": true,
                                      "is-invalid":
                                        _vm.$v.registerForm.user_attribute.npi
                                          .$dirty &&
                                        _vm.$v.registerForm.user_attribute.npi
                                          .$error,
                                    },
                                    attrs: {
                                      type: "text",
                                      name: "user_attribute[npi]",
                                      placeholder: "NPI",
                                      id: "npi",
                                      maxlength: "10",
                                    },
                                    domProps: {
                                      value:
                                        _vm.$v.registerForm.user_attribute.npi
                                          .$model,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.$v.registerForm.user_attribute
                                            .npi,
                                          "$model",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.$v.registerForm.user_attribute.npi
                                    .$dirty &&
                                  !_vm.$v.registerForm.user_attribute.npi
                                    .required
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [_vm._v("NPI is required.")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$v.registerForm.user_attribute.npi
                                    .$dirty &&
                                  !_vm.$v.registerForm.user_attribute.npi
                                    .numeric
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "NPI can only contain numbers."
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$v.registerForm.user_attribute.npi
                                    .$dirty &&
                                  _vm.$v.registerForm.user_attribute.npi
                                    .numeric &&
                                  (!_vm.$v.registerForm.user_attribute.npi
                                    .minLength ||
                                    !_vm.$v.registerForm.user_attribute.npi
                                      .maxLength)
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [_vm._v("Invalid NPI.")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.registerForm.user_attribute.practice.location_id !==
                      null && _vm.specialtyOptions.length > 1
                      ? _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "card bg-light" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [_vm._v("Your specialties")]
                                  ),
                                  _vm._v(" "),
                                  _c("treeselect", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.tag_list.$dirty &&
                                        _vm.$v.tag_list.$error,
                                    },
                                    attrs: {
                                      multiple: true,
                                      options: _vm.specialtyOptions,
                                      flat: true,
                                      "value-consists-of":
                                        "ALL_WITH_INDETERMINATE",
                                      normalizer: _vm.normalizer,
                                      placeholder: "Select one or more",
                                    },
                                    model: {
                                      value: _vm.tag_list,
                                      callback: function ($$v) {
                                        _vm.tag_list = $$v
                                      },
                                      expression: "tag_list",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.$v.tag_list.$dirty &&
                                  (!_vm.$v.tag_list.required ||
                                    !_vm.$v.tag_list.minLength)
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "At least one specialty is required."
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.registerMethod === "methodRegister" &&
              _vm.registerType === "corporate"
                ? [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group select col-md-6" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Topics of Interest"),
                          ]),
                          _vm._v(" "),
                          _c("multiselect", {
                            attrs: {
                              options: _vm.topicsOfInterestOptions,
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              placeholder: "Select one or more",
                            },
                            model: {
                              value: _vm.topicsOfInterest,
                              callback: function ($$v) {
                                _vm.topicsOfInterest = $$v
                              },
                              expression: "topicsOfInterest",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              staticStyle: { display: "none" },
                              attrs: {
                                multiple: "",
                                name: "topics_of_interest[]",
                              },
                            },
                            _vm._l(_vm.topicsOfInterest, function (option) {
                              return _c(
                                "option",
                                {
                                  attrs: { selected: "" },
                                  domProps: { value: option },
                                },
                                [_vm._v(_vm._s(option))]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.topicsOfInterest.includes("Other")
                        ? _c("div", { staticClass: "form-group text" }, [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "topics_of_interest[other]",
                                placeholder: "Other",
                                maxlength: "255",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group select col-md-6" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Products of Interest"),
                          ]),
                          _vm._v(" "),
                          _c("multiselect", {
                            attrs: {
                              options: _vm.productsOfInterestOptions,
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              placeholder: "Select one or more",
                            },
                            model: {
                              value: _vm.productsOfInterest,
                              callback: function ($$v) {
                                _vm.productsOfInterest = $$v
                              },
                              expression: "productsOfInterest",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              staticStyle: { display: "none" },
                              attrs: {
                                multiple: "",
                                name: "products_of_interest[]",
                              },
                            },
                            _vm._l(_vm.productsOfInterest, function (option) {
                              return _c(
                                "option",
                                {
                                  attrs: { selected: "" },
                                  domProps: { value: option },
                                },
                                [_vm._v(_vm._s(option))]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.productsOfInterest.includes("Other")
                        ? _c("div", { staticClass: "form-group text" }, [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "products_of_interest[other]",
                                placeholder: "Other",
                                maxlength: "255",
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "text-center my-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: {
                      type: "submit",
                      disabled: !_vm.isMounted || _vm.isSubmitting,
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ]),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center my-3" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Sign In or Register")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center my-3" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Personalized Reports")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }