<template>
	<div>
		<navbar :subpage="true" />
		<header class="physicians-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Physicians</span>
			</li>
		</ol>
		<section class="physicians" id="physicians">
			<div class="container header-row mb-3">
				<div>
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-03.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h1 class="section-heading">Physician Surveys</h1>
					</div>
				</div>
			</div>
			<div class="container intro-content my-3">
				<div class="text-center mb-2">
					<h3>Use Our Free Tool to Benchmark Your Practice! <a href="#" v-scroll-to="'#register'"><strong>Register Now</strong></a>.</h3>
				</div>
				<p>Our process is simple: We ask you for a few minutes of your time to complete a survey and, in exchange, provide you with a range of online tools to effectively measure and improve your practice. Since 1998, Market Scope has collected and analyzed more than 20,000 surveys from more than 3,500 practices.</p>
			</div>
			<div class="container process-content my-3">
				<div class="text-center mb-3">
					<h3>Our Process</h3>
				</div>
				<div class="card mb-4">
					<h5 class="card-header"><strong>Step 1:</strong> Complete a Survey</h5>
					<div class="card-body">
						<p class="card-text">Our surveys cover ophthalmology subspecialties and optometry and are customized to cover the latest trends in both business and practice of medicine issues.</p>
						<ul class="card-text fa-ul">
							<li><span class="fa-li"><i class="fas fa-check-circle"></i></span> Survey reports utilize aggregated data. Individual responses are confidential, all data files are de-identified, and paper surveys are shredded after data entry. We ensure the integrity of all survey data by validating responses and confirming that respondents are from ophthalmic practices.</li>
						</ul>
					</div>
				</div>
				<div class="card mb-4">
					<h5 class="card-header"><strong>Step 2:</strong> Benchmark Your Practice's Performance</h5>
					<div class="card-body">
						<p class="card-text">Survey results help analyze practice patterns, evaluate treatment choices, and benchmark your practice. Benchmark reports allow you to gauge your performance against the US average and global average. Benchmark topics include:</p>
						<div class="row">
							<div class="col-md-6">
								<ul class="card-text fa-ul">
									<li><span class="fa-li"><i class="fas fa-check-circle"></i></span> Procedure volumes for all subspecialties.</li>
									<li><span class="fa-li"><i class="fas fa-check-circle"></i></span> Conversion rates and average pricing.</li>
									<li><span class="fa-li"><i class="fas fa-check-circle"></i></span> Surgical outcomes and preferred techniques.</li>
								</ul>
							</div>
							<div class="col-md-6">
								<ul class="card-text fa-ul">
									<li><span class="fa-li"><i class="fas fa-check-circle"></i></span> Diagnosis and treatment methods.</li>
									<li><span class="fa-li"><i class="fas fa-check-circle"></i></span> Patient referral sources.</li>
									<li><span class="fa-li"><i class="fas fa-check-circle"></i></span> Practice market techniques, budgets, and goals.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-5">
					<h5 class="card-header"><strong>Step 3:</strong> Gain Market Insight With Instant Results</h5>
					<div class="card-body">
						<p class="card-text">Instant results are available for review immediately on your personalized dashboard following completion of the survey. A fully analyzed report is made available a few weeks after the survey closes.</p>
						<ul class="card-text fa-ul">
							<li><span class="fa-li"><i class="fas fa-check-circle"></i></span> Survey results help you analyze practice patterns, evaluate treatment choices, and benchmark your practice.</li>
						</ul>
					</div>
				</div>
				<div class="text-center mb-4">
					<a class="btn btn-success" href="#" v-scroll-to="'#register'">Register Today to Get Started</a>
				</div>
			</div>
			<div class="benefits-content">
				<div class="container my-3">
					<div class="text-center pt-4 pb-4">
						<h3>Benefits of Market Scope Surveys</h3>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-instant.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">Instant Results</h5>
							</div>
							<p>We provide instant survey results that help you analyze practice patterns, evaluate treatment choices, and benchmark your practice.</p>
						</div>
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-confidentiality.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">Confidentiality</h5>
							</div>
							<p>Our survey reports use only aggregated data. Individual responses are confidential, data files are de-identified, and paper surveys are shredded after data entry.</p>
						</div>
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-updates.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">Ophthalmic Market Trends</h5>
							</div>
							<p>New topics are added every quarter that focus on medical practice, surgical volumes, technology adoption, and practice marketing.</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-quick.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">Quick Surveys</h5>
							</div>
							<p>On average, our surveys take 5-15 minutes to complete. You may also finish your survey at a later time as all answers are auto-saved.</p>
						</div>
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-topics.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">Topics Covered</h5>
							</div>
							<p>Survey topics include cataract, refractive, glaucoma, retina, and ocular surface.</p>
						</div>
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-donate.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">$75,000+ Donated</h5>
							</div>
							<p>All survey participants have the option of a charitable donation in their name. Past recipients include Orbis International, ASCRS Foundation, Glaucoma 360, Doctors Without Borders, and the Houston Food Bank.</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-email.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">Weekly Email</h5>
							</div>
							<p>Emails include updates on current surveys, available results, topical weekly polls, and industry news.</p>
						</div>
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-lectures.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">Boost Your Lectures</h5>
							</div>
							<p>If you have completed a recent survey, we will gladly provide you with data and charts to help enhance your lectures. Just contact us via email or phone with your specific Market Scope request.</p>
						</div>
						<div class="col-md-4">
							<div class="media mb-3">
								<img v-lazy="require('@assets/img/physicians/physicians-icon-free.png')" class="align-self-center mr-2" />
								<h5 class="align-self-center m-0">Free Tool</h5>
							</div>
							<p>Your personalized dashboard is a free tool to help you benchmark your practice, stay current with trends, and learn about new technologies.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="container features-content my-3">
				<div class="text-center pt-5 pb-3">
					<h3>Your Market Scope Dashboard</h3>
				</div>
				<p>The dashboard feature provides physicians and administrators with extensive analytical tools to help measure practice performance and stay informed with the latest industry news and trends.</p>
				<div class="row mt-5">
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<img v-lazy="require('@assets/img/physicians/physicians-callout-report.png')" class="img-fluid w-100" />
							</div>
						</div>
						<h4 class="mt-4 mb-3">Comprehensive Analyzed Report</h4>
						<p>Our team of highly experienced consultants and analysts meticulously evaluate and validate survey data to ensure accuracy. Aggregate results are published within 90 days of the close of the survey and are available on your personalized dashboard.</p>
					</div>
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<img v-lazy="require('@assets/img/physicians/physicians-callout-benchmark.png')" class="img-fluid w-100" />
							</div>
						</div>
						<h4 class="mt-4 mb-3">Personal Benchmark Data</h4>
						<p>Compare your practice’s metrics to national averages. Set goals, monitor changes, and stay informed.</p>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<img v-lazy="require('@assets/img/physicians/physicians-callout-dashboard.png')" class="img-fluid w-100" />
							</div>
						</div>
						<h4 class="mt-4 mb-3">Interactive Dashboard Data</h4>
						<p>MyScope is an interactive data dashboard where members can compare practice metrics to aggregate survey results over time, providing powerful insight into the surgical volumes, technology adoption, etc.</p>
					</div>
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<img v-lazy="require('@assets/img/physicians/physicians-callout-databank.png')" class="img-fluid w-100" />
							</div>
						</div>
						<h4 class="mt-4 mb-3">Data Bank</h4>
						<p>Looking for a chart or PowerPoint slide for your next lecture? Our DataBank is a robust collection of up-to-date charts that can be downloaded and incorporated into presentations, studies, etc.</p>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<img v-lazy="require('@assets/img/physicians/physicians-callout-polls.png')" class="img-fluid w-100" />
							</div>
						</div>
						<h4 class="mt-4 mb-3">Searchable Poll Archive</h4>
						<p>Our weekly polls cover all facets of ophthalmology. Members benefit from a searchable archive of topical poll results.</p>
					</div>
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<img v-lazy="require('@assets/img/physicians/physicians-callout-news.png')" class="img-fluid w-100" />
							</div>
						</div>
						<h4 class="mt-4 mb-3">Searchable News Archive</h4>
						<p>All of our published content is collected in a completely searchable archive for our members.</p>
					</div>
				</div>
			</div>
		</section>
		<register :headerText="'Register Today!'" :defaultForm="'clinical'" />
		<footer-section />
	</div>
</template>

<script>
import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'

export default {
	name: 'Physicians',
	metaInfo: {
		title: 'Physicians',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/physicians',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/physicians-subpage-bg.jpg',
				vmid: 'og:image'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/physicians',
				vmid: 'canonical'
			}
		]
	},
	components: {
		Navbar,
		Register,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.physicians-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/physicians/physicians-subpage-bg.jpg'), $theme-secondary;
	background: url('~@assets/img/physicians/physicians-subpage-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: center;
}

section.physicians {
	.section-heading {
		color: #0a5384;
	}
	
	h3,
	h4 {
		color: #496f93;
	}
	
	p,
	li {
		color: $gray-light;
		font-size: inherit;
	}
	
	.intro-content h3 {
		strong {
			font-weight: 600;
		}
		
		a {
			color: #2fbbcc;
		}
	}
	
	.process-content {
		.card {
			border: none;
			border-radius: 0;
			box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
			
			.card-header {
				background: url('~@assets/img/physicians/physicians-card-header-bg.jpg');
				@include background-cover;
				color: #fff;
				padding-top: 1.25rem;
				padding-bottom: 1.25rem;
				
				&:first-child {
					border-radius: 0;
				}
			}
			
			.card-body {
				li > span > i {
					color: #0a5384;
				}
			}
		}
		
		.btn {
			text-transform: none;
		}
	}
	
	.benefits-content {
		background: url('~@assets/img/physicians/physicians-benefits-bg.jpg');
		@include background-cover;
		
		h3 {
			color: #fff;
		}
		
		h5 {
			color: #fff;
			font-weight: 500;
		}
		
		p {
			color: $gray-lighter;
		}
		
		.media > img {
			height: 50px;
		}
	}
	
	.features-content {
		.card {
			border: none;
			border-radius: 0;
			box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
			
			.card-body {
				padding: 0;
			}
		}
	}
}
</style>