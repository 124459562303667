<template>
	<div>
		<navbar :subpage="true" />
		<header class="bio-masthead w-100">
			<div class="row no-gutters">
				<div class="col-md-6 d-flex align-items-center justify-content-center p-5 bio-info">
					<div>
						<h1>Krista Davidson</h1>
						<h3 class="my-4 font-weight-bold text-uppercase">Writer &amp; Analyst</h3>
						<h4>Phone: <a href="tel:+13148350600">314-835-0600</a></h4>
						<h4>Email: <a href="mailto:krista@market-scope.com">krista@market-scope.com</a></h4>
						<a href="https://www.linkedin.com/in/krista-davidson-020682145/" target="_blank"><img v-lazy="require('@assets/img/about/bios/LinkedIn-Icon.svg')" class="d-block" /></a>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-center justify-content-end bio-headshot">
					<img v-lazy="require('@assets/img/about/headshot-19.jpg')" class="img-fluid" />
				</div>
			</div>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'About' }" exact>About</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Krista Davidson</span>
			</li>
		</ol>
		<section class="bio content">
			<div class="container">
				<div class="row px-5">
					<div class="col">
						<div class="mx-md-5">
							<h4 class="mt-3 mb-4">Krista Davidson has always found herself at the intersection of research and health care, investing her time at Market Scope in both primary research and most recently on secondary research as an author/analyst of key market reports.</h4>
							<p>Krista studied public health at Truman State University and earned her B.S. in health science, then began her career in medical technology – initially with respiratory therapy products, overseeing distribution to patients during the COVID-19 pandemic. She worked closely with clinicians and industry to meet eligibility criteria for payer coverage, sparking a growing interest in reimbursement and regulatory affairs.</p>
							<p>Her experience in ophthalmology began as research manager for Market Scope, where she spent recent years learning the ophthalmic market by designing custom surveys for clients to better understand product development, price sensitivity, and adoption curves.</p>
							<p>In addition to her research and writing efforts on the ophthalmic market, she contributes to Market Scope’s monthly newsletter, Ophthalmic Market Perspectives.</p>
							<p>When she’s not ruminating on the latest ophthalmic news, Krista enjoys crosswording, cross-stitching, and cross-training.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<authored-reports :authorId="9195" />
		<combined-registration origin="bio" />
		<footer-section />
	</div>
</template>

<script>
import Navbar from '../Navbar'
import AuthoredReports from '../AuthoredReports'
import CombinedRegistration from '../forms/CombinedRegistration'
import FooterSection from '../FooterSection'

export default {
	name: 'KristaDavidson',
	metaInfo: {
		title: 'About: Krista Davidson',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/about/krista-davidson',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/headshot-19.jpg',
				vmid: 'og:image'
			},
			{
				name: 'title',
				property: 'og:title',
				content: 'About: Krista Davidson',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/about/krista-davidson',
				vmid: 'canonical'
			}
		]
	},
	components: {
		Navbar,
		AuthoredReports,
		CombinedRegistration,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.bio-masthead {
	position: relative;
	overflow: hidden;
	
	.row {
		padding-top: 81px; /* change to auto-adjust based on navbar height */
		
		.bio-info {
			background-color: #0a5384;
			padding-top: 4rem !important;
			
			> div {
				h1,
				h3,
				h4 {
					color: #fff;
				}
				
				h1 {
					font-size: 50px;
					font-weight: 400;
				}
				
				h3 {
					font-size: 18px;
				}
				
				h4 {
					font-size: 20px;
				}
			}
		}
		
		.bio-headshot {
			background-color: #0a5384;
		}
	}
	
	@media (min-width: 482px) {
		.row .bio-info {
			padding-top: 3rem !important;
		}
	}
}

section.bio.content {
	h4 {
		font-size: 30px;
		color: #0a5384;
		font-weight: 500;
	}
	
	p {
		font-size: 18px;
		color: #777;
	}
}
</style>