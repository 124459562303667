<template>
	<div id="app">
		<no-ssr>
			<flash-message variant="danger" :autoHide="true" />
			<flash-message variant="warning" :autoHide="true" />
		</no-ssr>
		<transition :name="transitionName" :mode="transitionMode" @after-leave="afterLeave">
			<router-view />
		</transition>
		<vue-programmatic-invisible-google-recaptcha-custom
			ref="invisibleRecaptcha"
			elementId="invisible-recaptcha"
			badgePosition="left"
			:showBadgeMobile="false"
			:showBadgeDesktop="false"
			@recaptcha-callback="recaptchaCallback"
			:sitekey="gRecaptchaSitekey"
		/>
		<no-ssr>
			<cookie-law theme="dark-lime--rounded" buttonText="Accept" message="This website uses cookies to ensure you get the best experience. By continuing to use our website, you consent to our use of cookies." />
		</no-ssr>
	</div>
</template>

<script>
import EventBus from './event-bus'
import NoSSR from 'vue-no-ssr'
import CookieLaw from 'vue-cookie-law'

import VueProgrammaticInvisibleGoogleRecaptchaCustom from './components/vue-programmatic-invisible-google-recaptcha-custom'

export default {
	name: 'LandingApp',
	metaInfo() {
		const scripts = []
		const disabledSanitizers = {}
		
		if (!process.env.DEBUG) {
			scripts.push({
				vmid: 'olark',
				innerHTML: ' ;(function(o,l,a,r,k,y){if(o.olark)return; r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0]; y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r); y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)}; y.extend=function(i,j){y("extend",i,j)}; y.identify=function(i){y("identify",k.i=i)}; y.configure=function(i,j){y("configure",i,j);k.c[i]=j}; k=y._={s:[],t:[+new Date],c:{},l:a}; })(window,document,"static.olark.com/jsclient/loader.js");olark.configure("system.is_single_page_application", true);olark.identify("7536-910-10-5125");',
				type: 'text/javascript',
				async: true,
				body: true
			})
			disabledSanitizers.olark = ['innerHTML']
		}
		
		return {
			title: 'Market Scope',
			titleTemplate: '%s | Market Scope',
			script: scripts,
			__dangerouslyDisableSanitizersByTagID: disabledSanitizers
		}
	},
	data() {
		return {
			gRecaptchaSitekey: process.env.RECAPTCHA_SITEKEY,
			mapdataLoaded: false,
			transitionName: 'v',
			transitionMode: ''
		}
	},
	mounted() {
		if (this.$cookies.isKey('flash_message_error')) {
			let message = this.$cookies.get('flash_message_error').replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent).replace(/\+/g, ' ')
			this.flash({ message: message, variant: 'danger' })
			this.$cookies.remove('flash_message_error')
		}
		if (this.$cookies.isKey('flash_message_info')) {
			let message = this.$cookies.get('flash_message_info').replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent).replace(/\+/g, ' ')
			this.flash({ message: message, variant: 'warning' })
			this.$cookies.remove('flash_message_info')
		}
		
		const invite = this.$route.query.invite
		if (invite) {
			this.$store.commit('SET_INVITE', invite)
		}
		
		const accessCode = this.$route.query.access_code
		if (accessCode) {
			this.$store.dispatch('fetchCampaign', accessCode)
				.then(() => {
					this.$router.replace({ name: 'CampaignRegister', params: { access_code: accessCode } })
				})
				.catch(e => {
					if (e.status === 301) {
						window.location.replace(e.redirectUrl)
					}
				})
		}
	},
	watch: {
		'$route' (to, from) {
			if (to.name === 'Article' || from.name === 'Article') {
				this.transitionName = 'v'
				this.transitionMode = ''
			} else {
				this.transitionName = 'fade'
				this.transitionMode = 'out-in'
			}
		}
	},
	methods: {
		afterLeave() {
			EventBus.$emit('triggerScroll')
		},
		recaptchaCallback({ token, originComponent }) {
			this.$store.commit('SET_INVISIBLE_RECAPTCHA', {
				token: token,
				originComponent: originComponent
			})
		}
	},
	components: {
		'no-ssr': NoSSR,
		CookieLaw,
		VueProgrammaticInvisibleGoogleRecaptchaCustom
	}
}
</script>

<style lang="scss">
$accentColor: #2fbbcc;
$textColor: #2c3e50;
$borderColor: #eaecef;
$codeBgColor: #282c34;
$arrowBgColor: #ccc;
$badgeTipColor: #42b983;
$badgeWarningColor: darken(#ffe564, 35%);
$badgeErrorColor: #DA5961;

@font-face {
	font-family: 'Fontello';
	src: url('~@assets/fonts/fontello.eot?26533562');
	src: url('~@assets/fonts/fontello.eot?26533562#iefix') format('embedded-opentype'),
		 url('~@assets/fonts/fontello.woff2?26533562') format('woff2'),
		 url('~@assets/fonts/fontello.woff?26533562') format('woff'),
		 url('~@assets/fonts/fontello.ttf?26533562') format('truetype'),
		 url('~@assets/fonts/fontello.svg?26533562#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}

.Cookie--dark-lime--rounded .Cookie__button {
	margin-right: 11em;
}

.v--modal-overlay {
	z-index: 10000 !important;
}

.fade-enter-active,
.fade-leave-active {
	transition: all .2s ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.alert {
	position: fixed;
	top: 20px;
	left: 25%;
	z-index: 9999;
	width: 50%;
}

.tooltip {
	z-index: 10001 !important;
}

.is-invalid .vue-treeselect__control {
	border-color: #dc3545;
	
	&:hover {
		border-color: #dc3545 !important;
	}
}

#autosuggest__input.autosuggest__input-open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.autosuggest__results-container {
	position: relative;
	width: 100%;
}

.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	width: 100%;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: #fff;
	padding: 0;
}

.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.autosuggest__results .autosuggest__results_item {
	cursor: pointer;
	padding: 5px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}

.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results
.autosuggest__results_item.autosuggest__results_item-highlighted {
	background-color: #ddd;
}

.autosuggest__results {
	overflow-y: auto;
	max-height: 400px;
}

.grecaptcha-badge {
	visibility: hidden !important;
}

.search-box {
	display: inline-block;
	position: relative;
	margin-right: 1rem;
	
	input {
		cursor: text;
		width: 12rem;
		height: 2rem;
		color: lighten($textColor, 25%);
		display: inline-block;
		border: 1px solid darken($borderColor, 10%);
		border-radius: 2rem;
		font-size: 0.9rem;
		line-height: 2rem;
		padding: 0 0.5rem 0 2rem;
		outline: none;
		transition: all .2s ease;
		&:focus {
			cursor: auto;
			border-color: $accentColor;
		}
		background: #fff url(~@assets/img/icons/search.svg) 0.6rem 0.5rem no-repeat;
		background-size: 1rem;
	}
}
.meilisearch-search-wrapper {
	& > span {
		vertical-align: middle;
	}
	.dsb-dropdown-menu [class^='dsb-dataset-'] {
		max-height: calc(100vh - 6rem) !important; /* Fallback for browsers that do not support Custom Properties */
		max-height: calc((var(--vh, 1vh) * 100) - 6rem) !important;
	}
	.meilisearch-autocomplete {
		line-height: 2;
		
		.dsb-dropdown-menu {
			min-width: 650px;
			max-width: 750px;
		}
		
		.searchbar-suggestion--highlight {
			color: darken($accentColor, 20%);
		}
		.searchbar-suggestion {
			border-color: $borderColor;
			
			.searchbar-suggestion--category-header {
				background: #f1f3f5;
				padding: 5px 10px;
				border-radius: 4px;
				/*background: lighten($accentColor, 20%);*/
				background: lighten($accentColor, 10%);
				font-weight: 600;
				color: #fff;
				
				.searchbar-suggestion--highlight {
					background: rgba(255, 255, 255, 0.6);
					box-shadow: none;
				}
			}
			.searchbar-suggestion--wrapper {
				padding: 0;
				
				.searchbar-suggestion--no-results {
					padding-top: 1rem;
				}
			}
			.searchbar-suggestion--title {
				margin-bottom: 0;
				color: $textColor;
				line-height: 1.5;
			}
			.searchbar-suggestion--text {
				line-height: 1.8;
			}
			.searchbar-suggestion--subcategory-column {
				border-color: $borderColor;
			}
			.searchbar-suggestion--subcategory-column-text {
				color: #555;
			}
			.searchbar-suggestion--text .searchbar-suggestion--highlight {
				box-shadow: inset 0 -2px 0 0 lighten($accentColor, 20%);
			}
		}
		.dsb-cursor .searchbar-suggestion--content {
			background-color: #e7edf3 !important;
			color: $textColor;
		}
	}
}
@media (min-width: 992px) {
	.meilisearch-search-wrapper .meilisearch-autocomplete .searchbar-suggestion {
		.searchbar-suggestion--subcategory-column {
			float: none;
			width: 200px;
			min-width: 200px;
			display: table-cell;
		}
		.searchbar-suggestion--content {
			float: none;
			display: table-cell;
			width: 100%;
			vertical-align: top;
		}
		.dsb-dropdown-menu {
			min-width: 515px !important;
		}
	}
}
@media (max-width: 992px) {
	.meilisearch-search-wrapper {
		.dsb-dropdown-menu {
			min-width: calc(100vw - 4rem) !important;
			max-width: calc(100vw - 4rem) !important;
			
			[class^='dsb-dataset-'] {
				max-height: calc(100vh - 10rem) !important; /* Fallback for browsers that do not support Custom Properties */
				max-height: calc((var(--vh, 1vh) * 100) - 10rem) !important;
			}
		}
		.searchbar-suggestion--wrapper {
			padding: 5px 7px 5px 5px !important;
		}
		.searchbar-suggestion--subcategory-column {
			padding: 0 !important;
			background: white !important;
		}
		.searchbar-suggestion--subcategory-column-text:after {
			content: " > ";
			font-size: 10px;
			line-height: 14.4px;
			display: inline-block;
			width: 5px;
			margin: -3px 3px 0;
			vertical-align: middle;
		}
	}
}

#mainNav {
	border-color: fade-out($gray-darker, .95);
	background-color: #fff;
	@include transition-all;
	@include heading-font;
	
	.navbar-brand {
		color: $gray-dark;
		@include heading-font;
		
		&:hover,
		&:focus {
			color: darken($gray-dark, 10%);
		}
		
		a {
			color: $gray-dark;
			font-size: 1rem;
			margin-left: 10px;
			
			&:hover {
				text-decoration: none;
			}
		}
	}
	
	.navbar-toggler {
		font-size: 12px;
		padding: 8px 10px;
		color: $gray-darker;
	}
	
	.navbar-nav {
		> li {
			> a {
				font-size: 13px;
				@include alt-font;
				letter-spacing: 1px;
				font-weight: bold;
				
				&.active {
					color: $gray-light !important;
					background-color: transparent;
					
					&:hover {
						background-color: transparent;
					}
				}
			}
			> a,
			> a:focus {
				color: $gray-darker;
				
				&:hover {
					color: $gray-light;
				}
			}
			
			.fa-search {
				font-size: 14px;
			}
		}
	}
	
	.full-width.dropdown {
		position: static;
	}
	.full-width.dropdown > .dropdown-menu {
		right: 0;
		margin: 0;
		border: none;
		border-radius: 0;
		background-clip: border-box;
		background-color: rgba(255, 255, 255, 0.9);
	}
	.full-width.dropdown > .dropdown-menu > li > a {
		white-space: normal;
	}
	
	.dropdown-item {
		font-weight: 500;
		color: #555;
	}
	.dropdown-item.focus,
	.dropdown-item:focus,
	.dropdown-item.hover,
	.dropdown-item:hover {
		background-color: #fff;
	}
	.dropdown-item.active,
	.dropdown-item:active {
		color: #fff;
		background-color: rgba(47, 187, 204, 0.8);
	}
	
	@media (min-width: 992px) {
		border-color: transparent;
		background-color: transparent;
		
		.navbar-brand {
			color: #fff;
			
			&:hover,
			&:focus {
				color: #fff;
			}
			
			a {
				font-size: 1rem;
				margin-left: 15px;
				color: #fff;
				
				&:hover {
					text-decoration: none;
				}
			}
		}
		
		.navbar-nav > li > a,
		.navbar-nav > li > a:focus {
			color: fade-out(#fff, .3);
			
			&:hover {
				color: $theme-primary;
			}
		}
		
		&.navbar-shrink {
			border-color: fade-out($gray-darker, .9);
			background-color: #fff;
			
			.navbar-brand {
				color: $gray-darker;
				&:hover,
				&:focus {
					color: $gray-light;
				}
				
				a {
					font-size: 1rem;
					margin-left: 15px;
					color: $gray-darker;
					
					&:hover,
					&:focus {
						color: $gray-light;
					}
				}
			}
			
			.navbar-nav > li > a,
			.navbar-nav > li > a:focus {
				color: $gray-darker;
				
				&:hover {
					color: $gray-light;
				}
			}
		}
	}
}

#mainNav.subpage {
	.navbar-brand {
		font-size: 40px;
		font-family: MistralStd;
		letter-spacing: normal;
		padding-bottom: 0;
		color: #0a5384;
		
		&:hover,
		&:focus {
			color: darken(#0a5384, 0.1);
		}
	}
	
	.register-link a.nav-link {
		font-size: 16px;
		color: #2fbbcc !important;
		text-decoration: none;
		text-transform: none;
		font-weight: 500;
	}
	
	@media (min-width: 992px) {
		border-color: #fff;
		background-color: #fff;
		
		.navbar-brand {
			color: transparentize(#0a5384, 0.7);
			
			&:hover,
			&:focus {
				color: #0a5384;
			}
		}
		.navbar-nav > li > a,
		.navbar-nav > li > a:focus {
			color: fade-out(#0a5384, .3);
			
			&:hover {
				color: #0a5384;
			}
		}
		
		&.navbar-shrink {
			border-color: fade-out($gray-darker, 0.9);
			background-color: #fff;
			
			.navbar-brand {
				color: #0a5384;
			}
			
			.navbar-nav > li > a,
			.navbar-nav > li > a:focus {
				color: $gray-darker;
				&:hover {
					color: $gray-light;
				}
			}
		}
		
		.login-link a.nav-link {
			margin-left: 1rem !important;
		}
	}
}
</style>