var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "news-archive-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "news-archive py-0", attrs: { id: "news-archive" } },
        [
          _c("div", { staticClass: "container header-row" }, [
            _c("div", [
              _c("div", { staticClass: "mx-auto text-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/icons/MarketScope-Icons-08.svg"),
                      expression:
                        "require('@assets/img/icons/MarketScope-Icons-08.svg')",
                    },
                  ],
                  staticStyle: {
                    "max-width": "70px",
                    width: "auto",
                    height: "auto",
                  },
                  attrs: { width: "70", height: "70" },
                }),
                _vm._v(" "),
                _vm.$route.name === "NewsArchive"
                  ? _c("h1", { staticClass: "section-heading" }, [
                      _vm._v("More News"),
                    ])
                  : _c("h2", { staticClass: "section-heading" }, [
                      _vm._v("More News"),
                    ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "container p-0 pt-4",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.resetSearchQuery.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        ref: "searchInput",
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "Filter by...",
                          "aria-label": "Filter by...",
                          "aria-describedby": _vm.searchQuery,
                        },
                        on: { input: _vm.updateSearchQuery },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-group-append",
                          on: { click: _vm.resetSearchQuery },
                        },
                        [_vm._m(1)]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container articles-list" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("virtual-list", {
                  ref: "vList",
                  staticClass: "virtual-list-wrapper",
                  attrs: {
                    "estimate-size": 100,
                    keeps: 50,
                    "data-key": "slug",
                    "data-sources": _vm.articles,
                    "data-component": _vm.articleComponent,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("register", {
        attrs: { headerText: "Want to Read Locked Articles?" },
      }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("News Archive")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "input-group-text bg-transparent",
        attrs: { id: "searchQuery" },
      },
      [
        _c("i", {
          staticClass: "fa fa-times",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }