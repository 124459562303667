var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "no-ssr",
        [
          _c("flash-message", { attrs: { variant: "danger", autoHide: true } }),
          _vm._v(" "),
          _c("flash-message", {
            attrs: { variant: "warning", autoHide: true },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: _vm.transitionName, mode: _vm.transitionMode },
          on: { "after-leave": _vm.afterLeave },
        },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("vue-programmatic-invisible-google-recaptcha-custom", {
        ref: "invisibleRecaptcha",
        attrs: {
          elementId: "invisible-recaptcha",
          badgePosition: "left",
          showBadgeMobile: false,
          showBadgeDesktop: false,
          sitekey: _vm.gRecaptchaSitekey,
        },
        on: { "recaptcha-callback": _vm.recaptchaCallback },
      }),
      _vm._v(" "),
      _c(
        "no-ssr",
        [
          _c("cookie-law", {
            attrs: {
              theme: "dark-lime--rounded",
              buttonText: "Accept",
              message:
                "This website uses cookies to ensure you get the best experience. By continuing to use our website, you consent to our use of cookies.",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }