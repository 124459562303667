<template>
	<div>
		<navbar :subpage="true" />
		<header class="bio-masthead w-100">
			<div class="row no-gutters">
				<div class="col-md-6 d-flex align-items-center justify-content-center p-5 bio-info">
					<div>
						<h1>Kristen Harmon Ingenito, MBA</h1>
						<h3 class="my-4 font-weight-bold text-uppercase">CEO</h3>
						<h4>Phone: <a href="tel:+13148350600">314-835-0600</a></h4>
						<h4>Email: <a href="mailto:kristen@market-scope.com">kristen@market-scope.com</a></h4>
						<a href="https://www.linkedin.com/in/kharmon/" target="_blank"><img v-lazy="require('@assets/img/about/bios/LinkedIn-Icon.svg')" class="d-block" /></a>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-center justify-content-end bio-headshot">
					<img v-lazy="require('@assets/img/about/headshot-6.jpg')" class="img-fluid" />
				</div>
			</div>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'About' }" exact>About</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Kristen Harmon Ingenito</span>
			</li>
		</ol>
		<section class="bio content">
			<div class="container">
				<div class="row px-5">
					<div class="col">
						<div class="mx-md-5">
							<h4 class="mt-3 mb-4">Kristen Harmon Ingenito has nearly two decades of experience in the ophthalmic industry, with over 15 of those years spent analyzing data and reporting for Market Scope.</h4>
							<p>Kristen’s industry experience began in 2005 at Market Scope, where she served in many roles, including editing, analysis, sales, and marketing. She then joined an ophthalmic patient education software company, where she worked closely with physicians to help drive value in all aspects of their practices, while also training incoming executives on the business of eye care.</p>
							<p>She returned to Market Scope with added experience to help expand the company’s portfolio of products and services.</p>
							<p>Kristen earned her bachelor’s degree from the University of Missouri–St. Louis and her master’s of business administration from the George Washington University School of Business as a Forté Fellow and a member of the Golden Key International Honor Society.</p>
							<p>In addition to managing all of Market Scope’s ophthalmic publications and services, she is a frequent speaker at ophthalmic meetings around the world, contributes to the Ophthalmic Market Perspectives newsletter, and helps our team research and write reports.</p>
							<p>Kristen is also an executive board member of Ophthalmic World Leaders, which aims to promote and develop diverse leadership to advance ophthalmic innovation and patient care.</p>
							<p>When she’s not crunching numbers or building charts, Kristen enjoys spending time outdoors with her husband, three children, and doodle dog.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<authored-reports :authorId="313" />
		<section class="bio extra">
			<div class="container">
				<div class="row">
					<div class="col text-center">
						<h3 class="mt-4 mb-5">Presentation Videos</h3>
					</div>
				</div>
				<div class="row px-5 mb-md-4">
					<div class="col-md-6 pb-4 pb-md-0">
						<div class="position-relative">
							<img v-lazy="require('@assets/img/about/bios/kristen-harmon-ingenito/eyecelerator-2024.jpeg')" class="img-fluid" />
							<a href="https://www.eyecelerator.com/ascrs-2024-sessions?wix-vod-video-id=c2fa2615ce2748d39c893e73caa1f7dc&wix-vod-comp-id=comp-lwh1th6k2" target="_blank" class="position-absolute" style="left: calc(50% - 31.5px); bottom: -31.5px;">
								<img v-lazy="require('@assets/img/about/bios/play-icon.png')" />
							</a>
						</div>
						<h5 class="text-center mx-5 mt-5">Eyecelerator 2024 - ASCRS 2024 Main Stage Sessions</h5>
					</div>
					<div class="col-md-6 pb-4 pb-md-0">
						<div class="position-relative">
							<template v-if="playPresentationVideo2">
								<LazyVimeo src="https://vimeo.com/926307249" :showTitle="false" :autoplay="true" customThumbnail="https://vumbnail.com/926307249.jpg" />
							</template>
							<template v-else>
								<img v-lazy="require('@assets/img/about/bios/kristen-harmon-ingenito/926307249.jpg')" class="img-fluid" />
								<a @click.prevent="playPresentationVideo2 = true" href="#" class="position-absolute" style="left: calc(50% - 31.5px); bottom: -31.5px;">
									<img v-lazy="require('@assets/img/about/bios/play-icon.png')" />
								</a>
							</template>
						</div>
						<h5 class="text-center mx-5 mt-5">2024 New Horizons Forum - Through the Eye of Market Scope</h5>
					</div>
				</div>
				<div class="row px-5 mb-md-4">
					<div class="col-md-6 pb-4 pb-md-0">
						<div class="position-relative">
							<template v-if="playPresentationVideo3">
								<LazyVimeo src="https://vimeo.com/807712817" :showTitle="false" :autoplay="true" customThumbnail="https://vumbnail.com/807712817.jpg" />
							</template>
							<template v-else>
								<img v-lazy="require('@assets/img/about/bios/kristen-harmon-ingenito/807712817.jpg')" class="img-fluid" />
								<a @click.prevent="playPresentationVideo3 = true" href="#" class="position-absolute" style="left: calc(50% - 31.5px); bottom: -31.5px;">
									<img v-lazy="require('@assets/img/about/bios/play-icon.png')" />
								</a>
							</template>
						</div>
						<h5 class="text-center mx-5 mt-5">2023 New Horizons Forum - A Bird’s Eye View of Innovation</h5>
					</div>
					<div class="col-md-6 pb-4 pb-md-0">
						<div class="position-relative">
							<template v-if="playPresentationVideo4">
								<LazyVimeo src="https://vimeo.com/686398398" :showTitle="false" :autoplay="true" customThumbnail="https://vumbnail.com/686398398.jpg" />
							</template>
							<template v-else>
								<img v-lazy="require('@assets/img/about/bios/kristen-harmon-ingenito/686398398.jpg')" class="img-fluid" />
								<a @click.prevent="playPresentationVideo4 = true" href="#" class="position-absolute" style="left: calc(50% - 31.5px); bottom: -31.5px;">
									<img v-lazy="require('@assets/img/about/bios/play-icon.png')" />
								</a>
							</template>
						</div>
						<h5 class="text-center mx-5 mt-5">2022 New Horizons Forum - The Pressure’s On: An Inside Look at the Glaucoma Treatment Market</h5>
					</div>
				</div>
				<div class="row px-5">
					<div class="col-md-6 offset-md-3 text-center">
						<div class="position-relative">
							<img v-lazy="require('@assets/img/about/bios/kristen-harmon-ingenito/eyeluminaries.jpeg')" class="img-fluid" />
							<a href="https://www.healio.com/news/ophthalmology/podcasts/eyeluminaries/episode-20" target="_blank" class="position-absolute" style="left: calc(50% - 31.5px); bottom: -31.5px;">
								<img v-lazy="require('@assets/img/about/bios/play-icon.png')" />
							</a>
						</div>
						<h5 class="text-center mx-5 mt-5">Healio Eyeluminaries - Live from AAO Eyecelerator 2023</h5>
					</div>
				</div>
			</div>
		</section>
		<combined-registration origin="bio" />
		<footer-section />
	</div>
</template>

<script>
import { LazyVimeo } from 'vue-lazytube'

import Navbar from '../Navbar'
import AuthoredReports from '../AuthoredReports'
import CombinedRegistration from '../forms/CombinedRegistration'
import FooterSection from '../FooterSection'

export default {
	name: 'KristenHarmonIngenito',
	metaInfo: {
		title: 'About: Kristen Harmon Ingenito',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/about/kristen-harmon-ingenito',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/headshot-6.jpg',
				vmid: 'og:image'
			},
			{
				name: 'title',
				property: 'og:title',
				content: 'About: Kristen Harmon Ingenito',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/about/kristen-harmon-ingenito',
				vmid: 'canonical'
			}
		]
	},
	data() {
		return {
			playPresentationVideo2: false,
			playPresentationVideo3: false,
			playPresentationVideo4: false
		}
	},
	components: {
		LazyVimeo,
		Navbar,
		AuthoredReports,
		CombinedRegistration,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.bio-masthead {
	position: relative;
	overflow: hidden;
	
	.row {
		padding-top: 81px; /* change to auto-adjust based on navbar height */
		
		.bio-info {
			background-color: #0a5384;
			padding-top: 4rem !important;
			
			> div {
				h1,
				h3,
				h4 {
					color: #fff;
				}
				
				h1 {
					font-size: 50px;
					font-weight: 400;
				}
				
				h3 {
					font-size: 18px;
				}
				
				h4 {
					font-size: 20px;
				}
			}
		}
		
		.bio-headshot {
			background-color: #0a5384;
		}
	}
	
	@media (min-width: 482px) {
		.row .bio-info {
			padding-top: 3rem !important;
		}
	}
}

section.bio.content {
	h4 {
		font-size: 30px;
		color: #0a5384;
		font-weight: 500;
	}
	
	p {
		font-size: 18px;
		color: #777;
	}
}

section.bio.extra {
	background: url('~@assets/img/about/bios/Presentation-Video-Background.jpg');
	@include background-cover;
	background-position-x: center;
	
	h3 {
		color: #fff;
		font-size: 40px;
		font-weight: 500;
	}
	
	img {
		filter: drop-shadow(0px 20px 40px #0000001f);
	}
	
	h5 {
		color: #fff;
		font-size: 24px;
		font-weight: 500;
	}
}
</style>