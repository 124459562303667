<template>
	<div class="card text-center border-0">
		<div class="card-header card-right text-center">
			<img v-lazy="require('@assets/img/Doctors-Unselected-New.jpg')" style="visibility: hidden;" />
			<h3 class="card-title">Register A Clinical Account</h3>
		</div>
		<div class="card-body">
			<div :id="containerId">
				<p class="card-text">A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard.</p>
				<form method="post" v-promise-btn="{ action: 'submit' }" @submit.prevent="submit" accept-charset="utf-8" novalidate id="clinical-registration-form" ref="clinicalRegistrationForm" role="form" action="/users/register">
					<div style="display: none;">
						<input type="hidden" name="_method" value="POST" class="form-control" />
						<input type="hidden" name="_csrfToken" autocomplete="off" :value="csrfToken" />
						<input v-if="invite" type="hidden" name="invite" :value="invite" />
						<input v-if="campaign && campaign.access_code" type="hidden" name="access_code" :value="campaign.access_code" />
						<input type="hidden" name="g_recaptcha_response" autocomplete="off" :value="gRecaptchaToken" />
						<select multiple name="tag_list[]">
							<option v-for="tag in tag_list" :value="tag" selected>{{ tag }}</option>
						</select>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group text required">
								<input type="text" v-model.trim="$v.full_name.$model" name="full_name" placeholder="Full Name" id="clinical-full-name" required maxlength="50" :class="{ 'form-control': true, 'is-invalid': $v.full_name.$dirty && $v.full_name.$error }" />
								<div class="invalid-feedback" v-if="$v.full_name.$dirty && !$v.full_name.required">Full name is required.</div>
								<div class="invalid-feedback" v-if="$v.full_name.$dirty && !$v.full_name.minLength">Full name has a minimum of 3 characters.</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group email required">
								<input type="email" v-model.trim="$v.email.$model" name="email" placeholder="Email" id="clinical-email" required maxlength="254" :class="{ 'form-control': true, 'is-invalid': $v.email.$dirty && $v.email.$error }" />
								<div class="invalid-feedback" v-if="$v.email.$dirty && !$v.email.required">Email address is required.</div>
								<div class="invalid-feedback" v-if="$v.email.$dirty && !$v.email.email">Email address has to be valid.</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group text required">
								<input type="text" v-model.trim="$v.user_attribute.practice.name.$model" name="user_attribute[practice][name]" placeholder="Practice Name" id="clinical-practice-name" required maxlength="255" :class="{ 'form-control': true, 'is-invalid': $v.user_attribute.practice.name.$dirty && $v.user_attribute.practice.name.$error }" />
								<div class="invalid-feedback" v-if="$v.user_attribute.practice.name.$dirty && !$v.user_attribute.practice.name.required">Practice name is required.</div>
								<div class="invalid-feedback" v-if="$v.user_attribute.practice.name.$dirty && !$v.user_attribute.practice.name.minLength">Practice name has a minimum of 3 characters.</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group select required">
								<label for="user-attribute-practice-location-id" class="control-label">Country</label>
								<multiselect
									v-model.trim="selectedCountry"
									:options="countryOptions"
									placeholder="Select your country..."
									label="alias"
									track-by="id"
									deselect-label=""
									aria-invalid="false"
									id="user-attribute-practice-location-id"
									required
									:class="{ 'is-invalid': $v.user_attribute.practice.location_id.$dirty && $v.user_attribute.practice.location_id.$error }"
								>
									<span slot="noResult">No matching countries found.</span>
								</multiselect>
								<input type="hidden" name="user_attribute[practice][location_id]" :value="user_attribute.practice.location_id" />
								<div class="invalid-feedback" v-if="$v.user_attribute.practice.location_id.$dirty && !$v.user_attribute.practice.location_id.required">Country is required.</div>
							</div>
						</div>
					</div>
					<div v-if="user_attribute.practice.location_id === 1" class="form-row">
						<div class="col">
							<div class="form-group select required">
								<label for="user-attribute-practice-state" class="control-label">State</label>
								<select v-model="user_attribute.practice.state" :class="{ 'form-control': true, 'is-invalid': $v.user_attribute.practice.state.$dirty && $v.user_attribute.practice.state.$error }" name="user_attribute[practice][state]" id="user-attribute-practice-state">
									<option value="AL">Alabama</option>
									<option value="AK">Alaska</option>
									<option value="AZ">Arizona</option>
									<option value="AR">Arkansas</option>
									<option value="CA">California</option>
									<option value="CO">Colorado</option>
									<option value="CT">Connecticut</option>
									<option value="DE">Delaware</option>
									<option value="FL">Florida</option>
									<option value="GA">Georgia</option>
									<option value="HI">Hawaii</option>
									<option value="ID">Idaho</option>
									<option value="IL">Illinois</option>
									<option value="IN">Indiana</option>
									<option value="IA">Iowa</option>
									<option value="KS">Kansas</option>
									<option value="KY">Kentucky</option>
									<option value="LA">Louisiana</option>
									<option value="ME">Maine</option>
									<option value="MD">Maryland</option>
									<option value="MA">Massachusetts</option>
									<option value="MI">Michigan</option>
									<option value="MN">Minnesota</option>
									<option value="MS">Mississippi</option>
									<option value="MO">Missouri</option>
									<option value="MT">Montana</option>
									<option value="NE">Nebraska</option>
									<option value="NV">Nevada</option>
									<option value="NH">New Hampshire</option>
									<option value="NJ">New Jersey</option>
									<option value="NM">New Mexico</option>
									<option value="NY">New York</option>
									<option value="NC">North Carolina</option>
									<option value="ND">North Dakota</option>
									<option value="OH">Ohio</option>
									<option value="OK">Oklahoma</option>
									<option value="OR">Oregon</option>
									<option value="PA">Pennsylvania</option>
									<option value="RI">Rhode Island</option>
									<option value="SC">South Carolina</option>
									<option value="SD">South Dakota</option>
									<option value="TN">Tennessee</option>
									<option value="TX">Texas</option>
									<option value="UT">Utah</option>
									<option value="VT">Vermont</option>
									<option value="VA">Virginia</option>
									<option value="WA">Washington</option>
									<option value="WV">West Virginia</option>
									<option value="WI">Wisconsin</option>
									<option value="WY">Wyoming</option>
								</select>
								<div class="invalid-feedback" v-if="$v.user_attribute.practice.state.$dirty && (!$v.user_attribute.practice.state.required || !$v.user_attribute.practice.state.minLength || !$v.user_attribute.practice.state.maxLength)">State is required.</div>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col">
							<div class="card bg-light">
								<div class="card-body">
									<div class="required">
										<label class="control-label">Practice Role</label>
										<div class="form-group select">
											<select v-model="user_attribute.practice_role_id" @change="onChangePracticeRole" :class="{ 'form-control': true, 'is-invalid': $v.user_attribute.practice_role_id.$dirty && $v.user_attribute.practice_role_id.$error }" name="user_attribute[practice_role_id]" id="user-attribute-practice-role-id">
												<option v-for="practiceRoleOption in practiceRoleOptions" :value="practiceRoleOption.id">{{ practiceRoleOption.label }}</option>
											</select>
											<div class="invalid-feedback" v-if="$v.user_attribute.practice_role_id.$dirty && (!$v.user_attribute.practice_role_id.required || !$v.user_attribute.practice_role_id.numeric)">Practice role is required.</div>
										</div>
									</div>
									<div v-if="npiRequired" class="form-group text">
										<input type="text" v-model.trim="$v.user_attribute.npi.$model" name="user_attribute[npi]" placeholder="NPI" id="npi" maxlength="10" :class="{ 'form-control': true, 'is-invalid': $v.user_attribute.npi.$dirty && $v.user_attribute.npi.$error }" />
										<div class="invalid-feedback" v-if="$v.user_attribute.npi.$dirty && !$v.user_attribute.npi.required">NPI is required.</div>
										<div class="invalid-feedback" v-if="$v.user_attribute.npi.$dirty && !$v.user_attribute.npi.numeric">NPI can only contain numbers.</div>
										<div class="invalid-feedback" v-if="$v.user_attribute.npi.$dirty && $v.user_attribute.npi.numeric && (!$v.user_attribute.npi.minLength || !$v.user_attribute.npi.maxLength)">Invalid NPI.</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col-6">
							<div class="form-group password required">
								<input type="password" v-model.trim="$v.passwd.$model" name="passwd" placeholder="Enter a password" id="clinical-password" required :class="{ 'form-control': true, 'is-invalid': $v.passwd.$dirty && $v.passwd.$error }" />
								<div class="invalid-feedback" v-if="$v.passwd.$dirty && !$v.passwd.required">Password is required.</div>
								<div class="invalid-feedback" v-if="$v.passwd.$dirty && !$v.passwd.minLength">Password has a minimum of 6 characters.</div>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group password">
								<input type="password" v-model.trim="$v.passwd_confirm.$model" name="passwd_confirm" placeholder="Re-enter your password" id="clinical-password-confirm" :class="{ 'form-control': true, 'is-invalid': $v.passwd_confirm.$dirty && $v.passwd_confirm.$error }" />
								<div class="invalid-feedback" v-if="$v.passwd_confirm.$dirty && !$v.passwd_confirm.sameAsPasswd">Password re-entry has to match.</div>
							</div>
						</div>
					</div>
					<div v-if="user_attribute.practice.location_id !== null && specialtyOptions.length > 1" class="form-row mb-3">
						<div class="col">
							<div class="card bg-light">
								<div class="card-body">
									<div class="form-group select">
										<label class="control-label"><h5>Your specialties</h5></label>
										<treeselect
											:multiple="true"
											:options="specialtyOptions"
											v-model="tag_list"
											:flat="true"
											:value-consists-of="'ALL_WITH_INDETERMINATE'"
											:normalizer="normalizer"
											placeholder="Select one or more"
											:class="{ 'is-invalid': $v.tag_list.$dirty && $v.tag_list.$error }"
										/>
										<div class="invalid-feedback" v-if="$v.tag_list.$dirty && (!$v.tag_list.required || !$v.tag_list.minLength)">At least one specialty is required.</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center">
						<button type="submit" class="btn btn-large btn-success">Register</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
//import uniqueId from 'lodash/uniqueId'
import { mapState } from 'vuex'
import { email, minLength, maxLength, numeric, required, sameAs } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'

export default {
	name: 'ClinicalRegistration',
	props: {
		containerId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			normalizer(node) {
				if (node.children && node.children.length) {
					return {
						//id: uniqueId(node.label),
						id: node.label,
						label: node.label,
						children: node.children
					}
				} else {
					return {
						//id: uniqueId(node.label),
						id: node.label,
						label: node.label,
						children: undefined
					}
				}
			},
			countryOptions: [],
			practiceRoleOptions: [],
			specialtyOptions: [],
			email: '',
			full_name: '',
			passwd: '',
			passwd_confirm: '',
			tag_list: [],
			user_attribute: {
				practice_role_id: null,
				npi: null,
				practice: {
					name: '',
					location_id: null,
					state: ''
				}
			},
			csrfToken: '',
			gRecaptchaToken: '',
			unsubscribe: null
		}
	},
	computed: {
		...mapState({
			invite: state => state.invite,
			campaign: state => state.campaign
		}),
		selectedCountry: {
			get() {
				return this.countryOptions.find(countryOption => countryOption.id === this.user_attribute.practice.location_id)
			},
			set(v) {
				if (v) {
					this.$set(this.user_attribute.practice, 'location_id', v.id)
				}
			}
		},
		npiRequired() {
			if (this.user_attribute.practice.location_id === 1 && this.user_attribute.practice_role_id !== null) {
				const selectedPracticeRole = this.practiceRoleOptions.find(practiceRoleOption => practiceRoleOption.id === this.user_attribute.practice_role_id)
				return selectedPracticeRole && selectedPracticeRole.rules && !!parseInt(selectedPracticeRole.rules.npi_required, 10);
			}
			
			return false;
		}
	},
	validations() {
		const validations = {
			email: {
				required,
				email
			},
			full_name: {
				required,
				minLength: minLength(3)
			},
			passwd: {
				required,
				minLength: minLength(6)
			},
			passwd_confirm: {
				sameAsPasswd: sameAs('passwd')
			},
			tag_list: {},
			user_attribute: {
				practice_role_id: {
					required,
					numeric
				},
				npi: {},
				practice: {
					name: {
						required,
						minLength: minLength(3)
					},
					location_id: {
						required
					},
					state: {}
				}
			}
		}
		
		if (this.npiRequired) {
			validations.user_attribute.npi = {
				required,
				numeric,
				minLength: minLength(10),
				maxLength: maxLength(10)
			}
		}
		
		if (this.user_attribute.practice.location_id === 1) {
			validations.user_attribute.practice.state = {
				required,
				minLength: minLength(2),
				maxLength: maxLength(2)
			}
		}
		
		if (this.specialtyOptions.length > 0) {
			validations.tag_list = {
				required,
				minLength: minLength(1)
			}
		}
		
		return validations
	},
	mounted() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'SET_INVISIBLE_RECAPTCHA' && state.invisibleRecaptcha.originComponent === this.$options.name) {
				this.recaptchaCallback(state.invisibleRecaptcha.token);
				
				this.unsubscribe()
			}
		})
		
		this.$http.get('funcs/countries')
			.then(response => {
				this.countryOptions = response.data.result.countries
			})
		this.$http.get('funcs/specialties')
			.then(response => {
				this.practiceRoleOptions = response.data.result.specialties
			})
	},
	beforeDestroy() {
		typeof this.unsubscribe === 'function' && this.unsubscribe()
	},
	watch: {
		'user_attribute.practice.location_id': function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$set(this.user_attribute.practice, 'state', '')
			}
		}
	},
	methods: {
		onChangePracticeRole(e) {
			this.$http.get('funcs/specialties/' + e.target.value)
				.then(response => {
					this.specialtyOptions = response.data.result.specialties
					
					this.tag_list = []
					
					if (response.data.result.specialties.length === 1 && response.data.result.specialties[0].children.length === 0) {
						this.tag_list.push(response.data.result.specialties[0].label)
					}
				})
		},
		submit() {
			this.$v.$touch()
			
			if (!this.$v.$invalid) {
				this.$root.$children[0].$refs.invisibleRecaptcha.execute(this.$options.name)
			}
		},
		recaptchaCallback(token) {
			this.gRecaptchaToken = token
			
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.clinicalRegistrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		}
	},
	components: {
		Multiselect,
		Treeselect
	}
}
</script>