var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "bio-masthead w-100" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-center p-5 bio-info",
            },
            [
              _c("div", [
                _c("h1", [_vm._v("Katherine Brouillet")]),
                _vm._v(" "),
                _c(
                  "h3",
                  { staticClass: "my-4 font-weight-bold text-uppercase" },
                  [_vm._v("Administrative & Research Assistant")]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/in/katherine-brouillet/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/LinkedIn-Icon.svg"),
                          expression:
                            "require('@assets/img/about/bios/LinkedIn-Icon.svg')",
                        },
                      ],
                      staticClass: "d-block",
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-end bio-headshot",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/about/headshot-20.jpg"),
                    expression: "require('@assets/img/about/headshot-20.jpg')",
                  },
                ],
                staticClass: "img-fluid",
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c("router-link", { attrs: { to: { name: "About" }, exact: "" } }, [
              _vm._v("About"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("authored-reports", { attrs: { authorId: 9454 } }),
      _vm._v(" "),
      _c("section", { staticClass: "bio managed-products" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "row justify-content-center px-5" }, [
            _c("div", { staticClass: "col-md-6 col-lg-4 p-2" }, [
              _c("div", { staticClass: "card border-0 rounded-0 text-white" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/about/bios/custom-and-primary-research.jpg"),
                      expression:
                        "require('@assets/img/about/bios/custom-and-primary-research.jpg')",
                    },
                  ],
                  staticClass: "card-img py-4 px-5",
                  attrs: { alt: "Custom & Primary Research Solutions" },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-img-overlay text-center" },
                  [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-success py-2 px-3 font-weight-bold",
                        attrs: { to: { name: "PrimaryResearch" } },
                      },
                      [_vm._v("LEARN MORE")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("combined-registration", { attrs: { origin: "bio" } }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Phone: "),
      _c("a", { attrs: { href: "tel:+13148350600" } }, [
        _vm._v("314-835-0600"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Email: "),
      _c("a", { attrs: { href: "mailto:katherine@market-scope.com" } }, [
        _vm._v("katherine@market-scope.com"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Katherine Brouillet")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bio content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row px-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mx-md-5" }, [
              _c("h4", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "Katherine Brouillet serves as the office administrator, keeping all things humming along, while also assisting with primary market research, provider recruitment, retention, and satisfaction."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Katherine joined the Market Scope team in late 2021 after graduating from the University of Central Missouri with a B.S. in psychology. As the administrator, she oversees the day-to-day operations of the office. In her role as a research assistant, Katherine manages Market Scope’s quarterly ophthalmologist surveys, works to recruit clinical users, and supports the research manager with primary research projects."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Outside of work, Katherine enjoys exploring St. Louis, nurturing her green thumb, and spending time with friends and family."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "mt-4 mb-5" }, [
          _vm._v("Managed Products & Services"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "card-title" }, [
      _vm._v("Custom & Primary"),
      _c("br"),
      _vm._v("Research Solutions"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }