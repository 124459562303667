var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", {
        attrs: { subpage: true, requestedProductId: _vm.requestedProductId },
      }),
      _vm._v(" "),
      _c("header", { staticClass: "reports-masthead" }),
      _vm._v(" "),
      _vm.product
        ? _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Landing" }, exact: "" } },
                  [_vm._v("Home")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Reports" }, exact: "" } },
                  [_vm._v("Reports")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _c("span", [_vm._v(_vm._s(_vm.product.label))]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "reports", attrs: { id: "reports" } }, [
        _c("div", { staticClass: "container header-row" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "mx-auto text-center" },
              [
                _c(
                  "router-link",
                  {
                    class: { btn: true, "btn-primary": true },
                    attrs: {
                      to: { name: "Reports", query: { page: 1 } },
                      exact: "",
                    },
                  },
                  [
                    _c("i", { staticClass: "fas fa-list" }),
                    _vm._v(" Show Latest Reports"),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container content-row" }, [
          _vm.product
            ? _c(
                "div",
                {
                  class: {
                    row: true,
                    "mb-1":
                      _vm.product &&
                      (_vm.product.related_subscriptions.length ||
                        _vm.product.related_products.length),
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-4 report-sample" },
                    [
                      _vm.product.document_cover_filename ||
                      _vm.product.document_sample_page_images.length
                        ? [
                            _c("div", [
                              _vm.product.document_cover_filename
                                ? _c("img", {
                                    directives: [
                                      {
                                        name: "lazy",
                                        rawName: "v-lazy",
                                        value:
                                          "/files/products/covers/" +
                                          _vm.product.id +
                                          "/" +
                                          _vm.product.document_cover_filename,
                                        expression:
                                          "'/files/products/covers/' + product.id + '/' + product.document_cover_filename",
                                      },
                                    ],
                                    staticClass: "img-fluid",
                                    attrs: {
                                      alt: _vm.product.cover_img_alt
                                        ? _vm.product.cover_img_alt
                                        : _vm.product.label,
                                    },
                                  })
                                : _vm.product.document_sample_page_images.length
                                ? _c("img", {
                                    directives: [
                                      {
                                        name: "lazy",
                                        rawName: "v-lazy",
                                        value:
                                          "/files/products/samples/" +
                                          _vm.product.id +
                                          "/" +
                                          _vm.product
                                            .document_sample_page_images[0],
                                        expression:
                                          "'/files/products/samples/' + product.id + '/' + product.document_sample_page_images[0]",
                                      },
                                    ],
                                    staticClass: "img-fluid",
                                    attrs: {
                                      alt: _vm.product.cover_img_alt
                                        ? _vm.product.cover_img_alt
                                        : _vm.product.label,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.product.published
                                ? _c(
                                    "div",
                                    {
                                      class: {
                                        "unpublished-content": true,
                                        "has-video":
                                          _vm.product.youtube_video_id,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          style: {
                                            top:
                                              "calc(50% - " +
                                              (_vm.product.coming_soon_text
                                                ? "42"
                                                : "21") +
                                              "px)",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-clock",
                                          }),
                                          _vm._v(" COMING SOON"),
                                          _vm.product.coming_soon_text
                                            ? [
                                                _c("br"),
                                                _c("small", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.product
                                                        .coming_soon_text
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm.product.youtube_video_id
                                        ? _c(
                                            "a",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.$modal.show(
                                                    "reportVideoModal",
                                                    {
                                                      header:
                                                        _vm.product.product_type
                                                          .name,
                                                      subheader:
                                                        _vm.product.attributes,
                                                      videoId:
                                                        _vm.product
                                                          .youtube_video_id,
                                                    }
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "play-btn" },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        viewBox:
                                                          "0 0 311.69 311.69",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          d: "M155.84,0A155.85,155.85,0,1,0,311.69,155.84,155.84,155.84,0,0,0,155.84,0Zm0,296.42A140.58,140.58,0,1,1,296.42,155.84,140.58,140.58,0,0,1,155.84,296.42Z",
                                                        },
                                                      }),
                                                      _c("polygon", {
                                                        attrs: {
                                                          points:
                                                            "218.79 155.84 119.22 94.34 119.22 217.34 218.79 155.84",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.product.published &&
                              _vm.product.youtube_video_id
                                ? _c("div", { staticClass: "has-video" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.$modal.show(
                                              "reportVideoModal",
                                              {
                                                header:
                                                  _vm.product.product_type.name,
                                                subheader:
                                                  _vm.product.attributes,
                                                videoId:
                                                  _vm.product.youtube_video_id,
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "play-btn" }, [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 311.69 311.69",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d: "M155.84,0A155.85,155.85,0,1,0,311.69,155.84,155.84,155.84,0,0,0,155.84,0Zm0,296.42A140.58,140.58,0,1,1,296.42,155.84,140.58,140.58,0,0,1,155.84,296.42Z",
                                                },
                                              }),
                                              _c("polygon", {
                                                attrs: {
                                                  points:
                                                    "218.79 155.84 119.22 94.34 119.22 217.34 218.79 155.84",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-3" }, [
                              _c(
                                "div",
                                { staticClass: "float-left mt-1 mr-1 mb-3" },
                                [
                                  _vm.product.document_brochure_filename
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-primary download-link",
                                          attrs: {
                                            target: "_blank",
                                            href:
                                              "/files/products/brochures/" +
                                              _vm.product.id +
                                              "/" +
                                              _vm.product
                                                .document_brochure_filename,
                                          },
                                        },
                                        [_vm._v("Brochure")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "float-left mt-1 mr-1 mb-3" },
                                [
                                  _vm.product.product_skus.length
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-sm btn-primary",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.showRequestTocModal(
                                                _vm.product.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Request a Sample")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.product.product_type.plans.length
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-sm btn-primary",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.showRequestSampleModal(
                                                _vm.product.product_type.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Request a Sample")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "float-left mt-1 mb-3" },
                                [
                                  _vm.product.published
                                    ? _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "scroll-to",
                                              rawName: "v-scroll-to",
                                              value: "#register",
                                              expression: "'#register'",
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-sm btn-purchase",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.requestProduct(
                                                _vm.product
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isPaid
                                                ? "Purchase"
                                                : "Download"
                                            )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "scroll-to",
                                              rawName: "v-scroll-to",
                                              value: "#register",
                                              expression: "'#register'",
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-sm btn-purchase",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.requestUnpublishedProduct(
                                                _vm.product
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Notify Me")]
                                      ),
                                ]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-8 report-info" },
                    [
                      _c("h3", [_vm._v(_vm._s(_vm.product.product_type.name))]),
                      _vm._v(" "),
                      _c("h1", [_vm._v(_vm._s(_vm.product.attributes))]),
                      _vm._v(" "),
                      _c(
                        "b-tabs",
                        {
                          ref: "tabs",
                          model: {
                            value: _vm.activeTab,
                            callback: function ($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab",
                          },
                        },
                        [
                          _vm.product.whats_new_summary
                            ? _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: "What's New",
                                    active: !!_vm.product.whats_new_summary,
                                  },
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.product.whats_new_summary
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.product.description
                            ? _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: "Description",
                                    active:
                                      !_vm.product.whats_new_summary &&
                                      !!_vm.product.description,
                                  },
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.product.description
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.product.contents_summary
                            ? _c("b-tab", { attrs: { title: "Contents" } }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.product.contents_summary
                                    ),
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.product.methodology_summary
                            ? _c("b-tab", { attrs: { title: "Methodology" } }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.product.methodology_summary
                                    ),
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            {
                              attrs: {
                                title: "Pricing",
                                active:
                                  !_vm.product.whats_new_summary &&
                                  !_vm.product.description,
                              },
                            },
                            [
                              _vm.product.product_skus.length
                                ? _c(
                                    "div",
                                    [
                                      _vm.product.product_skus.length > 1
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.product.product_skus.length
                                              ) +
                                                " licensing types are available for this report:"
                                            ),
                                          ])
                                        : _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.product.product_skus.length
                                              ) +
                                                " licensing type is available for this report:"
                                            ),
                                          ]),
                                      _vm._v(" "),
                                      _c("ul", [
                                        _vm.singleUserPrices.length
                                          ? _c("li", [
                                              _vm._v("The price of the "),
                                              _c("strong", [
                                                _vm._v("single-user license"),
                                              ]),
                                              _vm._v(
                                                " is " +
                                                  _vm._s(
                                                    _vm.singleUserPrices[0]
                                                      .price
                                                  ) +
                                                  "."
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.enterprisePrices.length
                                          ? _c(
                                              "li",
                                              [
                                                _vm._v("The price of the "),
                                                _c("strong", [
                                                  _vm._v("enterprise license"),
                                                ]),
                                                _vm._v(
                                                  " is " +
                                                    _vm._s(
                                                      _vm.enterprisePrices[0]
                                                        .price
                                                    )
                                                ),
                                                _vm.isPaid
                                                  ? [
                                                      _vm._v(
                                                        " (includes supplemental Excel file with all the tables and figures in the report, and report summary slides)"
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm._v("."),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _vm.isPaid
                                        ? [
                                            _vm.product.product_skus.length > 1
                                              ? _c("p", [
                                                  _vm._v(
                                                    "Both licenses include an electronic PDF. Printed copies may be purchased for an additional $250."
                                                  ),
                                                ])
                                              : _c("p", [
                                                  _vm._v(
                                                    "The license includes an electronic PDF. Printed copies may be purchased for an additional $250."
                                                  ),
                                                ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.product.product_type.plans.length
                                ? _c(
                                    "div",
                                    [
                                      _vm.product.product_type.plans.length > 1
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.product.product_type.plans
                                                  .length
                                              ) +
                                                " licensing types are available for this subscription:"
                                            ),
                                          ])
                                        : _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.product.product_type.plans
                                                  .length
                                              ) +
                                                " licensing type is available for this subscription:"
                                            ),
                                          ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.singleUserPrices,
                                        function (singleUserPrice) {
                                          return _c("ul", [
                                            _c("li", [
                                              _vm._v(
                                                "The price of the " +
                                                  _vm._s(
                                                    singleUserPrice.interval
                                                  ) +
                                                  " "
                                              ),
                                              _c("strong", [
                                                _vm._v("single-user license"),
                                              ]),
                                              _vm._v(
                                                " is " +
                                                  _vm._s(
                                                    singleUserPrice.price
                                                  ) +
                                                  "."
                                              ),
                                            ]),
                                          ])
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.enterprisePrices,
                                        function (enterprisePrice) {
                                          return _c("ul", [
                                            _c("li", [
                                              _vm._v(
                                                "The price of the " +
                                                  _vm._s(
                                                    enterprisePrice.interval
                                                  ) +
                                                  " "
                                              ),
                                              _c("strong", [
                                                _vm._v("enterprise license"),
                                              ]),
                                              _vm._v(
                                                " is " +
                                                  _vm._s(
                                                    enterprisePrice.price
                                                  ) +
                                                  "."
                                              ),
                                            ]),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.product.licensing_summary
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.product.authors.length
                            ? _c(
                                "b-tab",
                                {
                                  staticClass: "author-profiles",
                                  attrs: {
                                    lazy: "",
                                    title:
                                      _vm.product.product_type.author_label +
                                      " Profiles",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.product.authors,
                                    function (author) {
                                      return [
                                        !!author.user_attribute.author_profile
                                          ? [
                                              _c("h4", [
                                                author.user_attribute.title
                                                  ? _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          author.formal_name
                                                        ) +
                                                          ", " +
                                                          _vm._s(
                                                            author
                                                              .user_attribute
                                                              .title
                                                          )
                                                      ),
                                                    ])
                                                  : _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          author.formal_name
                                                        )
                                                      ),
                                                    ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    author.user_attribute
                                                      .author_profile
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("authored-reports", {
                                                attrs: {
                                                  authorId: author.uid,
                                                  productId: _vm.product.id,
                                                  cardsPerRowMd: 1,
                                                  cardsPerRowLg: 2,
                                                  cardsPerRowXl: 3,
                                                  componentStyle: {
                                                    padding: 0,
                                                    backgroundColor:
                                                      "transparent",
                                                  },
                                                  headerText:
                                                    "Additional Authored Reports",
                                                  headerClasses: [],
                                                  headerFontSize: "28px",
                                                  labelFontSize: "1.15rem",
                                                  rowPadding: 0,
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "no-ssr",
                        [
                          _c("vue-goodshare-linked-in", {
                            attrs: { has_icon: "" },
                          }),
                          _vm._v(" "),
                          _c("vue-goodshare-facebook", {
                            attrs: { has_icon: "" },
                          }),
                          _vm._v(" "),
                          _c("vue-goodshare-twitter", {
                            attrs: { has_icon: "" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "button-social email__design__flat mr-auto",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$modal.show("shareProductModal", {
                                productId: _vm.product.id,
                              })
                            },
                          },
                        },
                        [_c("i", { staticClass: "icon-email" })]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "div",
                {
                  class: {
                    "mb-4":
                      _vm.product &&
                      (_vm.product.related_subscriptions.length ||
                        _vm.product.related_products.length),
                  },
                },
                [_vm._m(0)]
              ),
          _vm._v(" "),
          _vm.product &&
          (_vm.product.related_subscriptions.length ||
            _vm.product.related_products.length)
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h5", [_vm._v("Related Reports")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "container related-products" }, [
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-start" },
                      [
                        _vm._l(
                          _vm.product.related_products,
                          function (relatedProduct) {
                            return _c(
                              "div",
                              { staticClass: "col-md-3 p-1 bg-white border" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value:
                                          _vm.product.product_type.name +
                                          ": " +
                                          relatedProduct.attributes,
                                        expression:
                                          "product.product_type.name + ': ' + relatedProduct.attributes",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass: "d-block",
                                    attrs: {
                                      to: {
                                        name: "Report",
                                        params: {
                                          id: relatedProduct.id,
                                          slug: relatedProduct.slug,
                                        },
                                        hash: "#reports",
                                      },
                                    },
                                  },
                                  [
                                    relatedProduct.document_original_filename ||
                                    relatedProduct.document_sample_filename
                                      ? _c("img", {
                                          directives: [
                                            {
                                              name: "lazy",
                                              rawName: "v-lazy",
                                              value:
                                                "/files/products/samples/" +
                                                relatedProduct.id +
                                                "/" +
                                                (relatedProduct.document_sample_filename
                                                  ? relatedProduct.document_sample_filename.substr(
                                                      0,
                                                      relatedProduct.document_sample_filename.lastIndexOf(
                                                        "."
                                                      )
                                                    )
                                                  : relatedProduct.document_original_filename.substr(
                                                      0,
                                                      relatedProduct.document_original_filename.lastIndexOf(
                                                        "."
                                                      )
                                                    )) +
                                                ".thumb.jpg",
                                              expression:
                                                "'/files/products/samples/' + relatedProduct.id + '/' + (relatedProduct.document_sample_filename ? relatedProduct.document_sample_filename.substr(0, relatedProduct.document_sample_filename.lastIndexOf('.')) : relatedProduct.document_original_filename.substr(0, relatedProduct.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                            },
                                          ],
                                          staticClass:
                                            "img-thumbnail d-block mx-auto border-0",
                                          attrs: {
                                            alt: relatedProduct.cover_img_alt
                                              ? relatedProduct.cover_img_alt
                                              : _vm.product.product_type.name +
                                                ": " +
                                                relatedProduct.attributes,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.product.related_subscriptions,
                          function (relatedSubscription) {
                            return _c(
                              "div",
                              { staticClass: "col-md-3 p-1 bg-white border" },
                              [
                                relatedSubscription.products.length
                                  ? _c(
                                      "router-link",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            value:
                                              relatedSubscription.products[0]
                                                .product_type.name,
                                            expression:
                                              "relatedSubscription.products[0].product_type.name",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        staticClass: "d-block",
                                        attrs: {
                                          to: {
                                            name: "Report",
                                            params: {
                                              id: relatedSubscription
                                                .products[0].id,
                                              slug: relatedSubscription
                                                .products[0].slug,
                                            },
                                            hash: "#reports",
                                          },
                                        },
                                      },
                                      [
                                        relatedSubscription.products[0]
                                          .document_original_filename ||
                                        relatedSubscription.products[0]
                                          .document_sample_filename
                                          ? _c("img", {
                                              directives: [
                                                {
                                                  name: "lazy",
                                                  rawName: "v-lazy",
                                                  value:
                                                    "/files/products/samples/" +
                                                    relatedSubscription
                                                      .products[0].id +
                                                    "/" +
                                                    (relatedSubscription
                                                      .products[0]
                                                      .document_sample_filename
                                                      ? relatedSubscription.products[0].document_sample_filename.substr(
                                                          0,
                                                          relatedSubscription.products[0].document_sample_filename.lastIndexOf(
                                                            "."
                                                          )
                                                        )
                                                      : relatedSubscription.products[0].document_original_filename.substr(
                                                          0,
                                                          relatedSubscription.products[0].document_original_filename.lastIndexOf(
                                                            "."
                                                          )
                                                        )) +
                                                    ".thumb.jpg",
                                                  expression:
                                                    "'/files/products/samples/' + relatedSubscription.products[0].id + '/' + (relatedSubscription.products[0].document_sample_filename ? relatedSubscription.products[0].document_sample_filename.substr(0, relatedSubscription.products[0].document_sample_filename.lastIndexOf('.')) : relatedSubscription.products[0].document_original_filename.substr(0, relatedSubscription.products[0].document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                },
                                              ],
                                              staticClass:
                                                "img-thumbnail d-block mx-auto border-0",
                                              attrs: {
                                                alt: relatedSubscription
                                                  .products[0].cover_img_alt
                                                  ? relatedSubscription
                                                      .products[0].cover_img_alt
                                                  : relatedSubscription
                                                      .products[0].product_type
                                                      .name,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !relatedSubscription.products[0]
                                          .published
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "unpublished-content",
                                              },
                                              [
                                                _c("span", [
                                                  _c("i", {
                                                    staticClass: "fa fa-clock",
                                                  }),
                                                  _vm._v(" COMING SOON"),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("register", {
        attrs: {
          headerText:
            "Want to " +
            (_vm.isPaid ? "Purchase" : "Download") +
            " the Full Report?",
          requestedProductId: _vm.requestedProductId,
        },
      }),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("request-sample"),
      _vm._v(" "),
      _c("request-toc"),
      _vm._v(" "),
      _c("share-product"),
      _vm._v(" "),
      _c("report-video"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-auto" }, [
      _c("h4", [_vm._v("No report found.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }