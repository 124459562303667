var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.products.length
    ? _c(
        "section",
        { staticClass: "bio authored-reports", style: _vm.componentStyle },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col text-center" }, [
                _c(
                  "h3",
                  {
                    class: _vm.headerClasses,
                    style: { fontSize: _vm.headerFontSize },
                  },
                  [_vm._v(_vm._s(_vm.headerText))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: {
                  row: true,
                  "justify-content-center": true,
                  ["px-" + _vm.rowPadding]: true,
                },
              },
              _vm._l(_vm.products, function (product) {
                return _c(
                  "div",
                  {
                    class: {
                      ["col-md-" + 12 / _vm.cardsPerRowMd]: true,
                      ["col-lg-" + 12 / _vm.cardsPerRowLg]: true,
                      ["col-xl-" + 12 / _vm.cardsPerRowXl]: true,
                      "p-2": true,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card border-0 rounded-0 overflow-hidden text-white",
                      },
                      [
                        product.cover_img
                          ? _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: product.cover_img,
                                  expression: "product.cover_img",
                                },
                              ],
                              staticClass: "card-img py-4 px-5",
                              attrs: {
                                alt: product.cover_img_alt
                                  ? product.cover_img_alt
                                  : product.label,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-img-overlay text-center" },
                          [
                            product.product_skus.length
                              ? _c(
                                  "h5",
                                  {
                                    staticClass: "card-title",
                                    style: { fontSize: _vm.labelFontSize },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        product.product_type_attributes[0]
                                          ._joinData.value
                                      )
                                    ),
                                  ]
                                )
                              : product.product_type.plans.length
                              ? _c(
                                  "h5",
                                  {
                                    staticClass: "card-title",
                                    style: { fontSize: _vm.labelFontSize },
                                  },
                                  [_vm._v(_vm._s(product.label))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-sm btn-success py-2 px-3 font-weight-bold",
                                attrs: {
                                  to: {
                                    name: "Report",
                                    params: {
                                      id: product.id,
                                      slug: product.slug,
                                    },
                                    hash: "#reports",
                                  },
                                },
                              },
                              [_vm._v("VIEW REPORT")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }