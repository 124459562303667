import { render, staticRenderFns } from "./CombinedRegistration.vue?vue&type=template&id=79ca056e&scoped=true"
import script from "./CombinedRegistration.vue?vue&type=script&lang=js"
export * from "./CombinedRegistration.vue?vue&type=script&lang=js"
import style0 from "./CombinedRegistration.vue?vue&type=style&index=0&id=79ca056e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ca056e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/staging.market-scope.com/front/landing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79ca056e')) {
      api.createRecord('79ca056e', component.options)
    } else {
      api.reload('79ca056e', component.options)
    }
    module.hot.accept("./CombinedRegistration.vue?vue&type=template&id=79ca056e&scoped=true", function () {
      api.rerender('79ca056e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/forms/CombinedRegistration.vue"
export default component.exports