<template>
	<modal name="shareArticleModal" @before-open="beforeOpen" @opened="opened" @closed="closed" height="auto" :scrollable="true" :reset="true" :clickToClose="clickToClose" :adaptive="true" transition="nice-modal-fade">
		<form novalidate="novalidate" role="form" v-promise-btn="{ action: 'submit' }" @submit.prevent="share" v-on:keyup.esc="$modal.hide('shareArticleModal')">
			<div class="modal-header">
				<h4 class="modal-title">Share Article Sample</h4>
				<button type="button" class="close" @click="$modal.hide('shareArticleModal')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body pb-2">
				<div class="form-row bg-light">
					<div class="col-md-12 mb-1">To:</div>
					<div class="form-group col-md-6 mb-1">
						<input v-model="form.toName" type="text" class="form-control" name="to-name" ref="toName" placeholder="Enter recipient's name" />
					</div>
					<div class="form-group col-md-6 mb-1">
						<input v-model="form.toEmail" type="text" class="form-control" name="to-email" placeholder="Enter recipient's email" />
					</div>
				</div>
				<div class="form-row bg-light pb-1 mt-3">
					<div class="col-md-12 mb-1">From:</div>
					<div class="form-group col-md-6 mb-0">
						<input v-model="form.fromName" type="text" class="form-control" name="from-name" placeholder="Enter your name" />
					</div>
					<div class="form-group col-md-6 mb-0">
						<input v-model="form.fromEmail" type="text" class="form-control" name="from-email" placeholder="Enter your email" />
					</div>
					<div class="col-md-12">
						<small class="form-text text-muted">The email will be sent by us, on behalf of you.</small>
					</div>
				</div>
				<div class="form-group mt-3">
					<input v-model="form.message" type="text" class="form-control" name="message" placeholder="Add a message (optional)" id="message" />
				</div>
				<div class="form-group mb-0">
					<small class="form-notice">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</small>
				</div>
			</div>
			<div class="modal-footer">
				<div v-if="form.status" v-html="form.status" class="mx-auto"></div>
				<template v-if="!shared">
					<button type="button" class="btn btn-default" @click="$modal.hide('shareArticleModal')">Cancel</button>
					<button type="submit" class="btn btn-primary">Share</button>
				</template>
			</div>
		</form>
	</modal>
</template>

<script>
import { useGoogleRecaptcha } from '../../util/use-google-recaptcha'

export default {
	name: 'ShareArticle',
	data () {
		return {
			articleId: null,
			clickToClose: true,
			shared: false,
			form: {
				toName: '',
				toEmail: '',
				fromName: '',
				fromEmail: '',
				message: '',
				status: ''
			}
		}
	},
	methods: {
		beforeOpen(e) {
			this.articleId = e.params.articleId
		},
		opened(e) {
			this.$refs.toName.focus()
		},
		closed(e) {
			Object.assign(this.form, this.$options.data.apply(this).form)
			
			this.articleId = null
			this.shared = false
		},
		share() {
			this.clickToClose = false
			
			return useGoogleRecaptcha().getRecaptchaToken('share_article').then(token => {
				return this.$http.put('articles/' + this.articleId + '/share-public', {
					toName: this.form.toName,
					toEmail: this.form.toEmail,
					fromName: this.form.fromName,
					fromEmail: this.form.fromEmail,
					message: this.form.message,
					recaptchaToken: token
				})
					.then(response => {
						this.clickToClose = true
						this.shared = true
						
						this.$set(this.form, 'status', '<h4 class="text-success"><i class="fas fa-check-circle"></i> Email sent.</h4>')
						
						const _paq = window._paq = window._paq || []
						_paq.push(['trackEvent', 'Articles', 'Form Submitted', '"Share Article Sample" Modal'])
						
						setTimeout(() => {
							this.$modal.hide('shareArticleModal')
						}, 1500)
					}, response => {
						this.clickToClose = true
						
						this.$set(this.form, 'status', '<span class="text-danger">Invalid name or email.</span>')
					})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
#message {
	background-color: #e9ecef;
	
	&:focus {
		background-color: #fff;
	}
}
</style>