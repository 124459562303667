<template>
	<div>
		<navbar :subpage="true" />
		<header class="atlases-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>United States Ophthalmic Atlases</span>
			</li>
		</ol>
		<section class="atlases" id="atlases">
			<div class="container header-row">
				<div>
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/Research-Icons-07.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h1 class="section-heading">United States Ophthalmic Atlases</h1>
						<p>Market Scope’s United States ophthalmic atlases identify treatment opportunity at the metro level for the top 85 metros—identifying high-opportunity and diseased populations, procedure volumes, premium penetration rates, surgeon counts, surgical locations, and much more. Companies often use this data throughout the development and commercialization processes to identify ideal clinical trial locations, develop launch strategies, define sales territories, allocate resources, and optimize sales and marketing initiatives.</p>
						<div class="mx-xl-5">
							<div id="map"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="container mt-3">
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Provided by metro</h3>
							</div>
							<div class="card-body pt-2">
								<ul>
									<li><strong>Identification of high-opportunity population</strong></li>
									<li><strong>Procedure volumes</strong> by type</li>
									<li><strong>Surgeon profiles</strong> by volume category</li>
									<li><strong>ASC profiles</strong>: Counts and average procedure volumes</li>
									<li><strong>Demographic profiles</strong>: Population by age group, median age, household income, and diseased population</li>
									<li><strong>Much more</strong> varying by atlas subspecialty</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Use cases</h3>
							</div>
							<div class="card-body pt-2">
								<ul>
									<li>Development of product launch strategies</li>
									<li>Sales territory analysis</li>
									<li>Clinical trial site selection</li>
									<li>Allocation of resources</li>
									<li>Identifying metro-level market share</li>
									<li>Comparing market potential between metros</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-body">
								<LazyYoutube src="https://youtu.be/GpTc_AUVlfg" :showTitle="false" customThumbnail="https://img.youtube.com/vi/GpTc_AUVlfg/sddefault.jpg" />
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Methodology</h3>
							</div>
							<div class="card-body pt-2">
								<p class="mb-0">Market Scope uses the proprietary MedOp Index™ Analysis to examine market potential and provides a benchmark against other metros. Each clinical area atlas considers multiple factors to identify opportunity. For example, the cataract MedOp Index™ considers the concentration of active, affluent seniors; the number of high-volume cataract surgeons; and ASC counts and volumes, while the retina MedOp Index™ considers annual IVT injections and vitrectomies per provider; the geographic concentration of diabetics; the total population with retinal diseases; and high-volume retinal care providers.</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="products.length" class="row mb-3">
					<div class="col">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Available Atlases</h3>
							</div>
							<div class="card-body pt-2">
								<div class="container">
									<div class="row">
										<div class="card-group">
											<div class="card col-md-3 p-1 bg-white" v-for="product in products">
												<div class="card-body p-0">
													<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug } }" :title="product.label">
														<img v-if="product.document_original_filename || product.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Learn More</h3>
							</div>
							<div class="card-body pt-2">
								<p class="mb-0">Contact us using the button below to request more information on atlas coverage, view a demo, or schedule a time to discuss this live with our team.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="text-center mt-3">
				<button type="button" class="btn btn-success" @click="showContactUsModal">Contact Us</button>
			</div>
		</section>
		<register />
		<footer-section />
		<contact-us />
	</div>
</template>

<script>
import { LazyYoutube } from 'vue-lazytube'

import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'
import ContactUs from './modals/ContactUs'

export default {
	name: 'Atlases',
	metaInfo() {
		return {
			title: 'Ophthalmic Atlases',
			meta: [
				{
					property: 'og:url',
					content: '//www.market-scope.com/atlases',
					vmid: 'og:url'
				},
				{
					name: 'image',
					property: 'og:image',
					content: 'https://www.market-scope.com/static/landing/img/Header-Atlases.jpg',
					vmid: 'og:image'
				}
			],
			link: [
				{
					rel: 'canonical',
					href: 'https://www.market-scope.com/atlases',
					vmid: 'canonical'
				}
			],
			script: [
				{
					src: '/js/mapdata.min.js',
					defer: true,
					async: true,
					callback: () => {
						this.$parent.mapdataLoaded = true
					},
					vmid: 'mapdata'
				},
				{
					src: '/js/usmap.js',
					defer: true,
					async: true,
					skip: !this.$parent.mapdataLoaded,
					callback: () => simplemaps_usmap.load(),
					vmid: 'usmap'
				}
			]
		}
	},
	asyncData({ store }) {
		return store.dispatch('fetchProducts', {
			limit: 4,
			tag: 'atlas'
		})
	},
	computed: {
		products() {
			return this.$store.state.products
		}
	},
	methods: {
		showContactUsModal() {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Reports', 'Opened Modal', '"Contact Us" Modal'])
			
			this.$modal.show('contactUsModal')
		}
	},
	components: {
		LazyYoutube,
		Navbar,
		Register,
		FooterSection,
		ContactUs
	}
}
</script>

<style lang="scss" scoped>
header.atlases-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/Header-Atlases.jpg'), $theme-secondary;
	background: url('~@assets/img/Header-Atlases.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: center;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position: 0 60%;
	}
}

section.atlases {
	.section-heading {
		color: #0a5384;
		margin-bottom: 8px;
	}
	
	.header-row p {
		color: $gray-light;
	}
	
	.card {
		p,
		li {
			font-size: 1rem;
		}
		
		.card-header h3.card-title {
			color: #0a5384;
		}
	}
}
</style>