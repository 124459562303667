<template>
	<b-navbar toggleable="lg" fixed="top" :class="subpage ? 'subpage' : ''" id="mainNav" ref="mainNav">
		<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
		<b-navbar-brand v-if="subpage" to="/">Market Scope</b-navbar-brand>
		<b-navbar-brand v-else>
			<i class="fa fa-phone"></i> 314-835-0600
			<a href="#" @click.prevent="campaign ? $modal.show('campaignLoginModal', {}) : $modal.show('loginModal')"><i class="fa fa-lock"></i> Sign In</a>
			<a href="#" v-scroll-to="'#register'"><i class="fa fa-edit"></i> Join</a>
		</b-navbar-brand>
		<b-navbar-nav v-if="subpage" class="flex-row">
			<b-nav-item href="#" @click.prevent="campaign ? $modal.show('campaignLoginModal', {}) : $modal.show('loginModal')" class="align-self-center login-link"><i class="fa fa-lock"></i> Sign In</b-nav-item>
			<b-nav-item href="#register" v-scroll-to="'#register'" class="ml-2 register-link"><i class="fa fa-edit"></i> Register Now</b-nav-item>
		</b-navbar-nav>
		<b-collapse is-nav id="nav_collapse">
			<b-navbar-nav class="ml-auto">
				<li v-if="$route.name !== 'Article'" class="nav-item">
					<form @submit.prevent id="search-form" class="meilisearch-search-wrapper search-box" role="search">
						<input id="meilisearch-search-input" class="search-query" :placeholder="placeholder" ref="searchInput" :value="searchQuery" @input="updateSearchQuery" />
					</form>
					<b-popover :show="showFirstrun" placement="bottom" target="search-form" triggers="blur" variant="warning">
						<template v-slot:title><strong>New</strong> Instant Search</template>
						Results populate as you type
					</b-popover>
				</li>
				<b-nav-item-dropdown v-if="activeContext === 'combined' || activeContext === 'industry'" text="Products" @shown="alignNavItems" class="full-width" ref="navDropdown">
					<b-dropdown-item to="/pages/reports?page=1" exact>Global &amp; Regional Market Reports</b-dropdown-item>
					<b-dropdown-item to="/ophthalmic-market-trends" exact>Ophthalmic Market Trends</b-dropdown-item>
					<b-dropdown-item to="/atlases">Atlases</b-dropdown-item>
					<b-dropdown-item to="/disease-models" exact>Disease Models</b-dropdown-item>
					<b-dropdown-item to="/ophthalmic-newsletter" exact>Ophthalmic Newsletter</b-dropdown-item>
					<b-dropdown-item to="/pages/reports/deals?page=1" exact>Ophthalmic Deals Analysis</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item v-if="activeContext === 'combined' || activeContext === 'medical'" to="/pages/physicians">Surveys</b-nav-item>
				<b-nav-item v-if="activeContext === 'combined' || activeContext === 'industry'" to="/pages/primary-research">Primary Research</b-nav-item>
				<b-nav-item to="/pages/news">News</b-nav-item>
				<b-nav-item v-if="activeContext === 'combined' || activeContext === 'medical'" to="/plus">Market Scope+</b-nav-item>
				<b-nav-item v-if="activeContext === 'combined' || activeContext === 'industry'" to="/pages/methodology">Methodology</b-nav-item>
				<b-nav-item to="/pages/about">About</b-nav-item>
				<!-- <b-nav-item href="#contact">Contact</b-nav-item> -->
			</b-navbar-nav>
		</b-collapse>
		<campaign-login v-if="campaign" />
		<login v-else :requestedProductId="requestedProductId" />
	</b-navbar>
</template>

<script>
import debounce from 'lodash/debounce'
import VueRouter from 'vue-router'
import { mapState } from 'vuex'
import { BNavbar, BNavbarNav, BNav, BNavItemDropdown, BDropdownItem, BNavItem, BNavbarToggle, BNavbarBrand, BCollapse, VBToggle, VBScrollspy, BPopover } from 'bootstrap-vue'
import Login from './modals/Login'
import CampaignLogin from './modals/CampaignLogin'

const { NavigationFailureType } = VueRouter

export default {
	name: 'Nav',
	props: {
		subpage: {
			type: Boolean,
			default: false
		},
		requestedProductId: {
			type: Number,
			default: null
		},
		activeContext: {
			type: String,
			default: 'combined'
		}
	},
	data() {
		return {
			placeholder: 'Search as you type...',
			showFirstrun: false
		}
	},
	computed: {
		...mapState({
			searchQuery: state => state.searchQuery,
			campaign: state => state.campaign
		})
	},
	created() {
		this.$_debounce = null
	},
	mounted() {
		this.$_debounce = debounce(this.onResize, 250)
		
		// Collapse now if page is not at top
		this.$nextTick(function () {
			this.navbarCollapse()
		})
		
		window.addEventListener('scroll', this.navbarCollapse)
		window.addEventListener('resize', this.$_debounce)
		
		const options = {
			hostUrl: process.env.MEILISEARCH_URL,
			apiKey: process.env.MEILISEARCH_PUBLICKEY,
			indexUid: 'landing',
			meilisearchOptions: {
				limit: 10,
				cropLength: 30
			},
			autocompleteOptions: {
				keyboardShortcuts: ['s', '/'],
				clearOnSelected: false,
				openOnFocus: true,
				autoselect: false
			}
		}
		this.initialize(options)
		
		if (!localStorage.getItem('landing_firstrun')) {
			localStorage.setItem('landing_firstrun', Date.now())
			this.showFirstrun = true
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.navbarCollapse)
		window.removeEventListener('resize', this.$_debounce)
		
		this.$_debounce = null
	},
	methods: {
		initialize(userOptions) {
			import(/* webpackChunkName: "static/landing/js/searchbar" */ '../libraries/searchbar.js/src/lib/main.js')
				.then(searchBar => {
					if (this.$refs.searchInput) {
						searchBar = searchBar.default
						const input = Object.assign({}, userOptions, {
							inputSelector: '#meilisearch-search-input',
							handleSelected: (input, event, suggestion) => {
								this.$refs.searchInput.blur()
								this.$router.push(`${suggestion.url}`)
									.catch(err => {
										if (err.type !== NavigationFailureType.duplicated) {
											throw new Error(err)
										}
									})
							}
						})
						searchBar(input)
					}
				})
		},
		updateSearchQuery(e) {
			this.$root.$emit('bv::hide::popover', 'search-form')
			this.$root.$emit('bv::disable::popover', 'search-form')
			
			this.$store.commit('SET_SEARCH_QUERY', e.target.value)
		},
		navbarCollapse() {
			let mainNav = this.$refs.mainNav.$el
			if (mainNav) {
				if (window.pageYOffset > 56) {
					mainNav.classList.add('navbar-shrink')
				} else {
					mainNav.classList.remove('navbar-shrink')
				}
			}
		},
		onResize() {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)
		},
		alignNavItems() {
			let navDropdown = $(this.$refs.navDropdown.$el)
			navDropdown.children('ul').first().css('padding-left', navDropdown.position().left-16 + 'px')
		}
	},
	components: {
		BNavbar,
		BNavbarNav,
		BNav,
		BNavItemDropdown,
		BDropdownItem,
		BNavItem,
		BNavbarToggle,
		BNavbarBrand,
		BCollapse,
		BPopover,
		Login,
		CampaignLogin
	},
	directives: {
		'b-toggle': VBToggle,
		'b-scrollspy': VBScrollspy
	}
}
</script>