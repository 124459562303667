var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "bio-masthead w-100" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-center p-5 bio-info",
            },
            [
              _c("div", [
                _c("h1", [_vm._v("Chelsea Jones")]),
                _vm._v(" "),
                _c(
                  "h3",
                  { staticClass: "my-4 font-weight-bold text-uppercase" },
                  [_vm._v("Writer & Analyst")]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/in/cjones2005",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/LinkedIn-Icon.svg"),
                          expression:
                            "require('@assets/img/about/bios/LinkedIn-Icon.svg')",
                        },
                      ],
                      staticClass: "d-block",
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-end bio-headshot",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/about/headshot-2.jpg"),
                    expression: "require('@assets/img/about/headshot-2.jpg')",
                  },
                ],
                staticClass: "img-fluid",
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c("router-link", { attrs: { to: { name: "About" }, exact: "" } }, [
              _vm._v("About"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("authored-reports", { attrs: { authorId: 1393 } }),
      _vm._v(" "),
      _c("combined-registration", { attrs: { origin: "bio" } }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Phone: "),
      _c("a", { attrs: { href: "tel:+13148350600" } }, [
        _vm._v("314-835-0600"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Email: "),
      _c("a", { attrs: { href: "mailto:chelsea@market-scope.com" } }, [
        _vm._v("chelsea@market-scope.com"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Chelsea Jones")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bio content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row px-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mx-md-5" }, [
              _c("h4", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "Chelsea Jones authors global reports on refractive surgery, cornea products, ophthalmic lasers, single-use surgical products, and ophthalmic deals."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Chelsea Jones")]),
                _vm._v(
                  " has over 10 years of experience as an analyst in multiple medical verticals."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "She began her career in healthcare IT, where she performed many roles in Population Health analytics. She has experience in crafting and executing competitive intelligence strategy, product development, and market opportunity assessment."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Chelsea authors the ophthalmic deals book, the global cornea report, the global refractive report, the global single-use surgery devices report, and the ophthalmic landscape report for Market Scope. She is also a contributor to Ophthalmic Market Perspectives, the firm’s monthly industry newsletter. Chelsea earned her MBA from Missouri University of Science & Technology and enjoys spending time with her family, hiking, and kayaking."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }