var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { activeContext: _vm.activeContext } }),
      _vm._v(" "),
      _c("header", { staticClass: "masthead" }, [
        _c("div", { staticClass: "container h-100" }, [
          _c(
            "div",
            {
              staticClass:
                "row h-100 align-items-center justify-content-between",
            },
            [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "header-content mx-auto text-center" },
                  [
                    _c("h1", [_vm._v("Market Scope")]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-lg-6 col-md-8 offset-lg-3 offset-md-2",
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("div", { staticClass: "context-switch-button" }, [
                          _c("span", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeContext !== "combined",
                                expression: "activeContext !== 'combined'",
                              },
                            ],
                            ref: "contextSwitchActive",
                            staticClass: "active",
                            style: _vm.activeContextSliderStyle,
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              ref: "contextSwitchBtnIndustry",
                              class: {
                                "context-switch-button-case": true,
                                "p-2": true,
                                left: true,
                                "active-case": _vm.activeContext === "industry",
                              },
                              attrs: { type: "button" },
                              on: { click: _vm.contextSwitchIndustry },
                            },
                            [_vm._v("Industry Professional")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              ref: "contextSwitchBtnMedical",
                              class: {
                                "context-switch-button-case": true,
                                "p-2": true,
                                right: true,
                                "active-case": _vm.activeContext === "medical",
                              },
                              attrs: { type: "button" },
                              on: { click: _vm.contextSwitchMedical },
                            },
                            [_vm._v("Medical Professional")]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "customers", attrs: { id: "customers" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "mx-auto text-center" }, [
              _c("h2", {
                staticClass: "section-heading",
                domProps: { innerHTML: _vm._s(_vm.currentCustomerHeading) },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "logos" }, [
                _c(
                  "div",
                  { staticClass: "logos-slide", style: _vm.styleAnimation },
                  _vm._l(_vm.customerSlides, function (slide, i) {
                    return _c(
                      "a",
                      {
                        key: i,
                        attrs: {
                          href: slide.href,
                          title: slide.title,
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@assets/img/customers/" + slide.img),
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "logos-slide", style: _vm.styleAnimation },
                  _vm._l(_vm.customerSlides, function (slide, i) {
                    return _c(
                      "a",
                      {
                        key: i,
                        attrs: {
                          href: slide.href,
                          title: slide.title,
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@assets/img/customers/" + slide.img),
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.products.length &&
      (_vm.activeContext === "combined" || _vm.activeContext === "industry")
        ? _c("section", { staticClass: "latest", attrs: { id: "latest" } }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "section-heading text-center" }, [
                _c("img", {
                  staticStyle: {
                    "max-width": "70px",
                    width: "auto",
                    height: "auto",
                  },
                  attrs: {
                    src: require("@assets/img/icons/MarketScope-Icons-05.svg"),
                    width: "70",
                    height: "70",
                  },
                }),
                _vm._v(" "),
                _c("h2", [_vm._v("Latest Market Reports")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Market Scope's industry reports are meticulously prepared by a dedicated team of in-house consultants/analysts with over 100 years of collective experience."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "row justify-content-center align-items-center carousel-container",
                },
                [
                  _c("div", { staticClass: "col-lg-3" }, [
                    _c("ul", { staticClass: "list-unstyled" }, [
                      _vm.products[_vm.slide].pagecount_visible &&
                      _vm.products[_vm.slide].document_original_pagecount
                        ? _c("li", { staticClass: "media" }, [
                            _c(
                              "div",
                              { staticStyle: { background: "#e8a520" } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .document_original_pagecount
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(2),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      typeof _vm.products[_vm.slide]
                        .product_metadata_attributes[1] !== "undefined"
                        ? _c("li", { staticClass: "media mt-4" }, [
                            _c(
                              "div",
                              { staticStyle: { background: "#b92f48" } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[1].quantity
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-body align-self-center ml-3",
                              },
                              [
                                _c("h5", { staticClass: "m-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[1]
                                        .highlight
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      typeof _vm.products[_vm.slide]
                        .product_metadata_attributes[3] !== "undefined"
                        ? _c("li", { staticClass: "media mt-4" }, [
                            _c(
                              "div",
                              { staticStyle: { background: "#435054" } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[3].quantity
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-body align-self-center ml-3",
                              },
                              [
                                _c("h5", { staticClass: "m-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[3]
                                        .highlight
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", { staticClass: "latest-content" }, [
                      _c("div", { staticClass: "device-wrapper w-100" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy:background-image",
                                value: require("@assets/img/ipad-frame.png"),
                                expression:
                                  "require('@assets/img/ipad-frame.png')",
                                arg: "background-image",
                              },
                            ],
                            staticClass: "device",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "screen" },
                              [
                                _c(
                                  "b-carousel",
                                  {
                                    attrs: {
                                      id: "productsCarousel",
                                      background: "#ffffff",
                                      interval: _vm.carouselInterval,
                                    },
                                    model: {
                                      value: _vm.slide,
                                      callback: function ($$v) {
                                        _vm.slide = $$v
                                      },
                                      expression: "slide",
                                    },
                                  },
                                  _vm._l(_vm.products, function (product, i) {
                                    return _c("b-carousel-slide", { key: i }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: product.cover_img,
                                            expression: "product.cover_img",
                                          },
                                        ],
                                        staticClass: "d-block img-fluid w-100",
                                        attrs: {
                                          slot: "img",
                                          width: "612",
                                          height: "792",
                                          alt: product.cover_img_alt
                                            ? product.cover_img_alt
                                            : product.label,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "Report",
                                              params: {
                                                id: product.id,
                                                slug: product.slug,
                                              },
                                              hash: "#reports",
                                            })
                                          },
                                        },
                                        slot: "img",
                                      }),
                                    ])
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-3" }, [
                    _c("ul", { staticClass: "list-unstyled" }, [
                      typeof _vm.products[_vm.slide]
                        .product_metadata_attributes[0] !== "undefined"
                        ? _c("li", { staticClass: "media" }, [
                            _c(
                              "div",
                              { staticStyle: { background: "#07547e" } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[0].quantity
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-body align-self-center ml-3",
                              },
                              [
                                _c("h5", { staticClass: "m-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[0]
                                        .highlight
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      typeof _vm.products[_vm.slide]
                        .product_metadata_attributes[2] !== "undefined"
                        ? _c("li", { staticClass: "media mt-4" }, [
                            _c(
                              "div",
                              { staticStyle: { background: "#86c34a" } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[2].quantity
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-body align-self-center ml-3",
                              },
                              [
                                _c("h5", { staticClass: "m-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[2]
                                        .highlight
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      typeof _vm.products[_vm.slide]
                        .product_metadata_attributes[4] !== "undefined"
                        ? _c("li", { staticClass: "media mt-4" }, [
                            _c(
                              "div",
                              { staticStyle: { background: "#515585" } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[4].quantity
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-body align-self-center ml-3",
                              },
                              [
                                _c("h5", { staticClass: "m-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.products[_vm.slide]
                                        .product_metadata_attributes[4]
                                        .highlight
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.slide in _vm.products
                ? _c(
                    "div",
                    {
                      staticClass:
                        "row justify-content-center carousel-controls",
                    },
                    [
                      _c("div", { staticClass: "col-1" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.slidePrev.apply(null, arguments)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-arrow-circle-left" })]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8 text-center" }, [
                        _c(
                          "h3",
                          [
                            _vm.products[_vm.slide].published
                              ? [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Report",
                                          params: {
                                            id: _vm.products[_vm.slide].id,
                                            slug: _vm.products[_vm.slide].slug,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.products[_vm.slide].label)
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Report",
                                          params: {
                                            id: _vm.products[_vm.slide].id,
                                            slug: _vm.products[_vm.slide].slug,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-clock",
                                          }),
                                          _vm._v(" COMING SOON"),
                                          _vm.products[_vm.slide]
                                            .coming_soon_text
                                            ? [
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.products[_vm.slide]
                                                        .coming_soon_text
                                                    )
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(_vm.products[_vm.slide].label)
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "text-left description",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.products[_vm.slide].description
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.slideNext.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-arrow-circle-right",
                            }),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "mx-auto text-center" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-secondary mt-4",
                        attrs: { to: { name: "Reports", query: { page: 1 } } },
                      },
                      [
                        _c("i", { staticClass: "fas fa-list" }),
                        _vm._v(" Show Latest Reports"),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeContext === "medical"
        ? _c(
            "section",
            { staticClass: "physicians", attrs: { id: "physicians" } },
            [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "section-heading text-center" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/icons/MarketScope-Icons-04.svg"),
                        expression:
                          "require('@assets/img/icons/MarketScope-Icons-04.svg')",
                      },
                    ],
                    staticStyle: {
                      "max-width": "70px",
                      width: "auto",
                      height: "auto",
                    },
                    attrs: { width: "70", height: "70" },
                  }),
                  _vm._v(" "),
                  _c("h2", [_vm._v("Provider Surveys & Interviews")]),
                  _vm._v(" "),
                  _vm._m(3),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row pt-5 align-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-lg-7 pr-lg-5 pr-3 pb-lg-0 pb-5",
                      staticStyle: { "z-index": "1" },
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(max-width: 768px)",
                            srcset: require("@assets/img/physicians/physicians-innovation-mobile.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/physicians/physicians-innovation.png"),
                              expression:
                                "require('@assets/img/physicians/physicians-innovation.png')",
                            },
                          ],
                          staticClass: "img-fluid",
                          attrs: { alt: "Table of Innovation" },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(4),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row py-4" }, [
                  _c(
                    "div",
                    { staticClass: "col text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-secondary mt-3",
                          attrs: { to: "/plus" },
                        },
                        [_vm._v("Learn More")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#register",
                              expression: "'#register'",
                            },
                          ],
                          staticClass: "btn btn-secondary mt-3 ml-2",
                          attrs: { href: "#" },
                        },
                        [_vm._v("Register Today")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row pb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-lg-8 pt-3 text-center physicians-footer",
                    },
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v(
                          "Download the Market Scope+ app to get started!"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://apps.apple.com/za/app/market-scope/id1642558540",
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: require("@assets/img/plus/plus-apple-download.png"),
                                  expression:
                                    "require('@assets/img/plus/plus-apple-download.png')",
                                },
                              ],
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "ml-0 ml-lg-3",
                            attrs: {
                              href: "https://play.google.com/store/apps/details?id=com.marketscope.app",
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: require("@assets/img/plus/plus-google-download.png"),
                                  expression:
                                    "require('@assets/img/plus/plus-google-download.png')",
                                },
                              ],
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-4 text-center" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-app-screen.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-app-screen.png')",
                        },
                      ],
                      staticClass: "img-fluid",
                      attrs: { alt: "Market Scope+ App Screen" },
                    }),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "news", attrs: { id: "news" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "mb-3" }, [
            _c("div", { staticClass: "mx-auto text-center" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/icons/MarketScope-Icons-08.svg"),
                    expression:
                      "require('@assets/img/icons/MarketScope-Icons-08.svg')",
                  },
                ],
                staticStyle: {
                  "max-width": "70px",
                  width: "auto",
                  height: "auto",
                },
                attrs: { width: "70", height: "70" },
              }),
              _vm._v(" "),
              _c("h2", { staticClass: "section-heading" }, [
                _vm._v("Latest Industry News & Trends"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.articles, function (article) {
              return _c(
                "div",
                { staticClass: "col-12 col-md-6 col-lg-3" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card",
                      on: {
                        click: function ($event) {
                          return _vm.showArticle(article)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "card-header" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(
                              new Date(article.publish_date).toLocaleDateString(
                                "en-US",
                                { timeZone: "UTC" }
                              )
                            ) +
                            "\n\t\t\t\t\t\t\t"
                        ),
                        _c(
                          "div",
                          { staticClass: "float-right text-right" },
                          [
                            _vm._l(article.landing_tags, function (tag) {
                              return [
                                tag.id
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "badge badge-pill",
                                        style: { backgroundColor: tag.color },
                                      },
                                      [_vm._v(_vm._s(tag.label))]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _c("h5", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(article.title)),
                        ]),
                        _vm._v(" "),
                        article.preview_img_url
                          ? _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: article.preview_img_url,
                                  expression: "article.preview_img_url",
                                },
                              ],
                              staticClass: "img-thumbnail d-block mx-auto",
                              attrs: { alt: article.title.trim() },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "card-text",
                          domProps: {
                            innerHTML: _vm._s(article.content_preview),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-footer text-center" }, [
                        article.content
                          ? _c("span", [_vm._v("Read Article")])
                          : _c("span", [
                              _c("i", { staticClass: "fas fa-lock" }),
                              _vm._v(" Read Article"),
                            ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "modal",
                    {
                      staticClass: "article-modal",
                      attrs: {
                        name: "articleModal" + article.id,
                        height: "auto",
                        scrollable: true,
                        reset: true,
                        adaptive: true,
                        transition: "nice-modal-fade",
                      },
                    },
                    [
                      _c("div", { staticClass: "modal-header" }, [
                        _c(
                          "h5",
                          {
                            staticClass: "modal-title",
                            attrs: { id: "article-modal-title-" + article.id },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(article.title) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                            article.author || article.author_custom !== ""
                              ? _c("br")
                              : _vm._e(),
                            _vm._v(" "),
                            article.author || article.author_custom !== ""
                              ? _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "Author: " +
                                      _vm._s(
                                        article.author
                                          ? article.author.user_attribute
                                              .title !== ""
                                            ? article.author.full_name +
                                              ", " +
                                              article.author.user_attribute
                                                .title
                                            : article.author.full_name
                                          : article.author_custom
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.$modal.hide(
                                  "articleModal" + article.id
                                )
                              },
                            },
                          },
                          [
                            _c("span", { attrs: { "aria-hidden": "true" } }, [
                              _vm._v("×"),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      article.content
                        ? _c("div", { staticClass: "modal-body" }, [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(article.content) },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-center pb-2" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.jumpToRegister(article)
                                    },
                                  },
                                },
                                [_vm._v("Register Now")]
                              ),
                            ]),
                            _vm._v(" "),
                            article.recommended_products.length
                              ? _c(
                                  "div",
                                  { staticClass: "card bg-light mt-3" },
                                  [
                                    _c("div", { staticClass: "card-body" }, [
                                      _c("h6", [
                                        _vm._v("Recommended Products"),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "container" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row d-flex align-items-start",
                                          },
                                          _vm._l(
                                            article.recommended_products,
                                            function (product) {
                                              return _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "recommended-product col-md-3 p-1 bg-white border border-light",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Report",
                                                          params: {
                                                            id: product.id,
                                                            slug: product.slug,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "text-center m-0 p-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.label
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      product.document_original_filename ||
                                                      product.document_sample_filename
                                                        ? _c("img", {
                                                            directives: [
                                                              {
                                                                name: "lazy",
                                                                rawName:
                                                                  "v-lazy",
                                                                value:
                                                                  "/files/products/samples/" +
                                                                  product.id +
                                                                  "/" +
                                                                  (product.document_sample_filename
                                                                    ? product.document_sample_filename.substr(
                                                                        0,
                                                                        product.document_sample_filename.lastIndexOf(
                                                                          "."
                                                                        )
                                                                      )
                                                                    : product.document_original_filename.substr(
                                                                        0,
                                                                        product.document_original_filename.lastIndexOf(
                                                                          "."
                                                                        )
                                                                      )) +
                                                                  ".thumb.jpg",
                                                                expression:
                                                                  "'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "img-thumbnail d-block mx-auto border-0",
                                                            attrs: {
                                                              alt: product.cover_img_alt
                                                                ? product.cover_img_alt
                                                                : product.label,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _c("div", { staticClass: "modal-body" }, [
                            article.preview_img_url
                              ? _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "lazy",
                                          rawName: "v-lazy",
                                          value: article.preview_img_url,
                                          expression: "article.preview_img_url",
                                        },
                                      ],
                                      staticClass:
                                        "img-thumbnail d-block mx-auto",
                                      attrs: { alt: article.title.trim() },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(article.content_teaser),
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-center pb-4" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.jumpToRegister(article)
                                    },
                                  },
                                },
                                [_vm._v("Register Now")]
                              ),
                            ]),
                            _vm._v(" "),
                            article.associated_products.length ||
                            (article.associated_subscriptions.length &&
                              article.associated_subscriptions.some(
                                (subscription) => subscription.products[0]
                              ))
                              ? _c("div", { staticClass: "card bg-light" }, [
                                  _c("div", { staticClass: "card-body" }, [
                                    _c("h6", [
                                      _vm._v(
                                        "Purchasing one of the following products will open up access to this article's content, which is also available in each comprehensive report/subscription."
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "container" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row d-flex align-items-start",
                                        },
                                        [
                                          _vm._l(
                                            article.associated_products,
                                            function (product) {
                                              return _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "associated-product col-md-3 p-1 bg-white border border-light",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Report",
                                                          params: {
                                                            id: product.id,
                                                            slug: product.slug,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "text-center m-0 p-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.attributes
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      product.document_original_filename ||
                                                      product.document_sample_filename
                                                        ? _c("img", {
                                                            directives: [
                                                              {
                                                                name: "lazy",
                                                                rawName:
                                                                  "v-lazy",
                                                                value:
                                                                  "/files/products/samples/" +
                                                                  product.id +
                                                                  "/" +
                                                                  (product.document_sample_filename
                                                                    ? product.document_sample_filename.substr(
                                                                        0,
                                                                        product.document_sample_filename.lastIndexOf(
                                                                          "."
                                                                        )
                                                                      )
                                                                    : product.document_original_filename.substr(
                                                                        0,
                                                                        product.document_original_filename.lastIndexOf(
                                                                          "."
                                                                        )
                                                                      )) +
                                                                  ".thumb.jpg",
                                                                expression:
                                                                  "'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "img-thumbnail d-block mx-auto border-0",
                                                            attrs: {
                                                              alt: product.cover_img_alt
                                                                ? product.cover_img_alt
                                                                : product.attributes,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            article.associated_subscriptions,
                                            function (subscription) {
                                              return subscription.products[0]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "associated-product col-md-3 p-1 bg-white border border-light",
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "Report",
                                                              params: {
                                                                id: subscription
                                                                  .products[0]
                                                                  .id,
                                                                slug: subscription
                                                                  .products[0]
                                                                  .slug,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "text-center m-0 p-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  subscription.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          subscription
                                                            .products[0]
                                                            .document_original_filename ||
                                                          subscription
                                                            .products[0]
                                                            .document_sample_filename
                                                            ? _c("img", {
                                                                directives: [
                                                                  {
                                                                    name: "lazy",
                                                                    rawName:
                                                                      "v-lazy",
                                                                    value:
                                                                      "/files/products/samples/" +
                                                                      subscription
                                                                        .products[0]
                                                                        .id +
                                                                      "/" +
                                                                      (subscription
                                                                        .products[0]
                                                                        .document_sample_filename
                                                                        ? subscription.products[0].document_sample_filename.substr(
                                                                            0,
                                                                            subscription.products[0].document_sample_filename.lastIndexOf(
                                                                              "."
                                                                            )
                                                                          )
                                                                        : subscription.products[0].document_original_filename.substr(
                                                                            0,
                                                                            subscription.products[0].document_original_filename.lastIndexOf(
                                                                              "."
                                                                            )
                                                                          )) +
                                                                      ".thumb.jpg",
                                                                    expression:
                                                                      "'/files/products/samples/' + subscription.products[0].id + '/' + (subscription.products[0].document_sample_filename ? subscription.products[0].document_sample_filename.substr(0, subscription.products[0].document_sample_filename.lastIndexOf('.')) : subscription.products[0].document_original_filename.substr(0, subscription.products[0].document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "img-thumbnail d-block mx-auto border-0",
                                                                attrs: {
                                                                  alt: subscription
                                                                    .products[0]
                                                                    .cover_img_alt
                                                                    ? subscription
                                                                        .products[0]
                                                                        .cover_img_alt
                                                                    : subscription.name +
                                                                      " - " +
                                                                      subscription
                                                                        .products[0]
                                                                        .attributes,
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            article.recommended_products.length
                              ? _c(
                                  "div",
                                  { staticClass: "card bg-light mt-3" },
                                  [
                                    _c("div", { staticClass: "card-body" }, [
                                      _c("h6", [
                                        _vm._v("Recommended Products"),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "container" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row d-flex align-items-start",
                                          },
                                          _vm._l(
                                            article.recommended_products,
                                            function (product) {
                                              return _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "recommended-product col-md-3 p-1 bg-white border border-light",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Report",
                                                          params: {
                                                            id: product.id,
                                                            slug: product.slug,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "text-center m-0 p-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.label
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      product.document_original_filename ||
                                                      product.document_sample_filename
                                                        ? _c("img", {
                                                            directives: [
                                                              {
                                                                name: "lazy",
                                                                rawName:
                                                                  "v-lazy",
                                                                value:
                                                                  "/files/products/samples/" +
                                                                  product.id +
                                                                  "/" +
                                                                  (product.document_sample_filename
                                                                    ? product.document_sample_filename.substr(
                                                                        0,
                                                                        product.document_sample_filename.lastIndexOf(
                                                                          "."
                                                                        )
                                                                      )
                                                                    : product.document_original_filename.substr(
                                                                        0,
                                                                        product.document_original_filename.lastIndexOf(
                                                                          "."
                                                                        )
                                                                      )) +
                                                                  ".thumb.jpg",
                                                                expression:
                                                                  "'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "img-thumbnail d-block mx-auto border-0",
                                                            attrs: {
                                                              alt: product.cover_img_alt
                                                                ? product.cover_img_alt
                                                                : product.label,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "modal-footer" },
                        [
                          _c(
                            "no-ssr",
                            [
                              _c("vue-goodshare-linked-in", {
                                attrs: {
                                  page_url:
                                    "https://www.market-scope.com/pages/news/" +
                                    article.id +
                                    "/" +
                                    article.slug,
                                  "page-title":
                                    article.title + " | Market Scope",
                                  has_icon: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("vue-goodshare-facebook", {
                                attrs: {
                                  page_url:
                                    "https://www.market-scope.com/pages/news/" +
                                    article.id +
                                    "/" +
                                    article.slug,
                                  "page-title":
                                    article.title + " | Market Scope",
                                  has_icon: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("vue-goodshare-twitter", {
                                attrs: {
                                  page_url:
                                    "https://www.market-scope.com/pages/news/" +
                                    article.id +
                                    "/" +
                                    article.slug,
                                  "page-title":
                                    article.title + " | Market Scope",
                                  has_icon: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "button-social email__design__flat mr-auto",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showShareArticleModal(article)
                                },
                              },
                            },
                            [_c("i", { staticClass: "icon-email" })]
                          ),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(
                              _vm._s(
                                new Date(
                                  article.publish_date
                                ).toLocaleDateString("en-US", {
                                  timeZone: "UTC",
                                })
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center mt-3" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary",
                  attrs: { to: "/pages/news" },
                },
                [_vm._v("Show More Articles")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.activeContext === "combined"
        ? _c(
            "section",
            { staticClass: "physicians", attrs: { id: "physicians" } },
            [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "section-heading text-center" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/icons/MarketScope-Icons-04.svg"),
                        expression:
                          "require('@assets/img/icons/MarketScope-Icons-04.svg')",
                      },
                    ],
                    staticStyle: {
                      "max-width": "70px",
                      width: "auto",
                      height: "auto",
                    },
                    attrs: { width: "70", height: "70" },
                  }),
                  _vm._v(" "),
                  _c("h2", [_vm._v("Provider Surveys & Interviews")]),
                  _vm._v(" "),
                  _vm._m(5),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row pt-5 align-items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-lg-7 pr-lg-5 pr-3 pb-lg-0 pb-5",
                      staticStyle: { "z-index": "1" },
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(max-width: 768px)",
                            srcset: require("@assets/img/physicians/physicians-innovation-mobile.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/physicians/physicians-innovation.png"),
                              expression:
                                "require('@assets/img/physicians/physicians-innovation.png')",
                            },
                          ],
                          staticClass: "img-fluid",
                          attrs: { alt: "Table of Innovation" },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(6),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row py-4" }, [
                  _c(
                    "div",
                    { staticClass: "col text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-secondary mt-3",
                          attrs: { to: "/plus" },
                        },
                        [_vm._v("Learn More")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#register",
                              expression: "'#register'",
                            },
                          ],
                          staticClass: "btn btn-secondary mt-3 ml-2",
                          attrs: { href: "#" },
                        },
                        [_vm._v("Register Today")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row pb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-lg-8 pt-3 text-center physicians-footer",
                    },
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v(
                          "Download the Market Scope+ app to get started!"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://apps.apple.com/za/app/market-scope/id1642558540",
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: require("@assets/img/plus/plus-apple-download.png"),
                                  expression:
                                    "require('@assets/img/plus/plus-apple-download.png')",
                                },
                              ],
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "ml-0 ml-lg-3",
                            attrs: {
                              href: "https://play.google.com/store/apps/details?id=com.marketscope.app",
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: require("@assets/img/plus/plus-google-download.png"),
                                  expression:
                                    "require('@assets/img/plus/plus-google-download.png')",
                                },
                              ],
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-4 text-center" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-app-screen.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-app-screen.png')",
                        },
                      ],
                      staticClass: "img-fluid",
                      attrs: { alt: "Market Scope+ App Screen" },
                    }),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeContext === "combined" || _vm.activeContext === "industry"
        ? _c(
            "section",
            { staticClass: "about bg-primary", attrs: { id: "about" } },
            [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("div", { staticClass: "mx-auto text-center" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/icons/MarketScope-Icons-03.svg"),
                          expression:
                            "require('@assets/img/icons/MarketScope-Icons-03.svg')",
                        },
                      ],
                      staticStyle: {
                        "max-width": "70px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "70", height: "70" },
                    }),
                    _vm._v(" "),
                    _c("h2", { staticClass: "section-heading" }, [
                      _vm._v("Market Scope Methodology"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "A Multi-Step Approach for Accurate & Timely Data"
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { "nav-class": "nav-justified" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { active: "" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: require("@assets/img/Homepage-CollectData-Methodology.png"),
                                      expression:
                                        "require('@assets/img/Homepage-CollectData-Methodology.png')",
                                    },
                                  ],
                                  staticClass: "img-fluid",
                                }),
                                _vm._v(" "),
                                _c("h4", [_vm._v("Collect Data")]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "container" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-lg-6 mb-3" }, [
                                    _c("div", { staticClass: "media" }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: require("@assets/img/icons/Methodology-Icons-01.svg"),
                                            expression:
                                              "require('@assets/img/icons/Methodology-Icons-01.svg')",
                                          },
                                        ],
                                        staticClass: "align-self-center mr-3",
                                        staticStyle: {
                                          "max-width": "70px",
                                          width: "auto",
                                          height: "auto",
                                        },
                                        attrs: { width: "70", height: "70" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "align-self-center m-0",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Proprietary data"),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("ul", [
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(
                                          " 20+ years of physician survey data"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Disease models"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(
                                          " Cultivated database of physicians, ASCs, and hospitals"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(
                                          " Insight from our team of in-house industry experts"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-lg-6" }, [
                                    _c("div", { staticClass: "media" }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: require("@assets/img/icons/Methodology-Icons-02.svg"),
                                            expression:
                                              "require('@assets/img/icons/Methodology-Icons-02.svg')",
                                          },
                                        ],
                                        staticClass: "align-self-center mr-3",
                                        staticStyle: {
                                          "max-width": "70px",
                                          width: "auto",
                                          height: "auto",
                                        },
                                        attrs: { width: "70", height: "70" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "align-self-center m-0",
                                        },
                                        [_c("strong", [_vm._v("Public data")])]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("ul", [
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(
                                          " Attendance and participation at major, worldwide meetings"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(
                                          " Public health systems and registries"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Clinical study data"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(
                                          " Analysis of company-published financial reports"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            [
                              _c("template", { slot: "title" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: require("@assets/img/Homepage-Analyze-Methodology.png"),
                                      expression:
                                        "require('@assets/img/Homepage-Analyze-Methodology.png')",
                                    },
                                  ],
                                  staticClass: "img-fluid",
                                }),
                                _vm._v(" "),
                                _c("h4", [_vm._v("Model & Analyze Market")]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "container" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-lg-4 mb-3" }, [
                                    _c("div", { staticClass: "media" }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: require("@assets/img/icons/Methodology-Icons-05.svg"),
                                            expression:
                                              "require('@assets/img/icons/Methodology-Icons-05.svg')",
                                          },
                                        ],
                                        staticClass: "align-self-center mr-3",
                                        staticStyle: {
                                          "max-width": "70px",
                                          width: "auto",
                                          height: "auto",
                                        },
                                        attrs: { width: "70", height: "70" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "align-self-center m-0",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Extensive analysis"),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("ul", [
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Integrated disease models"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Expert-based segmentation"),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-lg-4 mb-3" }, [
                                    _c("div", { staticClass: "media" }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: require("@assets/img/icons/Methodology-Icons-06.svg"),
                                            expression:
                                              "require('@assets/img/icons/Methodology-Icons-06.svg')",
                                          },
                                        ],
                                        staticClass: "align-self-center mr-3",
                                        staticStyle: {
                                          "max-width": "70px",
                                          width: "auto",
                                          height: "auto",
                                        },
                                        attrs: { width: "70", height: "70" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "align-self-center m-0",
                                        },
                                        [
                                          _c("strong", [_vm._v("Integrate")]),
                                          _vm._v(" and "),
                                          _c("strong", [_vm._v("reconcile")]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("ul", [
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Continuous feedback loop"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Integration of survey data"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Triangulation of results"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Cross-model analysis"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(
                                          " Reconciliation to company revenues"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-lg-4" }, [
                                    _c("div", { staticClass: "media" }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: require("@assets/img/icons/Methodology-Icons-07.svg"),
                                            expression:
                                              "require('@assets/img/icons/Methodology-Icons-07.svg')",
                                          },
                                        ],
                                        staticClass: "align-self-center mr-3",
                                        staticStyle: {
                                          "max-width": "70px",
                                          width: "auto",
                                          height: "auto",
                                        },
                                        attrs: { width: "70", height: "70" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "align-self-center m-0",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Produce products"),
                                          ]),
                                          _vm._v(
                                            " covering key medical markets"
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("ul", [
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Medical devices"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Drugs/biologics"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Equipment"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Providers"),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            [
                              _c("template", { slot: "title" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: require("@assets/img/Homepage-Incorporate-Methodology.png"),
                                      expression:
                                        "require('@assets/img/Homepage-Incorporate-Methodology.png')",
                                    },
                                  ],
                                  staticClass: "img-fluid",
                                }),
                                _vm._v(" "),
                                _c("h4", [
                                  _vm._v("Gather & Incorporate Feedback"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "container" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-lg-6 mb-3" }, [
                                    _c("div", { staticClass: "media" }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: require("@assets/img/icons/Methodology-Icons-03.svg"),
                                            expression:
                                              "require('@assets/img/icons/Methodology-Icons-03.svg')",
                                          },
                                        ],
                                        staticClass: "align-self-center mr-3",
                                        staticStyle: {
                                          "max-width": "70px",
                                          width: "auto",
                                          height: "auto",
                                        },
                                        attrs: { width: "70", height: "70" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "align-self-center m-0",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Gather feedback"),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("ul", [
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" New market insights"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Client requests"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Landmark study evaluations"),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-lg-6" }, [
                                    _c("div", { staticClass: "media" }, [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "lazy",
                                            rawName: "v-lazy",
                                            value: require("@assets/img/icons/Methodology-Icons-04.svg"),
                                            expression:
                                              "require('@assets/img/icons/Methodology-Icons-04.svg')",
                                          },
                                        ],
                                        staticClass: "align-self-center mr-3",
                                        staticStyle: {
                                          "max-width": "70px",
                                          width: "auto",
                                          height: "auto",
                                        },
                                        attrs: { width: "70", height: "70" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "align-self-center m-0",
                                        },
                                        [
                                          _c("strong", [_vm._v("Incorporate")]),
                                          _vm._v(" and "),
                                          _c("strong", [_vm._v("revise")]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("ul", [
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Forecasting models"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("i", {
                                          staticClass: "fas fa-check-circle",
                                        }),
                                        _vm._v(" Analytical tools and methods"),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "mx-auto" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary mt-5",
                          attrs: { to: "/pages/methodology" },
                        },
                        [_vm._v("Learn More")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "register", attrs: { id: "register" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "header-row mb-4" }, [
            _c("div", { staticClass: "mx-auto text-center" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/icons/MarketScope-Icons-09.svg"),
                    expression:
                      "require('@assets/img/icons/MarketScope-Icons-09.svg')",
                  },
                ],
                staticStyle: {
                  "max-width": "70px",
                  width: "auto",
                  height: "auto",
                },
                attrs: { width: "70", height: "70" },
              }),
              _vm._v(" "),
              _c("h2", { staticClass: "section-heading" }, [
                _vm._v("Join Market Scope Today"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "row justify-content-center registration-containers",
            },
            [
              _vm.activeContext === "combined"
                ? [
                    _c(
                      "div",
                      { staticClass: "col-lg-5" },
                      [
                        _c("corporate-registration", {
                          attrs: { containerId: "main-corporate-container" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-5" },
                      [
                        _vm.campaign && _vm.campaign.fields_list.length
                          ? _c("campaign-clinical-registration", {
                              attrs: { containerId: "main-clinical-container" },
                            })
                          : _c("clinical-registration", {
                              attrs: { containerId: "main-clinical-container" },
                            }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeContext === "industry",
                            expression: "activeContext === 'industry'",
                          },
                        ],
                        staticClass: "col-lg-10",
                      },
                      [
                        _c("corporate-registration", {
                          attrs: { containerId: "main-corporate-container" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeContext === "medical",
                            expression: "activeContext === 'medical'",
                          },
                        ],
                        staticClass: "col-lg-10",
                      },
                      [
                        _vm.campaign && _vm.campaign.fields_list.length
                          ? _c("campaign-clinical-registration", {
                              attrs: { containerId: "main-clinical-container" },
                            })
                          : _c("clinical-registration", {
                              attrs: { containerId: "main-clinical-container" },
                            }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("share-article"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "mb-5" }, [
      _vm._v(
        "Trusted Global Partner of Industry and Medical Professionals for "
      ),
      _c("strong", [_vm._v("Ophthalmic Market Research")]),
      _vm._v(", "),
      _c("strong", { staticClass: "text-nowrap" }, [
        _vm._v("Primary Research"),
      ]),
      _vm._v(", "),
      _c("strong", [_vm._v("Independent Perspective")]),
      _vm._v(", & "),
      _c("strong", { staticClass: "text-nowrap" }, [
        _vm._v("Objective Analysis"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _c("i", { staticClass: "fas fa-sliders-h small" }),
      _vm._v(" Choose Your Experience"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body align-self-center ml-3" }, [
      _c("h5", { staticClass: "m-0" }, [_vm._v("Comprehensive Pages")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", [
      _vm._v("Grab a seat at the table and join the thousands of "),
      _c("strong", [_vm._v("ophthalmologists and optometrists")]),
      _vm._v(" from "),
      _c("strong", [_vm._v("over 60 countries")]),
      _vm._v(" who are helping shape the "),
      _c("strong", [_vm._v("next generation")]),
      _vm._v(
        " of diagnostics, pharmaceuticals, devices, and equipment by sharing your opinion and experience. "
      ),
      _c("strong", [_vm._v("Make your voice heard!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-5 physicians-content" }, [
      _c("h4", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Choose Your Own Adventure"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Participation options include weekly polls, special sponsored surveys, syndicated quarterly surveys, focus groups, and interviews, each with varying levels of honoraria."
        ),
      ]),
      _vm._v(" "),
      _c("h4", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Safe, Secure & On Any Device"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "We follow all industry standards to ensure confidentiality between participants and clients. Start and stop surveys anytime on our free Market Scope+ App or on desktop, each with a personalized dashboard just for you."
        ),
      ]),
      _vm._v(" "),
      _c("h4", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Pre-Qualified Invitations"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "We individually verify all potential participants before each survey or interview, ensuring that only licensed eye care professionals are invited and they are never kicked out or disqualified."
        ),
      ]),
      _vm._v(" "),
      _c("h4", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Better Data = Better Decisions"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "A team of industry experts and analysts review each data point, so you can be confident in the results and trends provided in our reports."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", [
      _vm._v("Grab a seat at the table and join the thousands of "),
      _c("strong", [_vm._v("ophthalmologists and optometrists")]),
      _vm._v(" from "),
      _c("strong", [_vm._v("over 60 countries")]),
      _vm._v(" who are helping shape the "),
      _c("strong", [_vm._v("next generation")]),
      _vm._v(
        " of diagnostics, pharmaceuticals, devices, and equipment by sharing your opinion and experience. "
      ),
      _c("strong", [_vm._v("Make your voice heard!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-5 physicians-content" }, [
      _c("h4", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Choose Your Own Adventure"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Participation options include weekly polls, special sponsored surveys, syndicated quarterly surveys, focus groups, and interviews, each with varying levels of honoraria."
        ),
      ]),
      _vm._v(" "),
      _c("h4", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Safe, Secure & On Any Device"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "We follow all industry standards to ensure confidentiality between participants and clients. Start and stop surveys anytime on our free Market Scope+ App or on desktop, each with a personalized dashboard just for you."
        ),
      ]),
      _vm._v(" "),
      _c("h4", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Pre-Qualified Invitations"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "We individually verify all potential participants before each survey or interview, ensuring that only licensed eye care professionals are invited and they are never kicked out or disqualified."
        ),
      ]),
      _vm._v(" "),
      _c("h4", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Better Data = Better Decisions"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "A team of industry experts and analysts review each data point, so you can be confident in the results and trends provided in our reports."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }