var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "keep-alive",
        [
          _vm.currentParentComponent
            ? _c(_vm.currentParentComponent, { tag: "component" })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.article
        ? _c(
            "modal",
            {
              staticClass: "article-modal",
              attrs: {
                name: "articleModal",
                height: "auto",
                scrollable: true,
                reset: true,
                adaptive: true,
                transition: "nice-modal-fade",
              },
              on: { closed: _vm.closed },
            },
            [
              _c("div", { staticClass: "modal-header" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "modal-title",
                        attrs: { id: "article-modal-title-" + _vm.article.id },
                      },
                      [_vm._v(_vm._s(_vm.article.title.trim()))]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.article.author || _vm.article.author_custom !== ""
                    ? _c("div", { staticClass: "row" }, [
                        _c("h5", { staticClass: "modal-title" }, [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Author: " +
                                _vm._s(
                                  _vm.article.author
                                    ? _vm.article.author.user_attribute
                                        .title !== ""
                                      ? _vm.article.author.full_name +
                                        ", " +
                                        _vm.article.author.user_attribute.title
                                      : _vm.article.author.full_name
                                    : _vm.article.author_custom
                                )
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.$modal.hide("articleModal")
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.article.content
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.article.content) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center pb-2" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-success",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.jumpToRegister.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Register Now")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-login",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$modal.show(
                                "lockedArticleLoginModal",
                                { articleId: _vm.article.id }
                              )
                            },
                          },
                        },
                        [_vm._v("Sign In")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.article.recommended_products.length
                      ? _c("div", { staticClass: "card bg-light mt-3" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("h6", [_vm._v("Recommended Products")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "container" }, [
                              _c(
                                "div",
                                { staticClass: "row d-flex align-items-start" },
                                _vm._l(
                                  _vm.article.recommended_products,
                                  function (product) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "recommended-product col-md-3 p-1 bg-white border border-light",
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Report",
                                                params: {
                                                  id: product.id,
                                                  slug: product.slug,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "text-center m-0 p-1",
                                              },
                                              [_vm._v(_vm._s(product.label))]
                                            ),
                                            _vm._v(" "),
                                            product.document_original_filename ||
                                            product.document_sample_filename
                                              ? _c("img", {
                                                  directives: [
                                                    {
                                                      name: "lazy",
                                                      rawName: "v-lazy",
                                                      value:
                                                        "/files/products/samples/" +
                                                        product.id +
                                                        "/" +
                                                        (product.document_sample_filename
                                                          ? product.document_sample_filename.substr(
                                                              0,
                                                              product.document_sample_filename.lastIndexOf(
                                                                "."
                                                              )
                                                            )
                                                          : product.document_original_filename.substr(
                                                              0,
                                                              product.document_original_filename.lastIndexOf(
                                                                "."
                                                              )
                                                            )) +
                                                        ".thumb.jpg",
                                                      expression:
                                                        "'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "img-thumbnail d-block mx-auto border-0",
                                                  attrs: {
                                                    alt: product.cover_img_alt
                                                      ? product.cover_img_alt
                                                      : product.label,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _c("div", { staticClass: "modal-body" }, [
                    _vm.article.preview_img_url
                      ? _c("p", { staticStyle: { "text-align": "center" } }, [
                          _c("img", {
                            staticClass: "img-thumbnail d-block mx-auto",
                            attrs: {
                              src: _vm.article.preview_img_url,
                              alt: _vm.article.title.trim(),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.article.content_teaser),
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center pb-4" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-success",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.jumpToRegister.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Register Now")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-login",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$modal.show(
                                "lockedArticleLoginModal",
                                { articleId: _vm.article.id }
                              )
                            },
                          },
                        },
                        [_vm._v("Sign In")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.article.associated_products.length ||
                    (_vm.article.associated_subscriptions.length &&
                      _vm.article.associated_subscriptions.some(
                        (subscription) => subscription.products[0]
                      ))
                      ? _c("div", { staticClass: "card bg-light" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("h6", [
                              _vm._v(
                                "Purchasing one of the following products will open up access to this article's content, which is also available in each comprehensive report/subscription."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "container" }, [
                              _c(
                                "div",
                                { staticClass: "row d-flex align-items-start" },
                                [
                                  _vm._l(
                                    _vm.article.associated_products,
                                    function (product) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass:
                                            "associated-product col-md-3 p-1 bg-white border border-light",
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Report",
                                                  params: {
                                                    id: product.id,
                                                    slug: product.slug,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "text-center m-0 p-1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(product.attributes)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              product.document_original_filename ||
                                              product.document_sample_filename
                                                ? _c("img", {
                                                    directives: [
                                                      {
                                                        name: "lazy",
                                                        rawName: "v-lazy",
                                                        value:
                                                          "/files/products/samples/" +
                                                          product.id +
                                                          "/" +
                                                          (product.document_sample_filename
                                                            ? product.document_sample_filename.substr(
                                                                0,
                                                                product.document_sample_filename.lastIndexOf(
                                                                  "."
                                                                )
                                                              )
                                                            : product.document_original_filename.substr(
                                                                0,
                                                                product.document_original_filename.lastIndexOf(
                                                                  "."
                                                                )
                                                              )) +
                                                          ".thumb.jpg",
                                                        expression:
                                                          "'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "img-thumbnail d-block mx-auto border-0",
                                                    attrs: {
                                                      alt: product.cover_img_alt
                                                        ? product.cover_img_alt
                                                        : product.attributes,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.article.associated_subscriptions,
                                    function (subscription) {
                                      return subscription.products[0]
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "associated-product col-md-3 p-1 bg-white border border-light",
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "Report",
                                                      params: {
                                                        id: subscription
                                                          .products[0].id,
                                                        slug: subscription
                                                          .products[0].slug,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "text-center m-0 p-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          subscription.name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  subscription.products[0]
                                                    .document_original_filename ||
                                                  subscription.products[0]
                                                    .document_sample_filename
                                                    ? _c("img", {
                                                        directives: [
                                                          {
                                                            name: "lazy",
                                                            rawName: "v-lazy",
                                                            value:
                                                              "/files/products/samples/" +
                                                              subscription
                                                                .products[0]
                                                                .id +
                                                              "/" +
                                                              (subscription
                                                                .products[0]
                                                                .document_sample_filename
                                                                ? subscription.products[0].document_sample_filename.substr(
                                                                    0,
                                                                    subscription.products[0].document_sample_filename.lastIndexOf(
                                                                      "."
                                                                    )
                                                                  )
                                                                : subscription.products[0].document_original_filename.substr(
                                                                    0,
                                                                    subscription.products[0].document_original_filename.lastIndexOf(
                                                                      "."
                                                                    )
                                                                  )) +
                                                              ".thumb.jpg",
                                                            expression:
                                                              "'/files/products/samples/' + subscription.products[0].id + '/' + (subscription.products[0].document_sample_filename ? subscription.products[0].document_sample_filename.substr(0, subscription.products[0].document_sample_filename.lastIndexOf('.')) : subscription.products[0].document_original_filename.substr(0, subscription.products[0].document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "img-thumbnail d-block mx-auto border-0",
                                                        attrs: {
                                                          alt:
                                                            subscription.name +
                                                            " - " +
                                                            subscription
                                                              .products[0]
                                                              .attributes,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.article.recommended_products.length
                      ? _c("div", { staticClass: "card bg-light mt-3" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("h6", [_vm._v("Recommended Products")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "container" }, [
                              _c(
                                "div",
                                { staticClass: "row d-flex align-items-start" },
                                _vm._l(
                                  _vm.article.recommended_products,
                                  function (product) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "recommended-product col-md-3 p-1 bg-white border border-light",
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Report",
                                                params: {
                                                  id: product.id,
                                                  slug: product.slug,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "text-center m-0 p-1",
                                              },
                                              [_vm._v(_vm._s(product.label))]
                                            ),
                                            _vm._v(" "),
                                            product.document_original_filename ||
                                            product.document_sample_filename
                                              ? _c("img", {
                                                  directives: [
                                                    {
                                                      name: "lazy",
                                                      rawName: "v-lazy",
                                                      value:
                                                        "/files/products/samples/" +
                                                        product.id +
                                                        "/" +
                                                        (product.document_sample_filename
                                                          ? product.document_sample_filename.substr(
                                                              0,
                                                              product.document_sample_filename.lastIndexOf(
                                                                "."
                                                              )
                                                            )
                                                          : product.document_original_filename.substr(
                                                              0,
                                                              product.document_original_filename.lastIndexOf(
                                                                "."
                                                              )
                                                            )) +
                                                        ".thumb.jpg",
                                                      expression:
                                                        "'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "img-thumbnail d-block mx-auto border-0",
                                                  attrs: {
                                                    alt: product.cover_img_alt
                                                      ? product.cover_img_alt
                                                      : product.label,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "no-ssr",
                    [
                      _c("vue-goodshare-linked-in", {
                        attrs: { has_icon: "" },
                      }),
                      _vm._v(" "),
                      _c("vue-goodshare-facebook", { attrs: { has_icon: "" } }),
                      _vm._v(" "),
                      _c("vue-goodshare-twitter", { attrs: { has_icon: "" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button-social email__design__flat mr-auto",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showShareArticleModal.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-email" })]
                  ),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      _vm._s(
                        new Date(_vm.article.publish_date).toLocaleDateString(
                          "en-US",
                          { timeZone: "UTC" }
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("share-article"),
      _vm._v(" "),
      _c("locked-article-login"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }