var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "careers-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "careers", attrs: { id: "careers" } }, [
        _c("div", { staticClass: "container header-row" }, [
          _c("div", [
            _c("div", { staticClass: "mx-auto text-center" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/icons/MarketScope-Icons-28.svg"),
                    expression:
                      "require('@assets/img/icons/MarketScope-Icons-28.svg')",
                  },
                ],
                staticStyle: {
                  "max-width": "70px",
                  width: "auto",
                  height: "auto",
                },
                attrs: { width: "70", height: "70" },
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "section-heading mb-4" }, [
                _vm._v("Careers at Market Scope"),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "listings" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col mb-3" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("h4", [
                      _vm._v(
                        "Seeking Market Analyst/Writer for Market Research Firm in Health Care"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "The Market Analyst/Writer will be responsible for creating in-depth market reports. This will include researching and analyzing financial documents, medical databases, FDA filings, company websites, and doctor surveys, in addition to attending medical conferences. This research will be used to build analytical models and create a narrative that accurately reflects conditions in a global market."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.showModal(
                                "careerAnalystModal",
                                "Market Analyst/Writer"
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-external-link-alt" }),
                          _vm._v(" Learn More"),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("register"),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("career-analyst"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Careers")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }