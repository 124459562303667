var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "bio-masthead w-100" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-center p-5 bio-info",
            },
            [
              _c("div", [
                _c("h1", [_vm._v("Kristen Harmon Ingenito, MBA")]),
                _vm._v(" "),
                _c(
                  "h3",
                  { staticClass: "my-4 font-weight-bold text-uppercase" },
                  [_vm._v("CEO")]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/in/kharmon/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/LinkedIn-Icon.svg"),
                          expression:
                            "require('@assets/img/about/bios/LinkedIn-Icon.svg')",
                        },
                      ],
                      staticClass: "d-block",
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-end bio-headshot",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/about/headshot-6.jpg"),
                    expression: "require('@assets/img/about/headshot-6.jpg')",
                  },
                ],
                staticClass: "img-fluid",
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c("router-link", { attrs: { to: { name: "About" }, exact: "" } }, [
              _vm._v("About"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("authored-reports", { attrs: { authorId: 313 } }),
      _vm._v(" "),
      _c("section", { staticClass: "bio extra" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "row px-5 mb-md-4" }, [
            _c("div", { staticClass: "col-md-6 pb-4 pb-md-0" }, [
              _c("div", { staticClass: "position-relative" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/about/bios/kristen-harmon-ingenito/eyecelerator-2024.jpeg"),
                      expression:
                        "require('@assets/img/about/bios/kristen-harmon-ingenito/eyecelerator-2024.jpeg')",
                    },
                  ],
                  staticClass: "img-fluid",
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "position-absolute",
                    staticStyle: {
                      left: "calc(50% - 31.5px)",
                      bottom: "-31.5px",
                    },
                    attrs: {
                      href: "https://www.eyecelerator.com/ascrs-2024-sessions?wix-vod-video-id=c2fa2615ce2748d39c893e73caa1f7dc&wix-vod-comp-id=comp-lwh1th6k2",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/play-icon.png"),
                          expression:
                            "require('@assets/img/about/bios/play-icon.png')",
                        },
                      ],
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "text-center mx-5 mt-5" }, [
                _vm._v("Eyecelerator 2024 - ASCRS 2024 Main Stage Sessions"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 pb-4 pb-md-0" }, [
              _c(
                "div",
                { staticClass: "position-relative" },
                [
                  _vm.playPresentationVideo2
                    ? [
                        _c("LazyVimeo", {
                          attrs: {
                            src: "https://vimeo.com/926307249",
                            showTitle: false,
                            autoplay: true,
                            customThumbnail:
                              "https://vumbnail.com/926307249.jpg",
                          },
                        }),
                      ]
                    : [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/about/bios/kristen-harmon-ingenito/926307249.jpg"),
                              expression:
                                "require('@assets/img/about/bios/kristen-harmon-ingenito/926307249.jpg')",
                            },
                          ],
                          staticClass: "img-fluid",
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "position-absolute",
                            staticStyle: {
                              left: "calc(50% - 31.5px)",
                              bottom: "-31.5px",
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.playPresentationVideo2 = true
                              },
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: require("@assets/img/about/bios/play-icon.png"),
                                  expression:
                                    "require('@assets/img/about/bios/play-icon.png')",
                                },
                              ],
                            }),
                          ]
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("h5", { staticClass: "text-center mx-5 mt-5" }, [
                _vm._v(
                  "2024 New Horizons Forum - Through the Eye of Market Scope"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row px-5 mb-md-4" }, [
            _c("div", { staticClass: "col-md-6 pb-4 pb-md-0" }, [
              _c(
                "div",
                { staticClass: "position-relative" },
                [
                  _vm.playPresentationVideo3
                    ? [
                        _c("LazyVimeo", {
                          attrs: {
                            src: "https://vimeo.com/807712817",
                            showTitle: false,
                            autoplay: true,
                            customThumbnail:
                              "https://vumbnail.com/807712817.jpg",
                          },
                        }),
                      ]
                    : [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/about/bios/kristen-harmon-ingenito/807712817.jpg"),
                              expression:
                                "require('@assets/img/about/bios/kristen-harmon-ingenito/807712817.jpg')",
                            },
                          ],
                          staticClass: "img-fluid",
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "position-absolute",
                            staticStyle: {
                              left: "calc(50% - 31.5px)",
                              bottom: "-31.5px",
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.playPresentationVideo3 = true
                              },
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: require("@assets/img/about/bios/play-icon.png"),
                                  expression:
                                    "require('@assets/img/about/bios/play-icon.png')",
                                },
                              ],
                            }),
                          ]
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("h5", { staticClass: "text-center mx-5 mt-5" }, [
                _vm._v(
                  "2023 New Horizons Forum - A Bird’s Eye View of Innovation"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 pb-4 pb-md-0" }, [
              _c(
                "div",
                { staticClass: "position-relative" },
                [
                  _vm.playPresentationVideo4
                    ? [
                        _c("LazyVimeo", {
                          attrs: {
                            src: "https://vimeo.com/686398398",
                            showTitle: false,
                            autoplay: true,
                            customThumbnail:
                              "https://vumbnail.com/686398398.jpg",
                          },
                        }),
                      ]
                    : [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/about/bios/kristen-harmon-ingenito/686398398.jpg"),
                              expression:
                                "require('@assets/img/about/bios/kristen-harmon-ingenito/686398398.jpg')",
                            },
                          ],
                          staticClass: "img-fluid",
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "position-absolute",
                            staticStyle: {
                              left: "calc(50% - 31.5px)",
                              bottom: "-31.5px",
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.playPresentationVideo4 = true
                              },
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: require("@assets/img/about/bios/play-icon.png"),
                                  expression:
                                    "require('@assets/img/about/bios/play-icon.png')",
                                },
                              ],
                            }),
                          ]
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("h5", { staticClass: "text-center mx-5 mt-5" }, [
                _vm._v(
                  "2022 New Horizons Forum - The Pressure’s On: An Inside Look at the Glaucoma Treatment Market"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row px-5" }, [
            _c("div", { staticClass: "col-md-6 offset-md-3 text-center" }, [
              _c("div", { staticClass: "position-relative" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/about/bios/kristen-harmon-ingenito/eyeluminaries.jpeg"),
                      expression:
                        "require('@assets/img/about/bios/kristen-harmon-ingenito/eyeluminaries.jpeg')",
                    },
                  ],
                  staticClass: "img-fluid",
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "position-absolute",
                    staticStyle: {
                      left: "calc(50% - 31.5px)",
                      bottom: "-31.5px",
                    },
                    attrs: {
                      href: "https://www.healio.com/news/ophthalmology/podcasts/eyeluminaries/episode-20",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/play-icon.png"),
                          expression:
                            "require('@assets/img/about/bios/play-icon.png')",
                        },
                      ],
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "text-center mx-5 mt-5" }, [
                _vm._v(
                  "Healio Eyeluminaries - Live from AAO Eyecelerator 2023"
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("combined-registration", { attrs: { origin: "bio" } }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Phone: "),
      _c("a", { attrs: { href: "tel:+13148350600" } }, [
        _vm._v("314-835-0600"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Email: "),
      _c("a", { attrs: { href: "mailto:kristen@market-scope.com" } }, [
        _vm._v("kristen@market-scope.com"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Kristen Harmon Ingenito")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bio content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row px-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mx-md-5" }, [
              _c("h4", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "Kristen Harmon Ingenito has nearly two decades of experience in the ophthalmic industry, with over 15 of those years spent analyzing data and reporting for Market Scope."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Kristen’s industry experience began in 2005 at Market Scope, where she served in many roles, including editing, analysis, sales, and marketing. She then joined an ophthalmic patient education software company, where she worked closely with physicians to help drive value in all aspects of their practices, while also training incoming executives on the business of eye care."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "She returned to Market Scope with added experience to help expand the company’s portfolio of products and services."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Kristen earned her bachelor’s degree from the University of Missouri–St. Louis and her master’s of business administration from the George Washington University School of Business as a Forté Fellow and a member of the Golden Key International Honor Society."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "In addition to managing all of Market Scope’s ophthalmic publications and services, she is a frequent speaker at ophthalmic meetings around the world, contributes to the Ophthalmic Market Perspectives newsletter, and helps our team research and write reports."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Kristen is also an executive board member of Ophthalmic World Leaders, which aims to promote and develop diverse leadership to advance ophthalmic innovation and patient care."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "When she’s not crunching numbers or building charts, Kristen enjoys spending time outdoors with her husband, three children, and doodle dog."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "mt-4 mb-5" }, [_vm._v("Presentation Videos")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }