var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "atlases-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "atlases", attrs: { id: "atlases" } }, [
        _c("div", { staticClass: "container header-row" }, [
          _c("div", [
            _c("div", { staticClass: "mx-auto text-center" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/icons/Research-Icons-07.svg"),
                    expression:
                      "require('@assets/img/icons/Research-Icons-07.svg')",
                  },
                ],
                staticStyle: {
                  "max-width": "70px",
                  width: "auto",
                  height: "auto",
                },
                attrs: { width: "70", height: "70" },
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "section-heading" }, [
                _vm._v("United States Ophthalmic Atlases"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Market Scope’s United States ophthalmic atlases identify treatment opportunity at the metro level for the top 85 metros—identifying high-opportunity and diseased populations, procedure volumes, premium penetration rates, surgeon counts, surgical locations, and much more. Companies often use this data throughout the development and commercialization processes to identify ideal clinical trial locations, develop launch strategies, define sales territories, allocate resources, and optimize sales and marketing initiatives."
                ),
              ]),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container mt-3" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card border-0" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("LazyYoutube", {
                      attrs: {
                        src: "https://youtu.be/GpTc_AUVlfg",
                        showTitle: false,
                        customThumbnail:
                          "https://img.youtube.com/vi/GpTc_AUVlfg/sddefault.jpg",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(3),
          ]),
          _vm._v(" "),
          _vm.products.length
            ? _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "card border-0" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body pt-2" }, [
                      _c("div", { staticClass: "container" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "card-group" },
                            _vm._l(_vm.products, function (product) {
                              return _c(
                                "div",
                                { staticClass: "card col-md-3 p-1 bg-white" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card-body p-0" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Report",
                                              params: {
                                                id: product.id,
                                                slug: product.slug,
                                              },
                                            },
                                            title: product.label,
                                          },
                                        },
                                        [
                                          product.document_original_filename ||
                                          product.document_sample_filename
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "lazy",
                                                    rawName: "v-lazy",
                                                    value:
                                                      "/files/products/samples/" +
                                                      product.id +
                                                      "/" +
                                                      (product.document_sample_filename
                                                        ? product.document_sample_filename.substr(
                                                            0,
                                                            product.document_sample_filename.lastIndexOf(
                                                              "."
                                                            )
                                                          )
                                                        : product.document_original_filename.substr(
                                                            0,
                                                            product.document_original_filename.lastIndexOf(
                                                              "."
                                                            )
                                                          )) +
                                                      ".thumb.jpg",
                                                    expression:
                                                      "'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                  },
                                                ],
                                                staticClass:
                                                  "img-thumbnail d-block mx-auto border-0",
                                                attrs: {
                                                  alt: product.cover_img_alt
                                                    ? product.cover_img_alt
                                                    : product.label,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(5),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center mt-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              attrs: { type: "button" },
              on: { click: _vm.showContactUsModal },
            },
            [_vm._v("Contact Us")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("register"),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("contact-us"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("United States Ophthalmic Atlases")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-xl-5" }, [
      _c("div", { attrs: { id: "map" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "card border-0" }, [
          _c(
            "div",
            { staticClass: "card-header bg-transparent border-bottom-0" },
            [
              _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
                _vm._v("Provided by metro"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body pt-2" }, [
            _c("ul", [
              _c("li", [
                _c("strong", [
                  _vm._v("Identification of high-opportunity population"),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Procedure volumes")]),
                _vm._v(" by type"),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Surgeon profiles")]),
                _vm._v(" by volume category"),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("ASC profiles")]),
                _vm._v(": Counts and average procedure volumes"),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Demographic profiles")]),
                _vm._v(
                  ": Population by age group, median age, household income, and diseased population"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Much more")]),
                _vm._v(" varying by atlas subspecialty"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "card border-0" }, [
          _c(
            "div",
            { staticClass: "card-header bg-transparent border-bottom-0" },
            [
              _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
                _vm._v("Use cases"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body pt-2" }, [
            _c("ul", [
              _c("li", [_vm._v("Development of product launch strategies")]),
              _vm._v(" "),
              _c("li", [_vm._v("Sales territory analysis")]),
              _vm._v(" "),
              _c("li", [_vm._v("Clinical trial site selection")]),
              _vm._v(" "),
              _c("li", [_vm._v("Allocation of resources")]),
              _vm._v(" "),
              _c("li", [_vm._v("Identifying metro-level market share")]),
              _vm._v(" "),
              _c("li", [_vm._v("Comparing market potential between metros")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "card border-0" }, [
        _c(
          "div",
          { staticClass: "card-header bg-transparent border-bottom-0" },
          [
            _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
              _vm._v("Methodology"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body pt-2" }, [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              "Market Scope uses the proprietary MedOp Index™ Analysis to examine market potential and provides a benchmark against other metros. Each clinical area atlas considers multiple factors to identify opportunity. For example, the cataract MedOp Index™ considers the concentration of active, affluent seniors; the number of high-volume cataract surgeons; and ASC counts and volumes, while the retina MedOp Index™ considers annual IVT injections and vitrectomies per provider; the geographic concentration of diabetics; the total population with retinal diseases; and high-volume retinal care providers."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header bg-transparent border-bottom-0" },
      [
        _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
          _vm._v("Available Atlases"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card border-0" }, [
          _c(
            "div",
            { staticClass: "card-header bg-transparent border-bottom-0" },
            [
              _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
                _vm._v("Learn More"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body pt-2" }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "Contact us using the button below to request more information on atlas coverage, view a demo, or schedule a time to discuss this live with our team."
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }