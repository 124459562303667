import { load } from 'recaptcha-v3'

export const useGoogleRecaptcha = () => {
	const getRecaptchaToken = async action => {
		const recaptcha = await load(process.env.RECAPTCHA_V3_SITEKEY, {
			autoHideBadge: true,
			useRecaptchaNet: true
		})
		const token = await recaptcha.execute(action)
		return token
	}
	
	return { getRecaptchaToken }
}