var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "surveys-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "surveys" }, [
        _c("div", { staticClass: "container header-row" }, [
          _c("div", [
            _c("div", { staticClass: "mx-auto text-center mb-5" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/icons/MarketScope-Icons-05.svg"),
                    expression:
                      "require('@assets/img/icons/MarketScope-Icons-05.svg')",
                  },
                ],
                staticClass: "d-block mx-auto",
                staticStyle: {
                  "max-width": "70px",
                  width: "auto",
                  height: "auto",
                },
                attrs: { width: "70", height: "70" },
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "section-heading" }, [
                _vm._v("Complete Surveys. Gain Insights."),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container mt-3" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card border-0" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "card-body pt-2" }, [
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _c("li", { staticClass: "media" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-01.svg"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-01.svg')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(3),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "media my-4" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-02.svg"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-02.svg')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(4),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "media" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-03.svg"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-03.svg')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(5),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card border-0" }, [
                _vm._m(6),
                _vm._v(" "),
                _c("div", { staticClass: "card-body pt-2" }, [
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _c("li", { staticClass: "media" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-04.svg"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-04.svg')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(7),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "media my-4" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-05.svg"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-05.svg')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(8),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "media" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-06.svg"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-06.svg')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(9),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-md-11 offset-md-1" }, [
              _c("div", { staticClass: "card border-0" }, [
                _vm._m(10),
                _vm._v(" "),
                _c("div", { staticClass: "card-body pt-2" }, [
                  _c("div", { staticClass: "media" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/icons/CareCredit-Icons-07.png"),
                          expression:
                            "require('@assets/img/icons/CareCredit-Icons-07.png')",
                        },
                      ],
                      staticClass: "align-self-start mr-3",
                      staticStyle: {
                        "max-width": "80px",
                        width: "auto",
                        height: "auto",
                      },
                      attrs: { width: "80", height: "80" },
                    }),
                    _vm._v(" "),
                    _vm._m(11),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(12),
          _vm._v(" "),
          _c("div", { staticClass: "row my-3 align-items-center" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card border-0" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _c("li", { staticClass: "media mb-5" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-08.svg"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-08.svg')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(13),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "media my-5" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-09.svg"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-09.svg')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(14),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "media mt-5" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/icons/CareCredit-Icons-10.png"),
                            expression:
                              "require('@assets/img/icons/CareCredit-Icons-10.png')",
                          },
                        ],
                        staticClass: "mr-3",
                        staticStyle: {
                          "max-width": "80px",
                          width: "auto",
                          height: "auto",
                        },
                        attrs: { width: "80", height: "80" },
                      }),
                      _vm._v(" "),
                      _vm._m(15),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center mt-4" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { to: { name: "CarecreditAtlases" } },
                        },
                        [_vm._v("Learn More")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card bg-light" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "form",
                    {
                      directives: [
                        {
                          name: "promise-btn",
                          rawName: "v-promise-btn",
                          value: { action: "submit" },
                          expression: "{ action: 'submit' }",
                        },
                      ],
                      ref: "carecreditRegistrationForm",
                      attrs: {
                        method: "post",
                        "accept-charset": "utf-8",
                        novalidate: "",
                        id: "carecredit-registration-form",
                        role: "form",
                        action: "/clinical-groups/register-landing/carecredit",
                      },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticStyle: { display: "none" } }, [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "hidden",
                            name: "_method",
                            value: "POST",
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "_csrfToken",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.csrfToken },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "g_recaptcha_response",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.gRecaptchaToken },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "form-group text required" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.full_name.$model,
                                    expression: "$v.full_name.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: {
                                  "form-control": true,
                                  "is-invalid":
                                    _vm.$v.full_name.$dirty &&
                                    _vm.$v.full_name.$error,
                                },
                                attrs: {
                                  type: "text",
                                  name: "full_name",
                                  placeholder: "Full Name",
                                  id: "clinical-full-name",
                                  required: "",
                                  maxlength: "50",
                                },
                                domProps: { value: _vm.$v.full_name.$model },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.full_name,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.full_name.$dirty &&
                              !_vm.$v.full_name.required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Full name is required.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.full_name.$dirty &&
                              !_vm.$v.full_name.minLength
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "Full name has a minimum of 3 characters."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "form-group email required" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.email.$model,
                                    expression: "$v.email.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: {
                                  "form-control": true,
                                  "is-invalid":
                                    _vm.$v.email.$dirty && _vm.$v.email.$error,
                                },
                                attrs: {
                                  type: "email",
                                  name: "email",
                                  placeholder: "Email",
                                  id: "clinical-email",
                                  required: "",
                                  maxlength: "254",
                                },
                                domProps: { value: _vm.$v.email.$model },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.email,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.email.$dirty && !_vm.$v.email.required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Email address is required.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.email.$dirty && !_vm.$v.email.email
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Email address has to be valid.")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "form-group text required" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value:
                                      _vm.$v.user_attribute.practice.name
                                        .$model,
                                    expression:
                                      "$v.user_attribute.practice.name.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: {
                                  "form-control": true,
                                  "is-invalid":
                                    _vm.$v.user_attribute.practice.name
                                      .$dirty &&
                                    _vm.$v.user_attribute.practice.name.$error,
                                },
                                attrs: {
                                  type: "text",
                                  name: "user_attribute[practice][name]",
                                  placeholder: "Practice Name",
                                  id: "clinical-practice-name",
                                  required: "",
                                  maxlength: "255",
                                },
                                domProps: {
                                  value:
                                    _vm.$v.user_attribute.practice.name.$model,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.user_attribute.practice.name,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.user_attribute.practice.name.$dirty &&
                              !_vm.$v.user_attribute.practice.name.required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Practice name is required.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.user_attribute.practice.name.$dirty &&
                              !_vm.$v.user_attribute.practice.name.minLength
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "Practice name has a minimum of 3 characters."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "form-group select required" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "user-attribute-practice-location-id",
                                  },
                                },
                                [_vm._v("Country")]
                              ),
                              _vm._v(" "),
                              _c(
                                "multiselect",
                                {
                                  class: {
                                    "is-invalid":
                                      _vm.$v.user_attribute.practice.location_id
                                        .$dirty &&
                                      _vm.$v.user_attribute.practice.location_id
                                        .$error,
                                  },
                                  attrs: {
                                    options: _vm.countryOptions,
                                    placeholder: "Select your country...",
                                    label: "alias",
                                    "track-by": "id",
                                    "deselect-label": "",
                                    "aria-invalid": "false",
                                    id: "user-attribute-practice-location-id",
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.selectedCountry,
                                    callback: function ($$v) {
                                      _vm.selectedCountry =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "selectedCountry",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult",
                                    },
                                    [_vm._v("No matching countries found.")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: "user_attribute[practice][location_id]",
                                },
                                domProps: {
                                  value:
                                    _vm.user_attribute.practice.location_id,
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.user_attribute.practice.location_id
                                .$dirty &&
                              !_vm.$v.user_attribute.practice.location_id
                                .required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Country is required.")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.user_attribute.practice.location_id === 1
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                { staticClass: "form-group select required" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "user-attribute-practice-state",
                                      },
                                    },
                                    [_vm._v("State")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.user_attribute.practice.state,
                                          expression:
                                            "user_attribute.practice.state",
                                        },
                                      ],
                                      class: {
                                        "form-control": true,
                                        "is-invalid":
                                          _vm.$v.user_attribute.practice.state
                                            .$dirty &&
                                          _vm.$v.user_attribute.practice.state
                                            .$error,
                                      },
                                      attrs: {
                                        name: "user_attribute[practice][state]",
                                        id: "user-attribute-practice-state",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.user_attribute.practice,
                                            "state",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "AL" } }, [
                                        _vm._v("Alabama"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "AK" } }, [
                                        _vm._v("Alaska"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "AZ" } }, [
                                        _vm._v("Arizona"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "AR" } }, [
                                        _vm._v("Arkansas"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "CA" } }, [
                                        _vm._v("California"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "CO" } }, [
                                        _vm._v("Colorado"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "CT" } }, [
                                        _vm._v("Connecticut"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "DE" } }, [
                                        _vm._v("Delaware"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "FL" } }, [
                                        _vm._v("Florida"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "GA" } }, [
                                        _vm._v("Georgia"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "HI" } }, [
                                        _vm._v("Hawaii"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "ID" } }, [
                                        _vm._v("Idaho"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "IL" } }, [
                                        _vm._v("Illinois"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "IN" } }, [
                                        _vm._v("Indiana"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "IA" } }, [
                                        _vm._v("Iowa"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "KS" } }, [
                                        _vm._v("Kansas"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "KY" } }, [
                                        _vm._v("Kentucky"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "LA" } }, [
                                        _vm._v("Louisiana"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "ME" } }, [
                                        _vm._v("Maine"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "MD" } }, [
                                        _vm._v("Maryland"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "MA" } }, [
                                        _vm._v("Massachusetts"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "MI" } }, [
                                        _vm._v("Michigan"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "MN" } }, [
                                        _vm._v("Minnesota"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "MS" } }, [
                                        _vm._v("Mississippi"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "MO" } }, [
                                        _vm._v("Missouri"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "MT" } }, [
                                        _vm._v("Montana"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "NE" } }, [
                                        _vm._v("Nebraska"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "NV" } }, [
                                        _vm._v("Nevada"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "NH" } }, [
                                        _vm._v("New Hampshire"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "NJ" } }, [
                                        _vm._v("New Jersey"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "NM" } }, [
                                        _vm._v("New Mexico"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "NY" } }, [
                                        _vm._v("New York"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "NC" } }, [
                                        _vm._v("North Carolina"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "ND" } }, [
                                        _vm._v("North Dakota"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "OH" } }, [
                                        _vm._v("Ohio"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "OK" } }, [
                                        _vm._v("Oklahoma"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "OR" } }, [
                                        _vm._v("Oregon"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "PA" } }, [
                                        _vm._v("Pennsylvania"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "RI" } }, [
                                        _vm._v("Rhode Island"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "SC" } }, [
                                        _vm._v("South Carolina"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "SD" } }, [
                                        _vm._v("South Dakota"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "TN" } }, [
                                        _vm._v("Tennessee"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "TX" } }, [
                                        _vm._v("Texas"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "UT" } }, [
                                        _vm._v("Utah"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "VT" } }, [
                                        _vm._v("Vermont"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "VA" } }, [
                                        _vm._v("Virginia"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "WA" } }, [
                                        _vm._v("Washington"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "WV" } }, [
                                        _vm._v("West Virginia"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "WI" } }, [
                                        _vm._v("Wisconsin"),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "WY" } }, [
                                        _vm._v("Wyoming"),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.$v.user_attribute.practice.state.$dirty &&
                                  (!_vm.$v.user_attribute.practice.state
                                    .required ||
                                    !_vm.$v.user_attribute.practice.state
                                      .minLength ||
                                    !_vm.$v.user_attribute.practice.state
                                      .maxLength)
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [_vm._v("State is required.")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user_attribute.practice.location_id === 1
                        ? _c("div", { staticClass: "row mb-3" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "card bg-white" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-body pt-3 pb-1" },
                                  [
                                    _c("div", { staticClass: "required" }, [
                                      _c(
                                        "label",
                                        { staticClass: "control-label" },
                                        [_vm._v("Practice Role")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-group select" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.user_attribute
                                                      .practice_role_id,
                                                  expression:
                                                    "user_attribute.practice_role_id",
                                                },
                                              ],
                                              class: {
                                                "form-control": true,
                                                "is-invalid":
                                                  _vm.$v.user_attribute
                                                    .practice_role_id.$dirty &&
                                                  _vm.$v.user_attribute
                                                    .practice_role_id.$error,
                                              },
                                              attrs: {
                                                name: "user_attribute[practice_role_id]",
                                                id: "user-attribute-practice-role-id",
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.user_attribute,
                                                      "practice_role_id",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  _vm.onChangePracticeRole,
                                                ],
                                              },
                                            },
                                            _vm._l(
                                              _vm.practiceRoleOptions,
                                              function (practiceRoleOption) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: {
                                                      value:
                                                        practiceRoleOption.id,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        practiceRoleOption.label
                                                      )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _vm.$v.user_attribute.practice_role_id
                                            .$dirty &&
                                          (!_vm.$v.user_attribute
                                            .practice_role_id.required ||
                                            !_vm.$v.user_attribute
                                              .practice_role_id.numeric)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    "Practice role is required."
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.npiRequired
                                      ? _c(
                                          "div",
                                          { staticClass: "form-group text" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value:
                                                    _vm.$v.user_attribute.npi
                                                      .$model,
                                                  expression:
                                                    "$v.user_attribute.npi.$model",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              class: {
                                                "form-control": true,
                                                "is-invalid":
                                                  _vm.$v.user_attribute.npi
                                                    .$dirty &&
                                                  _vm.$v.user_attribute.npi
                                                    .$error,
                                              },
                                              attrs: {
                                                type: "text",
                                                name: "user_attribute[npi]",
                                                placeholder: "NPI",
                                                id: "npi",
                                                maxlength: "10",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.$v.user_attribute.npi
                                                    .$model,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.$v.user_attribute.npi,
                                                    "$model",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _vm.$v.user_attribute.npi.$dirty &&
                                            !_vm.$v.user_attribute.npi.required
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback",
                                                  },
                                                  [_vm._v("NPI is required.")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.$v.user_attribute.npi.$dirty &&
                                            !_vm.$v.user_attribute.npi.numeric
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "NPI can only contain numbers."
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.$v.user_attribute.npi.$dirty &&
                                            _vm.$v.user_attribute.npi.numeric &&
                                            (!_vm.$v.user_attribute.npi
                                              .minLength ||
                                              !_vm.$v.user_attribute.npi
                                                .maxLength)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "invalid-feedback",
                                                  },
                                                  [_vm._v("Invalid NPI.")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group password required" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.passwd.$model,
                                    expression: "$v.passwd.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: {
                                  "form-control": true,
                                  "is-invalid":
                                    _vm.$v.passwd.$dirty &&
                                    _vm.$v.passwd.$error,
                                },
                                attrs: {
                                  type: "password",
                                  name: "passwd",
                                  placeholder: "Enter a password",
                                  id: "clinical-password",
                                  required: "",
                                },
                                domProps: { value: _vm.$v.passwd.$model },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.passwd,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.passwd.$dirty && !_vm.$v.passwd.required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Password is required.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.passwd.$dirty && !_vm.$v.passwd.minLength
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "Password has a minimum of 6 characters."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group password" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.passwd_confirm.$model,
                                  expression: "$v.passwd_confirm.$model",
                                  modifiers: { trim: true },
                                },
                              ],
                              class: {
                                "form-control": true,
                                "is-invalid":
                                  _vm.$v.passwd_confirm.$dirty &&
                                  _vm.$v.passwd_confirm.$error,
                              },
                              attrs: {
                                type: "password",
                                name: "passwd_confirm",
                                placeholder: "Re-enter your password",
                                id: "clinical-password-confirm",
                              },
                              domProps: { value: _vm.$v.passwd_confirm.$model },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.passwd_confirm,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.passwd_confirm.$dirty &&
                            !_vm.$v.passwd_confirm.sameAsPasswd
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v("Password re-entry has to match."),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._m(16),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Survey Opportunities")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("p", [
          _vm._v(
            "Since 2000, Market Scope has been a confidential and unbiased third party that provides ophthalmologists with real-time trends curated by a robust team of data and industry experts through surveys on practice of medicine techniques, procedure volumes, preferences for new technology, marketing strategies, and much more."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header bg-transparent border-bottom-0" },
      [
        _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
          _vm._v("Why participate?"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Industry Insights")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tGain access to and stay up to date on industry insights and market trends.\n\t\t\t\t\t\t\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Make Your Voice Heard")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tCompanies rely on Market Scope to provide guidance on the needs of ophthalmologists, so your participation can often help guide product development, messaging, pricing, and access.\n\t\t\t\t\t\t\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Incentives")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tMost surveys include a donation to a charitable organization or an e-gift card.\n\t\t\t\t\t\t\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header bg-transparent border-bottom-0" },
      [
        _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
          _vm._v("What makes Market Scope unique?"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Analysis")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tWe take careful steps to cross-analyze and uncover trends beyond the individual data points.\n\t\t\t\t\t\t\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("History & Repetition")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tMany of our data points have extensive historical analysis. Our quarterly survey process also ensures that we are actively capturing market shifts.\n\t\t\t\t\t\t\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Content")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tWe actively update our questions to ensure that we are asking about topics of highest interest and relevance to the business of ophthalmology.\n\t\t\t\t\t\t\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header bg-transparent border-bottom-0" },
      [
        _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
          _vm._v("Will your data be shared with other colleagues/companies?"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Your Data is Confidential")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Market Scope only reports aggregated data in our survey reports that are shared with participants or included in the market reports that we publish for purchase."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Your individual responses will always be de-identified before summary analysis to safeguard your anonymity."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "font-weight-bold" }, [
          _vm._v("Ready to get started?"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Register a Free Account")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tA clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard.\n\t\t\t\t\t\t\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Contact Us")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tStart a chart using the chat box at the bottom of this page or email us at "
      ),
      _c("a", { attrs: { href: "mailto:info@market-scope.com" } }, [
        _vm._v("info@market-scope.com"),
      ]),
      _vm._v(".\n\t\t\t\t\t\t\t\t\t"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("h5", { staticClass: "my-0" }, [_vm._v("Need More Data?")]),
      _vm._v(
        "\n\t\t\t\t\t\t\t\t\t\tClick the below button to learn about metro-level opportunity analyses available to CareCredit enrolled providers.\n\t\t\t\t\t\t\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        { staticClass: "btn btn-large btn-success", attrs: { type: "submit" } },
        [_vm._v("Register")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }