<template>
	<section class="register">
		<div class="container" id="register">
			<div class="row">
				<div class="col text-center mb-3">
					<h3>Join Market Scope Today</h3>
					<div class="d-flex justify-content-center align-items-end mt-3 mb-4">
						<div :class="{ 'position-relative': true, 'mr-3': true, selected: registerType === 'corporate' }">
							<button @click="registerType = 'corporate'" type="button" class="btn btn-register py-2 px-3">I am an Industry Professional</button>
							<div class="btn-bottom-arrow"></div>
						</div>
						<div :class="{ 'position-relative': true, selected: registerType === 'clinical' }">
							<button @click="registerType = 'clinical'" type="button" class="btn btn-register py-2 px-3">I am a Medical Professional</button>
							<div class="btn-bottom-arrow"></div>
						</div>
					</div>
					<p v-if="registerType === 'corporate'">A corporate account gives you access to licensed reports and subscriptions, the latest news, a personalized dashboard, and weekly emails with news and data.</p>
					<p v-if="registerType === 'clinical'">A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard.</p>
				</div>
			</div>
			<div class="card shadow-lg mx-sm-0 mx-md-5">
				<div class="card-body">
					<form method="post" v-promise-btn="{ action: registerMethod === 'methodRegister' ? 'submit' : 'login' }" @submit.prevent="registerMethod === 'methodRegister' ? submit() : login()" accept-charset="utf-8" novalidate id="registration-form" ref="registrationForm" role="form" :action="registerMethod === 'methodRegister' ? '/users/' + (registerType === 'corporate' ? 'corporate-' : '') + 'register' : '/users/login'">
						<div style="display: none;">
							<input type="hidden" name="_method" value="POST" class="form-control" />
							<input type="hidden" name="_csrfToken" autocomplete="off" :value="csrfToken" />
							<input type="hidden" name="g_recaptcha_response" autocomplete="off" :value="gRecaptchaToken" />
							<input type="hidden" name="origin" :value="origin" />
							<select v-if="registerType === 'clinical'" multiple name="tag_list[]">
								<option v-for="tag in tag_list" :value="tag" selected>{{ tag }}</option>
							</select>
						</div>
						<div class="text-center my-3">
							<h5 class="card-title">Sign In or Register</h5>
						</div>
						<div class="form-row">
							<div class="form-group col text-center">
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" v-model="registerMethod" value="methodRegister" id="methodRegister" class="custom-control-input" />
									<label class="custom-control-label" for="methodRegister">Register an account</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" v-model="registerMethod" value="methodSignIn" id="methodSignIn" class="custom-control-input" />
									<label class="custom-control-label" for="methodSignIn">Sign in</label>
								</div>
							</div>
						</div>
						<div v-if="registerMethod === 'methodRegister'" class="form-row">
							<div class="form-group col-md-6">
								<label for="inputFullName">Full Name*</label>
								<input type="text" v-model.trim="$v.registerForm.full_name.$model" name="full_name" placeholder="Full Name" id="inputFullName" required maxlength="50" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.full_name.$dirty && $v.registerForm.full_name.$error }" />
								<div class="invalid-feedback" v-if="$v.registerForm.full_name.$dirty && !$v.registerForm.full_name.required">Full name is required.</div>
								<div class="invalid-feedback" v-if="$v.registerForm.full_name.$dirty && !$v.registerForm.full_name.minLength">Full name has a minimum of 3 characters.</div>
							</div>
							<div v-if="registerType === 'corporate'" class="form-group col-md-3">
								<label for="inputCompany">Company*</label>
								<vue-bootstrap-typeahead
									placeholder="Company Name"
									v-model.trim="$v.companyName.$model"
									:data="companyNames"
									:serializer="v => v.name"
									@hit="selectCompany"
									@input="deselectCompany"
									id="inputCompany"
									:inputClass="$v.companyName.$dirty && $v.companyName.$error ? 'is-invalid' : ''"
								/>
								<input type="hidden" name="companies[0][name]" ref="corporate_company_name" value="" />
								<input type="hidden" name="companies[0][id]" ref="corporate_company_id" value="" />
								<div v-if="$v.companyName.$dirty && !$v.companyName.required" class="invalid-feedback" style="display: block;">Company name is required.</div>
							</div>
							<div v-if="registerType === 'corporate'" class="form-group col-md-3">
								<label for="inputJobTitle">Job Title</label>
								<input type="text" class="form-control" name="user_attribute[title]" id="inputJobTitle" placeholder="Job Title" maxlength="255">
							</div>
							<div v-if="registerType === 'clinical'" class="form-group col-md-6">
								<label for="inputPracticeName">Practice Name*</label>
								<input type="text" v-model.trim="$v.registerForm.user_attribute.practice.name.$model" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.user_attribute.practice.name.$dirty && $v.registerForm.user_attribute.practice.name.$error }" name="user_attribute[practice][name]" id="inputPracticeName" placeholder="Practice Name" required maxlength="255">
								<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice.name.$dirty && !$v.registerForm.user_attribute.practice.name.required">Practice name is required.</div>
								<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice.name.$dirty && !$v.registerForm.user_attribute.practice.name.minLength">Practice name has a minimum of 3 characters.</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-6">
								<label for="inputEmail">Email*</label>
								<input type="email" v-model.trim="$v.registerForm.email.$model" :name="registerMethod === 'methodRegister' ? 'email' : 'login_email'" placeholder="Email" id="inputEmail" required maxlength="254" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.email.$dirty && $v.registerForm.email.$error }" />
								<div class="invalid-feedback" v-if="$v.registerForm.email.$dirty && !$v.registerForm.email.required">Email address is required.</div>
								<div class="invalid-feedback" v-if="$v.registerForm.email.$dirty && !$v.registerForm.email.email">Email address has to be valid.</div>
							</div>
							<div :class="{ 'form-group': true, 'col-md-3': registerMethod === 'methodRegister', 'col-md-6': registerMethod === 'methodSignIn' }">
								<label for="inputPassword">Password*</label>
								<input type="password" v-model.trim="$v.registerForm.passwd.$model" :name="registerMethod === 'methodRegister' ? 'passwd' : 'password'" placeholder="Enter a password" id="inputPassword" required :class="{ 'form-control': true, 'is-invalid': $v.registerForm.passwd.$dirty && $v.registerForm.passwd.$error }" />
								<div class="invalid-feedback" v-if="$v.registerForm.passwd.$dirty && !$v.registerForm.passwd.required">Password is required.</div>
								<div class="invalid-feedback" v-if="$v.registerForm.passwd.$dirty && !$v.registerForm.passwd.minLength">Password has a minimum of 6 characters.</div>
							</div>
							<div v-if="registerMethod === 'methodRegister'" class="form-group col-md-3">
								<label for="inputReenterPassword">Re-enter Password*</label>
								<input type="password" v-model.trim="$v.registerForm.passwd_confirm.$model" name="passwd_confirm" placeholder="Re-enter your password" id="inputReenterPassword" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.passwd_confirm.$dirty && $v.registerForm.passwd_confirm.$error }" />
								<div class="invalid-feedback" v-if="$v.registerForm.passwd_confirm.$dirty && !$v.registerForm.passwd_confirm.sameAsPasswd">Password re-entry has to match.</div>
							</div>
						</div>
						<div v-if="registerMethod === 'methodRegister' && registerType === 'clinical'" class="form-row">
							<div :class="{ 'form-group': true, 'col-md-6': registerForm.user_attribute.practice.location_id === 1, col: registerForm.user_attribute.practice.location_id !== 1 }">
								<label for="inputPracticeLocationId" class="control-label">Country*</label>
								<multiselect
									v-model.trim="selectedCountry"
									:options="countryOptions"
									placeholder="Select your country..."
									label="alias"
									track-by="id"
									deselect-label=""
									aria-invalid="false"
									id="inputPracticeLocationId"
									required
									:class="{ 'is-invalid': $v.registerForm.user_attribute.practice.location_id.$dirty && $v.registerForm.user_attribute.practice.location_id.$error }"
								>
									<span slot="noResult">No matching countries found.</span>
								</multiselect>
								<input type="hidden" name="user_attribute[practice][location_id]" :value="registerForm.user_attribute.practice.location_id" />
								<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice.location_id.$dirty && !$v.registerForm.user_attribute.practice.location_id.required">Country is required.</div>
							</div>
							<div v-if="registerForm.user_attribute.practice.location_id === 1" class="form-group col-md-6">
								<label for="inputPracticeState" class="control-label">State</label>
								<select v-model="registerForm.user_attribute.practice.state" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.user_attribute.practice.state.$dirty && $v.registerForm.user_attribute.practice.state.$error }" name="user_attribute[practice][state]" id="inputPracticeState">
									<option value="AL">Alabama</option>
									<option value="AK">Alaska</option>
									<option value="AZ">Arizona</option>
									<option value="AR">Arkansas</option>
									<option value="CA">California</option>
									<option value="CO">Colorado</option>
									<option value="CT">Connecticut</option>
									<option value="DE">Delaware</option>
									<option value="FL">Florida</option>
									<option value="GA">Georgia</option>
									<option value="HI">Hawaii</option>
									<option value="ID">Idaho</option>
									<option value="IL">Illinois</option>
									<option value="IN">Indiana</option>
									<option value="IA">Iowa</option>
									<option value="KS">Kansas</option>
									<option value="KY">Kentucky</option>
									<option value="LA">Louisiana</option>
									<option value="ME">Maine</option>
									<option value="MD">Maryland</option>
									<option value="MA">Massachusetts</option>
									<option value="MI">Michigan</option>
									<option value="MN">Minnesota</option>
									<option value="MS">Mississippi</option>
									<option value="MO">Missouri</option>
									<option value="MT">Montana</option>
									<option value="NE">Nebraska</option>
									<option value="NV">Nevada</option>
									<option value="NH">New Hampshire</option>
									<option value="NJ">New Jersey</option>
									<option value="NM">New Mexico</option>
									<option value="NY">New York</option>
									<option value="NC">North Carolina</option>
									<option value="ND">North Dakota</option>
									<option value="OH">Ohio</option>
									<option value="OK">Oklahoma</option>
									<option value="OR">Oregon</option>
									<option value="PA">Pennsylvania</option>
									<option value="RI">Rhode Island</option>
									<option value="SC">South Carolina</option>
									<option value="SD">South Dakota</option>
									<option value="TN">Tennessee</option>
									<option value="TX">Texas</option>
									<option value="UT">Utah</option>
									<option value="VT">Vermont</option>
									<option value="VA">Virginia</option>
									<option value="WA">Washington</option>
									<option value="WV">West Virginia</option>
									<option value="WI">Wisconsin</option>
									<option value="WY">Wyoming</option>
								</select>
								<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice.state.$dirty && (!$v.registerForm.user_attribute.practice.state.required || !$v.registerForm.user_attribute.practice.state.minLength || !$v.registerForm.user_attribute.practice.state.maxLength)">State is required.</div>
							</div>
						</div>
						<div v-if="registerMethod === 'methodRegister' && registerType === 'clinical'" class="form-row">
							<div :class="{ 'col-md-6': registerForm.user_attribute.practice.location_id !== null && specialtyOptions.length > 1, col: registerForm.user_attribute.practice.location_id === null || specialtyOptions.length <= 1 }">
								<div class="card bg-light">
									<div class="card-body">
										<div class="required">
											<label class="control-label">Practice Role</label>
											<div class="form-group">
												<select v-model="registerForm.user_attribute.practice_role_id" @change="onChangePracticeRole" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.user_attribute.practice_role_id.$dirty && $v.registerForm.user_attribute.practice_role_id.$error }" name="user_attribute[practice_role_id]" id="user-attribute-practice-role-id">
													<option v-for="practiceRoleOption in practiceRoleOptions" :value="practiceRoleOption.id">{{ practiceRoleOption.label }}</option>
												</select>
												<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.practice_role_id.$dirty && (!$v.registerForm.user_attribute.practice_role_id.required || !$v.registerForm.user_attribute.practice_role_id.numeric)">Practice role is required.</div>
											</div>
										</div>
										<div v-if="npiRequired" class="form-group">
											<input type="text" v-model.trim="$v.registerForm.user_attribute.npi.$model" name="user_attribute[npi]" placeholder="NPI" id="npi" maxlength="10" :class="{ 'form-control': true, 'is-invalid': $v.registerForm.user_attribute.npi.$dirty && $v.registerForm.user_attribute.npi.$error }" />
											<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.npi.$dirty && !$v.registerForm.user_attribute.npi.required">NPI is required.</div>
											<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.npi.$dirty && !$v.registerForm.user_attribute.npi.numeric">NPI can only contain numbers.</div>
											<div class="invalid-feedback" v-if="$v.registerForm.user_attribute.npi.$dirty && $v.registerForm.user_attribute.npi.numeric && (!$v.registerForm.user_attribute.npi.minLength || !$v.registerForm.user_attribute.npi.maxLength)">Invalid NPI.</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="registerForm.user_attribute.practice.location_id !== null && specialtyOptions.length > 1" class="col-md-6">
								<div class="card bg-light">
									<div class="card-body">
										<div class="form-group">
											<label class="control-label">Your specialties</label>
											<treeselect
												:multiple="true"
												:options="specialtyOptions"
												v-model="tag_list"
												:flat="true"
												:value-consists-of="'ALL_WITH_INDETERMINATE'"
												:normalizer="normalizer"
												placeholder="Select one or more"
												:class="{ 'is-invalid': $v.tag_list.$dirty && $v.tag_list.$error }"
											/>
											<div class="invalid-feedback" v-if="$v.tag_list.$dirty && (!$v.tag_list.required || !$v.tag_list.minLength)">At least one specialty is required.</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<template v-if="registerMethod === 'methodRegister' && registerType === 'corporate'">
							<div class="text-center my-3">
								<h5 class="card-title">Personalized Reports</h5>
							</div>
							<div class="form-row">
								<div class="form-group select col-md-6">
									<label class="control-label">Topics of Interest</label>
									<multiselect v-model="topicsOfInterest" :options="topicsOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" />
									<select multiple name="topics_of_interest[]" style="display: none;">
										<option v-for="option in topicsOfInterest" :value="option" selected>{{ option }}</option>
									</select>
								</div>
								<div v-if="topicsOfInterest.includes('Other')" class="form-group text">
									<input type="text" name="topics_of_interest[other]" placeholder="Other" maxlength="255" class="form-control" />
								</div>
								<div class="form-group select col-md-6">
									<label class="control-label">Products of Interest</label>
									<multiselect v-model="productsOfInterest" :options="productsOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" />
									<select multiple name="products_of_interest[]" style="display: none;">
										<option v-for="option in productsOfInterest" :value="option" selected>{{ option }}</option>
									</select>
								</div>
								<div v-if="productsOfInterest.includes('Other')" class="form-group text">
									<input type="text" name="products_of_interest[other]" placeholder="Other" maxlength="255" class="form-control" />
								</div>
							</div>
						</template>
						<div class="text-center my-3">
							<button type="submit" class="btn btn-success" :disabled="!isMounted || isSubmitting">Submit</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { required, minLength, maxLength, numeric, email, sameAs } from 'vuelidate/lib/validators'

export default {
	name: 'CombinedRegistration',
	props: {
		origin: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			csrfToken: '',
			gRecaptchaToken: '',
			isMounted: false,
			unsubscribe: null,
			isSubmitting: false,
			companyName: '',
			registerType: 'corporate',
			registerMethod: 'methodRegister',
			registerForm: {
				email: '',
				full_name: '',
				passwd: '',
				passwd_confirm: '',
				user_attribute: {
					practice_role_id: null,
					npi: null,
					practice: {
						name: '',
						location_id: null,
						state: ''
					}
				}
			},
			topicsOfInterest: [],
			topicsOfInterestOptions: ['Cataract', 'Refractive', 'Glaucoma', 'Retina', 'Diagnostics', 'Pharmaceuticals', 'Surgical Devices', 'Dry Eye', 'Cornea', 'Other'],
			productsOfInterest: [],
			productsOfInterestOptions: ['Comprehensive Report', 'Quarterly Update', 'Newsletter', 'Custom Research', 'Other'],
			tag_list: [],
			normalizer(node) {
				if (node.children && node.children.length) {
					return {
						//id: uniqueId(node.label),
						id: node.label,
						label: node.label,
						children: node.children
					}
				} else {
					return {
						//id: uniqueId(node.label),
						id: node.label,
						label: node.label,
						children: undefined
					}
				}
			},
			countryOptions: [],
			practiceRoleOptions: [],
			specialtyOptions: []
		}
	},
	computed: {
		companyNames() {
			return this.$store.state.companyNames
		},
		selectedCountry: {
			get() {
				return this.countryOptions.find(countryOption => countryOption.id === this.registerForm.user_attribute.practice.location_id)
			},
			set(v) {
				if (v) {
					this.$set(this.registerForm.user_attribute.practice, 'location_id', v.id)
				}
			}
		},
		npiRequired() {
			if (this.registerForm.user_attribute.practice.location_id === 1 && this.registerForm.user_attribute.practice_role_id !== null) {
				const selectedPracticeRole = this.practiceRoleOptions.find(practiceRoleOption => practiceRoleOption.id === this.registerForm.user_attribute.practice_role_id)
				return selectedPracticeRole && selectedPracticeRole.rules && !!parseInt(selectedPracticeRole.rules.npi_required, 10);
			}
			
			return false;
		}
	},
	validations() {
		const validations = {
			registerForm: {
				email: {
					required,
					email
				},
				passwd: {
					required,
					minLength: minLength(6)
				}
			}
		}
		
		if (this.registerMethod === 'methodRegister') {
			validations.registerForm.full_name = {
				required,
				minLength: minLength(3)
			}
			validations.registerForm.passwd_confirm = {
				sameAsPasswd: sameAs('passwd')
			}
			
			if (this.registerType === 'corporate') {
				validations.companyName = {
					required
				}
			} else if (this.registerType === 'clinical') {
				validations.tag_list = {}
				validations.registerForm.user_attribute = {
					practice_role_id: {
						required,
						numeric
					},
					npi: {},
					practice: {
						name: {
							required,
							minLength: minLength(3)
						},
						location_id: {
							required
						},
						state: {}
					}
				}
				
				if (this.npiRequired) {
					validations.registerForm.user_attribute.npi = {
						required,
						numeric,
						minLength: minLength(10),
						maxLength: maxLength(10)
					}
				}
				
				if (this.registerForm.user_attribute.practice.location_id === 1) {
					validations.registerForm.user_attribute.practice.state = {
						required,
						minLength: minLength(2),
						maxLength: maxLength(2)
					}
				}
				
				if (this.specialtyOptions.length > 0) {
					validations.tag_list = {
						required,
						minLength: minLength(1)
					}
				}
			}
		}
		
		return validations
	},
	mounted() {
		this.isMounted = true
		
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'SET_INVISIBLE_RECAPTCHA' && state.invisibleRecaptcha.originComponent === this.$options.name) {
				this.recaptchaCallback(state.invisibleRecaptcha.token)
				
				this.unsubscribe()
			}
		})
		
		this.$http.get('funcs/countries')
			.then(response => {
				this.countryOptions = response.data.result.countries
			})
		this.$http.get('funcs/specialties')
			.then(response => {
				this.practiceRoleOptions = response.data.result.specialties
			})
	},
	beforeDestroy() {
		typeof this.unsubscribe === 'function' && this.unsubscribe()
	},
	watch: {
		'registerForm.user_attribute.practice.location_id': function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$set(this.registerForm.user_attribute.practice, 'state', '')
			}
		}
	},
	methods: {
		selectCompany(company) {
			this.$refs.corporate_company_id.value = company.id
			this.$refs.corporate_company_name.value = ''
		},
		deselectCompany() {
			this.$refs.corporate_company_id.value = ''
			this.$refs.corporate_company_name.value = this.companyName
		},
		onChangePracticeRole(e) {
			this.$http.get('funcs/specialties/' + e.target.value)
				.then(response => {
					this.specialtyOptions = response.data.result.specialties
					
					this.tag_list = []
					
					if (response.data.result.specialties.length === 1 && response.data.result.specialties[0].children.length === 0) {
						this.tag_list.push(response.data.result.specialties[0].label)
					}
				})
		},
		submit() {
			this.$v.$touch()
			
			if (!this.$v.$invalid) {
				this.isSubmitting = true
				
				this.$root.$children[0].$refs.invisibleRecaptcha.execute(this.$options.name)
			}
		},
		recaptchaCallback(token) {
			this.gRecaptchaToken = token
			
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.registrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		},
		login() {
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.registrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		}
	},
	components: {
		Multiselect,
		Treeselect,
		VueBootstrapTypeahead
	}
}
</script>

<style lang="scss" scoped>
section.register {
	width: 100%;
	margin-bottom: 0;
	box-shadow: none;
	
	background: transparent linear-gradient(to bottom, #229eB6 0%, #127497 100%) 0% 0% no-repeat;
	
	h3 {
		color: #fff;
		font-size: 40px;
		font-weight: 500;
	}
	
	.btn-bottom-arrow {
		display: none;
	}
	
	p {
		color: #fff;
	}
	
	.card {
		max-width: unset;
	}
	
	.btn-register {
		color: #fff;
		background-color: transparent;
		border: 1px solid #fff;
	}
	
	.selected {
		.btn-register {
			color: #0a5384;
			background-color: #fff;
			border: 1px solid #fff;
		}
		
		.btn-bottom-arrow {
			display: block;
			
			&:after {
				content: '';
				position: absolute;
				bottom: -11px;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 0;
				height: 0;
				border-right: 15px solid transparent;
				border-top: 12px solid #fff;
				border-left: 15px solid transparent;
			}
		}
	}
	
	h5.card-title {
		color: #0a5384;
		font-size: 1.4rem;
		font-weight: 600;
	}
	
	label {
		color: #0a5384;
		font-weight: bold;
	}
}
</style>