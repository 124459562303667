<template>
	<div class="card text-center border-0">
		<div class="card-header card-right text-center">
			<img v-lazy="require('@assets/img/Doctors-Unselected-New.jpg')" style="visibility: hidden;" />
			<h3 class="card-title">Register A Clinical Account</h3>
		</div>
		<div class="card-body">
			<div :id="containerId">
				<p class="card-text">A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard.</p>
				<form v-promise-btn="{ action: 'submit' }" @submit.prevent="register" accept-charset="utf-8" novalidate id="clinical-registration-form" role="form">
					<div class="form-row">
						<div class="col">
							<div class="form-group text required">
								<input type="text" v-model.trim="$v.form.full_name.$model" name="full_name" placeholder="Full Name" id="clinical-full-name" required maxlength="50" :class="{ 'form-control': true, 'is-invalid': $v.form.full_name.$dirty && $v.form.full_name.$error }" />
								<div class="invalid-feedback" v-if="$v.form.full_name.$dirty && !$v.form.full_name.required">Full name is required.</div>
								<div class="invalid-feedback" v-if="$v.form.full_name.$dirty && !$v.form.full_name.minLength">Full name has a minimum of 3 characters.</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group email required">
								<input type="email" v-model.trim="$v.form.email.$model" name="email" placeholder="Email" id="clinical-email" required maxlength="254" :class="{ 'form-control': true, 'is-invalid': $v.form.email.$dirty && $v.form.email.$error }" />
								<div class="invalid-feedback" v-if="$v.form.email.$dirty && !$v.form.email.required">Email address is required.</div>
								<div class="invalid-feedback" v-if="$v.form.email.$dirty && !$v.form.email.email">Email address has to be valid.</div>
								<div class="invalid-feedback" v-if="$v.form.email.$dirty && !$v.form.email.isUnique">Sorry, that email address is already in use.</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group select required">
								<label for="user-attribute-practice-location-id" class="control-label">Country</label>
								<multiselect
									v-model.trim="selectedCountry"
									:options="countryOptions"
									placeholder="Select your country..."
									label="alias"
									track-by="id"
									deselect-label=""
									aria-invalid="false"
									id="user-attribute-practice-location-id"
									required
									:class="{ 'is-invalid': $v.form.user_attribute.practice.location_id.$dirty && $v.form.user_attribute.practice.location_id.$error }"
								>
									<span slot="noResult">No matching countries found.</span>
								</multiselect>
								<input type="hidden" name="user_attribute[practice][location_id]" :value="form.user_attribute.practice.location_id" />
								<div class="invalid-feedback" v-if="$v.form.user_attribute.practice.location_id.$dirty && !$v.form.user_attribute.practice.location_id.required">Country is required.</div>
							</div>
						</div>
					</div>
					<div class="text-center">
						<button type="submit" class="btn btn-large btn-success" :disabled="!isMounted">Register</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { email, minLength, required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

export default {
	name: 'CampaignClinicalRegistration',
	props: {
		containerId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			countryOptions: [],
			form: {
				email: '',
				full_name: '',
				user_attribute: {
					practice: {
						location_id: null
					}
				}
			},
			gRecaptchaToken: '',
			validationErrors: {},
			csrfToken: '',
			isMounted: false,
			unsubscribe: null
		}
	},
	computed: {
		...mapState({
			campaign: state => state.campaign
		}),
		selectedCountry: {
			get() {
				return this.countryOptions.find(countryOption => countryOption.id === this.form.user_attribute.practice.location_id)
			},
			set(v) {
				if (v) {
					this.$set(this.form.user_attribute.practice, 'location_id', v.id)
				}
			}
		}
	},
	validations() {
		const validations = {
			form: {
				email: {
					required,
					email,
					isUnique(val) {
						if (val === '') return true
						
						return new Promise((resolve, reject) => {
							resolve(!('email.unique' in this.validationErrors))
						})
					}
				},
				full_name: {
					required,
					minLength: minLength(3)
				},
				user_attribute: {
					practice: {
						location_id: {
							required
						}
					}
				}
			}
		}
		
		return validations
	},
	mounted() {
		this.isMounted = true
		
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'SET_INVISIBLE_RECAPTCHA' && state.invisibleRecaptcha.originComponent === this.$options.name) {
				this.recaptchaCallback(state.invisibleRecaptcha.token);
				
				this.unsubscribe()
			}
		})
		
		this.$http.get('funcs/countries')
			.then(response => {
				this.countryOptions = response.data.result.countries
			})
	},
	beforeDestroy() {
		typeof this.unsubscribe === 'function' && this.unsubscribe()
	},
	watch: {
		'$v.form.email.$model': function (newVal, oldVal) {
			this.validationErrors = {}
		}
	},
	methods: {
		register() {
			this.$v.$touch()
			
			if (!this.$v.$invalid) {
				this.$root.$children[0].$refs.invisibleRecaptcha.execute(this.$options.name)
			}
		},
		recaptchaCallback(token) {
			const gRecaptchaToken = token
			
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						const csrfToken = response.data.result.csrfToken
						
						return this.$http.post('account/campaign-register', {
							...this.form,
							_csrfToken: csrfToken,
							g_recaptcha_response: gRecaptchaToken,
							access_code: this.campaign.access_code
						})
							.then(() => {
								this.$modal.show('campaignLoginModal', { email: this.form.email })
							})
							.catch(error => {
								if (error.response && error.response.status === 422) {
									this.validationErrors = error.response.data.result.message
								}
							})
					})
			})
		}
	},
	components: {
		Multiselect
	}
}
</script>