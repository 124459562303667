import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'es6-promise/auto'
import Vue from 'vue'
import VueMatomo from 'vue-matomo'
import { createApp } from './app'
import ProgressBar from './components/ProgressBar'

// global progress bar
const bar = Vue.prototype.$bar = new Vue(ProgressBar).$mount()
document.body.appendChild(bar.$el)

Vue.mixin({
	data() {
		return {
			prevRoute: null
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (from.matched.length) {
				vm.prevRoute = from
			}
			
			if (to.meta.title) {
				const _paq = window._paq = window._paq || []
				if (vm.prevRoute && vm.prevRoute.fullPath) {
					_paq.push(['setReferrerUrl', window.location.origin + vm.$router.resolve(vm.prevRoute.fullPath).href])
				}
				_paq.push(['setCustomUrl', window.location.origin + vm.$router.resolve(to.fullPath).href])
				_paq.push(['trackPageView', to.meta.title + ' | Market Scope'])
			}
		})
	},
	beforeRouteUpdate(to, from, next) {
		if (from.matched.length) {
			this.prevRoute = from
		}
		
		if (to.meta.title) {
			const _paq = window._paq = window._paq || []
			if (this.prevRoute && this.prevRoute.fullPath) {
				_paq.push(['setReferrerUrl', window.location.origin + this.$router.resolve(this.prevRoute.fullPath).href])
			}
			_paq.push(['setCustomUrl', window.location.origin + this.$router.resolve(to.fullPath).href])
			_paq.push(['trackPageView', to.meta.title + ' | Market Scope'])
		}
		
		const { asyncData } = this.$options
		if (asyncData) {
			asyncData({
				store: this.$store,
				route: to
			}).then(next).catch(next)
		} else {
			next()
		}
	}
})

if (!!process.env.MATOMO_HOST) {
	Vue.use(VueMatomo, {
		host: process.env.MATOMO_HOST,
		siteId: parseInt(process.env.MATOMO_SITE_ID, 10),
		enableLinkTracking: false,
		requireConsent: false,
		trackInitialView: false,
		disableCookies: false,
		requireCookieConsent: false,
		enableHeartBeatTimer: false,
		debug: !!process.env.DEBUG,
		domains: process.env.MATOMO_DOMAINS
	})
	
	const _paq = window._paq = window._paq || []
	_paq.push(['setDownloadClasses', 'download-link'])
	_paq.push(['enableLinkTracking'])
}

const { app, store, router } = createApp()

if (window.__INITIAL_STATE__) {
	store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
	// Add router hook for handling asyncData.
	// Doing it after initial route is resolved so that we don't double-fetch
	// the data that we already have. Using router.beforeResolve() so that all
	// async components are resolved.
	router.beforeResolve((to, from, next) => {
		const matched = router.getMatchedComponents(to)
		const prevMatched = router.getMatchedComponents(from)
		let diffed = false
		const activated = matched.filter((c, i) => {
			return diffed || (diffed = (prevMatched[i] !== c))
		})
		const asyncDataHooks = activated.map(c => c.asyncData).filter(_ => _)
		if (!asyncDataHooks.length) {
			return next()
		}
		
		bar.start()
		Promise.all(asyncDataHooks.map(hook => hook({ store, route: to })))
			.then(() => {
				bar.finish()
				next()
			})
			.catch(err => {
				bar.finish()
				next(err)
			})
	})
	
	// actually mount to DOM
	app.$mount('#app')
})