var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "methodology-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "methodology", attrs: { id: "methodology" } },
        [
          _c("div", { staticClass: "container header-row" }, [
            _c("div", [
              _c("div", { staticClass: "mx-auto text-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/icons/MarketScope-Icons-03.svg"),
                      expression:
                        "require('@assets/img/icons/MarketScope-Icons-03.svg')",
                    },
                  ],
                  staticStyle: {
                    "max-width": "70px",
                    width: "auto",
                    height: "auto",
                  },
                  attrs: { width: "70", height: "70" },
                }),
                _vm._v(" "),
                _c("h1", { staticClass: "section-heading" }, [
                  _vm._v("Our Methodology"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("A Multi-Step Approach for Accurate & Timely Data"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "steps step1" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Market Scope leverages an extensive database of proprietary and public data that we integrate into our models. This provides industry-leading insight and market intelligence."
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6 mb-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/icons/Methodology-Icons-01.svg"),
                              expression:
                                "require('@assets/img/icons/Methodology-Icons-01.svg')",
                            },
                          ],
                          staticClass: "align-self-center mr-3",
                          staticStyle: {
                            "max-width": "70px",
                            width: "auto",
                            height: "auto",
                          },
                          attrs: { width: "70", height: "70" },
                        }),
                        _vm._v(" "),
                        _vm._m(2),
                      ]),
                      _vm._v(" "),
                      _vm._m(3),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 mb-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/icons/Methodology-Icons-02.svg"),
                              expression:
                                "require('@assets/img/icons/Methodology-Icons-02.svg')",
                            },
                          ],
                          staticClass: "align-self-center mr-3",
                          staticStyle: {
                            "max-width": "70px",
                            width: "auto",
                            height: "auto",
                          },
                          attrs: { width: "70", height: "70" },
                        }),
                        _vm._v(" "),
                        _vm._m(4),
                      ]),
                      _vm._v(" "),
                      _vm._m(5),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "steps step2" }, [
              _vm._m(6),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Market Scope’s industry reports are meticulously prepared by a dedicated team of in-house consultants/analysts with over 100 years of collective experience. Each data point is derived from a combination of sources."
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-4 mb-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/icons/Methodology-Icons-05.svg"),
                              expression:
                                "require('@assets/img/icons/Methodology-Icons-05.svg')",
                            },
                          ],
                          staticClass: "align-self-center mr-3",
                          staticStyle: {
                            "max-width": "70px",
                            width: "auto",
                            height: "auto",
                          },
                          attrs: { width: "70", height: "70" },
                        }),
                        _vm._v(" "),
                        _vm._m(7),
                      ]),
                      _vm._v(" "),
                      _vm._m(8),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-4 mb-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/icons/Methodology-Icons-06.svg"),
                              expression:
                                "require('@assets/img/icons/Methodology-Icons-06.svg')",
                            },
                          ],
                          staticClass: "align-self-center mr-3",
                          staticStyle: {
                            "max-width": "70px",
                            width: "auto",
                            height: "auto",
                          },
                          attrs: { width: "70", height: "70" },
                        }),
                        _vm._v(" "),
                        _vm._m(9),
                      ]),
                      _vm._v(" "),
                      _vm._m(10),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-4 mb-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/icons/Methodology-Icons-07.svg"),
                              expression:
                                "require('@assets/img/icons/Methodology-Icons-07.svg')",
                            },
                          ],
                          staticClass: "align-self-center mr-3",
                          staticStyle: {
                            "max-width": "70px",
                            width: "auto",
                            height: "auto",
                          },
                          attrs: { width: "70", height: "70" },
                        }),
                        _vm._v(" "),
                        _vm._m(11),
                      ]),
                      _vm._v(" "),
                      _vm._m(12),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "steps step3" }, [
              _vm._m(13),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "We continuously maintain an influx of data, feedback, and requests. We use this feedback to further improve upon our deliverables."
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6 mb-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/icons/Methodology-Icons-03.svg"),
                              expression:
                                "require('@assets/img/icons/Methodology-Icons-03.svg')",
                            },
                          ],
                          staticClass: "align-self-center mr-3",
                          staticStyle: {
                            "max-width": "70px",
                            width: "auto",
                            height: "auto",
                          },
                          attrs: { width: "70", height: "70" },
                        }),
                        _vm._v(" "),
                        _vm._m(14),
                      ]),
                      _vm._v(" "),
                      _vm._m(15),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 mb-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: require("@assets/img/icons/Methodology-Icons-04.svg"),
                              expression:
                                "require('@assets/img/icons/Methodology-Icons-04.svg')",
                            },
                          ],
                          staticClass: "align-self-center mr-3",
                          staticStyle: {
                            "max-width": "70px",
                            width: "auto",
                            height: "auto",
                          },
                          attrs: { width: "70", height: "70" },
                        }),
                        _vm._v(" "),
                        _vm._m(16),
                      ]),
                      _vm._v(" "),
                      _vm._m(17),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("register"),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Methodology")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-auto text-center" }, [
      _c("h3", [_vm._v("Step 1: Collect Data")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "align-self-center m-0" }, [
      _c("strong", [_vm._v("Proprietary data")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" 20+ years of physician survey data"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Disease models"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Cultivated database of physicians, ASCs, and hospitals"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Insight from our team of in-house industry experts"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "align-self-center m-0" }, [
      _c("strong", [_vm._v("Public data")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Attendance and participation at major, worldwide meetings"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Public health systems and registries"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Clinical study data"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Analysis of company-published financial reports"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-auto text-center" }, [
      _c("h3", [_vm._v("Step 2: Model and Analyze Market")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "align-self-center m-0" }, [
      _c("strong", [_vm._v("Extensive analysis")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Integrated disease models"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Expert-based segmentation"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "align-self-center m-0" }, [
      _c("strong", [_vm._v("Integrate")]),
      _vm._v(" and "),
      _c("strong", [_vm._v("reconcile")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Continuous feedback loop"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Integration of survey data"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Triangulation of results"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Cross-model analysis"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Reconciliation to company revenues"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "align-self-center m-0" }, [
      _c("strong", [_vm._v("Produce products")]),
      _vm._v(" covering key medical markets"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Medical devices"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Drugs/biologics"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Equipment"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Providers"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-auto text-center" }, [
      _c("h3", [_vm._v("Step 3: Gather and Incorporate Feedback")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "align-self-center m-0" }, [
      _c("strong", [_vm._v("Gather feedback")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" New market insights"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Client requests"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Landmark study evaluations"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "align-self-center m-0" }, [
      _c("strong", [_vm._v("Incorporate")]),
      _vm._v(" and "),
      _c("strong", [_vm._v("revise")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Forecasting models"),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm._v(" Analytical tools and methods"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }