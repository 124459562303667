<template>
	<div>
		<keep-alive>
			<component :is="currentParentComponent" v-if="currentParentComponent" />
		</keep-alive>
		<modal v-if="article" name="articleModal" class="article-modal" height="auto" :scrollable="true" :reset="true" :adaptive="true" transition="nice-modal-fade" @closed="closed">
			<div class="modal-header">
				<div class="container">
					<div class="row">
						<h1 class="modal-title" :id="'article-modal-title-' + article.id">{{ article.title.trim() }}</h1>
					</div>
					<div v-if="article.author || article.author_custom !== ''" class="row">
						<h5 class="modal-title"><small  class="text-muted">Author: {{ article.author ? (article.author.user_attribute.title !== '' ? article.author.full_name + ', ' + article.author.user_attribute.title : article.author.full_name) : article.author_custom }}</small></h5>
					</div>
				</div>
				<button type="button" class="close" @click="$modal.hide('articleModal')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div v-if="article.content" class="modal-body">
				<div v-html="article.content"></div>
				<div class="text-center pb-2">
					<a href="#" @click.prevent="jumpToRegister" class="btn btn-success">Register Now</a>
					<a href="#" @click.prevent="$modal.show('lockedArticleLoginModal', { articleId: article.id })" class="btn btn-login">Sign In</a>
				</div>
				<div v-if="article.recommended_products.length" class="card bg-light mt-3">
					<div class="card-body">
						<h6>Recommended Products</h6>
						<div class="container">
							<div class="row d-flex align-items-start">
								<div class="recommended-product col-md-3 p-1 bg-white border border-light" v-for="product in article.recommended_products">
									<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug } }">
										<h6 class="text-center m-0 p-1">{{ product.label }}</h6>
										<img v-if="product.document_original_filename || product.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="modal-body">
				<p v-if="article.preview_img_url" style="text-align: center;">
					<img class="img-thumbnail d-block mx-auto" :src="article.preview_img_url" :alt="(article.title).trim()" />
				</p>
				<p v-html="article.content_teaser"></p>
				<div class="text-center pb-4">
					<a href="#" @click.prevent="jumpToRegister" class="btn btn-success">Register Now</a>
					<a href="#" @click.prevent="$modal.show('lockedArticleLoginModal', { articleId: article.id })" class="btn btn-login">Sign In</a>
				</div>
				<div v-if="article.associated_products.length || (article.associated_subscriptions.length && article.associated_subscriptions.some(subscription => subscription.products[0]))" class="card bg-light">
					<div class="card-body">
						<h6>Purchasing one of the following products will open up access to this article's content, which is also available in each comprehensive report/subscription.</h6>
						<div class="container">
							<div class="row d-flex align-items-start">
								<div class="associated-product col-md-3 p-1 bg-white border border-light" v-for="product in article.associated_products">
									<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug } }">
										<h6 class="text-center m-0 p-1">{{ product.attributes }}</h6>
										<img v-if="product.document_original_filename || product.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="product.cover_img_alt ? product.cover_img_alt : product.attributes" />
									</router-link>
								</div>
								<div class="associated-product col-md-3 p-1 bg-white border border-light" v-for="subscription in article.associated_subscriptions" v-if="subscription.products[0]">
									<router-link :to="{ name: 'Report', params: { id: subscription.products[0].id, slug: subscription.products[0].slug } }">
										<h6 class="text-center m-0 p-1">{{ subscription.name }}</h6>
										<img v-if="subscription.products[0].document_original_filename || subscription.products[0].document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + subscription.products[0].id + '/' + (subscription.products[0].document_sample_filename ? subscription.products[0].document_sample_filename.substr(0, subscription.products[0].document_sample_filename.lastIndexOf('.')) : subscription.products[0].document_original_filename.substr(0, subscription.products[0].document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="subscription.name + ' - ' + subscription.products[0].attributes" />
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="article.recommended_products.length" class="card bg-light mt-3">
					<div class="card-body">
						<h6>Recommended Products</h6>
						<div class="container">
							<div class="row d-flex align-items-start">
								<div class="recommended-product col-md-3 p-1 bg-white border border-light" v-for="product in article.recommended_products">
									<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug } }">
										<h6 class="text-center m-0 p-1">{{ product.label }}</h6>
										<img v-if="product.document_original_filename || product.document_sample_filename" class="img-thumbnail d-block mx-auto border-0" v-lazy="'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<no-ssr>
					<vue-goodshare-linked-in
						has_icon
					/>
					<vue-goodshare-facebook
						has_icon
					/>
					<vue-goodshare-twitter
						has_icon
					/>
				</no-ssr>
				<a @click.prevent="showShareArticleModal" class="button-social email__design__flat mr-auto">
					<i class="icon-email"></i>
				</a>
				<small class="text-muted">{{ new Date(article.publish_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }}</small>
			</div>
		</modal>
		<share-article />
		<locked-article-login />
	</div>
</template>

<script>
import truncate from 'lodash/truncate'
import NoSSR from 'vue-no-ssr'
import VueGoodshareLinkedIn from 'vue-goodshare/src/providers/LinkedIn.vue'
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'

import ShareArticle from './ShareArticle'
import LockedArticleLogin from './LockedArticleLogin'

export default {
	name: 'Article',
	metaInfo() {
		if (this.$route.name === 'Article' && this.article) {
			let meta = [
				{
					property: 'og:title',
					content: this.article.title.trim(),
					template: content => `${content} | Market Scope`,
					vmid: 'og:title'
				},
				{
					property: 'og:description',
					content: this.article.content_teaser,
					vmid: 'og:description'
				},
				{
					property: 'og:url',
					content: '//www.market-scope.com/pages/news/' + this.article.id + '/' + this.article.slug,
					vmid: 'og:url'
				},
				{
					property: 'og:type',
					content: 'article',
					vmid: 'og:type'
				},
				{
					property: 'article:published_time',
					content: new Date(this.article.publish_date).toISOString(),
					vmid: 'article:published_time'
				}
			]
			if (this.article.share_img_filename || this.article.preview_img_url) {
				if (this.article.share_img_filename) {
					meta.push({
						name: 'image',
						property: 'og:image',
						content: 'https://www.market-scope.com/files/articles/shares/' + this.article.id + '/' + this.article.share_img_filename,
						vmid: 'og:image'
					})
				} else {
					meta.push({
						name: 'image',
						property: 'og:image',
						content: 'https://www.market-scope.com' + this.article.preview_img_url,
						vmid: 'og:image'
					})
				}
			}
			if (this.article.author || this.article.author_custom !== '') {
				meta.push({
					name: 'author',
					content: this.article.author ? (this.article.author.user_attribute.title !== '' ? this.article.author.full_name + ', ' + this.article.author.user_attribute.title : this.article.author.full_name) : this.article.author_custom,
					vmid: 'author'
				})
			}
			if (this.article.meta_description) {
				meta.push({
					name: 'description',
					content: this.article.meta_description,
					vmid: 'description'
				})
			} else {
				meta.push({
					name: 'description',
					content: truncate(this.article.content_teaser, {
						length: 160
					}),
					vmid: 'description'
				})
			}
			
			return {
				title: this.article.meta_title ? this.article.meta_title.trim() : this.article.title.trim(),
				meta: meta,
				link: [
					{
						rel: 'canonical',
						href: 'https://www.market-scope.com/pages/news/' + this.article.id + '/' + this.article.slug,
						vmid: 'canonical'
					}
				]
			}
		} else {
			return {}
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fromRoute = from
			
			const _paq = window._paq = window._paq || []
			if (vm.prevRoute && vm.prevRoute.fullPath) {
				_paq.push(['setReferrerUrl', window.location.origin + vm.$router.resolve(vm.prevRoute.fullPath).href])
			}
			_paq.push(['setCustomUrl', window.location.origin + vm.$router.resolve(vm.$route.fullPath).href])
			_paq.push(['trackPageView', vm.$metaInfo.title + ' | Market Scope', {
				dimension1: vm.article.publish_date
			}])
		})
	},
	asyncData({ store, route }) {
		return store.dispatch('fetchArticle', route.params.id)
	},
	props: ['offset'],
	data() {
		return {
			fromRoute: null
		}
	},
	computed: {
		article() {
			return this.$store.state.article
		},
		currentParentComponent() {
			if (this.article && this.article.enabled && !this.article.draft) {
				if (this.article.adjusted_position < 24) {
					return 'News'
				} else {
					return 'NewsArchive'
				}
			}
			
			return null
		}
	},
	mounted() {
		this.$modal.show('articleModal')
	},
	watch: {
		'$route': function (newVal, oldVal) {
			if (this.article && newVal.params.id !== oldVal.params.id) {
				this.$modal.show('articleModal')
			}
		}
	},
	methods: {
		closed(e) {
			if (!this.fromRoute.name) {
				this.$router.push({ name: this.currentParentComponent })
			} else {
				if (this.fromRoute.name === 'NewsArchive') {
					this.$router.push({ name: 'NewsArchive', params: { offset: this.offset } })
				} else {
					this.$router.back()
				}
			}
		},
		jumpToRegister() {
			const _paq = window._paq = window._paq || []
			if (this.article.content) {
				_paq.push(['trackEvent', 'Articles', 'Article Register Button Click', this.article.title, this.article.id])
			} else {
				_paq.push(['trackEvent', 'Articles', 'Locked Article Register Button Click', this.article.title, this.article.id])
			}
			
			this.$modal.hide('articleModal')
			
			setTimeout(() => {
				this.$scrollTo('#register')
			}, 200)
		},
		showShareArticleModal() {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Articles', 'Opened Modal', '"Share Article Sample" Modal', this.article.id])
			
			this.$modal.show('shareArticleModal', { articleId: this.article.id })
		}
	},
	components: {
		'no-ssr': NoSSR,
		VueGoodshareLinkedIn,
		VueGoodshareFacebook,
		VueGoodshareTwitter,
		News: () => import('@components/News'),
		NewsArchive: () => import('@components/NewsArchive'),
		ShareArticle,
		LockedArticleLogin
	}
}
</script>

<style lang="scss">
.article-modal {
	.modal-header h1.modal-title {
		font-size: 20px;
	}
	
	.modal-body {
		p {
			color: $gray-light;
			font-size: smaller;
		}
		
		a {
			color: #2fbbcc;
		}
		
		a.btn {
			color: #fff;
			
			&:active,
			&:hover {
				color: #fff;
			}
		}
		
		a.btn-login {
			background-color: rgba(10, 83, 132, 0.7);
			border-color: rgba(10, 83, 132, 0.7);
			
			&:active,
			&:hover {
				background-color: rgba(10, 83, 132, 0.9);
				border-color: rgba(10, 83, 132, 0.9);
			}
		}
		
		.associated-product,
		.recommended-product {
			a:hover,
			a:focus {
				color: #2fbbcc;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.button-social * {
	box-sizing: border-box;
}

// Button Social link style
.button-social {
	display: inline-flex;
	cursor: pointer;
	padding: 7px 10px;
	margin: 3px 1.5px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

// Button Social link style on hover
.button-social:hover {
	opacity: 0.9;
}

a.button-social {
	color: #fff !important;
	
	[class^="icon-"]:before,
	[class*=" icon-"]:before {
		font-family: Fontello;
		font-style: normal;
		font-weight: normal;
		speak: none;
		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		margin-right: 0.2em;
		text-align: center;
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		margin-left: 0.2em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	
	.icon-email:before {
		content: "\e80f";
	}
}

a.email__design__flat {
	background-color: rgb(65, 65, 65);
	color: rgb(254, 254, 254);
}
</style>