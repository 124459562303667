var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", {
        attrs: { subpage: true, requestedProductId: _vm.requestedProductId },
      }),
      _vm._v(" "),
      _c("header", { staticClass: "reports-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.pageTitle))]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "reports", attrs: { id: "reports" } }, [
        _c("div", { staticClass: "container header-row" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "mx-auto" },
              [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/icons/MarketScope-Icons-05.svg"),
                      expression:
                        "require('@assets/img/icons/MarketScope-Icons-05.svg')",
                    },
                  ],
                  staticClass: "d-block mx-auto",
                  staticStyle: {
                    "max-width": "70px",
                    width: "auto",
                    height: "auto",
                  },
                  attrs: { width: "70", height: "70" },
                }),
                _vm._v(" "),
                _c("h1", { staticClass: "section-heading text-center" }, [
                  _vm._v(_vm._s(_vm.pageTitle)),
                ]),
                _vm._v(" "),
                _vm.tagPageDescription
                  ? [
                      _c("div", {
                        staticClass: "overflow-hidden",
                        domProps: { innerHTML: _vm._s(_vm.tagPageDescription) },
                      }),
                    ]
                  : _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        "Market Scope uses a multi-source methodology when preparing our annual and quarterly reports. We incorporate surgeon surveys, our extensive database of physicians and surgery centers, disease models, clinical studies, financial reports, and more. Our wide-ranging knowledge of products and technology and our comprehensive network of industry contacts also contribute to our coverage on the ophthalmic industry."
                      ),
                    ]),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "transition-group",
                {
                  staticClass: "mx-auto text-center tags",
                  attrs: { name: "list-complete", tag: "div" },
                },
                [
                  _vm.activeTag &&
                  _vm.tags.some(
                    (tag) =>
                      tag.slug === _vm.activeTag &&
                      (tag.parent_tags.length || tag.child_tags.length)
                  )
                    ? _c(
                        "button",
                        {
                          key: 0,
                          staticClass:
                            "btn btn-sm btn-level-up list-complete-item m-1",
                          attrs: { type: "button" },
                          on: { click: _vm.filterByParentTag },
                        },
                        [
                          _vm._v(" "),
                          _c("i", { staticClass: "fas fa-level-up-alt" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.filteredTags, function (tag) {
                    return _c(
                      "router-link",
                      {
                        key: tag.id,
                        class: {
                          btn: true,
                          "btn-primary": true,
                          "btn-sm": true,
                          "list-complete-item": true,
                          "m-1": true,
                          active: _vm.activeTag === tag.slug,
                        },
                        attrs: {
                          to: {
                            params: {
                              tag: _vm.activeTag !== tag.slug ? tag.slug : null,
                            },
                            query: Object.assign({}, _vm.$route.query, {
                              page: 1,
                            }),
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(tag.label)),
                        _vm.activeTag === tag.slug
                          ? _c("i", { staticClass: "fas fa-times-circle ml-1" })
                          : _vm._e(),
                        _vm.loadingTag === tag.slug &&
                        _vm.activeTag !== tag.slug
                          ? _c("i", {
                              staticClass: "fas fa-spinner fa-spin ml-1",
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container", attrs: { id: "reports-container" } },
          [
            _vm.pageCount > 0
              ? _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _c("b-form-select", {
                      staticClass: "pr-4 w-auto position-absolute",
                      attrs: { options: _vm.sortOptions },
                      model: {
                        value: _vm.sortSelected,
                        callback: function ($$v) {
                          _vm.sortSelected = $$v
                        },
                        expression: "sortSelected",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-pagination-nav", {
                      attrs: {
                        "number-of-pages": _vm.pageCount,
                        align: "center",
                        linkGen: _vm.linkGen,
                        "use-router": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$scrollTo("#reports-container")
                        },
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                )
              : _c("div", [_vm._m(0)]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._l(_vm.products, function (product) {
                  return _c(
                    "div",
                    {
                      staticClass: "card bg-light w-100 mb-4",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "Report",
                            params: { id: product.id, slug: product.slug },
                            hash: "#reports",
                          })
                        },
                      },
                    },
                    [
                      _c("router-link", {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          left: "0",
                          height: "100%",
                          width: "100%",
                        },
                        attrs: {
                          to: {
                            name: "Report",
                            params: { id: product.id, slug: product.slug },
                            hash: "#reports",
                          },
                        },
                      }),
                      _vm._v(" "),
                      product.pinned
                        ? _c(
                            "div",
                            { staticClass: "ribbon-wrapper ribbon-lg" },
                            [
                              _c("div", { staticClass: "ribbon bg-warning" }, [
                                _vm._v("FEATURED"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body h-100" }, [
                        _c("div", { staticClass: "container" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-2 pb-3 report-sample" },
                              [
                                product.document_cover_filename ||
                                product.document_sample_page_images.length
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            "report-sample-img": true,
                                            unpublished: !product.published,
                                          },
                                        },
                                        [
                                          product.document_cover_filename
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "lazy",
                                                    rawName: "v-lazy",
                                                    value:
                                                      "/files/products/covers/" +
                                                      product.id +
                                                      "/" +
                                                      product.document_cover_filename,
                                                    expression:
                                                      "'/files/products/covers/' + product.id + '/' + product.document_cover_filename",
                                                  },
                                                ],
                                                staticClass: "img-fluid",
                                                attrs: {
                                                  alt: product.cover_img_alt
                                                    ? product.cover_img_alt
                                                    : product.label,
                                                },
                                              })
                                            : product
                                                .document_sample_page_images
                                                .length
                                            ? _c("img", {
                                                directives: [
                                                  {
                                                    name: "lazy",
                                                    rawName: "v-lazy",
                                                    value:
                                                      "/files/products/samples/" +
                                                      product.id +
                                                      "/" +
                                                      product
                                                        .document_sample_page_images[0],
                                                    expression:
                                                      "'/files/products/samples/' + product.id + '/' + product.document_sample_page_images[0]",
                                                  },
                                                ],
                                                staticClass: "img-fluid",
                                                attrs: {
                                                  alt: product.cover_img_alt
                                                    ? product.cover_img_alt
                                                    : product.label,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !product.published
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "unpublished-content",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      style: {
                                                        top:
                                                          "calc(50% - " +
                                                          (product.coming_soon_text
                                                            ? "48"
                                                            : "36") +
                                                          "px)",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-clock",
                                                      }),
                                                      _c("br"),
                                                      _vm._v("COMING"),
                                                      _c("br"),
                                                      _vm._v("SOON"),
                                                      product.coming_soon_text
                                                        ? [
                                                            _c("br"),
                                                            _c("small", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  product.coming_soon_text
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "mt-3" }, [
                                        _c("div", [
                                          product.document_brochure_filename
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-primary w-100 download-link",
                                                  attrs: {
                                                    target: "_blank",
                                                    href:
                                                      "/files/products/brochures/" +
                                                      product.id +
                                                      "/" +
                                                      product.document_brochure_filename,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Brochure")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "mt-1" }, [
                                          product.product_skus.length
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-primary w-100",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.showRequestTocModal(
                                                        product.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Request a Sample")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          product.product_type.plans.length
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-primary w-100",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.showRequestSampleModal(
                                                        product.product_type.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Request a Sample")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "mt-1" }, [
                                          product.published
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "scroll-to",
                                                      rawName: "v-scroll-to",
                                                      value: "#register",
                                                      expression: "'#register'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-purchase w-100",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.requestProduct(
                                                        product
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.purchaseBtnText(
                                                        product
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "scroll-to",
                                                      rawName: "v-scroll-to",
                                                      value: "#register",
                                                      expression: "'#register'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-purchase w-100",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.requestUnpublishedProduct(
                                                        product
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Notify Me")]
                                              ),
                                        ]),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-10 report-info" },
                              [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "float-right": true,
                                      "text-right": true,
                                      "mr-md-5": product.pinned,
                                    },
                                  },
                                  _vm._l(product.tags, function (tag) {
                                    return _c(
                                      "span",
                                      {
                                        staticClass: "badge badge-pill",
                                        style: { backgroundColor: tag.color },
                                      },
                                      [
                                        tag.id
                                          ? _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    params: { tag: tag.slug },
                                                    query: { page: 1 },
                                                  },
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(tag.label))]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                product.product_type.plans.length
                                  ? _c("h3", [
                                      _vm._v(_vm._s(product.product_type.name)),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("h3", { staticClass: "mb-3" }, [
                                  _c("strong", [
                                    _vm._v(_vm._s(product.attributes)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "description",
                                  domProps: {
                                    innerHTML: _vm._s(product.description),
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "card bg-light w-100 mb-4 recommended",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ name: "PrimaryResearch" })
                      },
                    },
                  },
                  [
                    _c("router-link", {
                      staticStyle: {
                        position: "absolute",
                        top: "0",
                        left: "0",
                        height: "100%",
                        width: "100%",
                      },
                      attrs: { to: { name: "PrimaryResearch" } },
                    }),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body h-100" }, [
                      _c("div", { staticClass: "container" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-2 pb-3 report-sample" },
                            [
                              [
                                _c(
                                  "div",
                                  { staticClass: "report-sample-img" },
                                  [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "lazy",
                                          rawName: "v-lazy",
                                          value: require("@assets/img/product-primary-research-cover.jpg"),
                                          expression:
                                            "require('@assets/img/product-primary-research-cover.jpg')",
                                        },
                                      ],
                                      staticClass: "img-fluid",
                                      attrs: {
                                        alt: "Custom & Primary Research",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "mt-3" }, [
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mt-1" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-purchase w-100",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.showContactUsModal.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Contact Us")]
                                    ),
                                  ]),
                                ]),
                              ],
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm._m(3),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _vm.pageCount > 0
              ? _c(
                  "div",
                  [
                    _c("b-pagination-nav", {
                      attrs: {
                        "number-of-pages": _vm.pageCount,
                        align: "center",
                        linkGen: _vm.linkGen,
                        "use-router": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$scrollTo("#reports-container")
                        },
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("register", {
        attrs: {
          headerText: "Want to Purchase the Full Report?",
          requestedProductId: _vm.requestedProductId,
        },
      }),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("request-sample"),
      _vm._v(" "),
      _c("request-toc"),
      _vm._v(" "),
      _c("contact-us"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-auto text-center" }, [
      _c("h4", { staticClass: "my-4 text-muted" }, [_vm._v("No results")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ribbon-wrapper ribbon-lg" }, [
      _c("div", { staticClass: "ribbon" }, [_vm._v("RECOMMENDED")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-primary w-100",
          attrs: { type: "button" },
        },
        [_vm._v("View Primary Research Options")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-10 report-info" }, [
      _c("h3", { staticClass: "mb-3" }, [
        _c("strong", [_vm._v("Not seeing what you need?")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "description" }, [
        _c("p", [
          _vm._v(
            "In addition to our standard comprehensive reports, we also leverage our expert knowledge of the ophthalmic industry to design custom research that enhances strategy and operations for our clients. Our unique experience makes us a trusted partner in defining problems and executing solutions."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Common project types include custom data analysis, surveying and/or interviewing of ophthalmologists, focus groups, territory analyses, and other primary research initiatives -- but each project is custom designed to meet each specific clients' needs."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Please contact us for an individualized project consultation."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }