<template>
	<div>
		<navbar :subpage="true" :requestedProductId="requestedProductId" />
		<header class="omp-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Ophthalmic Newsletter</span>
			</li>
		</ol>
		<section class="omp" id="omp">
			<div class="container header-row">
				<div class="mx-auto text-center">
					<img v-lazy="require('@assets/img/icons/MarketScope-Icons-08.svg')" width="70" height="70" class="d-block mx-auto" style="max-width: 70px; width: auto; height: auto;" />
					<h1 class="section-heading font-weight-bold">Ophthalmic Market Perspectives Newsletter</h1>
					<p>For the past 25 years, Market Scope has published the Ophthalmic Market Perspectives newsletter to keep clients informed with third-party perspectives on the industry in an ad-free environment. This monthly newsletter explores new technologies, product trends, and company developments; features conference previews and recaps; and presents proprietary data, analysis, and forecasting.</p>
					<div class="card-deck w-100 mx-0">
						<div class="card border-0 text-center">
							<div class="card-body">
								<h5 ref="flipYears" class="card-title mb-0 font-weight-bold"></h5>
								<p class="font-weight-bold mb-0">Monthly Issues Spanning 25 Years</p>
							</div>
						</div>
						<div class="card border-0 text-center">
							<div class="card-body">
								<h5 ref="flipCirculation" class="card-title mb-0 font-weight-bold"></h5>
								<p class="font-weight-bold mb-0">Monthly Industry and Clinical Circulation</p>
							</div>
						</div>
						<div class="card border-0 text-center">
							<div class="card-body">
								<h5 ref="flipTypes" class="card-title mb-0 font-weight-bold"></h5>
								<p class="font-weight-bold mb-0">Subscribing Companies of All Sizes &amp; Types</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-3 pt-4 monthly-content">
				<div class="container">
					<div class="row">
						<div class="col text-center">
							<h3 class="font-weight-bold text-white">Curated Monthly Content</h3>
						</div>
					</div>
					<div class="card-deck w-100 mx-0 mb-3">
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-business-conversation.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Meeting Coverage</h5>
								<p class="font-weight-bold">Comprehensive coverage from the AAO, ESCRS, ASCRS, ARVO, and many more</p>
							</div>
						</div>
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-web-analytics.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Procedure Volumes</h5>
								<p class="font-weight-bold">Quarterly analysis of cataract, retina, glaucoma, and refractive procedures</p>
							</div>
						</div>
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-competitive-analysis.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Company Revenue</h5>
								<p class="font-weight-bold">Quarterly analysis of public and private ophthalmic company revenue</p>
							</div>
						</div>
					</div>
					<div class="card-deck mx-auto">
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-search-company.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Emerging Companies</h5>
								<p class="font-weight-bold">Identification of new companies pursuing ophthalmic indications</p>
							</div>
						</div>
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-profit-target.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Clinical Trial Updates</h5>
								<p class="font-weight-bold">Regular updates on clinical trials and milestone achievements</p>
							</div>
						</div>
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-agreement.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Business Transactions</h5>
								<p class="font-weight-bold">Mergers and acquisitions, financing, and business/technology sales</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container mt-3 contributors">
				<div class="row mb-3">
					<div class="mx-auto text-center">
						<h3 class="font-weight-bold">Contributors</h3>
						<p>This newsletter is written and edited by our in-house team of ophthalmic experts, who have a collective 150 years of experience analyzing markets and providing informed perspective.</p>
					</div>
				</div>
				<swiper
					ref="contributorSwiper"
					class="swiper contributor-thumbs px-3"
					:options="swiperOption"
					@slide-change-transition-end="handleSlideChange"
					@click-slide="handleClickSlide"
				>
					<swiper-slide v-for="(slide, i) in slides" :key="i" :class="slide.className">
						<div class="overlay"></div>
						<i class="fas fa-expand"></i>
					</swiper-slide>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</swiper>
				<div class="contributor-content mt-2">
					<h5>{{ slides[activeSlideIndex].contributorName }}<br /><small>{{ slides[activeSlideIndex].contributorTitle }}</small></h5>
				</div>
			</div>
			<div class="mt-3 pt-4 features">
				<div class="container">
					<div class="row">
						<div class="col text-center">
							<h3 class="font-weight-bold text-white">Subscription Features</h3>
						</div>
					</div>
					<div class="card-deck w-100 mx-0 mb-3">
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-calendar.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Access to Historical Issues Back to 2010</h5>
							</div>
						</div>
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-search-report.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Searchable Archive of News Stories</h5>
							</div>
						</div>
						<div class="card bg-transparent border-0 text-white text-center">
							<div class="card-body">
								<img v-lazy="require('@assets/img/omp/icons/omp-business-people.png')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
								<h5 class="card-title mb-0 mt-1">Scalable License for Large Teams</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-3 pt-4 purchase">
				<div class="container">
					<div class="row">
						<div v-if="products.length > 0 && (products[0].document_cover_filename || products[0].document_sample_page_images.length)" class="col-md-4 pb-3 report-sample">
							<div class="report-sample-img">
								<img v-if="products[0].document_cover_filename" class="img-fluid" v-lazy="'/files/products/covers/' + products[0].id + '/' + products[0].document_cover_filename" :alt="products[0].cover_img_alt ? products[0].cover_img_alt : products[0].label" />
								<img v-else-if="products[0].document_sample_page_images.length" class="img-fluid" v-lazy="'/files/products/samples/' + products[0].id + '/' + products[0].document_sample_page_images[0]" :alt="products[0].cover_img_alt ? products[0].cover_img_alt : products[0].label" />
							</div>
						</div>
						<div class="col-md-8 d-flex flex-column justify-content-between">
							<div>
								<h3 class="font-weight-bold">Purchase Subscription</h3>
								<p>The Ophthalmic Market Perspectives newsletter is licensed as a 12-month subscription, with prices starting at $1,700 for a single user license. Market Scope also offers scalable licenses for large teams, with the price per user decreasing significantly as more users are added.</p>
								<p>Join 20,000 other industry and clinical professionals by subscribing today.</p>
							</div>
							<div class="text-center my-auto">
								<div>
									<button @click="requestSubscription(products[0])" v-scroll-to="'#register'" type="button" class="btn btn-warning text-white font-weight-bold mb-3">Purchase Subscription</button>
								</div>
								<div>
									<button @click="showRequestSampleModal(products[0].product_type.id)" type="button" class="btn btn-primary font-weight-bold mb-3">Request a Sample</button>
									<button @click="showRequestCustomQuoteModal('Request Team Quote')" type="button" class="btn btn-primary font-weight-bold mb-3">Request Team Quote</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<register :requestedProductId="requestedProductId" />
		<footer-section />
		<request-sample />
		<request-custom-quote />
		<modal name="bioModal" height="auto" :scrollable="true" :reset="true" :adaptive="true" transition="nice-modal-fade">
			<div class="modal-header">
				<h5 class="modal-title">{{ slides[activeSlideIndex].contributorName }}<br /><small>{{ slides[activeSlideIndex].contributorTitle }}</small></h5>
				<button type="button" class="close" @click="$modal.hide('bioModal')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p class="mb-0">{{ slides[activeSlideIndex].bio }}</p>
			</div>
		</modal>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'
import RequestSample from './modals/RequestSample'
import RequestCustomQuote from './modals/RequestCustomQuote'

export default {
	name: 'OphthalmicNewsletter',
	metaInfo: {
		title: 'Ophthalmic Market Perspectives Newsletter',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/ophthalmic-newsletter',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/omp-promo.jpg',
				vmid: 'og:image'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/ophthalmic-newsletter',
				vmid: 'canonical'
			}
		]
	},
	asyncData({ store }) {
		return store.dispatch('fetchProducts', {
			limit: 1,
			tag: 'newsletter'
		})
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 3,
				spaceBetween: 0,
				centeredSlides: true,
				loop: true,
				touchRatio: 0.2,
      			slideToClickedSlide: true,
      			navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				breakpoints: {
					1024: {
						slidesPerView: 9
					},
					768: {
						slidesPerView: 7
					},
					640: {
						slidesPerView: 5
					}
				}
			},
			activeSlideIndex: 0,
			slides: [
				{
					className: 'slide-1',
					contributorName: 'Joan McKenna',
					contributorTitle: 'Senior Editor',
					bio: 'Joan McKenna, managing editor, covers news from the leading ophthalmic companies; tracks developments in the federal government that affect the US health care market; and reports on legal issues and changes in reimbursement.'
				},
				{
					className: 'slide-2',
					contributorName: 'Dave Harmon',
					contributorTitle: 'Founder & Senior Editor',
					bio: 'David Harmon, Market Scope founder and senior editor, has been surveying, analyzing, consulting, and writing reports on the medical device industry since 1996.'
				},
				{
					className: 'slide-3',
					contributorName: 'Bill Freeman',
					contributorTitle: 'Executive Vice President',
					bio: 'Bill Freeman, senior writer, analyzes the global markets for IOLs, retinal surgical devices, glaucoma surgical devices, cataract surgical equipment, single-use ophthalmic surgical devices, surgical instruments, and premium cataract surgery.'
				},
				{
					className: 'slide-4',
					contributorName: 'Jennie Crabbe',
					contributorTitle: 'Senior Editor',
					bio: 'Jennie Crabbe, managing editor, tracks emerging companies pursuing ophthalmic indications; reports on industry fundraising and personnel moves; and covers clinical trial milestones and new drug and device approvals.'
				},
				{
					className: 'slide-5',
					contributorName: 'Kristen Ingenito',
					contributorTitle: 'Vice President, Director of Ophthalmics',
					bio: 'Kristen Harmon Ingenito, director of ophthalmics, analyzes the global ophthalmic market landscape and covers major industry meetings, including the AAO, ASCRS, Eyecelerator, and Glaucoma 360 conferences.'
				},
				{
					className: 'slide-6',
					contributorName: 'Peter Downs',
					contributorTitle: 'Writer & Analyst',
					bio: 'Peter Downs analyzes the ophthalmic pharmaceuticals and dry eye markets and covers developments in China, India, and Europe. He also reports from major industry meetings, including the ARVO, ESCRS, ASRS, and APAO conferences.'
				},
				{
					className: 'slide-7',
					contributorName: 'Chelsea Jones',
					contributorTitle: 'Writer & Analyst',
					bio: 'Chelsea Jones analyzes the corneal, refractive, and ophthalmic laser markets and tracks ophthalmic deals, procedures, and revenue. She also reports from major industry meetings, including the World Cornea Congress.'
				},
				{
					className: 'slide-8',
					contributorName: 'Tony Ingenito',
					contributorTitle: 'VP of Sales & Marketing',
					bio: 'Tony Ingenito, director of operations, analyzes ophthalmic clinical trends and creates timely surveys and polls to gauge industry reaction to product launches and external challenges, such as pandemics, supply chain bottlenecks, and inflationary pressure.'
				}
			],
			requestedProductId: null
		}
	},
	computed: {
		flipYears() {
			return this.$refs.flipYears
		},
		flipCirculation() {
			return this.$refs.flipCirculation
		},
		flipTypes() {
			return this.$refs.flipTypes
		},
		swiper() {
			return this.$refs.contributorSwiper.$swiper
		},
		products() {
			return this.$store.state.products
		}
	},
	mounted() {
		const { Flip } = require('number-flip')
		
		new Flip({
			node: $(this.flipYears)[0],
			to: 321,
			duration: 2,
			delay: 0.5,
			direct: false
		})
		new Flip({
			node: $(this.flipCirculation)[0],
			to: 20016,
			separator: ',',
			duration: 2,
			delay: 1,
			direct: false
		})
		new Flip({
			node: $(this.flipTypes)[0],
			to: 305,
			duration: 2,
			delay: 1.5,
			direct: false
		})
	},
	methods: {
		requestSubscription(product) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Ophthalmic Market Perspectives Newsletter', 'Subscription Purchase Button Click', product.label, product.id])
			
			this.requestedProductId = product.id
		},
		handleSlideChange() {
			this.activeSlideIndex = this.swiper.realIndex
		},
		handleClickSlide(index, realIndex) {
			if (this.activeSlideIndex === realIndex) {
				this.$modal.show('bioModal')
			}
		},
		showRequestSampleModal(productTypeId) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Ophthalmic Market Perspectives Newsletter', 'Opened Modal', '"Request a Sample" Modal'])
			
			this.$modal.show('requestSampleModal', { productTypeId: productTypeId })
		},
		showRequestCustomQuoteModal(title) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Ophthalmic Market Perspectives Newsletter', 'Opened Modal', '"Request Team Quote" Modal'])
			
			this.$modal.show('requestCustomQuoteModal', { title: title })
		}
	},
	components: {
		Swiper,
		SwiperSlide,
		Navbar,
		Register,
		FooterSection,
		RequestSample,
		RequestCustomQuote
	}
}
</script>

<style lang="scss">
.swiper {
	&.contributor-thumbs {
		.swiper-button-prev:after,
		.swiper-button-next:after {
			font-size: 1.2rem;
			color: #555;
		}
	}
	&.contributor-thumbs .swiper-slide {
		height: 120px;
		transform: scale(0.7);
		transition: 0.3s ease-in-out;
		opacity: 0.85;
		background-color: rgba(10, 83, 132, 0.5);
		background-blend-mode: multiply;
		
		> i {
			opacity: 0;
		}
	}
	&.contributor-thumbs .swiper-slide-active {
		transform: scale(1);
		opacity: 1;
		z-index: 1;
		background-color: transparent;
		background-blend-mode: normal;
		
		.overlay {
			background: rgba(255, 255, 255, 0);
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition: background 0.5s ease;
		}
		
		&:hover .overlay {
			display: block;
			background: rgba(255, 255, 255, 0.3);
			cursor: zoom-in;
		}
		
		> i {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: 3rem;
			opacity: 0;
			transition: opacity .35s ease;
		}
		
		&:hover > i {
			opacity: 0.4;
			cursor: zoom-in;
		}
	}
	
	.swiper-slide {
		background-size: cover;
		background-position: center;
		
		&.slide-1 {
			background-image: url('~@assets/img/omp/omp-contributor-joan-mckenna.jpg');
		}
		&.slide-2 {
			background-image: url('~@assets/img/omp/omp-contributor-dave-harmon.jpg');
		}
		&.slide-3 {
			background-image: url('~@assets/img/omp/omp-contributor-bill-freeman.jpg');
		}
		&.slide-4 {
			background-image: url('~@assets/img/omp/omp-contributor-jennie-crabbe.jpg');
		}
		&.slide-5 {
			background-image: url('~@assets/img/omp/omp-contributor-kristen-ingenito.jpg');
		}
		&.slide-6 {
			background-image: url('~@assets/img/omp/omp-contributor-peter-downs.jpg');
		}
		&.slide-7 {
			background-image: url('~@assets/img/omp/omp-contributor-chelsea-jones.jpg');
		}
		&.slide-8 {
			background-image: url('~@assets/img/omp/omp-contributor-tony-ingenito.jpg');
		}
	}
}
</style>

<style lang="scss" scoped>
header.omp-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/omp/omp-header.jpg'), $theme-secondary;
	background: url('~@assets/img/omp/omp-header.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: center;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position-y: 50%;
	}
}

section.omp {
	padding-bottom: 30px;
	
	.section-heading {
		color: #0a5384;
		font-size: 2.8rem;
	}
	
	.header-row {
		.card {
			h5,
			p {
				color: #0a5384;
			}
			
			h5 {
				font-size: 3.2rem;
			}
			
			p {
				font-size: 1.2rem;
			}
		}
		
		p {
			color: #555;
		}
	}
	
	.card {
		p {
			font-size: 1rem;
		}
		
		.card-header h3.card-title {
			color: #0a5384;
		}
	}
	
	.monthly-content {
		background: url('~@assets/img/omp/omp-monthly-content-bg.jpg'), $theme-secondary;
		background: url('~@assets/img/omp/omp-monthly-content-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
		@include background-cover;
		background-position-x: center;
		
		h3 {
			font-size: 2.2rem;
		}
		
		.card-deck {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
		}
		
		.card {
			h5.card-title {
				font-size: 1.4rem;
				font-weight: 700;
			}
		}
	}
	
	.contributors {
		h3 {
			font-size: 2.2rem;
			color: #0a5384;
		}
		
		.contributor-content {
			text-align: center;
		}
	}
	
	.features {
		background: url('~@assets/img/omp/omp-features-bg.jpg'), $theme-secondary;
		background: url('~@assets/img/omp/omp-features-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
		@include background-cover;
		background-position-x: center;
		
		h3 {
			font-size: 2.2rem;
		}
		
		.card-deck {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
		}
		
		.card {
			h5.card-title {
				font-size: 1.4rem;
				font-weight: 700;
			}
		}
	}
	
	.purchase {
		.report-sample .report-sample-img {
			box-shadow: -5px 5px 30px 0px rgba(0, 0, 0, 0.3);
		}
		
		h3 {
			font-size: 2.2rem;
			color: #0a5384;
		}
	}
	
	.card-group .card {
		min-width: 180px;
	}
}
</style>