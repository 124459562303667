var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "bio-masthead w-100" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-center p-5 bio-info",
            },
            [
              _c("div", [
                _c("h1", [_vm._v("Tony Ingenito")]),
                _vm._v(" "),
                _c(
                  "h3",
                  { staticClass: "my-4 font-weight-bold text-uppercase" },
                  [_vm._v("VP of Sales & Marketing")]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/in/tony-ingenito-7361b49/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/LinkedIn-Icon.svg"),
                          expression:
                            "require('@assets/img/about/bios/LinkedIn-Icon.svg')",
                        },
                      ],
                      staticClass: "d-block",
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-end bio-headshot",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/about/headshot-1.jpg"),
                    expression: "require('@assets/img/about/headshot-1.jpg')",
                  },
                ],
                staticClass: "img-fluid",
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c("router-link", { attrs: { to: { name: "About" }, exact: "" } }, [
              _vm._v("About"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("authored-reports", { attrs: { authorId: 610 } }),
      _vm._v(" "),
      _c("section", { staticClass: "bio managed-products" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "row justify-content-center px-5" }, [
            _c("div", { staticClass: "col-md-6 col-lg-4 p-2" }, [
              _c("div", { staticClass: "card border-0 rounded-0 text-white" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/about/bios/custom-and-primary-research.jpg"),
                      expression:
                        "require('@assets/img/about/bios/custom-and-primary-research.jpg')",
                    },
                  ],
                  staticClass: "card-img py-4 px-5",
                  attrs: { alt: "Custom & Primary Research Solutions" },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-img-overlay text-center" },
                  [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-success py-2 px-3 font-weight-bold",
                        attrs: { to: { name: "PrimaryResearch" } },
                      },
                      [_vm._v("LEARN MORE")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "bio extra" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(6),
          _vm._v(" "),
          _c("div", { staticClass: "row px-5" }, [
            _c("div", { staticClass: "col-md-6 offset-md-3 text-center" }, [
              _c("div", { staticClass: "position-relative" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/about/bios/tony-ingenito/Podcast-Tony-Ingenito-1.png"),
                      expression:
                        "require('@assets/img/about/bios/tony-ingenito/Podcast-Tony-Ingenito-1.png')",
                    },
                  ],
                  staticClass: "img-fluid",
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "position-absolute",
                    staticStyle: {
                      left: "calc(50% - 31.5px)",
                      bottom: "-31.5px",
                    },
                    attrs: {
                      href: "https://podcasts.apple.com/us/podcast/s2-e1-understanding-trends-to-help-your-practice-succeed/id1518043630?i=1000488023295",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/play-icon.png"),
                          expression:
                            "require('@assets/img/about/bios/play-icon.png')",
                        },
                      ],
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "text-center mx-5 mt-5" }, [
                _vm._v(
                  "CareCredit Podcasts: S2: E1 - Understanding Trends to Help Your Practice Succeed on Apple Podcasts"
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("combined-registration", { attrs: { origin: "bio" } }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Phone: "),
      _c("a", { attrs: { href: "tel:+13144007652" } }, [
        _vm._v("314-400-7652"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Email: "),
      _c("a", { attrs: { href: "mailto:tony@market-scope.com" } }, [
        _vm._v("tony@market-scope.com"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Tony Ingenito")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bio content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row px-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mx-md-5" }, [
              _c("h4", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "Tony Ingenito oversees all sales, marketing, and primary research with corporate clients, while also engaging with surgeons on surveys, interviews, and speaking engagements."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Tony began his career in ophthalmology in 2002 as a technician for a multi-specialty practice in Baltimore. He went on to help build and develop a sales team with Eyemaginations, and then assisted in managing the Department of Ophthalmology at UT Southwestern Medical Center. Tony has a BA from Johns Hopkins University and enjoys being challenged by his wife and three children, trail running, “competitive” soccer, and power tools."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "mt-4 mb-5" }, [
          _vm._v("Managed Products & Services"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "card-title" }, [
      _vm._v("Custom & Primary"),
      _c("br"),
      _vm._v("Research Solutions"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "mt-4 mb-5" }, [_vm._v("Presentation Videos")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }