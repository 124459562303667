<template>
	<div>
		<navbar :subpage="true" />
		<header class="atlases-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>CareCredit Atlases</span>
			</li>
		</ol>
		<section class="atlases" id="atlases">
			<div class="container header-row">
				<div>
					<div class="mx-auto text-center mb-5">
						<img v-lazy="require('@assets/img/icons/Research-Icons-07.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h1 class="section-heading">Select your metro. Discover insights.</h1>
						<h3 class="section-heading"><span class="d-inline-block align-middle mr-1">Sponsored by</span><img v-lazy="require('@assets/img/carecredit-logo.png')" style="max-height: 20px;" /></h3>
					</div>
				</div>
			</div>
			<div class="container mt-3">
				<div class="row mb-3">
					<div class="col-md-6">
						<div id="map"></div>
					</div>
					<div class="col-md-6">
						<p>Market Scope’s United States ophthalmic atlases identify treatment opportunity at the metro level for the top 85 metros—identifying high-opportunity and diseased populations, procedure volumes, premium penetration rates, surgeon counts, surgical locations, and much more. Practices often use this data to identify opportunities for practice expansion, for consideration of new premium procedures, and to gain an overall understanding of their markets.</p>
						<p>This data is offered at a discount to CareCredit enrolled providers through the Engage portal.</p>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Provided by metro</h3>
							</div>
							<div class="card-body pt-2">
								<ul>
									<li><strong>Identification of high-opportunity population</strong></li>
									<li><strong>Procedure volumes</strong> by type</li>
									<li><strong>Surgeon profiles</strong> by volume category</li>
									<li><strong>ASC profiles</strong>: Counts and average procedure volumes</li>
									<li><strong>Demographic profiles</strong>: Population by age group, median age, household income, and diseased population</li>
									<li><strong>Much more</strong> varying by atlas subspecialty</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Use cases</h3>
							</div>
							<div class="card-body pt-2">
								<ul>
									<li>Practice expansion opportunities</li>
									<li>Determining market potential</li>
									<li>Understanding of your metro</li>
									<li>Consideration of new premium procedures</li>
									<li>Identifying competition</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-body">
								<LazyYoutube src="https://youtu.be/qudiuT29veE" :showTitle="false" customThumbnail="https://img.youtube.com/vi/qudiuT29veE/sddefault.jpg" />
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card border-0">
							<div class="card-header bg-transparent border-bottom-0">
								<h3 class="card-title font-weight-bold mb-0">Methodology</h3>
							</div>
							<div class="card-body pt-2">
								<p class="mb-0">Market Scope uses the proprietary MedOp Index™ Analysis to examine market potential and provides a benchmark against other metros. Each clinical area atlas considers multiple factors to identify opportunity. For example, the cataract MedOp Index™ considers the concentration of active, affluent seniors; the number of high-volume cataract surgeons; and ASC counts and volumes, while the retina MedOp Index™ considers annual IVT injections and vitrectomies per provider; the geographic concentration of diabetics; the total population with retinal diseases; and high-volume retinal care providers.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col">
						<p class="description">Through the Engage Portal, CareCredit enrolled providers can purchase any of the 85 metros identified on the above map from any clinical speciality for only $750—half of the standard price. To get started, please complete the form below, and Market Scope will be in touch with an official estimate.</p>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col">
						<form novalidate="novalidate" role="form" v-promise-btn="{ action: 'submit' }" @submit.prevent="request">
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="name">Name</label>
									<input v-model="form.name" type="text" class="form-control" id="name" placeholder="Name">
								</div>
								<div class="form-group select col-md-6">
									<label for="areas_of_interest">Clinical Areas of Interest</label>
									<multiselect v-model="form.areas_of_interest" :options="areasOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" />
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="practice">Practice Name</label>
									<input v-model="form.practice" type="text" class="form-control" id="practice" placeholder="Practice Name">
								</div>
								<div class="form-group select col-md-6">
									<label for="metros_of_interest">Metros of Interest</label>
									<multiselect v-model="form.metros_of_interest" :options="metrosOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" />
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="email">Email</label>
									<input v-model="form.email" type="email" class="form-control" id="email" placeholder="Email">
								</div>
								<div class="form-group col-md-6">
									<label for="phone">Phone # <span class="text-muted">(optional)</span></label>
									<input v-model="form.phone" type="tel" class="form-control" id="phone" placeholder="Phone #">
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col">
									<label for="comments">Comments (optional)</label>
									<input v-model="form.comments" type="text" class="form-control" id="comments" placeholder="Comments">
								</div>
							</div>
							<div class="form-row">
								<div class="col">
									<div v-if="form.status" v-html="form.status" class="text-right mb-3"></div>
									<button v-if="!requested" type="submit" class="btn btn-primary float-right">Submit</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<p class="legal-notice">This content is subject to change without notice and offered for informational use only. You are urged to consult with your individual business, financial, legal, tax and/or other advisors with respect to any information presented. Synchrony Financial and any of its affiliates, including CareCredit, (collectively, “Synchrony”) makes no representations or warranties regarding this content and accept no liability for any loss or harm arising from the use of the information provided.</p>
					</div>
				</div>
			</div>
		</section>
		<footer-section />
	</div>
</template>

<script>
import { LazyYoutube } from 'vue-lazytube'
import Multiselect from 'vue-multiselect'

import { required, email } from 'vuelidate/lib/validators'

import Navbar from './Navbar'
import FooterSection from './FooterSection'

import { useGoogleRecaptcha } from '../util/use-google-recaptcha'

export default {
	name: 'CarecreditAtlases',
	metaInfo() {
		return {
			title: 'CareCredit Atlases',
			meta: [
				{
					property: 'og:url',
					content: '//www.market-scope.com/pages/carecredit-atlases',
					vmid: 'og:url'
				},
				{
					name: 'image',
					property: 'og:image',
					content: 'https://www.market-scope.com/static/landing/img/Header-Atlases.jpg',
					vmid: 'og:image'
				}
			],
			link: [
				{
					rel: 'canonical',
					href: 'https://www.market-scope.com/pages/carecredit-atlases',
					vmid: 'canonical'
				}
			],
			script: [
				{
					src: '/js/mapdata.min.js',
					defer: true,
					async: true,
					callback: () => {
						this.$parent.mapdataLoaded = true
					},
					vmid: 'mapdata'
				},
				{
					src: '/js/usmap.js',
					defer: true,
					async: true,
					skip: !this.$parent.mapdataLoaded,
					callback: () => simplemaps_usmap.load(),
					vmid: 'usmap'
				}
			]
		}
	},
	data() {
		return {
			requested: false,
			areasOfInterestOptions: ['Cataract', 'Refractive', 'Dry Eye', 'Glaucoma', 'Retina'],
			metrosOfInterestOptions: ['Allentown, PA', 'Albany, NY', 'Atlanta, GA', 'Austin, TX', 'Bakersfield, CA', 'Baltimore, MD', 'Baton Rouge, LA', 'Albuquerque, NM', 'Birmingham, AL', 'Boise City, ID', 'Boston, MA', 'Boulder, CO', 'Bridgeport, CT', 'Buffalo, NY', 'Charleston, SC', 'Chicago, IL', 'Cincinnati, OH', 'Cleveland, OH', 'Columbia, SC', 'Columbus, OH', 'Dallas, TX', 'Dayton, OH', 'Denver, CO', 'Detroit, MI', 'El Paso, TX', 'Eugene, OR', 'Fresno, CA', 'Grand Rapids, MI', 'Greensboro, NC', 'Greenville, SC', 'Hartford, CT', 'Houston, TX', 'Indianapolis, IN', 'Jacksonville, FL', 'Kansas City, MO', 'Knoxville, TN', 'Las Vegas, NV', 'Los Angeles, CA', 'Louisville, KY', 'McAllen, TX', 'Memphis, TN', 'Miami, FL', 'Milwaukee, WI', 'Minneapolis, MN', 'Modesto, CA', 'Nashville, TN', 'New Haven, CT', 'New Orleans, LA', 'New York, NY', 'North Port, FL', 'Ogden, UT', 'Oklahoma City, OK', 'Omaha, NE', 'Orlando, FL', 'Oxnard, CA', 'Philadelphia, PA', 'Phoenix, AZ', 'Pittsburgh, PA', 'Portland, OR', 'Providence, RI', 'Provo, UT', 'Raleigh, NC', 'Richmond, VA', 'Rochester, NY', 'Sacramento, CA', 'Salinas, CA', 'Salt Lake City, UT', 'San Antonio, TX', 'San Diego, CA', 'San Francisco, CA', 'San Jose, CA', 'Santa Rosa, CA', 'Seattle, WA', 'St. Louis, MO', 'Stockton, CA', 'Tampa, FL', 'Tucson, AZ', 'Tulsa, OK', 'Honolulu, HI', 'Vallejo, CA', 'Virginia Beach, VA', 'Washington, DC', 'Worcester, MA'],
			form: {
				name: '',
				practice: '',
				email: '',
				phone: '',
				areas_of_interest: [],
				metros_of_interest: [],
				comments: '',
				status: ''
			}
		}
	},
	validations: {
		name: {
			required
		},
		practice: {
			required
		},
		email: {
			required,
			email
		}
	},
	methods: {
		request() {
			return useGoogleRecaptcha().getRecaptchaToken('request_carecredit_quote').then(token => {
				return this.$http.put('func/request-carecredit-quote', {
					name: this.form.name,
					practice: this.form.practice,
					email: this.form.email,
					phone: this.form.phone,
					areas_of_interest: this.form.areas_of_interest,
					metros_of_interest: this.form.metros_of_interest,
					comments: this.form.comments,
					recaptchaToken: token
				})
					.then(response => {
						this.requested = true
						
						this.$set(this.form, 'status', '<h4 class="text-success"><i class="fas fa-check-circle"></i> Submitted.</h4>')
					}, response => {
						this.$set(this.form, 'status', '<span class="text-danger">Invalid information entered.</span>')
					})
			})
		}
	},
	components: {
		LazyYoutube,
		Multiselect,
		Navbar,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.atlases-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/Header-Atlases.jpg'), $theme-secondary;
	background: url('~@assets/img/Header-Atlases.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: center;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position: 0 60%;
	}
}

section.atlases {
	.section-heading {
		color: #0a5384;
		margin-bottom: 0;
	}
	
	.header-row p {
		color: $gray-light;
	}
	
	.card {
		p,
		li {
			font-size: 1rem;
		}
		
		.card-header h3.card-title {
			color: #0a5384;
		}
	}
	
	p.legal-notice,
	p.description {
		font-size: 1rem;
	}
}
</style>