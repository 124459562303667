<template>
	<modal name="requestCustomQuoteModal" @before-open="beforeOpen" @opened="opened" @closed="closed" height="auto" :scrollable="true" :reset="true" :clickToClose="clickToClose" :adaptive="true" transition="nice-modal-fade">
		<form novalidate="novalidate" role="form" v-promise-btn="{ action: 'submit' }" @submit.prevent="request" v-on:keyup.esc="$modal.hide('requestCustomQuoteModal')">
			<div class="modal-header">
				<h4 class="modal-title">{{ title }}</h4>
				<button type="button" class="close" @click="$modal.hide('requestCustomQuoteModal')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body pb-2">
				<div class="form-group">
					<label for="to-name" class="control-label">Name:</label>
					<input v-model="form.toName" type="text" class="form-control" name="to-name" ref="toName" />
				</div>
				<div class="form-group">
					<label for="to-name" class="control-label">Company:</label>
					<input v-model="form.toCompany" type="text" class="form-control" name="to-company" />
				</div>
				<div class="form-group">
					<label for="to-email" class="control-label">Email:</label>
					<input v-model="form.toEmail" type="text" class="form-control" name="to-email" />
				</div>
				<div class="form-group">
					<label for="to-phone" class="control-label">Phone # <span class="text-muted">(optional)</span>:</label>
					<input v-model="form.toPhone" type="tel" class="form-control" name="to-phone" />
				</div>
				<div class="form-group mb-0">
					<small class="form-notice">Any submitted contact information is confidential and will not be given to any third parties.<br />This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</small>
				</div>
			</div>
			<div class="modal-footer">
				<div v-if="form.status" v-html="form.status" class="mx-auto"></div>
				<template v-if="!requested">
					<button type="button" class="btn btn-default" @click="$modal.hide('requestCustomQuoteModal')">Cancel</button>
					<button type="submit" class="btn btn-primary">Request</button>
				</template>
			</div>
		</form>
	</modal>
</template>

<script>
import { useGoogleRecaptcha } from '../../util/use-google-recaptcha'

export default {
	name: 'RequestCustomQuote',
	data() {
		return {
			title: 'Request a Custom Quote',
			clickToClose: true,
			requested: false,
			form: {
				toName: '',
				toCompany: '',
				toEmail: '',
				toPhone: '',
				status: ''
			}
		}
	},
	methods: {
		beforeOpen(e) {
			if (e.params && e.params.title) {
				this.title = e.params.title
			}
		},
		opened(e) {
			this.$refs.toName.focus()
		},
		closed(e) {
			Object.assign(this.form, this.$options.data.apply(this).form)
			
			this.title = 'Request a Custom Quote'
			this.clickToClose = true
			this.requested = false
		},
		request() {
			this.clickToClose = false
			
			return useGoogleRecaptcha().getRecaptchaToken('request_custom_quote').then(token => {
				return this.$http.put('func/request-custom-quote', {
					name: this.form.toName,
					company: this.form.toCompany,
					email: this.form.toEmail,
					phone: this.form.toPhone,
					recaptchaToken: token
				})
					.then(response => {
						this.clickToClose = true
						this.requested = true
						
						this.$set(this.form, 'status', '<h4 class="text-success"><i class="fas fa-check-circle"></i> Request sent.</h4>')
						
						const _paq = window._paq = window._paq || []
						_paq.push(['trackEvent', 'Reports', 'Form Submitted', '"' + this.title + '" Modal'])
						
						setTimeout(() => {
							this.$modal.hide('requestCustomQuoteModal')
						}, 1500)
					}, response => {
						this.clickToClose = true
						
						this.$set(this.form, 'status', '<span class="text-danger">Invalid name, company, or email.</span>')
					})
			})
		}
	}
}
</script>