<template>
	<modal name="campaignLoginModal" height="auto" :reset="true" :clickToClose="clickToClose" :adaptive="true" :maxWidth="350" @before-open="beforeOpen" @opened="opened" @closed="closed" transition="nice-modal-fade">
		<div class="modal-header">
			<h5 class="modal-title">Sign In</h5>
			<button type="button" class="close" @click="$modal.hide('campaignLoginModal')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<form v-if="!registeredEmail" v-promise-btn="{ action: 'submit' }" @submit.prevent="requestOtp" accept-charset="utf-8" novalidate="novalidate" role="form">
				<div class="form-group text required">
					<input type="text" name="login_email" v-model="form.email" inputmode="email" :class="{ 'form-control': true, 'is-invalid': !!emailErr }" placeholder="Your email address" ref="loginEmail">
					<div v-if="!!emailErr" class="invalid-feedback">{{ emailErr }}</div>
				</div>
				<div class="form-group mb-0">
					<button type="submit" :disabled="form.email.length < 1" class="btn btn-primary-outline form-control">Continue</button>
				</div>
			</form>
			<form v-else method="post" accept-charset="utf-8" novalidate="novalidate" action="/users/login" role="form" ref="campaignLoginForm">
				<div style="display: none;">
					<input type="hidden" name="_method" value="POST">
					<input type="hidden" name="_csrfToken" autocomplete="off" :value="form.csrfToken">
					<input v-if="campaign" type="hidden" name="access_code" :value="campaign.access_code" />
					<input type="text" name="login_email" autocomplete="off" :value="registeredEmail" style="display: none;" />
					<input type="hidden" name="verification_code" autocomplete="off" :value="form.verification_code" />
				</div>
				<div class="d-flex flex-column align-items-center">
					<h3>Check Your Email</h3>
					<div class="mb-3" style="width: 100px;">
						<img v-lazy="require('@assets/img/icons/lock-check.png')" class="img-fluid p-3" style="background-color: #2fbbcc; border-radius: 50%; overflow: hidden;" />
					</div>
					<div class="mb-3 text-center">
						<p class="mb-0" style="font-size: 16px;">We've sent a verification code to</p>
						<p class="mb-0 font-weight-bold" style="font-size: 16px;">{{ registeredEmail }}</p>
					</div>
					<div class="form-group w-100 mb-0">
						<otp-input
							ref="otpInput"
							class="justify-content-between"
							input-classes="otp-input"
							separator=""
							:num-inputs="6"
							:is-input-num="true"
							:should-auto-focus="true"
							@on-complete="otpCompleted"
						/>
					</div>
				</div>
			</form>
		</div>
	</modal>
</template>

<script>
import { mapState } from 'vuex'
import OtpInput from '@bachdgvn/vue-otp-input'

export default {
	name: 'CampaignLogin',
	data() {
		return {
			clickToClose: true,
			emailErr: '',
			registeredEmail: null,
			form: {
				email: '',
				csrfToken: '',
				verification_code: ''
			}
		}
	},
	computed: {
		...mapState({
			campaign: state => state.campaign
		})
	},
	methods: {
		beforeOpen(e) {
			if (e.params.email) {
				this.registeredEmail = e.params.email
			}
		},
		opened() {
			if (this.$refs.loginEmail) {
				this.$refs.loginEmail.focus()
			}
		},
		closed() {
			Object.assign(this.form, this.$options.data.apply(this).form)
			
			this.clickToClose = true
			this.emailErr = ''
			this.registeredEmail = null
		},
		requestOtp() {
			this.clickToClose = false
			
			return this.$http.post('account/start', {
				email: this.form.email,
				verification_code: this.form.verification_code // @todo: needed?
			})
				.then(() => {
					this.emailErr = ''
					this.registeredEmail = this.form.email
				})
				.catch(error => {
					if (error.response) {
						this.emailErr = error.response.data.result.error
					} else {
						console.error(error.toJSON())
					}
				})
				.finally(() => {
					this.clickToClose = true
				})
		},
		otpCompleted(code) {
			this.$set(this.form, 'verification_code', code)
			
			this.$http.get('func/csrf-token')
				.then(response => {
					this.$set(this.form, 'csrfToken', response.data.result.csrfToken)
					
					this.$nextTick(function () {
						this.$refs.campaignLoginForm.submit()
					})
				})
		}
	},
	components: {
		OtpInput
	}
}
</script>

<style lang="scss">
.otp-input {
	width: 40px;
	height: 40px;
	padding: 5px;
	font-size: 20px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	text-align: center;
	
	/*
	&.error {
		border: 1px solid red !important;
	}
	*/
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>