var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar",
    {
      ref: "mainNav",
      class: _vm.subpage ? "subpage" : "",
      attrs: { toggleable: "lg", fixed: "top", id: "mainNav" },
    },
    [
      _c("b-navbar-toggle", { attrs: { target: "nav_collapse" } }),
      _vm._v(" "),
      _vm.subpage
        ? _c("b-navbar-brand", { attrs: { to: "/" } }, [_vm._v("Market Scope")])
        : _c("b-navbar-brand", [
            _c("i", { staticClass: "fa fa-phone" }),
            _vm._v(" 314-835-0600\n\t\t"),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.campaign
                      ? _vm.$modal.show("campaignLoginModal", {})
                      : _vm.$modal.show("loginModal")
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-lock" }), _vm._v(" Sign In")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "scroll-to",
                    rawName: "v-scroll-to",
                    value: "#register",
                    expression: "'#register'",
                  },
                ],
                attrs: { href: "#" },
              },
              [_c("i", { staticClass: "fa fa-edit" }), _vm._v(" Join")]
            ),
          ]),
      _vm._v(" "),
      _vm.subpage
        ? _c(
            "b-navbar-nav",
            { staticClass: "flex-row" },
            [
              _c(
                "b-nav-item",
                {
                  staticClass: "align-self-center login-link",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.campaign
                        ? _vm.$modal.show("campaignLoginModal", {})
                        : _vm.$modal.show("loginModal")
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-lock" }), _vm._v(" Sign In")]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "scroll-to",
                      rawName: "v-scroll-to",
                      value: "#register",
                      expression: "'#register'",
                    },
                  ],
                  staticClass: "ml-2 register-link",
                  attrs: { href: "#register" },
                },
                [
                  _c("i", { staticClass: "fa fa-edit" }),
                  _vm._v(" Register Now"),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "nav_collapse" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _vm.$route.name !== "Article"
                ? _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "form",
                        {
                          staticClass: "meilisearch-search-wrapper search-box",
                          attrs: { id: "search-form", role: "search" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("input", {
                            ref: "searchInput",
                            staticClass: "search-query",
                            attrs: {
                              id: "meilisearch-search-input",
                              placeholder: _vm.placeholder,
                            },
                            domProps: { value: _vm.searchQuery },
                            on: { input: _vm.updateSearchQuery },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            show: _vm.showFirstrun,
                            placement: "bottom",
                            target: "search-form",
                            triggers: "blur",
                            variant: "warning",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("strong", [_vm._v("New")]),
                                    _vm._v(" Instant Search"),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2330614964
                          ),
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\tResults populate as you type\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContext === "combined" ||
              _vm.activeContext === "industry"
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      ref: "navDropdown",
                      staticClass: "full-width",
                      attrs: { text: "Products" },
                      on: { shown: _vm.alignNavItems },
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/pages/reports?page=1", exact: "" } },
                        [_vm._v("Global & Regional Market Reports")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { to: "/ophthalmic-market-trends", exact: "" },
                        },
                        [_vm._v("Ophthalmic Market Trends")]
                      ),
                      _vm._v(" "),
                      _c("b-dropdown-item", { attrs: { to: "/atlases" } }, [
                        _vm._v("Atlases"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/disease-models", exact: "" } },
                        [_vm._v("Disease Models")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/ophthalmic-newsletter", exact: "" } },
                        [_vm._v("Ophthalmic Newsletter")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/pages/reports/deals?page=1",
                            exact: "",
                          },
                        },
                        [_vm._v("Ophthalmic Deals Analysis")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContext === "combined" ||
              _vm.activeContext === "medical"
                ? _c("b-nav-item", { attrs: { to: "/pages/physicians" } }, [
                    _vm._v("Surveys"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContext === "combined" ||
              _vm.activeContext === "industry"
                ? _c(
                    "b-nav-item",
                    { attrs: { to: "/pages/primary-research" } },
                    [_vm._v("Primary Research")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/pages/news" } }, [
                _vm._v("News"),
              ]),
              _vm._v(" "),
              _vm.activeContext === "combined" ||
              _vm.activeContext === "medical"
                ? _c("b-nav-item", { attrs: { to: "/plus" } }, [
                    _vm._v("Market Scope+"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContext === "combined" ||
              _vm.activeContext === "industry"
                ? _c("b-nav-item", { attrs: { to: "/pages/methodology" } }, [
                    _vm._v("Methodology"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/pages/about" } }, [
                _vm._v("About"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.campaign
        ? _c("campaign-login")
        : _c("login", {
            attrs: { requestedProductId: _vm.requestedProductId },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }