import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import EventBus from './event-bus'

Vue.use(Router)
Vue.use(Meta)

import Landing from '@components/Landing'
import CampaignRegister from '@components/CampaignRegister'
import About from '@components/About'
import Bio from '@components/Bio'
import Methodology from '@components/Methodology'
import PrimaryResearch from '@components/PrimaryResearch'
import Physicians from '@components/Physicians'
import News from '@components/News'
import NewsArchive from '@components/NewsArchive'
import Article from '@components/modals/Article'
import Reports from '@components/Reports'
import Report from '@components/Report'
import Careers from '@components/Careers'
import Atlases from '@components/Atlases'
import DiseaseModels from '@components/DiseaseModels'
import CarecreditAtlases from '@components/CarecreditAtlases'
import CarecreditSurveys from '@components/CarecreditSurveys'
import OphthalmicNewsletter from '@components/OphthalmicNewsletter'
import OphthalmicMarketTrends from '@components/OphthalmicMarketTrends'
import Plus from '@components/Plus'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject)
	}
	
	return originalPush.call(this, location).catch(err => {
		if (Router.isNavigationFailure(err)) {
			return err
		}
		return Promise.reject(err)
	})
}

export function createRouter(store) {
	const scrollBehavior = function (to, from, savedPosition) {
		if (savedPosition) {
			// savedPosition is only available for popstate navigations.
			return savedPosition
		} else {
			const position = {}
			
			// scroll to anchor by returning the selector
			if (to.hash) {
				position.selector = to.hash
				
				if (document.querySelector(to.hash)) {
					return position
				}
				
				// if the returned position is falsy or an empty object,
				// will retain current scroll position.
				return false
			}
			
			return new Promise(resolve => {
				// check if any matched route config has meta that requires scrolling to top
				if (to.matched.some(m => m.meta.scrollToTop)) {
					// coords will be used if no selector is provided,
					// or if the selector didn't match any element.
					if (!(from.name === 'Article' && to.name === 'News') && !(from.name === 'Article' && to.name === 'NewsArchive')) {
						position.x = 0
						position.y = 0
					}
				}
				
				// wait for the out transition to complete (if necessary)
				EventBus.$once('triggerScroll', () => {
					// if the resolved position is falsy or an empty object,
					// will retain current scroll position.
					Vue.nextTick(() => resolve(position))
				})
			})
		}
	}
	
	const router = new Router({
		mode: 'history',
		fallback: false,
		scrollBehavior,
		routes: [
			{
				path: '/',
				name: 'Landing',
				component: Landing,
				alias: ['/medical', '/industry'],
				meta: {
					scrollToTop: true
				}
			},
			{
				path: '/login',
				redirect: {
					name: 'Landing',
					hash: '#login'
				}
			},
			{
				path: '/register',
				redirect: {
					name: 'Landing',
					hash: '#register'
				}
			},
			{
				path: '/register/:access_code',
				name: 'CampaignRegister',
				component: CampaignRegister,
				meta: {
					scrollToTop: true,
					title: 'Campaign Register'
				}
			},
			{
				path: '/pages/about',
				name: 'About',
				component: About,
				meta: {
					scrollToTop: true,
					breadcrumb: 'About',
					title: 'About'
				}
			},
			{
				path: '/pages/about/:slug',
				name: 'Bio',
				component: Bio,
				props: route => ({
					slug: route.params.slug
				}),
				meta: {
					scrollToTop: true,
					breadcrumb: routeParams => routeParams.slug ? `${routeParams.slug.split('-').map(i => i[0].toUpperCase() + i.substr(1)).join(' ')}` : 'About'
				}
			},
			{
				path: '/pages/methodology',
				name: 'Methodology',
				component: Methodology,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Methodology',
					title: 'Methodology'
				}
			},
			{
				path: '/pages/primary-research',
				name: 'PrimaryResearch',
				component: PrimaryResearch,
				alias: '/pages/custom-research',
				meta: {
					scrollToTop: true,
					breadcrumb: 'Primary Research',
					title: 'Primary Research'
				}
			},
			{
				path: '/pages/physicians',
				name: 'Physicians',
				component: Physicians,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Physicians',
					title: 'Physicians'
				}
			},
			{
				path: '/pages/news/:tag?',
				name: 'News',
				component: News,
				meta: {
					scrollToTop: true,
					breadcrumb: routeParams => {
						if (routeParams.tag) {
							let words = routeParams.tag.split('-')
							
							for (let i = 0; i < words.length; i++) {
								let word = words[i]
								words[i] = word.charAt(0).toUpperCase() + word.slice(1)
							}
							
							return `${words.join(' ')} News`
						}
						
						return 'News'
					}
				}
			},
			{
				path: '/pages/news-archive',
				name: 'NewsArchive',
				component: NewsArchive,
				meta: {
					scrollToTop: true,
					breadcrumb: 'More News',
					title: 'More News'
				},
				props: route => ({ offset: route.params.offset })
			},
			{
				path: '/pages/news/:id(\\d+)/:slug',
				name: 'Article',
				component: Article,
				props: route => ({ offset: route.params.offset })
			},
			{
				path: '/pages/reports/:tag?',
				name: 'Reports',
				component: Reports,
				meta: {
					scrollToTop: true,
					breadcrumb: routeParams => {
						if (routeParams.tag) {
							let words = routeParams.tag.split('-')
							
							for (let i = 0; i < words.length; i++) {
								let word = words[i]
								words[i] = word.charAt(0).toUpperCase() + word.slice(1)
							}
							
							return `${words.join(' ')} Reports`
						}
						
						return 'Reports'
					}
				}
			},
			{
				path: '/pages/reports/:id(\\d+)/:slug',
				name: 'Report',
				component: Report,
				meta: {
					scrollToTop: true,
					breadcrumb: routeParams => routeParams.slug ? `${routeParams.slug.split('-').map(i => i[0].toUpperCase() + i.substr(1)).join(' ')}` : 'Report'
				}
			},
			{
				path: '/careers',
				name: 'Careers',
				component: Careers,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Careers',
					title: 'Careers'
				}
			},
			{
				path: '/atlases',
				name: 'Atlases',
				component: Atlases,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Atlases',
					title: 'Atlases'
				}
			},
			{
				path: '/pages/carecredit-atlases',
				name: 'CarecreditAtlases',
				component: CarecreditAtlases,
				meta: {
					scrollToTop: true,
					breadcrumb: 'CareCredit Atlases',
					title: 'CareCredit Atlases'
				}
			},
			{
				path: '/pages/carecredit-surveys',
				name: 'CarecreditSurveys',
				component: CarecreditSurveys,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Survey Opportunities',
					title: 'Survey Opportunities'
				}
			},
			{
				path: '/disease-models',
				name: 'DiseaseModels',
				component: DiseaseModels,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Ophthalmic Disease Prevalence Models',
					title: 'Ophthalmic Disease Prevalence Models'
				}
			},
			{
				path: '/ophthalmic-newsletter',
				name: 'OphthalmicNewsletter',
				component: OphthalmicNewsletter,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Ophthalmic Newsletter',
					title: 'Ophthalmic Newsletter'
				}
			},
			{
				path: '/ophthalmic-market-trends',
				name: 'OphthalmicMarketTrends',
				component: OphthalmicMarketTrends,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Ophthalmic Market Trends',
					title: 'Ophthalmic Market Trends'
				}
			},
			{
				path: '/plus',
				name: 'Plus',
				component: Plus,
				meta: {
					scrollToTop: true,
					breadcrumb: 'Market Scope+ Mobile App',
					title: 'Market Scope+ Mobile App'
				}
			}
		],
		linkActiveClass: 'active'
	})
	
	return router
}