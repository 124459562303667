<template>
	<modal name="contactUsModal" height="auto" :scrollable="true" :reset="true" :clickToClose="clickToClose" :adaptive="true" transition="nice-modal-fade" @opened="opened" @closed="closed">
		<form novalidate="novalidate" role="form" v-promise-btn="{ action: 'submit' }" @submit.prevent="send" v-on:keyup.esc="$modal.hide('contactUsModal')">
			<div class="modal-header">
				<h5 class="modal-title">Contact Us</h5>
				<button type="button" class="close" @click="$modal.hide('contactUsModal')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group">
					<label for="to-name" class="control-label">Your Name:</label>
					<input v-model="form.toName" type="text" class="form-control" name="to-name" ref="toName" />
				</div>
				<div class="form-group">
					<label for="to-email" class="control-label">Your Email:</label>
					<input v-model="form.toEmail" type="text" class="form-control" name="to-email" />
				</div>
				<div class="form-group">
					<label for="to-phone" class="control-label">Your Phone # <span class="text-muted">(optional)</span>:</label>
					<input v-model="form.toPhone" type="tel" class="form-control" name="to-phone" />
				</div>
				<div class="form-group">
					<label for="to-message" class="control-label">Add a message:</label>
					<textarea type="text" v-model="form.toMessage" name="to-message" class="form-control" rows="5"></textarea>
				</div>
				<div class="form-group mb-0">
					<small class="form-notice">Any submitted contact information is confidential and will not be given to any third parties.<br />This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</small>
				</div>
			</div>
			<div class="modal-footer">
				<div v-if="form.status" v-html="form.status" class="w-100 mx-auto"></div>
				<template v-if="!submitted">
					<div class="mr-auto">
						<i class="fas fa-phone"></i> Call Us: <a href="tel:+13148350600" style="font-size: 1.1rem; color: #2fbbcc;">314-835-0600</a>
					</div>
					<button type="button" class="btn btn-default" @click="$modal.hide('contactUsModal')">Cancel</button>
					<button type="submit" class="btn btn-primary">Send</button>
				</template>
			</div>
		</form>
	</modal>
</template>

<script>
import { useGoogleRecaptcha } from '../../util/use-google-recaptcha'

export default {
	name: 'ContactUsModal',
	data() {
		return {
			clickToClose: true,
			submitted: false,
			form: {
				toName: '',
				toEmail: '',
				toPhone: '',
				toMessage: '',
				status: ''
			}
		}
	},
	methods: {
		opened(e) {
			this.$refs.toName.focus()
		},
		closed(e) {
			Object.assign(this.form, this.$options.data.apply(this).form)
			
			this.clickToClose = true
			this.submitted = false
		},
		send() {
			this.clickToClose = false
			
			return useGoogleRecaptcha().getRecaptchaToken('product_custom_research').then(token => {
				return this.$http.put('func/contact-us', {
					name: this.form.toName,
					email: this.form.toEmail,
					phone: this.form.toPhone,
					message: this.form.toMessage,
					recaptchaToken: token
				})
					.then(response => {
						this.clickToClose = true
						this.submitted = true
						
						this.$set(this.form, 'status', '<h4 class="text-success"><i class="fas fa-check-circle"></i> Email sent.</h4>')
						
						const _paq = window._paq = window._paq || []
						_paq.push(['trackEvent', 'Reports', 'Form Submitted', '"Contact Us" Modal'])
						
						setTimeout(() => {
							this.$modal.hide('contactUsModal')
						}, 1500)
					}, response => {
						this.clickToClose = true
						
						this.$set(this.form, 'status', '<span class="text-danger">One or more required fields are missing or invalid.</span>')
					})
			})
		}
	}
}
</script>