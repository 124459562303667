<template>
	<div>
		<navbar :subpage="true" />
		<header class="bio-masthead w-100">
			<div class="row no-gutters">
				<div class="col-md-6 d-flex align-items-center justify-content-center p-5 bio-info">
					<div>
						<h1>Peter Downs</h1>
						<h3 class="my-4 font-weight-bold text-uppercase">Writer &amp; Analyst</h3>
						<h4>Phone: <a href="tel:+13148350600">314-835-0600</a></h4>
						<h4>Email: <a href="mailto:peter@market-scope.com">peter@market-scope.com</a></h4>
						<a href="https://www.linkedin.com/in/peterdowns/" target="_blank"><img v-lazy="require('@assets/img/about/bios/LinkedIn-Icon.svg')" class="d-block" /></a>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-center justify-content-end bio-headshot">
					<img v-lazy="require('@assets/img/about/headshot-7.jpg')" class="img-fluid" />
				</div>
			</div>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'About' }" exact>About</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Peter Downs</span>
			</li>
		</ol>
		<section class="bio content">
			<div class="container">
				<div class="row px-5">
					<div class="col">
						<div class="mx-md-5">
							<h4 class="mt-3 mb-4">Peter Downs writes about pharmaceutical, retina, and dry eye topics for Market Scope and covers OUS markets.</h4>
							<p>Peter is a member of the Society of Professional Journalists. Before joining Market Scope in 2015, he had 30 years of experience in business, health, and science reporting.</p>
							<p>Since joining Market Scope, he has earned a reputation as the team’s “pharma expert” for his deep dives into glaucoma, retina, cataract, and dry eye pharmaceuticals. He’s also our “fearless traveler,” venturing to countries around the world to study ophthalmology at the local level and report back to other members of our team and in our comprehensive reports and monthly newsletter.</p>
							<p>When he’s not buried in reporting on ophthalmology, you might find Peter enjoying a brisk walk at a local St. Louis park, cycling one of the region’s many bike trails, or writing about local history.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<authored-reports :authorId="1303" />
		<section class="bio extra">
			<div class="container">
				<div class="row">
					<div class="col text-center">
						<h3 class="mt-4 mb-5">Presentation Videos</h3>
					</div>
				</div>
				<div class="row px-5">
					<div class="col-md-6 pb-4 pb-md-0">
						<div class="position-relative">
							<img v-lazy="require('@assets/img/about/bios/peter-downs/Video-Presentation-Peter-Downs-1.png')" class="img-fluid" />
							<a href="https://vimeo.com/637624644" target="_blank" class="position-absolute" style="left: calc(50% - 31.5px); bottom: -31.5px;">
								<img v-lazy="require('@assets/img/about/bios/play-icon.png')" />
							</a>
						</div>
						<h5 class="text-center mx-5 mt-5">Peter Downs - OIS Glaucoma Innovation Showcase 2021</h5>
					</div>
					<div class="col-md-6">
						<div class="position-relative">
							<template v-if="playPresentationVideo2">
								<LazyYoutube src="https://youtu.be/9WIlNPtI6iw" :showTitle="false" :autoplay="true" customThumbnail="https://img.youtube.com/vi/9WIlNPtI6iw/sddefault.jpg" />
							</template>
							<template v-else>
								<img v-lazy="require('@assets/img/about/bios/peter-downs/Video-Presentation-Peter-Downs-2.png')" class="img-fluid" />
								<a @click.prevent="playPresentationVideo2 = true" href="#" class="position-absolute" style="left: calc(50% - 31.5px); bottom: -31.5px;">
									<img v-lazy="require('@assets/img/about/bios/play-icon.png')" />
								</a>
							</template>
						</div>
						<h5 class="text-center mx-5 mt-5">Peter Downs - All India Ophthalmological Society 2018</h5>
					</div>
				</div>
			</div>
		</section>
		<combined-registration origin="bio" />
		<footer-section />
	</div>
</template>

<script>
import { LazyYoutube } from 'vue-lazytube'

import Navbar from '../Navbar'
import AuthoredReports from '../AuthoredReports'
import CombinedRegistration from '../forms/CombinedRegistration'
import FooterSection from '../FooterSection'

export default {
	name: 'PeterDowns',
	metaInfo: {
		title: 'About: Peter Downs',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/about/peter-downs',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/headshot-7.jpg',
				vmid: 'og:image'
			},
			{
				name: 'title',
				property: 'og:title',
				content: 'About: Peter Downs',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/about/peter-downs',
				vmid: 'canonical'
			}
		]
	},
	data() {
		return {
			playPresentationVideo2: false
		}
	},
	components: {
		LazyYoutube,
		Navbar,
		AuthoredReports,
		CombinedRegistration,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.bio-masthead {
	position: relative;
	overflow: hidden;
	
	.row {
		padding-top: 81px; /* change to auto-adjust based on navbar height */
		
		.bio-info {
			background-color: #0a5384;
			padding-top: 4rem !important;
			
			> div {
				h1,
				h3,
				h4 {
					color: #fff;
				}
				
				h1 {
					font-size: 50px;
					font-weight: 400;
				}
				
				h3 {
					font-size: 18px;
				}
				
				h4 {
					font-size: 20px;
				}
			}
		}
		
		.bio-headshot {
			background-color: #0a5384;
		}
	}
	
	@media (min-width: 482px) {
		.row .bio-info {
			padding-top: 3rem !important;
		}
	}
}

section.bio.content {
	h4 {
		font-size: 30px;
		color: #0a5384;
		font-weight: 500;
	}
	
	p {
		font-size: 18px;
		color: #777;
	}
}

section.bio.extra {
	background: url('~@assets/img/about/bios/Presentation-Video-Background.jpg');
	@include background-cover;
	background-position-x: center;
	
	h3 {
		color: #fff;
		font-size: 40px;
		font-weight: 500;
	}
	
	img {
		filter: drop-shadow(0px 20px 40px #0000001f);
	}
	
	h5 {
		color: #fff;
		font-size: 24px;
		font-weight: 500;
	}
}
</style>