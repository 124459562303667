var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "bio-masthead w-100" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-center p-5 bio-info",
            },
            [
              _c("div", [
                _c("h1", [_vm._v("Krista Davidson")]),
                _vm._v(" "),
                _c(
                  "h3",
                  { staticClass: "my-4 font-weight-bold text-uppercase" },
                  [_vm._v("Writer & Analyst")]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/in/krista-davidson-020682145/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/about/bios/LinkedIn-Icon.svg"),
                          expression:
                            "require('@assets/img/about/bios/LinkedIn-Icon.svg')",
                        },
                      ],
                      staticClass: "d-block",
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-end bio-headshot",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/about/headshot-19.jpg"),
                    expression: "require('@assets/img/about/headshot-19.jpg')",
                  },
                ],
                staticClass: "img-fluid",
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c("router-link", { attrs: { to: { name: "About" }, exact: "" } }, [
              _vm._v("About"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("authored-reports", { attrs: { authorId: 9195 } }),
      _vm._v(" "),
      _c("combined-registration", { attrs: { origin: "bio" } }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Phone: "),
      _c("a", { attrs: { href: "tel:+13148350600" } }, [
        _vm._v("314-835-0600"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _vm._v("Email: "),
      _c("a", { attrs: { href: "mailto:krista@market-scope.com" } }, [
        _vm._v("krista@market-scope.com"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Krista Davidson")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bio content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row px-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mx-md-5" }, [
              _c("h4", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "Krista Davidson has always found herself at the intersection of research and health care, investing her time at Market Scope in both primary research and most recently on secondary research as an author/analyst of key market reports."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Krista studied public health at Truman State University and earned her B.S. in health science, then began her career in medical technology – initially with respiratory therapy products, overseeing distribution to patients during the COVID-19 pandemic. She worked closely with clinicians and industry to meet eligibility criteria for payer coverage, sparking a growing interest in reimbursement and regulatory affairs."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Her experience in ophthalmology began as research manager for Market Scope, where she spent recent years learning the ophthalmic market by designing custom surveys for clients to better understand product development, price sensitivity, and adoption curves."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "In addition to her research and writing efforts on the ophthalmic market, she contributes to Market Scope’s monthly newsletter, Ophthalmic Market Perspectives."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "When she’s not ruminating on the latest ophthalmic news, Krista enjoys crosswording, cross-stitching, and cross-training."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }