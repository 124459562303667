<template>
	<div class="card text-center border-0">
		<div class="card-header card-left text-center">
			<img v-lazy="require('@assets/img/Business-Unselected-New.jpg')" style="visibility: hidden;" />
			<h3 class="card-title">Register A Corporate Account</h3>
		</div>
		<div class="card-body">
			<div :id="containerId">
				<p class="card-text">A corporate account gives you access to licensed reports and subscriptions, the latest news, a personalized dashboard, and weekly emails with news and data.</p>
				<form method="post" v-promise-btn="{ action: 'submit' }" @submit.prevent="submit" accept-charset="utf-8" novalidate id="corporate-registration-form" ref="corporateRegistrationForm" role="form" action="/users/corporate-register">
					<div style="display: none;">
						<input type="hidden" name="_method" value="POST" class="form-control" />
						<input type="hidden" name="_csrfToken" autocomplete="off" :value="csrfToken" />
						<input type="hidden" name="user_attribute[requested_product_id]" :value="requestedProductId" />
						<input v-if="invite" type="hidden" name="invite" :value="invite" />
						<input type="hidden" name="g_recaptcha_response" autocomplete="off" :value="gRecaptchaToken" />
						<input type="hidden" name="origin" value="default" />
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group text required">
								<input type="text" v-model.trim="$v.full_name.$model" name="full_name" placeholder="Full Name" id="corporate-full-name" required maxlength="50" :class="{ 'form-control': true, 'is-invalid': $v.full_name.$dirty && $v.full_name.$error }" />
								<div class="invalid-feedback" v-if="$v.full_name.$dirty && !$v.full_name.required">Full name is required.</div>
								<div class="invalid-feedback" v-if="$v.full_name.$dirty && !$v.full_name.minLength">Full name has a minimum of 3 characters.</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group email required">
								<input type="email" v-model.trim="$v.email.$model" name="email" placeholder="Email" id="corporate-email" required maxlength="254" :class="{ 'form-control': true, 'is-invalid': $v.email.$dirty && $v.email.$error }" />
								<div class="invalid-feedback" v-if="$v.email.$dirty && !$v.email.required">Email address is required.</div>
								<div class="invalid-feedback" v-if="$v.email.$dirty && !$v.email.email">Email address has to be valid.</div>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group text required">
								<vue-bootstrap-typeahead
									placeholder="Company Name"
									v-model.trim="$v.companyName.$model"
									:data="companyNames"
									:serializer="v => v.name"
									@hit="selectCompany"
									@input="deselectCompany"
									id="companies-company-name"
									:inputClass="$v.companyName.$dirty && $v.companyName.$error ? 'is-invalid' : ''"
								/>
								<div v-if="$v.companyName.$dirty && !$v.companyName.required" class="invalid-feedback" style="display: block;">Company name is required.</div>
							</div>
							<input type="hidden" name="companies[0][name]" ref="corporate_company_name" value="" />
							<input type="hidden" name="companies[0][id]" ref="corporate_company_id" value="" />
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group text">
								<input type="text" name="user_attribute[title]" placeholder="Title" id="corporate-title" maxlength="255" class="form-control" />
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col-6">
							<div class="form-group password required">
								<input type="password" v-model.trim="$v.passwd.$model" name="passwd" placeholder="Enter a password" id="corporate-password" required :class="{ 'form-control': true, 'is-invalid': $v.passwd.$dirty && $v.passwd.$error }" />
								<div class="invalid-feedback" v-if="$v.passwd.$dirty && !$v.passwd.required">Password is required.</div>
								<div class="invalid-feedback" v-if="$v.passwd.$dirty && !$v.passwd.minLength">Password has a minimum of 6 characters.</div>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group password required">
								<input type="password" v-model.trim="$v.passwd_confirm.$model" name="passwd_confirm" placeholder="Re-enter your password" id="corporate-password-confirm" :class="{ 'form-control': true, 'is-invalid': $v.passwd_confirm.$dirty && $v.passwd_confirm.$error }" />
								<div class="invalid-feedback" v-if="$v.passwd_confirm.$dirty && !$v.passwd_confirm.sameAsPasswd">Password re-entry has to match.</div>
							</div>
						</div>
					</div>
					<div class="form-row mb-3">
						<div class="col">
							<div class="card bg-light">
								<div class="card-body">
									<div class="form-group select">
										<label class="control-label"><h5>Topics of Interest</h5></label>
										<multiselect v-model="topicsOfInterest" :options="topicsOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" />
										<select multiple name="topics_of_interest[]" style="display: none;">
											<option v-for="option in topicsOfInterest" :value="option" selected>{{ option }}</option>
										</select>
									</div>
									<div v-if="topicsOfInterest.includes('Other')" class="form-group text">
										<input type="text" name="topics_of_interest[other]" placeholder="Other" maxlength="255" class="form-control" />
									</div>
									<div class="form-group select">
										<label class="control-label"><h5>Products of Interest</h5></label>
										<multiselect v-model="productsOfInterest" :options="productsOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" />
										<select multiple name="products_of_interest[]" style="display: none;">
											<option v-for="option in productsOfInterest" :value="option" selected>{{ option }}</option>
										</select>
									</div>
									<div v-if="productsOfInterest.includes('Other')" class="form-group text">
										<input type="text" name="products_of_interest[other]" placeholder="Other" maxlength="255" class="form-control" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center">
						<button type="submit" class="btn btn-large btn-success" :disabled="isSubmitting">Register</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
	name: 'CorporateRegistration',
	props: {
		containerId: {
			type: String,
			default: ''
		},
		requestedProductId: {
			type: Number,
			default: null
		}
	},
	asyncData({ store }) {
		return store.dispatch('fetchCompanyNames')
	},
	data() {
		return {
			full_name: '',
			companyName: '',
			email: '',
			passwd: '',
			passwd_confirm: '',
			topicsOfInterest: [],
			topicsOfInterestOptions: ['Cataract', 'Refractive', 'Glaucoma', 'Retina', 'Diagnostics', 'Pharmaceuticals', 'Surgical Devices', 'Dry Eye', 'Cornea', 'Other'],
			productsOfInterest: [],
			productsOfInterestOptions: ['Comprehensive Report', 'Quarterly Update', 'Newsletter', 'Custom Research', 'Other'],
			csrfToken: '',
			gRecaptchaToken: '',
			isSubmitting: false,
			unsubscribe: null
		}
	},
	computed: {
		...mapState({
			invite: state => state.invite
		}),
		companyNames() {
			return this.$store.state.companyNames
		}
	},
	validations: {
		full_name: {
			required,
			minLength: minLength(3)
		},
		companyName: {
			required
		},
		email: {
			required,
			email
		},
		passwd: {
			required,
			minLength: minLength(6)
		},
		passwd_confirm: {
			sameAsPasswd: sameAs('passwd')
		}
	},
	mounted() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'SET_INVISIBLE_RECAPTCHA' && state.invisibleRecaptcha.originComponent === this.$options.name) {
				this.recaptchaCallback(state.invisibleRecaptcha.token);
				
				this.unsubscribe()
			}
		})
	},
	beforeDestroy() {
		typeof this.unsubscribe === 'function' && this.unsubscribe()
	},
	methods: {
		selectCompany(company) {
			this.$refs.corporate_company_id.value = company.id
			this.$refs.corporate_company_name.value = ''
		},
		deselectCompany() {
			this.$refs.corporate_company_id.value = ''
			this.$refs.corporate_company_name.value = this.companyName
		},
		submit() {
			this.$v.$touch()
			
			if (!this.$v.$invalid) {
				this.isSubmitting = true
				
				this.$root.$children[0].$refs.invisibleRecaptcha.execute(this.$options.name)
			}
		},
		recaptchaCallback(token) {
			this.gRecaptchaToken = token
			
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.corporateRegistrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		}
	},
	components: {
		Multiselect,
		VueBootstrapTypeahead
	}
}
</script>