var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "register", attrs: { id: "register" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-6 mb-3 mb-lg-0" }, [
          _c("div", { staticClass: "header-row" }, [
            _c("h2", [_vm._v(_vm._s(_vm.headerText))]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                class: {
                  btn: true,
                  "btn-outline": true,
                  "mb-3": true,
                  active: _vm.activeForm === "corporate",
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.activeForm = "corporate"
                  },
                },
              },
              [_vm._v("I am an Industry Professional")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: {
                  btn: true,
                  "btn-outline": true,
                  "mb-3": true,
                  active: _vm.activeForm === "clinical",
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.activeForm = "clinical"
                  },
                },
              },
              [_vm._v("I am a Medical Professional")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5" }, [
            _c("h3", { staticClass: "text-white mb-3 login-header" }, [
              _vm._v("Already Have an Account?"),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline active",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.campaign
                      ? _vm.$modal.show("campaignLoginModal", {})
                      : _vm.$modal.show("loginModal")
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-lock" }), _vm._v(" Sign In")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-6" },
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                _vm.activeForm === "clinical"
                  ? [
                      _vm.campaign && _vm.campaign.fields_list.length
                        ? _c("campaign-clinical-registration", {
                            attrs: { containerId: "clinical-container" },
                          })
                        : _c("clinical-registration", {
                            attrs: { containerId: "clinical-container" },
                          }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeForm === "corporate"
                  ? _c("corporate-registration", {
                      attrs: {
                        containerId: "corporate-container",
                        requestedProductId: _vm.requestedProductId,
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }