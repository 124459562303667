<template>
	<div>
		<navbar :subpage="true" />
		<header class="bio-masthead w-100">
			<div class="row no-gutters">
				<div class="col-md-6 d-flex align-items-center justify-content-center p-5 bio-info">
					<div>
						<h1>Sharon Gotter</h1>
						<h3 class="my-4 font-weight-bold text-uppercase">Research Manager</h3>
						<h4>Phone: <a href="tel:+13148350600">314-835-0600</a></h4>
						<h4>Email: <a href="mailto:sharon@market-scope.com">sharon@market-scope.com</a></h4>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-center justify-content-end bio-headshot">
					<img v-lazy="require('@assets/img/about/headshot-23.jpg')" class="img-fluid" />
				</div>
			</div>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'About' }" exact>About</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Sharon Gotter</span>
			</li>
		</ol>
		<section class="bio content">
			<div class="container">
				<div class="row px-5">
					<div class="col">
						<div class="mx-md-5">
							<h4 class="mt-3 mb-4">Sharon Gotter specializes in all types of primary research techniques and focus group moderation.</h4>
							<p>Sharon joined Market Scope in 2024, bringing over 20 years’ experience in marketing research. She has a master’s degree in marketing research from Southern Illinois University at Edwardsville and has spent her career working in all types of research, such as political polling, financial, and retail research. In her role as research manager, she is responsible for overseeing and executing the development and delivery of primary research projects for corporate clients.</p>
							<p>In her spare time, she is an avid reader, reality show viewer, and bargain hunter/yard saler.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<authored-reports :authorId="11772" />
		<section class="bio managed-products">
			<div class="container">
				<div class="row">
					<div class="col text-center">
						<h3 class="mt-4 mb-5">Managed Products &amp; Services</h3>
					</div>
				</div>
				<div class="row justify-content-center px-5">
					<div class="col-md-6 col-lg-4 p-2">
						<div class="card border-0 rounded-0 text-white">
							<img class="card-img py-4 px-5" v-lazy="require('@assets/img/about/bios/custom-and-primary-research.jpg')" alt="Custom & Primary Research Solutions" />
							<div class="card-img-overlay text-center">
								<h5 class="card-title">Custom &amp; Primary<br />Research Solutions</h5>
								<router-link :to="{ name: 'PrimaryResearch' }" class="btn btn-sm btn-success py-2 px-3 font-weight-bold">LEARN MORE</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<combined-registration origin="bio" />
		<footer-section />
	</div>
</template>

<script>
import Navbar from '../Navbar'
import AuthoredReports from '../AuthoredReports'
import CombinedRegistration from '../forms/CombinedRegistration'
import FooterSection from '../FooterSection'

export default {
	name: 'SharonGotter',
	metaInfo: {
		title: 'About: Sharon Gotter',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/about/sharon-gotter',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/headshot-23.jpg',
				vmid: 'og:image'
			},
			{
				name: 'title',
				property: 'og:title',
				content: 'About: Sharon Gotter',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/about/sharon-gotter',
				vmid: 'canonical'
			}
		]
	},
	components: {
		Navbar,
		AuthoredReports,
		CombinedRegistration,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.bio-masthead {
	position: relative;
	overflow: hidden;
	
	.row {
		padding-top: 81px; /* change to auto-adjust based on navbar height */
		
		.bio-info {
			background-color: #0a5384;
			padding-top: 4rem !important;
			
			> div {
				h1,
				h3,
				h4 {
					color: #fff;
				}
				
				h1 {
					font-size: 50px;
					font-weight: 400;
				}
				
				h3 {
					font-size: 18px;
				}
				
				h4 {
					font-size: 20px;
				}
			}
		}
		
		.bio-headshot {
			background-color: #0a5384;
		}
	}
	
	@media (min-width: 482px) {
		.row .bio-info {
			padding-top: 3rem !important;
		}
	}
}

section.bio.content {
	h4 {
		font-size: 30px;
		color: #0a5384;
		font-weight: 500;
	}
	
	p {
		font-size: 18px;
		color: #777;
	}
}

section.bio.managed-products {
	background-color: #f1f2f3;
	
	h3 {
		font-size: 40px;
		color: #0a5384;
		font-weight: 500;
	}
	
	.card {
		background-color: #229eb6;
		
		.card-img-overlay {
			top: auto;
			padding-top: 50%;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #0a5384 70%);
			
			h5 {
				font-weight: 500;
			}
		}
	}
}
</style>