var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "physicians-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "physicians", attrs: { id: "physicians" } },
        [
          _c("div", { staticClass: "container header-row mb-3" }, [
            _c("div", [
              _c("div", { staticClass: "mx-auto text-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/icons/MarketScope-Icons-03.svg"),
                      expression:
                        "require('@assets/img/icons/MarketScope-Icons-03.svg')",
                    },
                  ],
                  staticStyle: {
                    "max-width": "70px",
                    width: "auto",
                    height: "auto",
                  },
                  attrs: { width: "70", height: "70" },
                }),
                _vm._v(" "),
                _c("h1", { staticClass: "section-heading" }, [
                  _vm._v("Physician Surveys"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container intro-content my-3" }, [
            _c("div", { staticClass: "text-center mb-2" }, [
              _c("h3", [
                _vm._v("Use Our Free Tool to Benchmark Your Practice! "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: "#register",
                        expression: "'#register'",
                      },
                    ],
                    attrs: { href: "#" },
                  },
                  [_c("strong", [_vm._v("Register Now")])]
                ),
                _vm._v("."),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Our process is simple: We ask you for a few minutes of your time to complete a survey and, in exchange, provide you with a range of online tools to effectively measure and improve your practice. Since 1998, Market Scope has collected and analyzed more than 20,000 surveys from more than 3,500 practices."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container process-content my-3" }, [
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "text-center mb-4" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "scroll-to",
                      rawName: "v-scroll-to",
                      value: "#register",
                      expression: "'#register'",
                    },
                  ],
                  staticClass: "btn btn-success",
                  attrs: { href: "#" },
                },
                [_vm._v("Register Today to Get Started")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "benefits-content" }, [
            _c("div", { staticClass: "container my-3" }, [
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-instant.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-instant.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("Instant Results"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "We provide instant survey results that help you analyze practice patterns, evaluate treatment choices, and benchmark your practice."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-confidentiality.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-confidentiality.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("Confidentiality"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Our survey reports use only aggregated data. Individual responses are confidential, data files are de-identified, and paper surveys are shredded after data entry."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-updates.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-updates.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("Ophthalmic Market Trends"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "New topics are added every quarter that focus on medical practice, surgical volumes, technology adoption, and practice marketing."
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-quick.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-quick.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("Quick Surveys"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "On average, our surveys take 5-15 minutes to complete. You may also finish your survey at a later time as all answers are auto-saved."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-topics.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-topics.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("Topics Covered"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Survey topics include cataract, refractive, glaucoma, retina, and ocular surface."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-donate.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-donate.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("$75,000+ Donated"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "All survey participants have the option of a charitable donation in their name. Past recipients include Orbis International, ASCRS Foundation, Glaucoma 360, Doctors Without Borders, and the Houston Food Bank."
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-email.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-email.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("Weekly Email"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Emails include updates on current surveys, available results, topical weekly polls, and industry news."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-lectures.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-lectures.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("Boost Your Lectures"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "If you have completed a recent survey, we will gladly provide you with data and charts to help enhance your lectures. Just contact us via email or phone with your specific Market Scope request."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "media mb-3" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-icon-free.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-icon-free.png')",
                        },
                      ],
                      staticClass: "align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "align-self-center m-0" }, [
                      _vm._v("Free Tool"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Your personalized dashboard is a free tool to help you benchmark your practice, stay current with trends, and learn about new technologies."
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container features-content my-3" }, [
            _vm._m(6),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "The dashboard feature provides physicians and administrators with extensive analytical tools to help measure practice performance and stay informed with the latest industry news and trends."
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-5" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-callout-report.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-callout-report.png')",
                        },
                      ],
                      staticClass: "img-fluid w-100",
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-4 mb-3" }, [
                  _vm._v("Comprehensive Analyzed Report"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Our team of highly experienced consultants and analysts meticulously evaluate and validate survey data to ensure accuracy. Aggregate results are published within 90 days of the close of the survey and are available on your personalized dashboard."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-callout-benchmark.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-callout-benchmark.png')",
                        },
                      ],
                      staticClass: "img-fluid w-100",
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-4 mb-3" }, [
                  _vm._v("Personal Benchmark Data"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Compare your practice’s metrics to national averages. Set goals, monitor changes, and stay informed."
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-5" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-callout-dashboard.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-callout-dashboard.png')",
                        },
                      ],
                      staticClass: "img-fluid w-100",
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-4 mb-3" }, [
                  _vm._v("Interactive Dashboard Data"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "MyScope is an interactive data dashboard where members can compare practice metrics to aggregate survey results over time, providing powerful insight into the surgical volumes, technology adoption, etc."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-callout-databank.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-callout-databank.png')",
                        },
                      ],
                      staticClass: "img-fluid w-100",
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-4 mb-3" }, [_vm._v("Data Bank")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Looking for a chart or PowerPoint slide for your next lecture? Our DataBank is a robust collection of up-to-date charts that can be downloaded and incorporated into presentations, studies, etc."
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-5" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-callout-polls.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-callout-polls.png')",
                        },
                      ],
                      staticClass: "img-fluid w-100",
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-4 mb-3" }, [
                  _vm._v("Searchable Poll Archive"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Our weekly polls cover all facets of ophthalmology. Members benefit from a searchable archive of topical poll results."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/physicians/physicians-callout-news.png"),
                          expression:
                            "require('@assets/img/physicians/physicians-callout-news.png')",
                        },
                      ],
                      staticClass: "img-fluid w-100",
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-4 mb-3" }, [
                  _vm._v("Searchable News Archive"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "All of our published content is collected in a completely searchable archive for our members."
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("register", {
        attrs: { headerText: "Register Today!", defaultForm: "clinical" },
      }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Physicians")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-3" }, [
      _c("h3", [_vm._v("Our Process")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card mb-4" }, [
      _c("h5", { staticClass: "card-header" }, [
        _c("strong", [_vm._v("Step 1:")]),
        _vm._v(" Complete a Survey"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "card-text" }, [
          _vm._v(
            "Our surveys cover ophthalmology subspecialties and optometry and are customized to cover the latest trends in both business and practice of medicine issues."
          ),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "card-text fa-ul" }, [
          _c("li", [
            _c("span", { staticClass: "fa-li" }, [
              _c("i", { staticClass: "fas fa-check-circle" }),
            ]),
            _vm._v(
              " Survey reports utilize aggregated data. Individual responses are confidential, all data files are de-identified, and paper surveys are shredded after data entry. We ensure the integrity of all survey data by validating responses and confirming that respondents are from ophthalmic practices."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card mb-4" }, [
      _c("h5", { staticClass: "card-header" }, [
        _c("strong", [_vm._v("Step 2:")]),
        _vm._v(" Benchmark Your Practice's Performance"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "card-text" }, [
          _vm._v(
            "Survey results help analyze practice patterns, evaluate treatment choices, and benchmark your practice. Benchmark reports allow you to gauge your performance against the US average and global average. Benchmark topics include:"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("ul", { staticClass: "card-text fa-ul" }, [
              _c("li", [
                _c("span", { staticClass: "fa-li" }, [
                  _c("i", { staticClass: "fas fa-check-circle" }),
                ]),
                _vm._v(" Procedure volumes for all subspecialties."),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "fa-li" }, [
                  _c("i", { staticClass: "fas fa-check-circle" }),
                ]),
                _vm._v(" Conversion rates and average pricing."),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "fa-li" }, [
                  _c("i", { staticClass: "fas fa-check-circle" }),
                ]),
                _vm._v(" Surgical outcomes and preferred techniques."),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c("ul", { staticClass: "card-text fa-ul" }, [
              _c("li", [
                _c("span", { staticClass: "fa-li" }, [
                  _c("i", { staticClass: "fas fa-check-circle" }),
                ]),
                _vm._v(" Diagnosis and treatment methods."),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "fa-li" }, [
                  _c("i", { staticClass: "fas fa-check-circle" }),
                ]),
                _vm._v(" Patient referral sources."),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "fa-li" }, [
                  _c("i", { staticClass: "fas fa-check-circle" }),
                ]),
                _vm._v(" Practice market techniques, budgets, and goals."),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card mb-5" }, [
      _c("h5", { staticClass: "card-header" }, [
        _c("strong", [_vm._v("Step 3:")]),
        _vm._v(" Gain Market Insight With Instant Results"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "card-text" }, [
          _vm._v(
            "Instant results are available for review immediately on your personalized dashboard following completion of the survey. A fully analyzed report is made available a few weeks after the survey closes."
          ),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "card-text fa-ul" }, [
          _c("li", [
            _c("span", { staticClass: "fa-li" }, [
              _c("i", { staticClass: "fas fa-check-circle" }),
            ]),
            _vm._v(
              " Survey results help you analyze practice patterns, evaluate treatment choices, and benchmark your practice."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center pt-4 pb-4" }, [
      _c("h3", [_vm._v("Benefits of Market Scope Surveys")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center pt-5 pb-3" }, [
      _c("h3", [_vm._v("Your Market Scope Dashboard")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }