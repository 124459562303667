<template>
	<div>
		<navbar :subpage="true" />
		<header class="methodology-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Methodology</span>
			</li>
		</ol>
		<section class="methodology" id="methodology">
			<div class="container header-row">
				<div>
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-03.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h1 class="section-heading">Our Methodology</h1>
						<p>A Multi-Step Approach for Accurate &amp; Timely Data</p>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="steps step1">
					<div class="mx-auto text-center">
						<h3>Step 1: Collect Data</h3>
					</div>
					<p>Market Scope leverages an extensive database of proprietary and public data that we integrate into our models. This provides industry-leading insight and market intelligence.</p>
					<div class="row">
						<div class="col-md-6 mb-3">
							<div class="card">
								<div class="card-body">
									<div class="media">
										<img v-lazy="require('@assets/img/icons/Methodology-Icons-01.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
										<h4 class="align-self-center m-0"><strong>Proprietary data</strong></h4>
									</div>
									<ul>
										<li><i class="fas fa-check-circle"></i> 20+ years of physician survey data</li>
										<li><i class="fas fa-check-circle"></i> Disease models</li>
										<li><i class="fas fa-check-circle"></i> Cultivated database of physicians, ASCs, and hospitals</li>
										<li><i class="fas fa-check-circle"></i> Insight from our team of in-house industry experts</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-md-6 mb-3">
							<div class="card">
								<div class="card-body">
									<div class="media">
										<img v-lazy="require('@assets/img/icons/Methodology-Icons-02.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
										<h4 class="align-self-center m-0"><strong>Public data</strong></h4>
									</div>
									<ul>
										<li><i class="fas fa-check-circle"></i> Attendance and participation at major, worldwide meetings</li>
										<li><i class="fas fa-check-circle"></i> Public health systems and registries</li>
										<li><i class="fas fa-check-circle"></i> Clinical study data</li>
										<li><i class="fas fa-check-circle"></i> Analysis of company-published financial reports</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="steps step2">
					<div class="mx-auto text-center">
						<h3>Step 2: Model and Analyze Market</h3>
					</div>
					<p>Market Scope’s industry reports are meticulously prepared by a dedicated team of in-house consultants/analysts with over 100 years of collective experience. Each data point is derived from a combination of sources.</p>
					<div class="row">
						<div class="col-lg-4 mb-3">
							<div class="card">
								<div class="card-body">
									<div class="media">
										<img v-lazy="require('@assets/img/icons/Methodology-Icons-05.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
										<h4 class="align-self-center m-0"><strong>Extensive analysis</strong></h4>
									</div>
									<ul>
										<li><i class="fas fa-check-circle"></i> Integrated disease models</li>
										<li><i class="fas fa-check-circle"></i> Expert-based segmentation</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-4 mb-3">
							<div class="card">
								<div class="card-body">
									<div class="media">
										<img v-lazy="require('@assets/img/icons/Methodology-Icons-06.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
										<h4 class="align-self-center m-0"><strong>Integrate</strong> and <strong>reconcile</strong></h4>
									</div>
									<ul>
										<li><i class="fas fa-check-circle"></i> Continuous feedback loop</li>
										<li><i class="fas fa-check-circle"></i> Integration of survey data</li>
										<li><i class="fas fa-check-circle"></i> Triangulation of results</li>
										<li><i class="fas fa-check-circle"></i> Cross-model analysis</li>
										<li><i class="fas fa-check-circle"></i> Reconciliation to company revenues</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-4 mb-3">
							<div class="card">
								<div class="card-body">
									<div class="media">
										<img v-lazy="require('@assets/img/icons/Methodology-Icons-07.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
										<h4 class="align-self-center m-0"><strong>Produce products</strong> covering key medical markets</h4>
									</div>
									<ul>
										<li><i class="fas fa-check-circle"></i> Medical devices</li>
										<li><i class="fas fa-check-circle"></i> Drugs/biologics</li>
										<li><i class="fas fa-check-circle"></i> Equipment</li>
										<li><i class="fas fa-check-circle"></i> Providers</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="steps step3">
					<div class="mx-auto text-center">
						<h3>Step 3: Gather and Incorporate Feedback</h3>
					</div>
					<p>We continuously maintain an influx of data, feedback, and requests. We use this feedback to further improve upon our deliverables.</p>
					<div class="row">
						<div class="col-md-6 mb-3">
							<div class="card">
								<div class="card-body">
									<div class="media">
										<img v-lazy="require('@assets/img/icons/Methodology-Icons-03.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
										<h4 class="align-self-center m-0"><strong>Gather feedback</strong></h4>
									</div>
									<ul>
										<li><i class="fas fa-check-circle"></i> New market insights</li>
										<li><i class="fas fa-check-circle"></i> Client requests</li>
										<li><i class="fas fa-check-circle"></i> Landmark study evaluations</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-md-6 mb-3">
							<div class="card">
								<div class="card-body">
									<div class="media">
										<img v-lazy="require('@assets/img/icons/Methodology-Icons-04.svg')" class="align-self-center mr-3" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
										<h4 class="align-self-center m-0"><strong>Incorporate</strong> and <strong>revise</strong></h4>
									</div>
									<ul>
										<li><i class="fas fa-check-circle"></i> Forecasting models</li>
										<li><i class="fas fa-check-circle"></i> Analytical tools and methods</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<register />
		<footer-section />
	</div>
</template>

<script>
import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'

export default {
	name: 'Methodology',
	metaInfo: {
		title: 'Methodology',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/methodology',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/methodology-bg.jpg',
				vmid: 'og:image'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/methodology',
				vmid: 'canonical'
			}
		]
	},
	components: {
		Navbar,
		Register,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.methodology-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/methodology/methodology-bg.jpg'), $theme-secondary;
	background: url('~@assets/img/methodology/methodology-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: 70%;
}

section.methodology {
	.section-heading {
		color: #0a5384;
	}
	
	h3 {
		margin: 30px 0 15px;
		color: #0a5384;
	}
	
	p {
		color: $gray-light;
		font-size: inherit;
	}
	
	.card {
		box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
		border: none;
		
		.media h4 {
			overflow: hidden; // fixes text overflow on mobile?
		}
		
		ul {
			margin-top: 20px;
			padding-left: 10px;
			list-style-type: none;
			
			> li {
				color: #0a5384;
				margin: 10px 0;
			}
		}
	}
	
	.step1 .card {
		border-top: 4px solid #8dc752;
		
		.media h4 > strong {
			color: #0a5384;
		}
	}
	.step2 .card {
		border-top: 4px solid #0a5384;
		
		.media h4 {
			color: #0a5384;
			
			> strong {
				color: #0a5384;
			}
		}
	}
	.step3 .card {
		border-top: 4px solid #2ebacb;
		
		.media h4 {
			color: #0a5384;
			
			> strong {
				color: #0a5384;
			}
		}
	}
}
</style>