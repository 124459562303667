<template>
	<div>
		<navbar :subpage="true" />
		<header class="cs-masthead">
			<h1 class="section-heading">Custom &amp; Primary Research Solutions</h1>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Custom & Primary Research Solutions</span>
			</li>
		</ol>
		<section class="cs about-us" id="cs">
			<div class="container">
				<div class="row">
					<div class="col-md-5 my-auto">
						<h6>About Us</h6>
						<h3>Why use Market Scope?</h3>
						<p>Market Scope is a market intelligence firm focused on ophthalmology. Since 1998, our reports and data have been regarded across the industry as unbiased and contextualized sources of truth in the ophthalmic market. Our experience and focus on the field sets us apart and gives us the ability to provide you with better data and insights, so you can make the best decisions more quickly.</p>
						<div>
							<button type="button" class="btn btn-success" @click="showRequestCustomQuoteModal">Request a Quote</button>
						</div>
					</div>
					<div class="col-md-7">
						<div class="accordion shadow-lg bg-white rounded" role="tablist">
							<b-card no-body>
								<b-card-header header-tag="header" class="p-0" role="tab">
									<b-button block v-b-toggle.accordion-1 variant="transparent" class="d-flex p-3 rounded-0">
										<img v-lazy="require('@assets/img/primary-research/checkmark.svg')" width="25" height="25" style="max-width: 25px; width: auto; height: auto;" />
										<span class="ml-2 font-weight-bold">Experience</span>
										<i class="fa-solid fa-angle-up ml-auto when-open" style="line-height: inherit;"></i>
										<i class="fa-solid fa-angle-down ml-auto when-closed" style="line-height: inherit;"></i>
									</b-button>
								</b-card-header>
								<b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
									<b-card-body class="pt-0 pb-2">
										<b-card-text>We have executed hundreds of primary research products exclusively in ophthalmology and optometry. We understand how to design the right initial and follow-up questions, whom to ask, and how to deliver a clear and actionable plan.</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<b-card no-body>
								<b-card-header header-tag="header" class="p-0" role="tab">
									<b-button block v-b-toggle.accordion-2 variant="transparent" class="d-flex p-3 rounded-0">
										<img v-lazy="require('@assets/img/primary-research/checkmark.svg')" width="25" height="25" style="max-width: 25px; width: auto; height: auto;" />
										<span class="ml-2 font-weight-bold">Industry Expertise</span>
										<i class="fa-solid fa-angle-up ml-auto when-open" style="line-height: inherit;"></i>
										<i class="fa-solid fa-angle-down ml-auto when-closed" style="line-height: inherit;"></i>
									</b-button>
								</b-card-header>
								<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
									<b-card-body class="pt-0 pb-2">
										<b-card-text>Our directly employed team has over 100 years of collective experience in ophthalmology in both clinical and corporate settings. We attend and report on all the major meetings, and we’ve worked and maintained relationships with over 500 companies of every size and specialty.</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<b-card no-body>
								<b-card-header header-tag="header" class="p-0" role="tab">
									<b-button block v-b-toggle.accordion-3 variant="transparent" class="d-flex p-3 rounded-0">
										<img v-lazy="require('@assets/img/primary-research/checkmark.svg')" width="25" height="25" style="max-width: 25px; width: auto; height: auto;" />
										<span class="ml-2 font-weight-bold">Confidentiality</span>
										<i class="fa-solid fa-angle-up ml-auto when-open" style="line-height: inherit;"></i>
										<i class="fa-solid fa-angle-down ml-auto when-closed" style="line-height: inherit;"></i>
									</b-button>
								</b-card-header>
								<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
									<b-card-body class="pt-0 pb-2">
										<b-card-text>We take extensive efforts to ensure the anonymity of our research participants, securing personally identifiable information, requiring NDAs, and following all local, state, national, and international requirements for data security.</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<b-card no-body>
								<b-card-header header-tag="header" class="p-0" role="tab">
									<b-button block v-b-toggle.accordion-4 variant="transparent" class="d-flex p-3 rounded-0">
										<img v-lazy="require('@assets/img/primary-research/checkmark.svg')" width="25" height="25" style="max-width: 25px; width: auto; height: auto;" />
										<span class="ml-2 font-weight-bold">Customization</span>
										<i class="fa-solid fa-angle-up ml-auto when-open" style="line-height: inherit;"></i>
										<i class="fa-solid fa-angle-down ml-auto when-closed" style="line-height: inherit;"></i>
									</b-button>
								</b-card-header>
								<b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
									<b-card-body class="pt-0 pb-2">
										<b-card-text>We scale our projects to meet all needs of our clients, including mixing platforms, creating target profiles, meeting tough deadlines, and working within budgets.</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<b-card no-body>
								<b-card-header header-tag="header" class="p-0" role="tab">
									<b-button block v-b-toggle.accordion-5 variant="transparent" class="d-flex p-3 rounded-0">
										<img v-lazy="require('@assets/img/primary-research/checkmark.svg')" width="25" height="25" style="max-width: 25px; width: auto; height: auto;" />
										<span class="ml-2 font-weight-bold">Unparalleled Access</span>
										<i class="fa-solid fa-angle-up ml-auto when-open" style="line-height: inherit;"></i>
										<i class="fa-solid fa-angle-down ml-auto when-closed" style="line-height: inherit;"></i>
									</b-button>
								</b-card-header>
								<b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
									<b-card-body class="pt-0 pb-2">
										<b-card-text>Our proprietary database includes only individually confirmed, practicing ophthalmologists and optometrists in a well-balanced mix of geographic location, leadership, surgical/patient volume, technology adoption, age, and gender.</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="cs step-1" id="step1">
			<div class="container">
				<div class="row">
					<div class="col text-center mb-3">
						<h6>Let's Get Started</h6>
						<h3>Step 1: What Are Your Objectives?</h3>
						<p>Market Scope provides comprehensive third-party primary research solutions to clients seeking to solicit feedback from United States and European ophthalmologists on new product design, messaging, pricing and experience with current technology, and expectations from future treatments.</p>
						<h4>Potential Research Objectives</h4>
					</div>
				</div>
				<div class="row">
					<div class="card-deck mx-auto" style="margin-bottom: 30px;">
						<div v-for="card in step1cards.slice(0, 3)" class="card text-white text-center">
							<div :class="{ 'pt-1': true, 'card-selected': true, 'card-selected-slide': step1values.some(v => v.title === card.title) }">
								<span class="fa-stack">
									<i class="fa-solid fa-circle fa-stack-2x"></i>
									<i class="fa-solid fa-check fa-stack-1x"></i>
								</span>
							</div>
							<div class="card-body d-flex align-items-center justify-content-center">
								<a href="#" @click.prevent="toggleCard(card)" class="stretched-link">
									<div>
										<img v-lazy="require('@assets/img/primary-research/' + card.icon + '.svg')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
										<h5 class="card-title mb-0">{{ card.title }}</h5>
									</div>
									<div class="card-overlay d-flex align-items-center justify-content-center">
										<div>
											<h5 class="card-title mb-0">{{ card.title }}</h5>
											<p class="card-text">{{ card.content }}</p>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
					<div class="card-deck mx-auto">
						<div v-for="card in step1cards.slice(3)" class="card text-white text-center">
							<div :class="{ 'pt-1': true, 'card-selected': true, 'card-selected-slide': step1values.some(v => v.title === card.title) }">
								<span class="fa-stack">
									<i class="fa-solid fa-circle fa-stack-2x"></i>
									<i class="fa-solid fa-check fa-stack-1x"></i>
								</span>
							</div>
							<div class="card-body d-flex align-items-center justify-content-center">
								<a href="#" @click.prevent="toggleCard(card)" class="stretched-link">
									<div>
										<img v-lazy="require('@assets/img/primary-research/' + card.icon + '.svg')" width="50" height="50" style="max-width: 50px; width: auto; height: auto;" />
										<h5 class="card-title mb-0">{{ card.title }}</h5>
									</div>
									<div class="card-overlay d-flex align-items-center justify-content-center">
										<div>
											<h5 class="card-title mb-0">{{ card.title }}</h5>
											<p class="card-text">{{ card.content }}</p>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col text-center mt-5">
						<button type="button" class="btn btn-success" :disabled="!step1values.length" @click="$scrollTo('#step2')">Next Step</button>
					</div>
				</div>
			</div>
		</section>
		<section class="cs step-2" id="step2">
			<div class="container">
				<div class="row">
					<div class="col-lg-6">
						<div class="card shadow-lg">
							<div class="card-body p-4">
								<h3>Step 2: Define Your Project</h3>
								<b-form>
									<b-form-group
										id="input-group-1"
										label="1. What are the target markets?"
										label-for="input-1"
										class="mb-4"
									>
										<multiselect v-model="step2values.field1" :options="step2options.field1" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select all that apply..." :showLabels="false" />
									</b-form-group>
									<b-form-group
										id="input-group-2"
										label="2. Who is the target audience?"
										label-for="input-2"
										class="mb-4"
									>
										<multiselect v-model="step2values.field2" :options="step2options.field2" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select all that apply..." :showLabels="false" />
									</b-form-group>
									<b-form-group
										id="input-group-3"
										label="3. What is our target sample size?"
										label-for="input-3"
										class="mb-4"
									>
										<b-form-select
											id="input-3"
											v-model="step2values.field3"
											:options="step2options.field3"
										>
											<template #first>
												<b-form-select-option :value="null" disabled>Select option...</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>
									<b-form-group
										id="input-group-4"
										label="4. How quickly do you need the data?"
										label-for="input-4"
										class="mb-4"
									>
										<b-form-select
											id="input-4"
											v-model="step2values.field4"
											:options="step2options.field4"
										>
											<template #first>
												<b-form-select-option :value="null" disabled>Select option...</b-form-select-option>
											</template>
										</b-form-select>
									</b-form-group>
									<button @click.prevent="$scrollTo('#step3')" :disabled="!step2values.field1.length || !step2values.field2.length || !step2values.field3 || !step2values.field4" type="button" class="btn btn-success">Next Step</button>
								</b-form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="cs step-3" id="step3">
			<div class="container">
				<div class="row">
					<div class="col text-center mb-3">
						<h3>Step 3: Which Research Option is Right for You?</h3>
						<p>We leverage our expert knowledge of the ophthalmic industry to design custom research that enhances strategy and operations for our clients. Our unique experience makes us a trusted partner in defining problems and executing solutions.</p>
					</div>
				</div>
				<div class="row">
					<div class="card-deck mx-auto">
						<div class="card d-flex position-relative shadow-lg step-3-choice-1">
							<img v-lazy="require('@assets/img/primary-research/primary-research-online-surveys.jpg')" class="card-img-top w-100" style="height: 212px;" alt="Online Surveys" />
							<div v-if="step2values.field4 === '4-6 Weeks'" class="position-absolute bg-success text-white pl-4 py-2 w-100" style="top: 175px; font-size: 0.9rem;">RECOMMENDED</div>
							<div class="card-body">
								<h5 class="card-title">Online Surveys<br /><small>4 Week Turnaround</small></h5>
								<h6>Ideal for:</h6>
								<ul>
									<li>Large sample sizes</li>
									<li>Identifying new product features</li>
									<li>Price sensitivity</li>
								</ul>
								<div class="text-center mt-4 mb-3">
									<button @click="step3value = 'Online Surveys'; $scrollTo('#step4')" type="button" :class="{ btn: true, 'btn-success': step3value === 'Online Surveys', 'btn-outline-success': step3value !== 'Online Surveys' }">{{ step3value === 'Online Surveys' ? 'Selected Plan' : 'Select' }}</button>
								</div>
							</div>
						</div>
						<div class="card d-flex position-relative shadow-lg step-3-choice-2">
							<img v-lazy="require('@assets/img/primary-research/primary-research-focus-groups.jpg')" class="card-img-top w-100" style="height: 212px;" alt="Advisory Boards" />
							<div v-if="step2values.field4 === 'ASAP!' || step2values.field4 === '2-4 Weeks'" class="position-absolute bg-success text-white pl-4 py-2 w-100" style="top: 175px; font-size: 0.9rem;">RECOMMENDED</div>
							<div class="card-body">
								<h5 class="card-title">Advisory Boards<br /><small>2-4 Week Turnaround</small></h5>
								<h6>Ideal for:</h6>
								<ul>
									<li>Hand-selected participants</li>
									<li>Brainstorming next-gen technologies</li>
									<li>Challenging assumptions</li>
								</ul>
								<div class="text-center mt-4 mb-3">
									<button @click="step3value = 'Advisory Boards'; $scrollTo('#step4')" type="button" :class="{ btn: true, 'btn-success': step3value === 'Advisory Boards', 'btn-outline-success': step3value !== 'Advisory Boards' }">{{ step3value === 'Advisory Boards' ? 'Selected Plan' : 'Select' }}</button>
								</div>
							</div>
						</div>
						<div class="card d-flex position-relative shadow-lg step-3-choice-3">
							<img v-lazy="require('@assets/img/primary-research/primary-research-interviews.jpg')" class="card-img-top w-100" style="height: 212px;" alt="In-Depths Interviews (IDIs)" />
							<div v-if="step2values.field4 === '6 Weeks+'" class="position-absolute bg-success text-white pl-4 py-2 w-100" style="top: 175px; font-size: 0.9rem;">RECOMMENDED</div>
							<div class="card-body">
								<h5 class="card-title">In-Depths Interviews (IDIs)<br /><small>6-10 Week Turnaround</small></h5>
								<h6>Ideal for:</h6>
								<ul>
									<li>Deep diving into market needs</li>
									<li>Exploring marketing messages</li>
									<li>Defining clinical expectations</li>
								</ul>
								<div class="text-center mt-4 mb-3">
									<button @click="step3value = 'In-Depths Interviews (IDIs)'; $scrollTo('#step4')" type="button" :class="{ btn: true, 'btn-success': step3value === 'In-Depths Interviews (IDIs)', 'btn-outline-success': step3value !== 'In-Depths Interviews (IDIs)' }">{{ step3value === 'In-Depths Interviews (IDIs)' ? 'Selected Plan' : 'Select' }}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="cs step-4">
			<div class="container" id="step4">
				<div class="row">
					<div class="col text-center mb-3">
						<h3>Step 4: Contact Us</h3>
						<p>Contact us for a custom quote or register for your free account and gain access to licensed reports and subscriptions, industry news, trends, weekly news, and more.</p>
					</div>
				</div>
				<div class="card shadow-lg">
					<div class="card-body">
						<form method="post" v-promise-btn="{ action: step4method === 'methodRegister' ? 'submit' : 'login' }" @submit.prevent="step4method === 'methodRegister' ? submit() : login()" accept-charset="utf-8" novalidate id="corporate-registration-form" ref="corporateRegistrationForm" role="form" :action="step4method === 'methodRegister' ? '/users/corporate-register' : '/users/login'">
							<div style="display: none;">
								<input type="hidden" name="_method" value="POST" class="form-control" />
								<input type="hidden" name="_csrfToken" autocomplete="off" :value="csrfToken" />
								<input type="hidden" name="g_recaptcha_response" autocomplete="off" :value="gRecaptchaToken" />
								<input type="hidden" name="origin" value="custom_research" />
							</div>
							<div class="text-center my-3">
								<h5 class="card-title">Sign In or Register</h5>
							</div>
							<div class="form-row">
								<div class="form-group col text-center">
									<div class="custom-control custom-radio custom-control-inline">
										<input type="radio" v-model="step4method" value="methodRegister" id="methodRegister" class="custom-control-input" />
										<label class="custom-control-label" for="methodRegister">Register an account</label>
									</div>
									<div class="custom-control custom-radio custom-control-inline">
										<input type="radio" v-model="step4method" value="methodSignIn" id="methodSignIn" class="custom-control-input" />
										<label class="custom-control-label" for="methodSignIn">Sign in</label>
									</div>
								</div>
							</div>
							<div v-if="step4method === 'methodRegister'" class="form-row">
								<div class="form-group col-md-6">
									<label for="inputFullName">Full Name*</label>
									<input type="text" v-model.trim="$v.step4form.full_name.$model" name="full_name" placeholder="Full Name" id="inputFullName" required maxlength="50" :class="{ 'form-control': true, 'is-invalid': $v.step4form.full_name.$dirty && $v.step4form.full_name.$error }" />
									<div class="invalid-feedback" v-if="$v.step4form.full_name.$dirty && !$v.step4form.full_name.required">Full name is required.</div>
									<div class="invalid-feedback" v-if="$v.step4form.full_name.$dirty && !$v.step4form.full_name.minLength">Full name has a minimum of 3 characters.</div>
								</div>
								<div class="form-group col-md-3">
									<label for="inputCompany">Company*</label>
									<vue-bootstrap-typeahead
										placeholder="Company Name"
										v-model.trim="$v.companyName.$model"
										:data="companyNames"
										:serializer="v => v.name"
										@hit="selectCompany"
										@input="deselectCompany"
										id="inputCompany"
										:inputClass="$v.companyName.$dirty && $v.companyName.$error ? 'is-invalid' : ''"
									/>
									<input type="hidden" name="companies[0][name]" ref="corporate_company_name" value="" />
									<input type="hidden" name="companies[0][id]" ref="corporate_company_id" value="" />
									<div v-if="$v.companyName.$dirty && !$v.companyName.required" class="invalid-feedback" style="display: block;">Company name is required.</div>
								</div>
								<div class="form-group col-md-3">
									<label for="inputJobTitle">Job Title</label>
									<input type="text" class="form-control" name="user_attribute[title]" id="inputJobTitle" placeholder="Job Title" maxlength="255">
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="inputEmail">Email*</label>
									<input type="email" v-model.trim="$v.step4form.email.$model" :name="step4method === 'methodRegister' ? 'email' : 'login_email'" placeholder="Email" id="inputEmail" required maxlength="254" :class="{ 'form-control': true, 'is-invalid': $v.step4form.email.$dirty && $v.step4form.email.$error }" />
									<div class="invalid-feedback" v-if="$v.step4form.email.$dirty && !$v.step4form.email.required">Email address is required.</div>
									<div class="invalid-feedback" v-if="$v.step4form.email.$dirty && !$v.step4form.email.email">Email address has to be valid.</div>
								</div>
								<div :class="{ 'form-group': true, 'col-md-3': step4method === 'methodRegister', 'col-md-6': step4method === 'methodSignIn' }">
									<label for="inputPassword">Password*</label>
									<input type="password" v-model.trim="$v.step4form.passwd.$model" :name="step4method === 'methodRegister' ? 'passwd' : 'password'" placeholder="Enter a password" id="inputPassword" required :class="{ 'form-control': true, 'is-invalid': $v.step4form.passwd.$dirty && $v.step4form.passwd.$error }" />
									<div class="invalid-feedback" v-if="$v.step4form.passwd.$dirty && !$v.step4form.passwd.required">Password is required.</div>
									<div class="invalid-feedback" v-if="$v.step4form.passwd.$dirty && !$v.step4form.passwd.minLength">Password has a minimum of 6 characters.</div>
								</div>
								<div v-if="step4method === 'methodRegister'" class="form-group col-md-3">
									<label for="inputReenterPassword">Re-enter Password*</label>
									<input type="password" v-model.trim="$v.step4form.passwd_confirm.$model" name="passwd_confirm" placeholder="Re-enter your password" id="inputReenterPassword" :class="{ 'form-control': true, 'is-invalid': $v.step4form.passwd_confirm.$dirty && $v.step4form.passwd_confirm.$error }" />
									<div class="invalid-feedback" v-if="$v.step4form.passwd_confirm.$dirty && !$v.step4form.passwd_confirm.sameAsPasswd">Password re-entry has to match.</div>
								</div>
							</div>
							<div class="text-center my-3">
								<h5 class="card-title">Based on Your Feedback...</h5>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="inputResearchObjectives">What Are Your Objectives?</label>
									<multiselect v-model="step1values" :options="step1cards" label="title" track-by="title" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select all that apply..." :showLabels="false" id="inputResearchObjectives" />
									<select multiple name="research_objectives[]" style="display: none;">
										<option v-for="option in step1values" :value="option.title" selected>{{ option.title }}</option>
									</select>
								</div>
								<div class="form-group col-md-6">
									<label for="inputTargetMarkets">What are the target markets?</label>
									<multiselect v-model="step2values.field1" :options="step2options.field1" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select all that apply..." :showLabels="false" id="inputTargetMarkets" />
									<select multiple name="target_markets[]" style="display: none;">
										<option v-for="option in step2values.field1" :value="option" selected>{{ option }}</option>
									</select>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="inputTargetAudience">Who is the target audience?</label>
									<multiselect v-model="step2values.field2" :options="step2options.field2" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select all that apply..." :showLabels="false" id="inputTargetAudience" />
									<select multiple name="target_audience[]" style="display: none;">
										<option v-for="option in step2values.field2" :value="option" selected>{{ option }}</option>
									</select>
								</div>
								<div class="form-group col-md-6">
									<label for="inputTargetSampleSize">What is your target sample size?</label>
									<b-form-select
										id="inputTargetSampleSize"
										v-model="step2values.field3"
										:options="step2options.field3"
										name="target_sample_size"
									>
										<template #first>
											<b-form-select-option :value="null" disabled>Select option...</b-form-select-option>
										</template>
									</b-form-select>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="inputDataDelay">How quickly do you need the data?</label>
									<b-form-select
										id="inputDataDelay"
										v-model="step2values.field4"
										:options="step2options.field4"
										name="data_delay"
									>
										<template #first>
											<b-form-select-option :value="null" disabled>Select option...</b-form-select-option>
										</template>
									</b-form-select>
								</div>
								<div class="form-group col-md-6">
									<label for="inputResearchOption">Which research option is right for you?</label>
									<b-form-select
										id="inputResearchOption"
										v-model="step3value"
										:options="step3options"
										name="research_option"
									>
										<template #first>
											<b-form-select-option :value="null" disabled>Select option...</b-form-select-option>
										</template>
									</b-form-select>
								</div>
							</div>
							<div class="text-center my-3">
								<button type="submit" class="btn btn-success" :disabled="!isMounted || isSubmitting">Submit</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
		<footer-section />
		<request-custom-quote />
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import { BCard, BCardHeader, BCardBody, BCardText, BButton, BCollapse, BForm, BFormGroup, BFormSelect, BFormSelectOption, VBToggle } from 'bootstrap-vue'

import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

import Navbar from './Navbar'
import FooterSection from './FooterSection'
import RequestCustomQuote from './modals/RequestCustomQuote'

export default {
	name: 'PrimaryResearch',
	metaInfo: {
		title: 'Primary Research',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/primary-research',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/primary-research-share.jpg',
				vmid: 'og:image'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/primary-research',
				vmid: 'canonical'
			}
		]
	},
	asyncData({ store }) {
		return store.dispatch('fetchCompanyNames')
	},
	data() {
		return {
			csrfToken: '',
			gRecaptchaToken: '',
			isMounted: false,
			unsubscribe: null,
			isSubmitting: false,
			companyName: '',
			step1cards: [
				{
					icon: 'primary-research-target-practice-profile',
					title: 'Target Practice Profile',
					content: 'What types of practices are most likely to embrace our product? How does this change across international markets?'
				},
				{
					icon: 'primary-research-price-sensitivity',
					title: 'Price Sensitivity',
					content: 'What are providers and/or patients willing to pay for a new feature or technology? Is reimbursement a requirement? Should it be an upfront cost or per-use fee?'
				},
				{
					icon: 'primary-research-product-development',
					title: 'Product Development',
					content: 'What next generation technologies do providers want to see? How should we prioritize feature sets to meet market needs?'
				},
				{
					icon: 'primary-research-patient-outcomes',
					title: 'Patient Outcomes',
					content: 'What are the desired outcomes that providers would expect from your product? How many years of clinical data do they want to evaluate?'
				},
				{
					icon: 'primary-research-marketing-message',
					title: 'Marketing Message',
					content: 'What approach would resonate most with providers? Can they translate this as a value add to the patients?'
				},
				{
					icon: 'primary-research-product-adoption-curve',
					title: 'Product Adoption Curve',
					content: 'How quickly can we expect providers to embrace our product? How do we reach greater mass beyond Key Opinion Leaders (KOLs) and early adopters?'
				}
			],
			step1values: [],
			step2values: {
				field1: [],
				field2: [],
				field3: null,
				field4: null
			},
			step2options: {
				field1: ['United States', 'Western Europe', 'Rest of World'],
				field2: ['Cataract Surgeons', 'Refractive/Cornea Surgeons', 'Glaucoma Surgeons', 'Retina Surgeons', 'Comprehensive Ophthalmologists', 'Oculoplastic Surgeons', 'Optometrists', 'Other Not Listed'],
				field3: [
					{
						value: '0-5',
						text: '0-5'
					},
					{
						value: '6-15',
						text: '6-15'
					},
					{
						value: '15-30',
						text: '15-30'
					},
					{
						value: '30-50',
						text: '30-50'
					},
					{
						value: '50-100+',
						text: '50-100+'
					}
				],
				field4: [
					{
						value: 'ASAP!',
						text: 'ASAP!'
					},
					{
						value: '2-4 Weeks',
						text: '2-4 Weeks'
					},
					{
						value: '4-6 Weeks',
						text: '4-6 Weeks'
					},
					{
						value: '6 Weeks+',
						text: '6 Weeks+'
					}
				]
			},
			step3value: 'Online Surveys',
			step3options: [
				{
					value: 'Online Surveys',
					text: 'Online Surveys'
				},
				{
					value: 'Advisory Boards',
					text: 'Advisory Boards'
				},
				{
					value: 'In-Depths Interviews (IDIs)',
					text: 'In-Depths Interviews (IDIs)'
				}
			],
			step4method: 'methodRegister',
			step4form: {
				email: '',
				full_name: '',
				passwd: '',
				passwd_confirm: ''
			}
		}
	},
	computed: {
		companyNames() {
			return this.$store.state.companyNames
		}
	},
	validations() {
		const validations = {
			companyName: {
				required
			},
			step4form: {
				full_name: {
					required,
					minLength: minLength(3)
				},
				email: {
					required,
					email
				},
				passwd: {
					required,
					minLength: minLength(6)
				},
				passwd_confirm: {
					sameAsPasswd: sameAs('passwd')
				}
			}
		}
		
		return validations
	},
	mounted() {
		this.isMounted = true
		
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'SET_INVISIBLE_RECAPTCHA' && state.invisibleRecaptcha.originComponent === this.$options.name) {
				this.recaptchaCallback(state.invisibleRecaptcha.token);
				
				this.unsubscribe()
			}
		})
	},
	beforeDestroy() {
		typeof this.unsubscribe === 'function' && this.unsubscribe()
	},
	watch: {
		'step2values.field4' (to) {
			switch (to) {
				case 'ASAP!':
				case '2-4 Weeks':
					this.step3value = 'Advisory Boards'
					break;
				case '4-6 Weeks':
					this.step3value = 'Online Surveys'
					break;
				case '6 Weeks+':
					this.step3value = 'In-Depths Interviews (IDIs)'
					break;
				default:
			}
		}
	},
	methods: {
		selectCompany(company) {
			this.$refs.corporate_company_id.value = company.id
			this.$refs.corporate_company_name.value = ''
		},
		deselectCompany() {
			this.$refs.corporate_company_id.value = ''
			this.$refs.corporate_company_name.value = this.companyName
		},
		submit() {
			this.$v.$touch()
			
			if (!this.$v.$invalid) {
				this.isSubmitting = true
				
				this.$root.$children[0].$refs.invisibleRecaptcha.execute(this.$options.name)
			}
		},
		recaptchaCallback(token) {
			this.gRecaptchaToken = token
			
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.corporateRegistrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		},
		login() {
			return new Promise((res, rej) => {
				this.$http.get('func/csrf-token')
					.then(response => {
						this.csrfToken = response.data.result.csrfToken
						
						this.$nextTick(function () {
							this.$refs.corporateRegistrationForm.submit()
							
							setTimeout(res, 5000)
						})
					})
			})
		},
		toggleCard(card) {
			if (!this.step1values.some(v => v.title === card.title)) {
				this.step1values.push(card)
			} else {
				this.step1values.splice(this.step1values.findIndex(v => v.title === card.title), 1)
			}
		},
		showRequestCustomQuoteModal() {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Primary Research', 'Opened Modal', '"Request a Custom Quote" Modal'])
			
			this.$modal.show('requestCustomQuoteModal')
		}
	},
	components: {
		Multiselect,
		VueBootstrapTypeahead,
		BCard,
		BCardHeader,
		BCardBody,
		BCardText,
		BButton,
		BCollapse,
		BForm,
		BFormGroup,
		BFormSelect,
		BFormSelectOption,
		Navbar,
		FooterSection,
		RequestCustomQuote
	},
	directives: {
		'b-toggle': VBToggle
	}
}
</script>

<style lang="scss">
.accordion {
	.card {
		/* border-color: #195e8c; */
		
		.btn.focus,
		.btn:focus {
			box-shadow: none;
		}
		
		span {
			text-transform: none;
			color: #0a5384;
		}
	}
}

.accordion .collapsed {
	background-color: #0a5384;
	
	span {
		color: #fff;
	}
	
	> .when-closed {
		color: #fff;
	}
}

.accordion .collapsed > .when-open,
.accordion .not-collapsed > .when-closed {
	display: none;
}

header.card-header {
	background-color: #fff;
	border-bottom: none;
}

.cs.step-2 {
	.form-group label {
		color: #0a5384;
	}
}
</style>

<style lang="scss" scoped>
.breadcrumb {
	background-color: #f8f9fa;
}

header.cs-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 468px;
	
	background: url('~@assets/img/primary-research/primary-research-header-bg.jpg'), $theme-secondary;
	background: url('~@assets/img/primary-research/primary-research-header-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: 65%;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
		background-position-x: right;
	}
	
	> h1 {
		position: absolute;
		top: 242px;
		color: #fff;
		width: 100%;
		text-align: center;
		font-weight: 400;
	}
}

section.cs {
	padding: 50px 0;
	
	.section-heading {
		color: #0a5384;
		margin-bottom: 8px;
	}
	
	h3 {
		color: #0a5384;
		font-weight: 600;
	}
	
	h4 {
		color: #0a5384;
		font-size: 1.2rem;
		font-weight: 500;
	}
	
	h6 {
		color: #28a745;
		text-transform: uppercase;
		font-size: .9rem;
		font-weight: bold;
	}
	
	p {
		color: #777;
		font-size: 1rem;
	}
	
	.btn-success,
	.btn-success-outline {
		width: 210px;
	}
}

section.cs.about-us {
	background-color: #f8f9fa;
	padding-top: 30px;
}

section.cs.step-1 {
	.card {
		width: 18rem;
		height: 9rem;
		border-radius: 1rem !important;
		background-color: rgb(27, 141, 170);
		
		h5.card-title {
			font-weight: 500;
		}
		
		.card-overlay {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: #fff;
			border-radius: 1rem !important;
			overflow: hidden;
			width: 100%;
			height: 0;
			transition: .3s ease;
			
			h5.card-title {
				color: #0a5384;
				padding-top: 0;
				padding-bottom: 0.5rem;
				font-size: 1.1rem;
				font-weight: 500;
			}
			
			p.card-text {
				color: #0a5384;
				padding: 0 1rem 0;
				font-size: 0.8rem;
			}
		}
		
		.card-body:hover .card-overlay {
			height: 100%;
		}
		
		.card-selected {
			position: absolute;
			top: 0;
			z-index: 1;
			opacity: 0;
			transform: translate(0px, 70px);
			transition: all 0.2s ease-in-out;
			
			.fa-stack {
				line-height: 2.1em;
				
				i.fa-check {
					color: #28a745;
					font-size: 1.3rem;
				}
			}
			
			&.card-selected-slide {
				transform: translate(0px, 0px);
				opacity: 1;
			}
		}
	}
}

section.cs.step-2 {
	width: 100%;
	height: 500px;
	margin-bottom: 50px;
	
	background: url('~@assets/img/primary-research/primary-research-step-2-bg.jpg'), $theme-secondary;
	background: url('~@assets/img/primary-research/primary-research-step-2-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: right;
}

section.cs.step-3 {
	margin-bottom: -400px;
	padding-top: 70px;
	
	.card-body {
		h5 {
			font-weight: 600;
		}
		
		ul {
			list-style-type: none;
			padding-left: 0;
		}
		
		li {
			background-repeat: no-repeat;
			line-height: 25px;
			padding-left: 35px;
			padding-bottom: 5px;
			color: #555;
		}
	}
	
	.step-3-choice-1 {
		h5 {
			color: #0a5384;
			
			small {
				color: #555;
			}
		}
		
		h6 {
			color: #0a5384;
		}
		
		.card-body li {
			background-image: url('~@assets/img/primary-research/primary-research-blue-circle-checkmark.svg');
		}
	}
	
	.step-3-choice-2 {
		h5 {
			color: #3995a3;
			
			small {
				color: #555;
			}
		}
		
		h6 {
			color: #3995a3;
		}
		
		.card-body li {
			background-image: url('~@assets/img/primary-research/primary-research-teal-circle-checkmark.svg');
		}
	}
	
	.step-3-choice-3 {
		h5 {
			color: #2ba847;
			
			small {
				color: #555;
			}
		}
		
		h6 {
			color: #2ba847;
		}
		
		.card-body li {
			background-image: url('~@assets/img/primary-research/primary-research-green-circle-checkmark.svg');
		}
	}
}

section.cs.step-4 {
	width: 100%;
	padding-top: 400px;
	margin-bottom: 0;
	
	background: url('~@assets/img/primary-research/primary-research-step-3-bg.jpg'), #1681a1;
	background-position-x: left;
	background-position-y: top;
	background-repeat: no-repeat;
	
	h5.card-title {
		color: #0a5384;
		font-size: 1.4rem;
		font-weight: 600;
	}
	
	label {
		color: #0a5384;
		font-weight: bold;
	}
	
	h3, p {
		color: #fff;
	}
}
</style>