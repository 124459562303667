import Vue from 'vue'
import https from 'https'
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import VueAxios from 'vue-axios'
import Vue2Filters from 'vue2-filters'
import VueCookies from 'vue-cookies'
import VuexFlash from 'vuex-flash'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal/dist/ssr.nocss'
import VueScrollTo from 'vue-scrollto'
import VuePromiseBtn from 'vue-promise-btn'
import VueLazyload from 'vue-lazyload'
import LandingApp from './LandingApp'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'
import * as filters from './util/filters'

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

const cache = setupCache({
	maxAge: 15 * 60 * 1000, // 15 minutes
	exclude: {
		query: false
	},
	key: req => req.url + JSON.stringify(req.params)
	//debug: process.browser ? true : false
})

const host = process.env.HOST || 'localhost'
const axiosInstance = axios.create({
	timeout: 60000,
	baseURL: `https://${host}/api/`,
	httpsAgent: new https.Agent({
		rejectUnauthorized: false,
		keepAlive: true
	}),
	headers: {
		'X-Requested-With': 'XMLHttpRequest'
	},
	adapter: typeof window !== 'undefined' ? cache.adapter : null
})

Vue.use(VueAxios, axiosInstance)
Vue.use(Vue2Filters)
Vue.use(VueCookies)
Vue.use(VuexFlash, {
	mixin: true,
	duration: 5000
})
Vue.use(Vuelidate)
Vue.use(VModal)
Vue.use(VueScrollTo, {
	duration: 1000,
	lazy: false,
	offset: () => {
		return -Math.abs(document.getElementById('mainNav').offsetHeight)
	}
})
Vue.use(VuePromiseBtn)
Vue.use(VueLazyload, {
	observer: true
})

Vue.config.productionTip = false

export function createApp() {
	const store = createStore()
	const router = createRouter(store)
	
	sync(store, router)
	
	const app = new Vue({
		router,
		store,
		render: h => h(LandingApp),
		methods: {
			showLoginModal(e) {
				e.preventDefault()
				
				this.$modal.show('loginModal')
			},
			scrollToRegister(e) {
				e.preventDefault()
				
				this.$scrollTo('#register')
			}
		}
	})
	
	if (typeof window !== 'undefined') {
		window.landingApp = app
	}
	
	return { app, router, store }
}