import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=3e55aabd&scoped=true"
import script from "./Article.vue?vue&type=script&lang=js"
export * from "./Article.vue?vue&type=script&lang=js"
import style0 from "./Article.vue?vue&type=style&index=0&id=3e55aabd&lang=scss"
import style1 from "./Article.vue?vue&type=style&index=1&id=3e55aabd&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e55aabd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/staging.market-scope.com/front/landing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e55aabd')) {
      api.createRecord('3e55aabd', component.options)
    } else {
      api.reload('3e55aabd', component.options)
    }
    module.hot.accept("./Article.vue?vue&type=template&id=3e55aabd&scoped=true", function () {
      api.rerender('3e55aabd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/modals/Article.vue"
export default component.exports