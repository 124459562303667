<template>
	<section v-if="products.length" class="bio authored-reports" :style="componentStyle">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h3 :class="headerClasses" :style="{ fontSize: headerFontSize }">{{ headerText }}</h3>
				</div>
			</div>
			<div :class="{ row: true, 'justify-content-center': true, ['px-' + rowPadding]: true }">
				<div :class="{ ['col-md-' + 12/cardsPerRowMd]: true, ['col-lg-' + 12/cardsPerRowLg]: true, ['col-xl-' + 12/cardsPerRowXl]: true, 'p-2': true }" v-for="product in products">
					<div class="card border-0 rounded-0 overflow-hidden text-white">
						<img v-if="product.cover_img" class="card-img py-4 px-5" v-lazy="product.cover_img" :alt="product.cover_img_alt ? product.cover_img_alt : product.label" />
						<div class="card-img-overlay text-center">
							<h5 v-if="product.product_skus.length" class="card-title" :style="{ fontSize: labelFontSize }">{{ product.product_type_attributes[0]._joinData.value }}</h5>
							<h5 v-else-if="product.product_type.plans.length" class="card-title" :style="{ fontSize: labelFontSize }">{{ product.label }}</h5>
							<router-link :to="{ name: 'Report', params: { id: product.id, slug: product.slug }, hash: '#reports' }" class="btn btn-sm btn-success py-2 px-3 font-weight-bold">VIEW REPORT</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'AuthoredProducts',
	props: {
		authorId: {
			type: Number,
			required: true
		},
		productId: Number,
		cardsPerRowMd: {
			type: Number,
			default: 2
		},
		cardsPerRowLg: {
			type: Number,
			default: 3
		},
		cardsPerRowXl: {
			type: Number,
			default: 4
		},
		componentStyle: {
			type: Object,
			default: () => ({})
		},
		headerText: {
			type: String,
			default: 'Authored Market Reports'
		},
		headerClasses: {
			type: Array,
			default: () => (['mt-4', 'mb-5'])
		},
		headerFontSize: {
			type: String,
			default: '40px'
		},
		labelFontSize: {
			type: String,
			default: '1.25rem'
		},
		rowPadding: {
			type: Number,
			default: 5
		}
	},
	computed: {
		products() {
			if (this.authorId) {
				return this.$store.state.products.filter(product => {
					if (this.productId) {
						return product.authors.length && product.authors.some(author => author.uid === this.authorId && product.id !== this.productId)
					} else {
						return product.authors.length && product.authors.some(author => author.uid === this.authorId)
					}
				})
			}
			
			return []
		}
	},
	mounted() {
		this.$store.dispatch('fetchProducts', {
			limit: null,
			page: null,
			sort_type: null
		})
	}
}
</script>

<style lang="scss" scoped>
section.bio.authored-reports {
	background-color: #f1f2f3;
	
	h3 {
		color: #0a5384;
		font-weight: 500;
	}
	
	.card {
		background-color: #229eb6;
		
		.card-img-overlay {
			top: auto;
			padding-top: 50%;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #0a5384 70%);
			
			h5 {
				font-weight: 500;
				text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
			}
		}
	}
}
</style>