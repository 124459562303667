var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { media: true, "p-3": true, locked: !_vm.source.content } },
    [
      _c("router-link", {
        attrs: {
          to: {
            name: "Article",
            params: {
              id: _vm.source.id,
              slug: _vm.source.slug,
              offset: _vm.$parent.$parent.getOffset(),
            },
          },
        },
      }),
      _vm._v(" "),
      _vm.source.preview_img_url
        ? _c("img", {
            staticClass: "mr-3 img-thumbnail",
            attrs: {
              src: _vm.source.preview_img_url,
              alt: _vm.source.title.trim(),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "media-body" }, [
        _c("h5", { staticClass: "mt-0 mb-1" }, [
          _vm._v(_vm._s(_vm.source.title)),
        ]),
        _vm._v(" "),
        _c("p", {
          staticClass: "card-text",
          domProps: { innerHTML: _vm._s(_vm.source.content_preview) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }