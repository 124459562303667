<template>
	<div>
		<navbar :subpage="true" />
		<header class="bio-masthead w-100">
			<div class="row no-gutters">
				<div class="col-md-6 d-flex align-items-center justify-content-center p-5 bio-info">
					<div>
						<h1>Dave Harmon</h1>
						<h3 class="my-4 font-weight-bold text-uppercase">Founder, Chairman, &amp; Chief Financial Officer</h3>
						<h4>Phone: <a href="tel:+13148350600">314-835-0600</a></h4>
						<h4>Email: <a href="mailto:dave@market-scope.com">dave@market-scope.com</a></h4>
						<a href="https://www.linkedin.com/in/david-harmon-b0071/" target="_blank"><img v-lazy="require('@assets/img/about/bios/LinkedIn-Icon.svg')" class="d-block" /></a>
					</div>
				</div>
				<div class="col-md-6 d-flex align-items-center justify-content-end bio-headshot">
					<img v-lazy="require('@assets/img/about/headshot-10.jpg')" class="img-fluid" />
				</div>
			</div>
		</header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'About' }" exact>About</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Dave Harmon</span>
			</li>
		</ol>
		<section class="bio content">
			<div class="container">
				<div class="row px-5">
					<div class="col">
						<div class="mx-md-5">
							<h4 class="mt-3 mb-4">David Harmon has more than 40 years of experience in the medical technology industry, and he has been surveying, analyzing, consulting, and writing reports on the medical device industry since 1996.</h4>
							<p>Through this extensive experience and his contacts in the medical industry, Dave has become an expert in the industry’s business and technical aspects.</p>
							<p>Dave’s medical technology market experience began in 1984 at Alcon Laboratories, where he served in several capacities, including manager of business planning and acquisitions for the Surgical Division for three years, and general manager of the Alcon Systems Division for five years. His educational background includes a bachelor of science in business administration with an accounting major from Texas Tech University and a master of science in finance from the University of Texas at Dallas.</p>
							<p>Dave has retired from the day-to-day operations of Market Scope, but he is still the firm’s primary shareholder, and serves as chairman and chief financial officer.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<authored-reports :authorId="105" />
		<combined-registration origin="bio" />
		<footer-section />
	</div>
</template>

<script>
import Navbar from '../Navbar'
import AuthoredReports from '../AuthoredReports'
import CombinedRegistration from '../forms/CombinedRegistration'
import FooterSection from '../FooterSection'

export default {
	name: 'DaveHarmon',
	metaInfo: {
		title: 'About: Dave Harmon',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/pages/about/dave-harmon',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/headshot-10.jpg',
				vmid: 'og:image'
			},
			{
				name: 'title',
				property: 'og:title',
				content: 'About: Dave Harmon',
				template: content => `${content} | Market Scope`,
				vmid: 'og:title'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/pages/about/dave-harmon',
				vmid: 'canonical'
			}
		]
	},
	components: {
		Navbar,
		AuthoredReports,
		CombinedRegistration,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.bio-masthead {
	position: relative;
	overflow: hidden;
	
	.row {
		padding-top: 81px; /* change to auto-adjust based on navbar height */
		
		.bio-info {
			background-color: #0a5384;
			padding-top: 4rem !important;
			
			> div {
				h1,
				h3,
				h4 {
					color: #fff;
				}
				
				h1 {
					font-size: 50px;
					font-weight: 400;
				}
				
				h3 {
					font-size: 18px;
				}
				
				h4 {
					font-size: 20px;
				}
			}
		}
		
		.bio-headshot {
			background-color: #0a5384;
		}
	}
	
	@media (min-width: 482px) {
		.row .bio-info {
			padding-top: 3rem !important;
		}
	}
}

section.bio.content {
	h4 {
		font-size: 30px;
		color: #0a5384;
		font-weight: 500;
	}
	
	p {
		font-size: 18px;
		color: #777;
	}
}
</style>