var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-navbar",
        {
          ref: "mainNav",
          staticClass: "subpage",
          attrs: { toggleable: "lg", fixed: "top", id: "mainNav" },
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav_collapse" } }),
          _vm._v(" "),
          _c("b-navbar-brand", { attrs: { to: "/" } }, [
            _vm._v("Market Scope"),
          ]),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "flex-row" },
            [
              _c(
                "b-nav-item",
                {
                  staticClass: "align-self-center login-link",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.campaign
                        ? _vm.$modal.show("campaignLoginModal", {})
                        : _vm.$modal.show("loginModal")
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-lock" }), _vm._v(" Sign In")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { "is-nav": "", id: "nav_collapse" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item-dropdown",
                    {
                      ref: "navDropdown1",
                      staticClass: "full-width",
                      attrs: { text: "Products" },
                      on: {
                        shown: function ($event) {
                          return _vm.alignNavItems("navDropdown1")
                        },
                      },
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/pages/reports?page=1", exact: "" } },
                        [_vm._v("Global & Regional Market Reports")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { to: "/ophthalmic-market-trends", exact: "" },
                        },
                        [_vm._v("Ophthalmic Market Trends")]
                      ),
                      _vm._v(" "),
                      _c("b-dropdown-item", { attrs: { to: "/atlases" } }, [
                        _vm._v("Atlases"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/disease-models", exact: "" } },
                        [_vm._v("Disease Models")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/ophthalmic-newsletter", exact: "" } },
                        [_vm._v("Ophthalmic Newsletter")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/pages/reports/deals?page=1",
                            exact: "",
                          },
                        },
                        [_vm._v("Ophthalmic Deals Analysis")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { to: "/pages/physicians" } }, [
                    _vm._v("Surveys"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    { attrs: { to: "/pages/primary-research" } },
                    [_vm._v("Primary Research")]
                  ),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { to: "/pages/news" } }, [
                    _vm._v("News"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      ref: "navDropdown2",
                      staticClass: "full-width plus-nav-dropdown",
                      attrs: { text: "Market Scope+" },
                      on: {
                        shown: function ($event) {
                          return _vm.alignNavItems("navDropdown2")
                        },
                      },
                    },
                    [
                      _c("b-dropdown-item", { attrs: { href: "#features" } }, [
                        _vm._v("Features"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "#screenshots" } },
                        [_vm._v("Screenshots")]
                      ),
                      _vm._v(" "),
                      _c("b-dropdown-item", { attrs: { href: "#contact" } }, [
                        _vm._v("Contact"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            href: "#download",
                            "link-class": "font-weight-bold",
                          },
                        },
                        [_vm._v("Download")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { to: "/pages/methodology" } }, [
                    _vm._v("Methodology"),
                  ]),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { to: "/pages/about" } }, [
                    _vm._v("About"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.campaign ? _c("campaign-login") : _c("login"),
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "no-spacing", attrs: { id: "home" } }, [
        _c("div", { staticClass: "circle1" }),
        _vm._v(" "),
        _c("div", { staticClass: "circle2" }),
        _vm._v(" "),
        _c("div", { staticClass: "circle3" }),
        _vm._v(" "),
        _c("div", { staticClass: "circle4" }),
        _vm._v(" "),
        _c("div", { staticClass: "banner-caption" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "hero-text" }, [
                  _c("h1", { staticClass: "text-nowrap animated fadeInLeft" }, [
                    _vm._v("Market Scope+"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "animated fadeInLeft" }, [
                    _vm._v(
                      "Market Scope+ is our new robust app that allows ophthalmic and optometric survey participants to easily participate in syndicated and sponsored surveys, review practice benchmark reports, weigh in with weekly polls on new technology adoption, and read the latest trending news in an ad-free environment."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "d-inline-block mr-0 mr-lg-3 mb-3 animated fadeInLeft",
                      attrs: {
                        href: "https://apps.apple.com/za/app/market-scope/id1642558540",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/plus/plus-apple-download.png"),
                            expression:
                              "require('@assets/img/plus/plus-apple-download.png')",
                          },
                        ],
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.$route.query.referral_code
                    ? _c(
                        "a",
                        {
                          staticClass: "d-inline-block animated fadeInLeft",
                          attrs: {
                            href:
                              "https://play.google.com/store/apps/details?id=com.marketscope.app&referrer=" +
                              encodeURIComponent(
                                "referral_code=" +
                                  _vm.$route.query.referral_code
                              ),
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("@assets/img/plus/plus-google-download.png"),
                                expression:
                                  "require('@assets/img/plus/plus-google-download.png')",
                              },
                            ],
                          }),
                        ]
                      )
                    : _vm.$route.query.access_code
                    ? _c(
                        "a",
                        {
                          staticClass: "d-inline-block animated fadeInLeft",
                          attrs: {
                            href:
                              "https://play.google.com/store/apps/details?id=com.marketscope.app&referrer=" +
                              encodeURIComponent(
                                "access_code=" + _vm.$route.query.access_code
                              ),
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("@assets/img/plus/plus-google-download.png"),
                                expression:
                                  "require('@assets/img/plus/plus-google-download.png')",
                              },
                            ],
                          }),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "d-inline-block animated fadeInLeft",
                          attrs: {
                            href: "https://play.google.com/store/apps/details?id=com.marketscope.app",
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("@assets/img/plus/plus-google-download.png"),
                                expression:
                                  "require('@assets/img/plus/plus-google-download.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "phone-mockup animated fadeInUp text-center d-none d-md-block",
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/plus/plus-banner-phone.png"),
                          expression:
                            "require('@assets/img/plus/plus-banner-phone.png')",
                        },
                      ],
                      ref: "phoneMockup",
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "section-spacing", attrs: { id: "features" } },
        [
          _c("div", { staticClass: "container" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "features-item text-center wow fadeIn",
                    attrs: { "data-wow-delay": "0.2s" },
                  },
                  [
                    _c("div", { staticClass: "icon" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/plus/plus-feature-1.png"),
                            expression:
                              "require('@assets/img/plus/plus-feature-1.png')",
                          },
                        ],
                      }),
                    ]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Complete Surveys")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Survey participation offers eye care providers a unique chance to measure practice performance against nationwide averages and helps guide companies on the needs of ophthalmologists and optometrists to guide product development, messaging, pricing, and access."
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "features-item text-center wow fadeIn",
                    attrs: { "data-wow-delay": "0.3s" },
                  },
                  [
                    _c("div", { staticClass: "icon" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/plus/plus-feature-2.png"),
                            expression:
                              "require('@assets/img/plus/plus-feature-2.png')",
                          },
                        ],
                      }),
                    ]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Stay Informed")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Gain access to the latest industry insights and stay up to date on market news and trends. We provide instant survey results that help you analyze practice patterns, evaluate treatment choices, and benchmark your practice, as well weekly news coverage to keep you updated on the latest in the ophthalmic industry."
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "features-item text-center wow fadeIn",
                    attrs: { "data-wow-delay": "0.4s" },
                  },
                  [
                    _c("div", { staticClass: "icon" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/plus/plus-feature-3.png"),
                            expression:
                              "require('@assets/img/plus/plus-feature-3.png')",
                          },
                        ],
                      }),
                    ]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Earn Incentives")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Participants in most surveys have the option of an e-gift card sent to them or a charitable donation made in their name. Past recipients include Orbis International, ASCRS Foundation, Glaucoma 360, Doctors Without Borders, and the Houston Food Bank. We realize your time is valuable, and we offer these incentives to show our appreciation for the time you spend participating."
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-6 col-lg-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "features-item text-center wow fadeIn",
                    attrs: { "data-wow-delay": "0.5s" },
                  },
                  [
                    _c("div", { staticClass: "icon" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/plus/plus-feature-4.png"),
                            expression:
                              "require('@assets/img/plus/plus-feature-4.png')",
                          },
                        ],
                      }),
                    ]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Secure")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Our business is built on the privacy and trust of our participants. Market Scope reports only aggregated data in our survey reports that are shared with participants, and your individual responses will always be de-identified before summary analysis to safeguard your anonymity."
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "section-spacing white-bg",
          attrs: { id: "screenshots" },
        },
        [
          _c("div", { staticClass: "container" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6 col-md-3 p-3 text-center" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showModal(0)
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/plus/plus-screenshot-survey.png"),
                          expression:
                            "require('@assets/img/plus/plus-screenshot-survey.png')",
                        },
                      ],
                      staticClass: "img-fluid",
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-3 p-3 text-center" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showModal(1)
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/plus/plus-screenshot-results.png"),
                          expression:
                            "require('@assets/img/plus/plus-screenshot-results.png')",
                        },
                      ],
                      staticClass: "img-fluid",
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-3 p-3 text-center" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showModal(2)
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/plus/plus-screenshot-polls.png"),
                          expression:
                            "require('@assets/img/plus/plus-screenshot-polls.png')",
                        },
                      ],
                      staticClass: "img-fluid",
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-3 p-3 text-center" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showModal(3)
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/plus/plus-screenshot-news.png"),
                          expression:
                            "require('@assets/img/plus/plus-screenshot-news.png')",
                        },
                      ],
                      staticClass: "img-fluid",
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "section-spacing", attrs: { id: "download" } },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "download-img text-md-left text-center wow fadeIn",
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: require("@assets/img/plus/plus-download.png"),
                          expression:
                            "require('@assets/img/plus/plus-download.png')",
                        },
                      ],
                      staticClass: "img-fluid",
                      attrs: { alt: "Download" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 my-auto pl-4" }, [
                _c("div", { staticClass: "download-text wow fadeIn" }, [
                  _c("h2", [_vm._v("Get Started with Market Scope+")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Market Scope+ is available for iOS and Android users on the App Store and Google Play."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "d-inline-block mr-0 mr-lg-3 mb-3",
                      attrs: {
                        href: "https://apps.apple.com/za/app/market-scope/id1642558540",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/plus/plus-apple-download.png"),
                            expression:
                              "require('@assets/img/plus/plus-apple-download.png')",
                          },
                        ],
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.$route.query.referral_code
                    ? _c(
                        "a",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            href:
                              "https://play.google.com/store/apps/details?id=com.marketscope.app&referrer=" +
                              encodeURIComponent(
                                "referral_code=" +
                                  _vm.$route.query.referral_code
                              ),
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("@assets/img/plus/plus-google-download.png"),
                                expression:
                                  "require('@assets/img/plus/plus-google-download.png')",
                              },
                            ],
                          }),
                        ]
                      )
                    : _vm.$route.query.access_code
                    ? _c(
                        "a",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            href:
                              "https://play.google.com/store/apps/details?id=com.marketscope.app&referrer=" +
                              encodeURIComponent(
                                "access_code=" + _vm.$route.query.access_code
                              ),
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("@assets/img/plus/plus-google-download.png"),
                                expression:
                                  "require('@assets/img/plus/plus-google-download.png')",
                              },
                            ],
                          }),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            href: "https://play.google.com/store/apps/details?id=com.marketscope.app",
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: require("@assets/img/plus/plus-google-download.png"),
                                expression:
                                  "require('@assets/img/plus/plus-google-download.png')",
                              },
                            ],
                          }),
                        ]
                      ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "section-spacing white-bg", attrs: { id: "contact" } },
        [
          _c("div", { staticClass: "container" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-8 offset-md-2" }, [
                _c(
                  "form",
                  {
                    directives: [
                      {
                        name: "promise-btn",
                        rawName: "v-promise-btn",
                        value: { action: "submit" },
                        expression: "{ action: 'submit' }",
                      },
                    ],
                    staticClass: "contact-form wow zoomIn",
                    attrs: { novalidate: "novalidate", role: "form" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.send.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.firstName,
                                expression: "form.firstName",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              placeholder: "First Name",
                              id: "fname",
                              name: "fname",
                              type: "text",
                              required: "",
                              "data-error": "Please enter your first name",
                            },
                            domProps: { value: _vm.form.firstName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "firstName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "help-block with-errors" }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.lastName,
                                expression: "form.lastName",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              placeholder: "Last Name",
                              id: "lname",
                              name: "lname",
                              type: "text",
                            },
                            domProps: { value: _vm.form.lastName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "lastName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Email Address",
                          id: "email",
                          name: "email",
                          type: "email",
                          required: "",
                          "data-error": "Please enter your valid email address",
                        },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "email", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "help-block with-errors" }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phone,
                            expression: "form.phone",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Phone # (optional)",
                          id: "phone",
                          name: "phone",
                          type: "tel",
                        },
                        domProps: { value: _vm.form.phone },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "phone", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.practiceOrCompany,
                            expression: "form.practiceOrCompany",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Practice/Company Name",
                          id: "cname",
                          name: "cname",
                          type: "text",
                          required: "",
                          "data-error":
                            "Please enter your valid practice or copmany name",
                        },
                        domProps: { value: _vm.form.practiceOrCompany },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "practiceOrCompany",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "help-block with-errors" }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.comments,
                            expression: "form.comments",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Your Comments",
                          id: "message",
                          cols: "20",
                          rows: "5",
                          required: "",
                          "data-error": "Please enter your comments",
                        },
                        domProps: { value: _vm.form.comments },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "comments", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "help-block with-errors" }),
                    ]),
                    _vm._v(" "),
                    _vm._m(4),
                    _vm._v(" "),
                    _vm.status
                      ? _c("div", {
                          staticClass: "mx-auto mb-3",
                          attrs: { id: "msgSubmit" },
                          domProps: { innerHTML: _vm._s(_vm.status) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("Send Message")]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "footer-logo text-center" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _vm._v("Market Scope"),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "copyright-text text-center" }, [
                _c("p", [
                  _vm._v(
                    "©" +
                      _vm._s(new Date().getFullYear()) +
                      " Market Scope, LLC. All Rights reserved."
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "screenshotsModal",
          attrs: { id: "screenshots-modal" },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "light" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "b-carousel",
            {
              attrs: {
                id: "screenshots-carousel",
                interval: 0,
                controls: "",
                background: "none",
              },
              model: {
                value: _vm.activeSlide,
                callback: function ($$v) {
                  _vm.activeSlide = $$v
                },
                expression: "activeSlide",
              },
            },
            [
              _c("b-carousel-slide", {
                attrs: {
                  "img-src": require("@assets/img/plus/plus-screenshot-survey.png"),
                },
              }),
              _vm._v(" "),
              _c("b-carousel-slide", {
                attrs: {
                  "img-src": require("@assets/img/plus/plus-screenshot-results.png"),
                },
              }),
              _vm._v(" "),
              _c("b-carousel-slide", {
                attrs: {
                  "img-src": require("@assets/img/plus/plus-screenshot-polls.png"),
                },
              }),
              _vm._v(" "),
              _c("b-carousel-slide", {
                attrs: {
                  "img-src": require("@assets/img/plus/plus-screenshot-news.png"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "preloader" }, [
      _c("div", { staticClass: "preloader_image" }, [
        _c("div", { staticClass: "sk-double-bounce" }, [
          _c("div", { staticClass: "sk-child sk-double-bounce1" }),
          _vm._v(" "),
          _c("div", { staticClass: "sk-child sk-double-bounce2" }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "section-title text-center" }, [
          _c("h2", [_vm._v("Main Features")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Market Scope+ is designed to offer eye care providers a secure platform to easily participate in surveys, stay informed on industry insights, and earn incentives."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "section-title text-center" }, [
          _c("h2", [_vm._v("Take a Look")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Market Scope+ is designed to be secure, efficient, and easy to use."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "section-title text-center" }, [
          _c("h2", [_vm._v("Contact Us")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Contact us using the form below if we can assist with any questions, difficulties, or concerns."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group mb-3" }, [
      _c("small", { staticClass: "form-notice" }, [
        _vm._v(
          "Any submitted contact information is confidential and will not be given to any third parties."
        ),
        _c("br"),
        _vm._v("This site is protected by reCAPTCHA and the Google "),
        _c(
          "a",
          {
            attrs: {
              href: "https://policies.google.com/privacy",
              target: "_blank",
            },
          },
          [_vm._v("Privacy Policy")]
        ),
        _vm._v(" and "),
        _c(
          "a",
          {
            attrs: {
              href: "https://policies.google.com/terms",
              target: "_blank",
            },
          },
          [_vm._v("Terms of Service")]
        ),
        _vm._v(" apply."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12" }, [
      _c("ul", { staticClass: "social-icons text-center" }, [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.linkedin.com/company/market-scope-llc/",
                target: "_blank",
              },
            },
            [_c("i", { staticClass: "fa-brands fa-linkedin" })]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://twitter.com/marketscope_llc",
                target: "_blank",
              },
            },
            [_c("i", { staticClass: "fa-brands fa-twitter" })]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "back-top" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("i", { staticClass: "fa fa-angle-up" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }