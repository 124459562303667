<template>
	<div>
		<navbar :subpage="true" />
		<header class="news-archive-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>News Archive</span>
			</li>
		</ol>
		<section class="news-archive py-0" id="news-archive">
			<div class="container header-row">
				<div>
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-08.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h1 v-if="$route.name === 'NewsArchive'" class="section-heading">More News</h1>
						<h2 v-else class="section-heading">More News</h2>
						<form @submit.prevent @keyup.esc="resetSearchQuery" class="container p-0 pt-4">
							<div class="input-group">
								<input type="text" @input="updateSearchQuery" ref="searchInput" class="form-control" placeholder="Filter by..." aria-label="Filter by..." :aria-describedby="searchQuery" />
								<div @click="resetSearchQuery" class="input-group-append">
									<div class="input-group-text bg-transparent" id="searchQuery"><i class="fa fa-times" aria-hidden="true"></i></div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="container articles-list">
				<div class="row">
					<virtual-list
						:estimate-size="100"
						:keeps="50"
						data-key="slug"
						:data-sources="articles"
						:data-component="articleComponent"
						class="virtual-list-wrapper"
						ref="vList"
					/>
				</div>
			</div>
		</section>
		<register :headerText="'Want to Read Locked Articles?'" />
		<footer-section />
	</div>
</template>

<script>
import debounce from 'lodash/debounce'
import VirtualList from 'vue-virtual-scroll-list'
import NewsArchiveArticle from './NewsArchiveArticle'
import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'

export default {
	name: 'NewsArchive',
	metaInfo() {
		if (this.$route.name === 'NewsArchive') {
			return {
				title: 'More Industry News & Trends',
				meta: [
					{
						property: 'og:title',
						content: 'More Industry News & Trends',
						template: content => `${content} | Market Scope`,
						vmid: 'og:title'
					},
					{
						name: 'image',
						property: 'og:image',
						content: 'https://www.market-scope.com/static/landing/img/news-front-bg.jpg',
						vmid: 'og:image'
					},
					{
						property: 'og:url',
						content: '//www.market-scope.com/pages/news-archive',
						vmid: 'og:url'
					}
				],
				link: [
					{
						rel: 'canonical',
						href: 'https://www.market-scope.com/pages/news-archive',
						vmid: 'canonical'
					}
				]
			}
		} else {
			return {}
		}
	},
	asyncData({ store }) {
		return store.dispatch('fetchArticles', {
			vertical: 'ophthalmic',
			limit: 200,
			start_pos: 24
		})
	},
	props: ['offset'],
	data() {
		return {
			searchQuery: '',
			articleComponent: NewsArchiveArticle
		}
	},
	computed: {
		articles() {
			if (this.searchQuery.length >= 3) {
				return this.$store.state.articles.filter(article => {
					let searchQuery = this.searchQuery.toLowerCase()
					return article.title.toLowerCase().includes(searchQuery) || article.content_teaser.toLowerCase().includes(searchQuery)
				})
			} else {
				return this.$store.state.articles
			}
		},
		vList() {
			return this.$refs.vList
		}
	},
	mounted() {
		if (this.offset) {
			this.vList.scrollToOffset(this.offset)
		}
	},
	methods: {
		updateSearchQuery: debounce(function (e) {
			this.searchQuery = e.target.value
		}, 500),
		resetSearchQuery() {
			this.$refs.searchInput.value = ''
			this.searchQuery = ''
		}
	},
	components: {
		VirtualList,
		Navbar,
		Register,
		FooterSection
	}
}
</script>

<style lang="scss" scoped>
header.news-archive-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: 40% 100% url('~@assets/img/news-front-bg.jpg'), $theme-secondary;
	background: 40% 100% url('~@assets/img/news-front-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	
	@media (min-width: 992px) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

section.news-archive {
	.section-heading {
		color: #0a5384;
	}
	
	.header-row {
		background-color: #fefeff;
		padding-top: 30px;
		padding-bottom: 20px;
		min-width: 100%;
		position: relative;
		overflow: hidden;
		
		.input-group-append:hover {
			cursor: pointer;
		}
	}
	
	.articles-list {
		background-color: #f4f5f7;
	}
}
</style>

<style lang="scss">
.virtual-list-wrapper {
	height: 500px;
	width: 100%;
	overflow-y: scroll;
	border-top: 1px solid #eee;
	border-left: 1px solid #eee;
}
</style>