var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "atlases-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "atlases", attrs: { id: "atlases" } }, [
        _c("div", { staticClass: "container header-row" }, [
          _c("div", [
            _c("div", { staticClass: "mx-auto text-center mb-5" }, [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/icons/Research-Icons-07.svg"),
                    expression:
                      "require('@assets/img/icons/Research-Icons-07.svg')",
                  },
                ],
                staticStyle: {
                  "max-width": "70px",
                  width: "auto",
                  height: "auto",
                },
                attrs: { width: "70", height: "70" },
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "section-heading" }, [
                _vm._v("Select your metro. Discover insights."),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "section-heading" }, [
                _c(
                  "span",
                  { staticClass: "d-inline-block align-middle mr-1" },
                  [_vm._v("Sponsored by")]
                ),
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/carecredit-logo.png"),
                      expression: "require('@assets/img/carecredit-logo.png')",
                    },
                  ],
                  staticStyle: { "max-height": "20px" },
                }),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container mt-3" }, [
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "card border-0" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("LazyYoutube", {
                      attrs: {
                        src: "https://youtu.be/qudiuT29veE",
                        showTitle: false,
                        customThumbnail:
                          "https://img.youtube.com/vi/qudiuT29veE/sddefault.jpg",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(3),
          ]),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "form",
                {
                  directives: [
                    {
                      name: "promise-btn",
                      rawName: "v-promise-btn",
                      value: { action: "submit" },
                      expression: "{ action: 'submit' }",
                    },
                  ],
                  attrs: { novalidate: "novalidate", role: "form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.request.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "name",
                          placeholder: "Name",
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group select col-md-6" },
                      [
                        _c("label", { attrs: { for: "areas_of_interest" } }, [
                          _vm._v("Clinical Areas of Interest"),
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.areasOfInterestOptions,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            placeholder: "Select one or more",
                          },
                          model: {
                            value: _vm.form.areas_of_interest,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "areas_of_interest", $$v)
                            },
                            expression: "form.areas_of_interest",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("label", { attrs: { for: "practice" } }, [
                        _vm._v("Practice Name"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.practice,
                            expression: "form.practice",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "practice",
                          placeholder: "Practice Name",
                        },
                        domProps: { value: _vm.form.practice },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "practice", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group select col-md-6" },
                      [
                        _c("label", { attrs: { for: "metros_of_interest" } }, [
                          _vm._v("Metros of Interest"),
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.metrosOfInterestOptions,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            placeholder: "Select one or more",
                          },
                          model: {
                            value: _vm.form.metros_of_interest,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "metros_of_interest", $$v)
                            },
                            expression: "form.metros_of_interest",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("label", { attrs: { for: "email" } }, [
                        _vm._v("Email"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          id: "email",
                          placeholder: "Email",
                        },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "email", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _vm._m(5),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phone,
                            expression: "form.phone",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "tel",
                          id: "phone",
                          placeholder: "Phone #",
                        },
                        domProps: { value: _vm.form.phone },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "phone", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col" }, [
                      _c("label", { attrs: { for: "comments" } }, [
                        _vm._v("Comments (optional)"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.comments,
                            expression: "form.comments",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "comments",
                          placeholder: "Comments",
                        },
                        domProps: { value: _vm.form.comments },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "comments", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm.form.status
                        ? _c("div", {
                            staticClass: "text-right mb-3",
                            domProps: { innerHTML: _vm._s(_vm.form.status) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.requested
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary float-right",
                              attrs: { type: "submit" },
                            },
                            [_vm._v("Submit")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(6),
        ]),
      ]),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("CareCredit Atlases")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { attrs: { id: "map" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("p", [
          _vm._v(
            "Market Scope’s United States ophthalmic atlases identify treatment opportunity at the metro level for the top 85 metros—identifying high-opportunity and diseased populations, procedure volumes, premium penetration rates, surgeon counts, surgical locations, and much more. Practices often use this data to identify opportunities for practice expansion, for consideration of new premium procedures, and to gain an overall understanding of their markets."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "This data is offered at a discount to CareCredit enrolled providers through the Engage portal."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "card border-0" }, [
          _c(
            "div",
            { staticClass: "card-header bg-transparent border-bottom-0" },
            [
              _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
                _vm._v("Provided by metro"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body pt-2" }, [
            _c("ul", [
              _c("li", [
                _c("strong", [
                  _vm._v("Identification of high-opportunity population"),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Procedure volumes")]),
                _vm._v(" by type"),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Surgeon profiles")]),
                _vm._v(" by volume category"),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("ASC profiles")]),
                _vm._v(": Counts and average procedure volumes"),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Demographic profiles")]),
                _vm._v(
                  ": Population by age group, median age, household income, and diseased population"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Much more")]),
                _vm._v(" varying by atlas subspecialty"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "card border-0" }, [
          _c(
            "div",
            { staticClass: "card-header bg-transparent border-bottom-0" },
            [
              _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
                _vm._v("Use cases"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body pt-2" }, [
            _c("ul", [
              _c("li", [_vm._v("Practice expansion opportunities")]),
              _vm._v(" "),
              _c("li", [_vm._v("Determining market potential")]),
              _vm._v(" "),
              _c("li", [_vm._v("Understanding of your metro")]),
              _vm._v(" "),
              _c("li", [_vm._v("Consideration of new premium procedures")]),
              _vm._v(" "),
              _c("li", [_vm._v("Identifying competition")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "card border-0" }, [
        _c(
          "div",
          { staticClass: "card-header bg-transparent border-bottom-0" },
          [
            _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
              _vm._v("Methodology"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body pt-2" }, [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(
              "Market Scope uses the proprietary MedOp Index™ Analysis to examine market potential and provides a benchmark against other metros. Each clinical area atlas considers multiple factors to identify opportunity. For example, the cataract MedOp Index™ considers the concentration of active, affluent seniors; the number of high-volume cataract surgeons; and ASC counts and volumes, while the retina MedOp Index™ considers annual IVT injections and vitrectomies per provider; the geographic concentration of diabetics; the total population with retinal diseases; and high-volume retinal care providers."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("p", { staticClass: "description" }, [
          _vm._v(
            "Through the Engage Portal, CareCredit enrolled providers can purchase any of the 85 metros identified on the above map from any clinical speciality for only $750—half of the standard price. To get started, please complete the form below, and Market Scope will be in touch with an official estimate."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "phone" } }, [
      _vm._v("Phone # "),
      _c("span", { staticClass: "text-muted" }, [_vm._v("(optional)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("p", { staticClass: "legal-notice" }, [
          _vm._v(
            "This content is subject to change without notice and offered for informational use only. You are urged to consult with your individual business, financial, legal, tax and/or other advisors with respect to any information presented. Synchrony Financial and any of its affiliates, including CareCredit, (collectively, “Synchrony”) makes no representations or warranties regarding this content and accept no liability for any loss or harm arising from the use of the information provided."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }