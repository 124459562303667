var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "cs about-us", attrs: { id: "cs" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-5 my-auto" }, [
              _c("h6", [_vm._v("About Us")]),
              _vm._v(" "),
              _c("h3", [_vm._v("Why use Market Scope?")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Market Scope is a market intelligence firm focused on ophthalmology. Since 1998, our reports and data have been regarded across the industry as unbiased and contextualized sources of truth in the ophthalmic market. Our experience and focus on the field sets us apart and gives us the ability to provide you with better data and insights, so you can make the best decisions more quickly."
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: { click: _vm.showRequestCustomQuoteModal },
                  },
                  [_vm._v("Request a Quote")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-7" }, [
              _c(
                "div",
                {
                  staticClass: "accordion shadow-lg bg-white rounded",
                  attrs: { role: "tablist" },
                },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-0",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-1",
                                  modifiers: { "accordion-1": true },
                                },
                              ],
                              staticClass: "d-flex p-3 rounded-0",
                              attrs: { block: "", variant: "transparent" },
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: require("@assets/img/primary-research/checkmark.svg"),
                                    expression:
                                      "require('@assets/img/primary-research/checkmark.svg')",
                                  },
                                ],
                                staticStyle: {
                                  "max-width": "25px",
                                  width: "auto",
                                  height: "auto",
                                },
                                attrs: { width: "25", height: "25" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ml-2 font-weight-bold" },
                                [_vm._v("Experience")]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-up ml-auto when-open",
                                staticStyle: { "line-height": "inherit" },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-down ml-auto when-closed",
                                staticStyle: { "line-height": "inherit" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-1",
                            visible: "",
                            accordion: "my-accordion",
                            role: "tabpanel",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "pt-0 pb-2" },
                            [
                              _c("b-card-text", [
                                _vm._v(
                                  "We have executed hundreds of primary research products exclusively in ophthalmology and optometry. We understand how to design the right initial and follow-up questions, whom to ask, and how to deliver a clear and actionable plan."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-0",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-2",
                                  modifiers: { "accordion-2": true },
                                },
                              ],
                              staticClass: "d-flex p-3 rounded-0",
                              attrs: { block: "", variant: "transparent" },
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: require("@assets/img/primary-research/checkmark.svg"),
                                    expression:
                                      "require('@assets/img/primary-research/checkmark.svg')",
                                  },
                                ],
                                staticStyle: {
                                  "max-width": "25px",
                                  width: "auto",
                                  height: "auto",
                                },
                                attrs: { width: "25", height: "25" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ml-2 font-weight-bold" },
                                [_vm._v("Industry Expertise")]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-up ml-auto when-open",
                                staticStyle: { "line-height": "inherit" },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-down ml-auto when-closed",
                                staticStyle: { "line-height": "inherit" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-2",
                            accordion: "my-accordion",
                            role: "tabpanel",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "pt-0 pb-2" },
                            [
                              _c("b-card-text", [
                                _vm._v(
                                  "Our directly employed team has over 100 years of collective experience in ophthalmology in both clinical and corporate settings. We attend and report on all the major meetings, and we’ve worked and maintained relationships with over 500 companies of every size and specialty."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-0",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-3",
                                  modifiers: { "accordion-3": true },
                                },
                              ],
                              staticClass: "d-flex p-3 rounded-0",
                              attrs: { block: "", variant: "transparent" },
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: require("@assets/img/primary-research/checkmark.svg"),
                                    expression:
                                      "require('@assets/img/primary-research/checkmark.svg')",
                                  },
                                ],
                                staticStyle: {
                                  "max-width": "25px",
                                  width: "auto",
                                  height: "auto",
                                },
                                attrs: { width: "25", height: "25" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ml-2 font-weight-bold" },
                                [_vm._v("Confidentiality")]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-up ml-auto when-open",
                                staticStyle: { "line-height": "inherit" },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-down ml-auto when-closed",
                                staticStyle: { "line-height": "inherit" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-3",
                            accordion: "my-accordion",
                            role: "tabpanel",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "pt-0 pb-2" },
                            [
                              _c("b-card-text", [
                                _vm._v(
                                  "We take extensive efforts to ensure the anonymity of our research participants, securing personally identifiable information, requiring NDAs, and following all local, state, national, and international requirements for data security."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-0",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-4",
                                  modifiers: { "accordion-4": true },
                                },
                              ],
                              staticClass: "d-flex p-3 rounded-0",
                              attrs: { block: "", variant: "transparent" },
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: require("@assets/img/primary-research/checkmark.svg"),
                                    expression:
                                      "require('@assets/img/primary-research/checkmark.svg')",
                                  },
                                ],
                                staticStyle: {
                                  "max-width": "25px",
                                  width: "auto",
                                  height: "auto",
                                },
                                attrs: { width: "25", height: "25" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ml-2 font-weight-bold" },
                                [_vm._v("Customization")]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-up ml-auto when-open",
                                staticStyle: { "line-height": "inherit" },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-down ml-auto when-closed",
                                staticStyle: { "line-height": "inherit" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-4",
                            accordion: "my-accordion",
                            role: "tabpanel",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "pt-0 pb-2" },
                            [
                              _c("b-card-text", [
                                _vm._v(
                                  "We scale our projects to meet all needs of our clients, including mixing platforms, creating target profiles, meeting tough deadlines, and working within budgets."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-0",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-5",
                                  modifiers: { "accordion-5": true },
                                },
                              ],
                              staticClass: "d-flex p-3 rounded-0",
                              attrs: { block: "", variant: "transparent" },
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: require("@assets/img/primary-research/checkmark.svg"),
                                    expression:
                                      "require('@assets/img/primary-research/checkmark.svg')",
                                  },
                                ],
                                staticStyle: {
                                  "max-width": "25px",
                                  width: "auto",
                                  height: "auto",
                                },
                                attrs: { width: "25", height: "25" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ml-2 font-weight-bold" },
                                [_vm._v("Unparalleled Access")]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-up ml-auto when-open",
                                staticStyle: { "line-height": "inherit" },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa-solid fa-angle-down ml-auto when-closed",
                                staticStyle: { "line-height": "inherit" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-5",
                            accordion: "my-accordion",
                            role: "tabpanel",
                          },
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "pt-0 pb-2" },
                            [
                              _c("b-card-text", [
                                _vm._v(
                                  "Our proprietary database includes only individually confirmed, practicing ophthalmologists and optometrists in a well-balanced mix of geographic location, leadership, surgical/patient volume, technology adoption, age, and gender."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "cs step-1", attrs: { id: "step1" } }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "card-deck mx-auto",
                staticStyle: { "margin-bottom": "30px" },
              },
              _vm._l(_vm.step1cards.slice(0, 3), function (card) {
                return _c(
                  "div",
                  { staticClass: "card text-white text-center" },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          "pt-1": true,
                          "card-selected": true,
                          "card-selected-slide": _vm.step1values.some(
                            (v) => v.title === card.title
                          ),
                        },
                      },
                      [_vm._m(3, true)]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-body d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "stretched-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleCard(card)
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: require("@assets/img/primary-research/" +
                                      card.icon +
                                      ".svg"),
                                    expression:
                                      "require('@assets/img/primary-research/' + card.icon + '.svg')",
                                  },
                                ],
                                staticStyle: {
                                  "max-width": "50px",
                                  width: "auto",
                                  height: "auto",
                                },
                                attrs: { width: "50", height: "50" },
                              }),
                              _vm._v(" "),
                              _c("h5", { staticClass: "card-title mb-0" }, [
                                _vm._v(_vm._s(card.title)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-overlay d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", [
                                  _c("h5", { staticClass: "card-title mb-0" }, [
                                    _vm._v(_vm._s(card.title)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "card-text" }, [
                                    _vm._v(_vm._s(card.content)),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-deck mx-auto" },
              _vm._l(_vm.step1cards.slice(3), function (card) {
                return _c(
                  "div",
                  { staticClass: "card text-white text-center" },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          "pt-1": true,
                          "card-selected": true,
                          "card-selected-slide": _vm.step1values.some(
                            (v) => v.title === card.title
                          ),
                        },
                      },
                      [_vm._m(4, true)]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-body d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "stretched-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleCard(card)
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: require("@assets/img/primary-research/" +
                                      card.icon +
                                      ".svg"),
                                    expression:
                                      "require('@assets/img/primary-research/' + card.icon + '.svg')",
                                  },
                                ],
                                staticStyle: {
                                  "max-width": "50px",
                                  width: "auto",
                                  height: "auto",
                                },
                                attrs: { width: "50", height: "50" },
                              }),
                              _vm._v(" "),
                              _c("h5", { staticClass: "card-title mb-0" }, [
                                _vm._v(_vm._s(card.title)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-overlay d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", [
                                  _c("h5", { staticClass: "card-title mb-0" }, [
                                    _vm._v(_vm._s(card.title)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "card-text" }, [
                                    _vm._v(_vm._s(card.content)),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col text-center mt-5" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button", disabled: !_vm.step1values.length },
                  on: {
                    click: function ($event) {
                      return _vm.$scrollTo("#step2")
                    },
                  },
                },
                [_vm._v("Next Step")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "cs step-2", attrs: { id: "step2" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-6" }, [
              _c("div", { staticClass: "card shadow-lg" }, [
                _c(
                  "div",
                  { staticClass: "card-body p-4" },
                  [
                    _c("h3", [_vm._v("Step 2: Define Your Project")]),
                    _vm._v(" "),
                    _c(
                      "b-form",
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-4",
                            attrs: {
                              id: "input-group-1",
                              label: "1. What are the target markets?",
                              "label-for": "input-1",
                            },
                          },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.step2options.field1,
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                placeholder: "Select all that apply...",
                                showLabels: false,
                              },
                              model: {
                                value: _vm.step2values.field1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.step2values, "field1", $$v)
                                },
                                expression: "step2values.field1",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-4",
                            attrs: {
                              id: "input-group-2",
                              label: "2. Who is the target audience?",
                              "label-for": "input-2",
                            },
                          },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.step2options.field2,
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                placeholder: "Select all that apply...",
                                showLabels: false,
                              },
                              model: {
                                value: _vm.step2values.field2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.step2values, "field2", $$v)
                                },
                                expression: "step2values.field2",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-4",
                            attrs: {
                              id: "input-group-3",
                              label: "3. What is our target sample size?",
                              "label-for": "input-3",
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "input-3",
                                options: _vm.step2options.field3,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          attrs: { value: null, disabled: "" },
                                        },
                                        [_vm._v("Select option...")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.step2values.field3,
                                callback: function ($$v) {
                                  _vm.$set(_vm.step2values, "field3", $$v)
                                },
                                expression: "step2values.field3",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-4",
                            attrs: {
                              id: "input-group-4",
                              label: "4. How quickly do you need the data?",
                              "label-for": "input-4",
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "input-4",
                                options: _vm.step2options.field4,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          attrs: { value: null, disabled: "" },
                                        },
                                        [_vm._v("Select option...")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.step2values.field4,
                                callback: function ($$v) {
                                  _vm.$set(_vm.step2values, "field4", $$v)
                                },
                                expression: "step2values.field4",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: {
                              disabled:
                                !_vm.step2values.field1.length ||
                                !_vm.step2values.field2.length ||
                                !_vm.step2values.field3 ||
                                !_vm.step2values.field4,
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$scrollTo("#step3")
                              },
                            },
                          },
                          [_vm._v("Next Step")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "cs step-3", attrs: { id: "step3" } }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(5),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "card-deck mx-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card d-flex position-relative shadow-lg step-3-choice-1",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/primary-research/primary-research-online-surveys.jpg"),
                        expression:
                          "require('@assets/img/primary-research/primary-research-online-surveys.jpg')",
                      },
                    ],
                    staticClass: "card-img-top w-100",
                    staticStyle: { height: "212px" },
                    attrs: { alt: "Online Surveys" },
                  }),
                  _vm._v(" "),
                  _vm.step2values.field4 === "4-6 Weeks"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "position-absolute bg-success text-white pl-4 py-2 w-100",
                          staticStyle: { top: "175px", "font-size": "0.9rem" },
                        },
                        [_vm._v("RECOMMENDED")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("h6", [_vm._v("Ideal for:")]),
                    _vm._v(" "),
                    _vm._m(7),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center mt-4 mb-3" }, [
                      _c(
                        "button",
                        {
                          class: {
                            btn: true,
                            "btn-success": _vm.step3value === "Online Surveys",
                            "btn-outline-success":
                              _vm.step3value !== "Online Surveys",
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.step3value = "Online Surveys"
                              _vm.$scrollTo("#step4")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.step3value === "Online Surveys"
                                ? "Selected Plan"
                                : "Select"
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card d-flex position-relative shadow-lg step-3-choice-2",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/primary-research/primary-research-focus-groups.jpg"),
                        expression:
                          "require('@assets/img/primary-research/primary-research-focus-groups.jpg')",
                      },
                    ],
                    staticClass: "card-img-top w-100",
                    staticStyle: { height: "212px" },
                    attrs: { alt: "Advisory Boards" },
                  }),
                  _vm._v(" "),
                  _vm.step2values.field4 === "ASAP!" ||
                  _vm.step2values.field4 === "2-4 Weeks"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "position-absolute bg-success text-white pl-4 py-2 w-100",
                          staticStyle: { top: "175px", "font-size": "0.9rem" },
                        },
                        [_vm._v("RECOMMENDED")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _vm._m(8),
                    _vm._v(" "),
                    _c("h6", [_vm._v("Ideal for:")]),
                    _vm._v(" "),
                    _vm._m(9),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center mt-4 mb-3" }, [
                      _c(
                        "button",
                        {
                          class: {
                            btn: true,
                            "btn-success": _vm.step3value === "Advisory Boards",
                            "btn-outline-success":
                              _vm.step3value !== "Advisory Boards",
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.step3value = "Advisory Boards"
                              _vm.$scrollTo("#step4")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.step3value === "Advisory Boards"
                                ? "Selected Plan"
                                : "Select"
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card d-flex position-relative shadow-lg step-3-choice-3",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/primary-research/primary-research-interviews.jpg"),
                        expression:
                          "require('@assets/img/primary-research/primary-research-interviews.jpg')",
                      },
                    ],
                    staticClass: "card-img-top w-100",
                    staticStyle: { height: "212px" },
                    attrs: { alt: "In-Depths Interviews (IDIs)" },
                  }),
                  _vm._v(" "),
                  _vm.step2values.field4 === "6 Weeks+"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "position-absolute bg-success text-white pl-4 py-2 w-100",
                          staticStyle: { top: "175px", "font-size": "0.9rem" },
                        },
                        [_vm._v("RECOMMENDED")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _vm._m(10),
                    _vm._v(" "),
                    _c("h6", [_vm._v("Ideal for:")]),
                    _vm._v(" "),
                    _vm._m(11),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center mt-4 mb-3" }, [
                      _c(
                        "button",
                        {
                          class: {
                            btn: true,
                            "btn-success":
                              _vm.step3value === "In-Depths Interviews (IDIs)",
                            "btn-outline-success":
                              _vm.step3value !== "In-Depths Interviews (IDIs)",
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.step3value = "In-Depths Interviews (IDIs)"
                              _vm.$scrollTo("#step4")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.step3value === "In-Depths Interviews (IDIs)"
                                ? "Selected Plan"
                                : "Select"
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "cs step-4" }, [
        _c("div", { staticClass: "container", attrs: { id: "step4" } }, [
          _vm._m(12),
          _vm._v(" "),
          _c("div", { staticClass: "card shadow-lg" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  directives: [
                    {
                      name: "promise-btn",
                      rawName: "v-promise-btn",
                      value: {
                        action:
                          _vm.step4method === "methodRegister"
                            ? "submit"
                            : "login",
                      },
                      expression:
                        "{ action: step4method === 'methodRegister' ? 'submit' : 'login' }",
                    },
                  ],
                  ref: "corporateRegistrationForm",
                  attrs: {
                    method: "post",
                    "accept-charset": "utf-8",
                    novalidate: "",
                    id: "corporate-registration-form",
                    role: "form",
                    action:
                      _vm.step4method === "methodRegister"
                        ? "/users/corporate-register"
                        : "/users/login",
                  },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      _vm.step4method === "methodRegister"
                        ? _vm.submit()
                        : _vm.login()
                    },
                  },
                },
                [
                  _c("div", { staticStyle: { display: "none" } }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "hidden", name: "_method", value: "POST" },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "_csrfToken",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.csrfToken },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "g_recaptcha_response",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.gRecaptchaToken },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "origin",
                        value: "custom_research",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _vm._m(13),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col text-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-control custom-radio custom-control-inline",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.step4method,
                                expression: "step4method",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              value: "methodRegister",
                              id: "methodRegister",
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.step4method,
                                "methodRegister"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.step4method = "methodRegister"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "methodRegister" },
                            },
                            [_vm._v("Register an account")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-control custom-radio custom-control-inline",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.step4method,
                                expression: "step4method",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              value: "methodSignIn",
                              id: "methodSignIn",
                            },
                            domProps: {
                              checked: _vm._q(_vm.step4method, "methodSignIn"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.step4method = "methodSignIn"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "methodSignIn" },
                            },
                            [_vm._v("Sign in")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.step4method === "methodRegister"
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("label", { attrs: { for: "inputFullName" } }, [
                            _vm._v("Full Name*"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.step4form.full_name.$model,
                                expression: "$v.step4form.full_name.$model",
                                modifiers: { trim: true },
                              },
                            ],
                            class: {
                              "form-control": true,
                              "is-invalid":
                                _vm.$v.step4form.full_name.$dirty &&
                                _vm.$v.step4form.full_name.$error,
                            },
                            attrs: {
                              type: "text",
                              name: "full_name",
                              placeholder: "Full Name",
                              id: "inputFullName",
                              required: "",
                              maxlength: "50",
                            },
                            domProps: {
                              value: _vm.$v.step4form.full_name.$model,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.step4form.full_name,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.step4form.full_name.$dirty &&
                          !_vm.$v.step4form.full_name.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Full name is required."),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$v.step4form.full_name.$dirty &&
                          !_vm.$v.step4form.full_name.minLength
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "Full name has a minimum of 3 characters."
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3" },
                          [
                            _c("label", { attrs: { for: "inputCompany" } }, [
                              _vm._v("Company*"),
                            ]),
                            _vm._v(" "),
                            _c("vue-bootstrap-typeahead", {
                              attrs: {
                                placeholder: "Company Name",
                                data: _vm.companyNames,
                                serializer: (v) => v.name,
                                id: "inputCompany",
                                inputClass:
                                  _vm.$v.companyName.$dirty &&
                                  _vm.$v.companyName.$error
                                    ? "is-invalid"
                                    : "",
                              },
                              on: {
                                hit: _vm.selectCompany,
                                input: _vm.deselectCompany,
                              },
                              model: {
                                value: _vm.$v.companyName.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.companyName,
                                    "$model",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "$v.companyName.$model",
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              ref: "corporate_company_name",
                              attrs: {
                                type: "hidden",
                                name: "companies[0][name]",
                                value: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              ref: "corporate_company_id",
                              attrs: {
                                type: "hidden",
                                name: "companies[0][id]",
                                value: "",
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.companyName.$dirty &&
                            !_vm.$v.companyName.required
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "invalid-feedback",
                                    staticStyle: { display: "block" },
                                  },
                                  [_vm._v("Company name is required.")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._m(14),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("label", { attrs: { for: "inputEmail" } }, [
                        _vm._v("Email*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.$v.step4form.email.$model,
                            expression: "$v.step4form.email.$model",
                            modifiers: { trim: true },
                          },
                        ],
                        class: {
                          "form-control": true,
                          "is-invalid":
                            _vm.$v.step4form.email.$dirty &&
                            _vm.$v.step4form.email.$error,
                        },
                        attrs: {
                          type: "email",
                          name:
                            _vm.step4method === "methodRegister"
                              ? "email"
                              : "login_email",
                          placeholder: "Email",
                          id: "inputEmail",
                          required: "",
                          maxlength: "254",
                        },
                        domProps: { value: _vm.$v.step4form.email.$model },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.step4form.email,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.step4form.email.$dirty &&
                      !_vm.$v.step4form.email.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Email address is required."),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$v.step4form.email.$dirty &&
                      !_vm.$v.step4form.email.email
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Email address has to be valid."),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: {
                          "form-group": true,
                          "col-md-3": _vm.step4method === "methodRegister",
                          "col-md-6": _vm.step4method === "methodSignIn",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "inputPassword" } }, [
                          _vm._v("Password*"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.step4form.passwd.$model,
                              expression: "$v.step4form.passwd.$model",
                              modifiers: { trim: true },
                            },
                          ],
                          class: {
                            "form-control": true,
                            "is-invalid":
                              _vm.$v.step4form.passwd.$dirty &&
                              _vm.$v.step4form.passwd.$error,
                          },
                          attrs: {
                            type: "password",
                            name:
                              _vm.step4method === "methodRegister"
                                ? "passwd"
                                : "password",
                            placeholder: "Enter a password",
                            id: "inputPassword",
                            required: "",
                          },
                          domProps: { value: _vm.$v.step4form.passwd.$model },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.step4form.passwd,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.step4form.passwd.$dirty &&
                        !_vm.$v.step4form.passwd.required
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("Password is required."),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$v.step4form.passwd.$dirty &&
                        !_vm.$v.step4form.passwd.minLength
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("Password has a minimum of 6 characters."),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.step4method === "methodRegister"
                      ? _c("div", { staticClass: "form-group col-md-3" }, [
                          _c(
                            "label",
                            { attrs: { for: "inputReenterPassword" } },
                            [_vm._v("Re-enter Password*")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.step4form.passwd_confirm.$model,
                                expression:
                                  "$v.step4form.passwd_confirm.$model",
                                modifiers: { trim: true },
                              },
                            ],
                            class: {
                              "form-control": true,
                              "is-invalid":
                                _vm.$v.step4form.passwd_confirm.$dirty &&
                                _vm.$v.step4form.passwd_confirm.$error,
                            },
                            attrs: {
                              type: "password",
                              name: "passwd_confirm",
                              placeholder: "Re-enter your password",
                              id: "inputReenterPassword",
                            },
                            domProps: {
                              value: _vm.$v.step4form.passwd_confirm.$model,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.step4form.passwd_confirm,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.step4form.passwd_confirm.$dirty &&
                          !_vm.$v.step4form.passwd_confirm.sameAsPasswd
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Password re-entry has to match."),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._m(15),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c(
                          "label",
                          { attrs: { for: "inputResearchObjectives" } },
                          [_vm._v("What Are Your Objectives?")]
                        ),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.step1cards,
                            label: "title",
                            "track-by": "title",
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            placeholder: "Select all that apply...",
                            showLabels: false,
                            id: "inputResearchObjectives",
                          },
                          model: {
                            value: _vm.step1values,
                            callback: function ($$v) {
                              _vm.step1values = $$v
                            },
                            expression: "step1values",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            staticStyle: { display: "none" },
                            attrs: {
                              multiple: "",
                              name: "research_objectives[]",
                            },
                          },
                          _vm._l(_vm.step1values, function (option) {
                            return _c(
                              "option",
                              {
                                attrs: { selected: "" },
                                domProps: { value: option.title },
                              },
                              [_vm._v(_vm._s(option.title))]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c("label", { attrs: { for: "inputTargetMarkets" } }, [
                          _vm._v("What are the target markets?"),
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.step2options.field1,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            placeholder: "Select all that apply...",
                            showLabels: false,
                            id: "inputTargetMarkets",
                          },
                          model: {
                            value: _vm.step2values.field1,
                            callback: function ($$v) {
                              _vm.$set(_vm.step2values, "field1", $$v)
                            },
                            expression: "step2values.field1",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            staticStyle: { display: "none" },
                            attrs: { multiple: "", name: "target_markets[]" },
                          },
                          _vm._l(_vm.step2values.field1, function (option) {
                            return _c(
                              "option",
                              {
                                attrs: { selected: "" },
                                domProps: { value: option },
                              },
                              [_vm._v(_vm._s(option))]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c("label", { attrs: { for: "inputTargetAudience" } }, [
                          _vm._v("Who is the target audience?"),
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.step2options.field2,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            placeholder: "Select all that apply...",
                            showLabels: false,
                            id: "inputTargetAudience",
                          },
                          model: {
                            value: _vm.step2values.field2,
                            callback: function ($$v) {
                              _vm.$set(_vm.step2values, "field2", $$v)
                            },
                            expression: "step2values.field2",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            staticStyle: { display: "none" },
                            attrs: { multiple: "", name: "target_audience[]" },
                          },
                          _vm._l(_vm.step2values.field2, function (option) {
                            return _c(
                              "option",
                              {
                                attrs: { selected: "" },
                                domProps: { value: option },
                              },
                              [_vm._v(_vm._s(option))]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c(
                          "label",
                          { attrs: { for: "inputTargetSampleSize" } },
                          [_vm._v("What is your target sample size?")]
                        ),
                        _vm._v(" "),
                        _c("b-form-select", {
                          attrs: {
                            id: "inputTargetSampleSize",
                            options: _vm.step2options.field3,
                            name: "target_sample_size",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [_vm._v("Select option...")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.step2values.field3,
                            callback: function ($$v) {
                              _vm.$set(_vm.step2values, "field3", $$v)
                            },
                            expression: "step2values.field3",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c("label", { attrs: { for: "inputDataDelay" } }, [
                          _vm._v("How quickly do you need the data?"),
                        ]),
                        _vm._v(" "),
                        _c("b-form-select", {
                          attrs: {
                            id: "inputDataDelay",
                            options: _vm.step2options.field4,
                            name: "data_delay",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [_vm._v("Select option...")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.step2values.field4,
                            callback: function ($$v) {
                              _vm.$set(_vm.step2values, "field4", $$v)
                            },
                            expression: "step2values.field4",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c("label", { attrs: { for: "inputResearchOption" } }, [
                          _vm._v("Which research option is right for you?"),
                        ]),
                        _vm._v(" "),
                        _c("b-form-select", {
                          attrs: {
                            id: "inputResearchOption",
                            options: _vm.step3options,
                            name: "research_option",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [_vm._v("Select option...")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.step3value,
                            callback: function ($$v) {
                              _vm.step3value = $$v
                            },
                            expression: "step3value",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center my-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: {
                          type: "submit",
                          disabled: !_vm.isMounted || _vm.isSubmitting,
                        },
                      },
                      [_vm._v("Submit")]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("request-custom-quote"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "cs-masthead" }, [
      _c("h1", { staticClass: "section-heading" }, [
        _vm._v("Custom & Primary Research Solutions"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Custom & Primary Research Solutions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center mb-3" }, [
        _c("h6", [_vm._v("Let's Get Started")]),
        _vm._v(" "),
        _c("h3", [_vm._v("Step 1: What Are Your Objectives?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Market Scope provides comprehensive third-party primary research solutions to clients seeking to solicit feedback from United States and European ophthalmologists on new product design, messaging, pricing and experience with current technology, and expectations from future treatments."
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("Potential Research Objectives")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "fa-stack" }, [
      _c("i", { staticClass: "fa-solid fa-circle fa-stack-2x" }),
      _vm._v(" "),
      _c("i", { staticClass: "fa-solid fa-check fa-stack-1x" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "fa-stack" }, [
      _c("i", { staticClass: "fa-solid fa-circle fa-stack-2x" }),
      _vm._v(" "),
      _c("i", { staticClass: "fa-solid fa-check fa-stack-1x" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center mb-3" }, [
        _c("h3", [_vm._v("Step 3: Which Research Option is Right for You?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "We leverage our expert knowledge of the ophthalmic industry to design custom research that enhances strategy and operations for our clients. Our unique experience makes us a trusted partner in defining problems and executing solutions."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "card-title" }, [
      _vm._v("Online Surveys"),
      _c("br"),
      _c("small", [_vm._v("4 Week Turnaround")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Large sample sizes")]),
      _vm._v(" "),
      _c("li", [_vm._v("Identifying new product features")]),
      _vm._v(" "),
      _c("li", [_vm._v("Price sensitivity")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "card-title" }, [
      _vm._v("Advisory Boards"),
      _c("br"),
      _c("small", [_vm._v("2-4 Week Turnaround")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Hand-selected participants")]),
      _vm._v(" "),
      _c("li", [_vm._v("Brainstorming next-gen technologies")]),
      _vm._v(" "),
      _c("li", [_vm._v("Challenging assumptions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "card-title" }, [
      _vm._v("In-Depths Interviews (IDIs)"),
      _c("br"),
      _c("small", [_vm._v("6-10 Week Turnaround")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Deep diving into market needs")]),
      _vm._v(" "),
      _c("li", [_vm._v("Exploring marketing messages")]),
      _vm._v(" "),
      _c("li", [_vm._v("Defining clinical expectations")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center mb-3" }, [
        _c("h3", [_vm._v("Step 4: Contact Us")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Contact us for a custom quote or register for your free account and gain access to licensed reports and subscriptions, industry news, trends, weekly news, and more."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center my-3" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Sign In or Register")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group col-md-3" }, [
      _c("label", { attrs: { for: "inputJobTitle" } }, [_vm._v("Job Title")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "text",
          name: "user_attribute[title]",
          id: "inputJobTitle",
          placeholder: "Job Title",
          maxlength: "255",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center my-3" }, [
      _c("h5", { staticClass: "card-title" }, [
        _vm._v("Based on Your Feedback..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }