var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", {
        attrs: { subpage: true, requestedProductId: _vm.requestedProductId },
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "market-trends intro", attrs: { id: "market-trends" } },
        [
          _c("div", { staticClass: "container" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "row px-5" }, [
              _c("div", { staticClass: "col-md-3 text-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/market-trends/Icon-New-Product-Entry.png"),
                      expression:
                        "require('@assets/img/market-trends/Icon-New-Product-Entry.png')",
                    },
                  ],
                }),
                _vm._v(" "),
                _c("h6", { staticClass: "m-4" }, [_vm._v("New Product Entry")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3 text-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/market-trends/Icon-Product-Share-Tracking.png"),
                      expression:
                        "require('@assets/img/market-trends/Icon-Product-Share-Tracking.png')",
                    },
                  ],
                }),
                _vm._v(" "),
                _c("h6", { staticClass: "m-4" }, [
                  _vm._v("Established Product Share Tracking"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3 text-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/market-trends/Icon-Provider-Preferences.png"),
                      expression:
                        "require('@assets/img/market-trends/Icon-Provider-Preferences.png')",
                    },
                  ],
                }),
                _vm._v(" "),
                _c("h6", { staticClass: "m-4" }, [
                  _vm._v("Provider Preferences"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3 text-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/market-trends/Icon-Market-Fluctuations.png"),
                      expression:
                        "require('@assets/img/market-trends/Icon-Market-Fluctuations.png')",
                    },
                  ],
                }),
                _vm._v(" "),
                _c("h6", { staticClass: "m-4" }, [
                  _vm._v("Market Fluctuations"),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "market-trends offerings" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "row align-items-center mx-5 mb-5" }, [
            _c("div", { staticClass: "col-md-4 my-3 text-center" }, [
              _vm.selectedSpecialtyIdx > 0
                ? _c(
                    "a",
                    {
                      staticClass: "specialty-slider-btn",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.specialtyPrev.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/market-trends/Left-Arrow.png"),
                            expression:
                              "require('@assets/img/market-trends/Left-Arrow.png')",
                          },
                        ],
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4 text-center" },
              [
                _c(
                  "transition-group",
                  {
                    staticClass: "specialty-slider",
                    attrs: {
                      tag: "div",
                      name: _vm.specialtyBack ? "slideback" : "slide",
                    },
                  },
                  _vm._l(_vm.specialtyImgs, function (img) {
                    return _vm.specialtyImgs[_vm.selectedSpecialtyIdx] === img
                      ? _c("img", {
                          key: img,
                          staticClass: "img-fluid",
                          attrs: {
                            src: require("@assets/img/market-trends/" +
                              _vm.specialtyImgs[_vm.selectedSpecialtyIdx]),
                          },
                        })
                      : _vm._e()
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 my-3 text-center" }, [
              _vm.selectedSpecialtyIdx < _vm.specialtyImgs.length - 1
                ? _c(
                    "a",
                    {
                      staticClass: "specialty-slider-btn",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.specialtyNext.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: require("@assets/img/market-trends/Right-Arrow.png"),
                            expression:
                              "require('@assets/img/market-trends/Right-Arrow.png')",
                          },
                        ],
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "card-deck mx-auto" }, [
              _vm.cataractReport
                ? _c(
                    "div",
                    {
                      class: {
                        card: true,
                        "d-flex": true,
                        "shadow-lg": true,
                        "border-0": true,
                        "rounded-0": true,
                        "mx-2": true,
                        "mb-5": true,
                        "offerings-cataract-report": true,
                        selected: _vm.selectedSpecialtyIdx === 0,
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedSpecialtyIdx = 0
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "card-top-arrow" }),
                      _vm._v(" "),
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body d-flex flex-column px-3" },
                        [
                          _vm._m(5),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center mt-auto mt-4 mb-2" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn px-5 py-2",
                                  attrs: {
                                    to: {
                                      name: "Report",
                                      params: {
                                        id: _vm.cataractReport.id,
                                        slug: _vm.cataractReport.slug,
                                      },
                                      hash: "#reports",
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [_vm._v("Learn More")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.refractiveReport
                ? _c(
                    "div",
                    {
                      class: {
                        card: true,
                        "d-flex": true,
                        "shadow-lg": true,
                        "border-0": true,
                        "rounded-0": true,
                        "mx-2": true,
                        "mb-5": true,
                        "offerings-refractive-report": true,
                        selected: _vm.selectedSpecialtyIdx === 1,
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedSpecialtyIdx = 1
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "card-top-arrow" }),
                      _vm._v(" "),
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body d-flex flex-column px-3" },
                        [
                          _vm._m(7),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center mt-auto mt-4 mb-2" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn px-5 py-2",
                                  attrs: {
                                    to: {
                                      name: "Report",
                                      params: {
                                        id: _vm.refractiveReport.id,
                                        slug: _vm.refractiveReport.slug,
                                      },
                                      hash: "#reports",
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [_vm._v("Learn More")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "w-100 d-none d-sm-block d-md-block d-lg-none",
              }),
              _vm._v(" "),
              _vm.glaucomaReport
                ? _c(
                    "div",
                    {
                      class: {
                        card: true,
                        "d-flex": true,
                        "shadow-lg": true,
                        "border-0": true,
                        "rounded-0": true,
                        "mx-2": true,
                        "mb-5": true,
                        "offerings-glaucoma-report": true,
                        selected: _vm.selectedSpecialtyIdx === 2,
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedSpecialtyIdx = 2
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "card-top-arrow" }),
                      _vm._v(" "),
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body d-flex flex-column px-3" },
                        [
                          _vm._m(9),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center mt-auto mt-4 mb-2" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn px-5 py-2",
                                  attrs: {
                                    to: {
                                      name: "Report",
                                      params: {
                                        id: _vm.glaucomaReport.id,
                                        slug: _vm.glaucomaReport.slug,
                                      },
                                      hash: "#reports",
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [_vm._v("Learn More")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.retinaReport
                ? _c(
                    "div",
                    {
                      class: {
                        card: true,
                        "d-flex": true,
                        "shadow-lg": true,
                        "border-0": true,
                        "rounded-0": true,
                        "mx-2": true,
                        "mb-5": true,
                        "offerings-retina-report": true,
                        selected: _vm.selectedSpecialtyIdx === 3,
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedSpecialtyIdx = 3
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "card-top-arrow" }),
                      _vm._v(" "),
                      _vm._m(10),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body d-flex flex-column px-3" },
                        [
                          _vm._m(11),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center mt-auto mt-4 mb-2" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn px-5 py-2",
                                  attrs: {
                                    to: {
                                      name: "Report",
                                      params: {
                                        id: _vm.retinaReport.id,
                                        slug: _vm.retinaReport.slug,
                                      },
                                      hash: "#reports",
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [_vm._v("Learn More")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "market-trends deliverables" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(12),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "card-deck px-5 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass: "card d-flex border-0 rounded-0 shadow-lg mb-5",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/market-trends/OMT-Deliverables-Trend-Analysis.jpg"),
                        expression:
                          "require('@assets/img/market-trends/OMT-Deliverables-Trend-Analysis.jpg')",
                      },
                    ],
                    staticClass: "card-img-top rounded-0 w-100",
                    attrs: { alt: "Trend Analysis" },
                  }),
                  _vm._v(" "),
                  _vm._m(13),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "w-100 d-none d-sm-block d-md-block d-lg-none",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card d-flex border-0 rounded-0 shadow-lg mb-5",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/market-trends/OMT-Deliverables-Two-Year-Summary.jpg"),
                        expression:
                          "require('@assets/img/market-trends/OMT-Deliverables-Two-Year-Summary.jpg')",
                      },
                    ],
                    staticClass: "card-img-top rounded-0 w-100",
                    attrs: { alt: "Two-Year Summary" },
                  }),
                  _vm._v(" "),
                  _vm._m(14),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "w-100 d-none d-sm-block d-md-block d-lg-none",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card d-flex border-0 rounded-0 shadow-lg mb-5",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/market-trends/OMT-Deliverables-Full-Year-Forecast.jpg"),
                        expression:
                          "require('@assets/img/market-trends/OMT-Deliverables-Full-Year-Forecast.jpg')",
                      },
                    ],
                    staticClass: "card-img-top rounded-0 w-100",
                    attrs: { alt: "Full-Year Forecast" },
                  }),
                  _vm._v(" "),
                  _vm._m(15),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "market-trends sources" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(16),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "card-deck px-5 mx-auto" }, [
              _c(
                "div",
                {
                  staticClass: "card d-flex border-0 rounded-0 shadow-lg mb-5",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/market-trends/OMT-Sources-Surveys.jpg"),
                        expression:
                          "require('@assets/img/market-trends/OMT-Sources-Surveys.jpg')",
                      },
                    ],
                    staticClass: "card-img-top rounded-0 w-100",
                    attrs: {
                      alt: "Surveys of Individual Confirmed Ophthalmologists",
                    },
                  }),
                  _vm._v(" "),
                  _vm._m(17),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "w-100 d-none d-sm-block d-md-block d-lg-none",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card d-flex border-0 rounded-0 shadow-lg mb-5",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/market-trends/OMT-Sources-Disease-Models.jpg"),
                        expression:
                          "require('@assets/img/market-trends/OMT-Sources-Disease-Models.jpg')",
                      },
                    ],
                    staticClass: "card-img-top rounded-0 w-100",
                    attrs: { alt: "Proprietary Disease Models" },
                  }),
                  _vm._v(" "),
                  _vm._m(18),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "w-100 d-none d-sm-block d-md-block d-lg-none",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card d-flex border-0 rounded-0 shadow-lg mb-5",
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: require("@assets/img/market-trends/OMT-Sources-Primary-Research.jpg"),
                        expression:
                          "require('@assets/img/market-trends/OMT-Sources-Primary-Research.jpg')",
                      },
                    ],
                    staticClass: "card-img-top rounded-0 w-100",
                    attrs: {
                      alt: "Other Primary Research and Public Data Sources",
                    },
                  }),
                  _vm._v(" "),
                  _vm._m(19),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "market-trends highlights" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(20),
          _vm._v(" "),
          _vm.cataractReport
            ? _c("div", { staticClass: "row mb-5" }, [
                _c("div", { staticClass: "card-group px-5 mx-3 w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-flex border-0 rounded-0 report-overview",
                    },
                    [
                      _c("div", { staticClass: "card-body pt-5 px-5 pb-4" }, [
                        _c("h5", { staticClass: "mb-4" }, [
                          _vm._v("US OMT Cataract Report"),
                        ]),
                        _vm._v(" "),
                        _vm._m(21),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "scroll-to",
                                  rawName: "v-scroll-to",
                                  value: "#register",
                                  expression: "'#register'",
                                },
                              ],
                              staticClass:
                                "btn btn-purchase mr-3 mb-4 py-2 px-3",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.requestProduct(_vm.cataractReport)
                                },
                              },
                            },
                            [_vm._v("Purchase")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-purchase-outline mb-4 py-2 px-3",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$modal.show("requestSampleModal", {
                                    productTypeId:
                                      _vm.cataractReport.product_type_id,
                                  })
                                },
                              },
                            },
                            [_vm._v("Request a Sample")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-flex border-0 rounded-0 report-cover",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-body d-flex align-items-center justify-content-center px-5",
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid shadow",
                            staticStyle: { "max-height": "577px" },
                            attrs: { src: _vm.cataractReport.cover_img },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.refractiveReport
            ? _c("div", { staticClass: "row mb-5" }, [
                _c("div", { staticClass: "card-group px-5 mx-3 w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-flex border-0 rounded-0 report-cover",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-body d-flex align-items-center justify-content-center px-5",
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid shadow",
                            staticStyle: { "max-height": "577px" },
                            attrs: { src: _vm.refractiveReport.cover_img },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-flex border-0 rounded-0 report-overview",
                    },
                    [
                      _c("div", { staticClass: "card-body pt-5 px-5 pb-4" }, [
                        _c("h5", { staticClass: "mb-4" }, [
                          _vm._v("US OMT Refractive Report"),
                        ]),
                        _vm._v(" "),
                        _vm._m(22),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "scroll-to",
                                  rawName: "v-scroll-to",
                                  value: "#register",
                                  expression: "'#register'",
                                },
                              ],
                              staticClass:
                                "btn btn-purchase mr-3 mb-4 py-2 px-3",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.requestProduct(
                                    _vm.refractiveReport
                                  )
                                },
                              },
                            },
                            [_vm._v("Purchase")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-purchase-outline mb-4 py-2 px-3",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$modal.show("requestSampleModal", {
                                    productTypeId:
                                      _vm.refractiveReport.product_type_id,
                                  })
                                },
                              },
                            },
                            [_vm._v("Request a Sample")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.glaucomaReport
            ? _c("div", { staticClass: "row mb-5" }, [
                _c("div", { staticClass: "card-group px-5 mx-3 w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-flex border-0 rounded-0 report-overview",
                    },
                    [
                      _c("div", { staticClass: "card-body pt-5 px-5 pb-4" }, [
                        _c("h5", { staticClass: "mb-4" }, [
                          _vm._v("US OMT Glaucoma Report"),
                        ]),
                        _vm._v(" "),
                        _vm._m(23),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "scroll-to",
                                  rawName: "v-scroll-to",
                                  value: "#register",
                                  expression: "'#register'",
                                },
                              ],
                              staticClass:
                                "btn btn-purchase mr-3 mb-4 py-2 px-3",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.requestProduct(_vm.glaucomaReport)
                                },
                              },
                            },
                            [_vm._v("Purchase")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-purchase-outline mb-4 py-2 px-3",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$modal.show("requestSampleModal", {
                                    productTypeId:
                                      _vm.glaucomaReport.product_type_id,
                                  })
                                },
                              },
                            },
                            [_vm._v("Request a Sample")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-flex border-0 rounded-0 report-cover",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-body d-flex align-items-center justify-content-center px-5",
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid shadow",
                            staticStyle: { "max-height": "577px" },
                            attrs: { src: _vm.glaucomaReport.cover_img },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.retinaReport
            ? _c("div", { staticClass: "row mb-5" }, [
                _c("div", { staticClass: "card-group px-5 mx-3 w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-flex border-0 rounded-0 report-cover",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-body d-flex align-items-center justify-content-center px-5",
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid shadow",
                            staticStyle: { "max-height": "577px" },
                            attrs: { src: _vm.retinaReport.cover_img },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "card d-flex border-0 rounded-0 report-overview",
                    },
                    [
                      _c("div", { staticClass: "card-body pt-5 px-5 pb-4" }, [
                        _c("h5", { staticClass: "mb-4" }, [
                          _vm._v("US OMT Retina Report"),
                        ]),
                        _vm._v(" "),
                        _vm._m(24),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "scroll-to",
                                  rawName: "v-scroll-to",
                                  value: "#register",
                                  expression: "'#register'",
                                },
                              ],
                              staticClass:
                                "btn btn-purchase mr-3 mb-4 py-2 px-3",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.requestProduct(_vm.retinaReport)
                                },
                              },
                            },
                            [_vm._v("Purchase")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-purchase-outline mb-4 py-2 px-3",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$modal.show("requestSampleModal", {
                                    productTypeId:
                                      _vm.retinaReport.product_type_id,
                                  })
                                },
                              },
                            },
                            [_vm._v("Request a Sample")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(25),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "card-group px-5 mx-3 w-100" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card d-flex bg-transparent border-0 report-overview",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body d-flex flex-column justify-content-center",
                    },
                    [
                      _c("h5", { staticClass: "pt-3 mb-4" }, [
                        _vm._v("Custom Project Types Include:"),
                      ]),
                      _vm._v(" "),
                      _vm._m(26),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Each project is custom designed to meet each specific client's needs. Please contact us for an individualized project consultation."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-purchase mr-3 mb-4 py-2 px-3",
                              attrs: { type: "button" },
                              on: { click: _vm.showContactUsModal },
                            },
                            [_vm._v("Contact Us")]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-purchase-outline mb-4 py-2 px-3",
                              attrs: { to: { name: "PrimaryResearch" } },
                            },
                            [_vm._v("Primary Research Options")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._m(27),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "market-trends register" }, [
        _c("div", { staticClass: "container", attrs: { id: "register" } }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col text-center mb-3" }, [
              _c("h3", [_vm._v("Want to Purchase the Full Report?")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-center mt-3 mb-4" },
                [
                  _c(
                    "div",
                    {
                      class: {
                        "position-relative": true,
                        "mr-3": true,
                        selected: _vm.registerType === "corporate",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-register py-2 px-3",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.registerType = "corporate"
                            },
                          },
                        },
                        [_vm._v("I am an Industry Professional")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "btn-bottom-arrow" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "position-relative": true,
                        selected: _vm.registerType === "clinical",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-register py-2 px-3",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.registerType = "clinical"
                            },
                          },
                        },
                        [_vm._v("I am a Medical Professional")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "btn-bottom-arrow" }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.registerType === "corporate"
                ? _c("p", [
                    _vm._v(
                      "A corporate account gives you access to licensed reports and subscriptions, the latest news, a personalized dashboard, and weekly emails with news and data."
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.registerType === "clinical"
                ? _c("p", [
                    _vm._v(
                      "A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard."
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card shadow-lg mx-5" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  directives: [
                    {
                      name: "promise-btn",
                      rawName: "v-promise-btn",
                      value: {
                        action:
                          _vm.registerMethod === "methodRegister"
                            ? "submit"
                            : "login",
                      },
                      expression:
                        "{ action: registerMethod === 'methodRegister' ? 'submit' : 'login' }",
                    },
                  ],
                  ref: "registrationForm",
                  attrs: {
                    method: "post",
                    "accept-charset": "utf-8",
                    novalidate: "",
                    id: "registration-form",
                    role: "form",
                    action:
                      _vm.registerMethod === "methodRegister"
                        ? "/users/" +
                          (_vm.registerType === "corporate"
                            ? "corporate-"
                            : "") +
                          "register"
                        : "/users/login",
                  },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      _vm.registerMethod === "methodRegister"
                        ? _vm.submit()
                        : _vm.login()
                    },
                  },
                },
                [
                  _c("div", { staticStyle: { display: "none" } }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "hidden", name: "_method", value: "POST" },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "_csrfToken",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.csrfToken },
                    }),
                    _vm._v(" "),
                    _vm.registerType === "corporate"
                      ? _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "user_attribute[requested_product_id]",
                          },
                          domProps: { value: _vm.requestedProductId },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "g_recaptcha_response",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.gRecaptchaToken },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "origin",
                        value: "market_trends",
                      },
                    }),
                    _vm._v(" "),
                    _vm.registerType === "clinical"
                      ? _c(
                          "select",
                          { attrs: { multiple: "", name: "tag_list[]" } },
                          _vm._l(_vm.tag_list, function (tag) {
                            return _c(
                              "option",
                              {
                                attrs: { selected: "" },
                                domProps: { value: tag },
                              },
                              [_vm._v(_vm._s(tag))]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._m(28),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col text-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-control custom-radio custom-control-inline",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.registerMethod,
                                expression: "registerMethod",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              value: "methodRegister",
                              id: "methodRegister",
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.registerMethod,
                                "methodRegister"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.registerMethod = "methodRegister"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "methodRegister" },
                            },
                            [_vm._v("Register an account")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-control custom-radio custom-control-inline",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.registerMethod,
                                expression: "registerMethod",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              value: "methodSignIn",
                              id: "methodSignIn",
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.registerMethod,
                                "methodSignIn"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.registerMethod = "methodSignIn"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "methodSignIn" },
                            },
                            [_vm._v("Sign in")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.registerMethod === "methodRegister"
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c("label", { attrs: { for: "inputFullName" } }, [
                            _vm._v("Full Name*"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.registerForm.full_name.$model,
                                expression: "$v.registerForm.full_name.$model",
                                modifiers: { trim: true },
                              },
                            ],
                            class: {
                              "form-control": true,
                              "is-invalid":
                                _vm.$v.registerForm.full_name.$dirty &&
                                _vm.$v.registerForm.full_name.$error,
                            },
                            attrs: {
                              type: "text",
                              name: "full_name",
                              placeholder: "Full Name",
                              id: "inputFullName",
                              required: "",
                              maxlength: "50",
                            },
                            domProps: {
                              value: _vm.$v.registerForm.full_name.$model,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.registerForm.full_name,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.registerForm.full_name.$dirty &&
                          !_vm.$v.registerForm.full_name.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Full name is required."),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$v.registerForm.full_name.$dirty &&
                          !_vm.$v.registerForm.full_name.minLength
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "Full name has a minimum of 3 characters."
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.registerType === "corporate"
                          ? _c(
                              "div",
                              { staticClass: "form-group col-md-3" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "inputCompany" } },
                                  [_vm._v("Company*")]
                                ),
                                _vm._v(" "),
                                _c("vue-bootstrap-typeahead", {
                                  attrs: {
                                    placeholder: "Company Name",
                                    data: _vm.companyNames,
                                    serializer: (v) => v.name,
                                    id: "inputCompany",
                                    inputClass:
                                      _vm.$v.companyName.$dirty &&
                                      _vm.$v.companyName.$error
                                        ? "is-invalid"
                                        : "",
                                  },
                                  on: {
                                    hit: _vm.selectCompany,
                                    input: _vm.deselectCompany,
                                  },
                                  model: {
                                    value: _vm.$v.companyName.$model,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$v.companyName,
                                        "$model",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "$v.companyName.$model",
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  ref: "corporate_company_name",
                                  attrs: {
                                    type: "hidden",
                                    name: "companies[0][name]",
                                    value: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  ref: "corporate_company_id",
                                  attrs: {
                                    type: "hidden",
                                    name: "companies[0][id]",
                                    value: "",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.$v.companyName.$dirty &&
                                !_vm.$v.companyName.required
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "invalid-feedback",
                                        staticStyle: { display: "block" },
                                      },
                                      [_vm._v("Company name is required.")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.registerType === "corporate"
                          ? _c("div", { staticClass: "form-group col-md-3" }, [
                              _c("label", { attrs: { for: "inputJobTitle" } }, [
                                _vm._v("Job Title"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "user_attribute[title]",
                                  id: "inputJobTitle",
                                  placeholder: "Job Title",
                                  maxlength: "255",
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.registerType === "clinical"
                          ? _c("div", { staticClass: "form-group col-md-6" }, [
                              _c(
                                "label",
                                { attrs: { for: "inputPracticeName" } },
                                [_vm._v("Practice Name*")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value:
                                      _vm.$v.registerForm.user_attribute
                                        .practice.name.$model,
                                    expression:
                                      "$v.registerForm.user_attribute.practice.name.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: {
                                  "form-control": true,
                                  "is-invalid":
                                    _vm.$v.registerForm.user_attribute.practice
                                      .name.$dirty &&
                                    _vm.$v.registerForm.user_attribute.practice
                                      .name.$error,
                                },
                                attrs: {
                                  type: "text",
                                  name: "user_attribute[practice][name]",
                                  id: "inputPracticeName",
                                  placeholder: "Practice Name",
                                  required: "",
                                  maxlength: "255",
                                },
                                domProps: {
                                  value:
                                    _vm.$v.registerForm.user_attribute.practice
                                      .name.$model,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.registerForm.user_attribute
                                        .practice.name,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.registerForm.user_attribute.practice.name
                                .$dirty &&
                              !_vm.$v.registerForm.user_attribute.practice.name
                                .required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Practice name is required.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.registerForm.user_attribute.practice.name
                                .$dirty &&
                              !_vm.$v.registerForm.user_attribute.practice.name
                                .minLength
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "Practice name has a minimum of 3 characters."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("label", { attrs: { for: "inputEmail" } }, [
                        _vm._v("Email*"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.$v.registerForm.email.$model,
                            expression: "$v.registerForm.email.$model",
                            modifiers: { trim: true },
                          },
                        ],
                        class: {
                          "form-control": true,
                          "is-invalid":
                            _vm.$v.registerForm.email.$dirty &&
                            _vm.$v.registerForm.email.$error,
                        },
                        attrs: {
                          type: "email",
                          name:
                            _vm.registerMethod === "methodRegister"
                              ? "email"
                              : "login_email",
                          placeholder: "Email",
                          id: "inputEmail",
                          required: "",
                          maxlength: "254",
                        },
                        domProps: { value: _vm.$v.registerForm.email.$model },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.registerForm.email,
                              "$model",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.registerForm.email.$dirty &&
                      !_vm.$v.registerForm.email.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Email address is required."),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$v.registerForm.email.$dirty &&
                      !_vm.$v.registerForm.email.email
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Email address has to be valid."),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: {
                          "form-group": true,
                          "col-md-3": _vm.registerMethod === "methodRegister",
                          "col-md-6": _vm.registerMethod === "methodSignIn",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "inputPassword" } }, [
                          _vm._v("Password*"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.registerForm.passwd.$model,
                              expression: "$v.registerForm.passwd.$model",
                              modifiers: { trim: true },
                            },
                          ],
                          class: {
                            "form-control": true,
                            "is-invalid":
                              _vm.$v.registerForm.passwd.$dirty &&
                              _vm.$v.registerForm.passwd.$error,
                          },
                          attrs: {
                            type: "password",
                            name:
                              _vm.registerMethod === "methodRegister"
                                ? "passwd"
                                : "password",
                            placeholder: "Enter a password",
                            id: "inputPassword",
                            required: "",
                          },
                          domProps: {
                            value: _vm.$v.registerForm.passwd.$model,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.registerForm.passwd,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.registerForm.passwd.$dirty &&
                        !_vm.$v.registerForm.passwd.required
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("Password is required."),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$v.registerForm.passwd.$dirty &&
                        !_vm.$v.registerForm.passwd.minLength
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("Password has a minimum of 6 characters."),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.registerMethod === "methodRegister"
                      ? _c("div", { staticClass: "form-group col-md-3" }, [
                          _c(
                            "label",
                            { attrs: { for: "inputReenterPassword" } },
                            [_vm._v("Re-enter Password*")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value:
                                  _vm.$v.registerForm.passwd_confirm.$model,
                                expression:
                                  "$v.registerForm.passwd_confirm.$model",
                                modifiers: { trim: true },
                              },
                            ],
                            class: {
                              "form-control": true,
                              "is-invalid":
                                _vm.$v.registerForm.passwd_confirm.$dirty &&
                                _vm.$v.registerForm.passwd_confirm.$error,
                            },
                            attrs: {
                              type: "password",
                              name: "passwd_confirm",
                              placeholder: "Re-enter your password",
                              id: "inputReenterPassword",
                            },
                            domProps: {
                              value: _vm.$v.registerForm.passwd_confirm.$model,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.registerForm.passwd_confirm,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.registerForm.passwd_confirm.$dirty &&
                          !_vm.$v.registerForm.passwd_confirm.sameAsPasswd
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Password re-entry has to match."),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.registerMethod === "methodRegister" &&
                  _vm.registerType === "clinical"
                    ? _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          {
                            class: {
                              "form-group": true,
                              "col-md-6":
                                _vm.registerForm.user_attribute.practice
                                  .location_id === 1,
                              col:
                                _vm.registerForm.user_attribute.practice
                                  .location_id !== 1,
                            },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "inputPracticeLocationId" },
                              },
                              [_vm._v("Country*")]
                            ),
                            _vm._v(" "),
                            _c(
                              "multiselect",
                              {
                                class: {
                                  "is-invalid":
                                    _vm.$v.registerForm.user_attribute.practice
                                      .location_id.$dirty &&
                                    _vm.$v.registerForm.user_attribute.practice
                                      .location_id.$error,
                                },
                                attrs: {
                                  options: _vm.countryOptions,
                                  placeholder: "Select your country...",
                                  label: "alias",
                                  "track-by": "id",
                                  "deselect-label": "",
                                  "aria-invalid": "false",
                                  id: "inputPracticeLocationId",
                                  required: "",
                                },
                                model: {
                                  value: _vm.selectedCountry,
                                  callback: function ($$v) {
                                    _vm.selectedCountry =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "selectedCountry",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "noResult" },
                                    slot: "noResult",
                                  },
                                  [_vm._v("No matching countries found.")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              attrs: {
                                type: "hidden",
                                name: "user_attribute[practice][location_id]",
                              },
                              domProps: {
                                value:
                                  _vm.registerForm.user_attribute.practice
                                    .location_id,
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.registerForm.user_attribute.practice
                              .location_id.$dirty &&
                            !_vm.$v.registerForm.user_attribute.practice
                              .location_id.required
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v("Country is required."),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.registerForm.user_attribute.practice.location_id ===
                        1
                          ? _c("div", { staticClass: "form-group col-md-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "inputPracticeState" },
                                },
                                [_vm._v("State")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.registerForm.user_attribute.practice
                                          .state,
                                      expression:
                                        "registerForm.user_attribute.practice.state",
                                    },
                                  ],
                                  class: {
                                    "form-control": true,
                                    "is-invalid":
                                      _vm.$v.registerForm.user_attribute
                                        .practice.state.$dirty &&
                                      _vm.$v.registerForm.user_attribute
                                        .practice.state.$error,
                                  },
                                  attrs: {
                                    name: "user_attribute[practice][state]",
                                    id: "inputPracticeState",
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.registerForm.user_attribute
                                          .practice,
                                        "state",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "AL" } }, [
                                    _vm._v("Alabama"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "AK" } }, [
                                    _vm._v("Alaska"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "AZ" } }, [
                                    _vm._v("Arizona"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "AR" } }, [
                                    _vm._v("Arkansas"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "CA" } }, [
                                    _vm._v("California"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "CO" } }, [
                                    _vm._v("Colorado"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "CT" } }, [
                                    _vm._v("Connecticut"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "DE" } }, [
                                    _vm._v("Delaware"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "FL" } }, [
                                    _vm._v("Florida"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "GA" } }, [
                                    _vm._v("Georgia"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "HI" } }, [
                                    _vm._v("Hawaii"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "ID" } }, [
                                    _vm._v("Idaho"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "IL" } }, [
                                    _vm._v("Illinois"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "IN" } }, [
                                    _vm._v("Indiana"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "IA" } }, [
                                    _vm._v("Iowa"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "KS" } }, [
                                    _vm._v("Kansas"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "KY" } }, [
                                    _vm._v("Kentucky"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "LA" } }, [
                                    _vm._v("Louisiana"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "ME" } }, [
                                    _vm._v("Maine"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "MD" } }, [
                                    _vm._v("Maryland"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "MA" } }, [
                                    _vm._v("Massachusetts"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "MI" } }, [
                                    _vm._v("Michigan"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "MN" } }, [
                                    _vm._v("Minnesota"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "MS" } }, [
                                    _vm._v("Mississippi"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "MO" } }, [
                                    _vm._v("Missouri"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "MT" } }, [
                                    _vm._v("Montana"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "NE" } }, [
                                    _vm._v("Nebraska"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "NV" } }, [
                                    _vm._v("Nevada"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "NH" } }, [
                                    _vm._v("New Hampshire"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "NJ" } }, [
                                    _vm._v("New Jersey"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "NM" } }, [
                                    _vm._v("New Mexico"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "NY" } }, [
                                    _vm._v("New York"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "NC" } }, [
                                    _vm._v("North Carolina"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "ND" } }, [
                                    _vm._v("North Dakota"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "OH" } }, [
                                    _vm._v("Ohio"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "OK" } }, [
                                    _vm._v("Oklahoma"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "OR" } }, [
                                    _vm._v("Oregon"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "PA" } }, [
                                    _vm._v("Pennsylvania"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "RI" } }, [
                                    _vm._v("Rhode Island"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "SC" } }, [
                                    _vm._v("South Carolina"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "SD" } }, [
                                    _vm._v("South Dakota"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "TN" } }, [
                                    _vm._v("Tennessee"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "TX" } }, [
                                    _vm._v("Texas"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "UT" } }, [
                                    _vm._v("Utah"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "VT" } }, [
                                    _vm._v("Vermont"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "VA" } }, [
                                    _vm._v("Virginia"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "WA" } }, [
                                    _vm._v("Washington"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "WV" } }, [
                                    _vm._v("West Virginia"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "WI" } }, [
                                    _vm._v("Wisconsin"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "WY" } }, [
                                    _vm._v("Wyoming"),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.$v.registerForm.user_attribute.practice.state
                                .$dirty &&
                              (!_vm.$v.registerForm.user_attribute.practice
                                .state.required ||
                                !_vm.$v.registerForm.user_attribute.practice
                                  .state.minLength ||
                                !_vm.$v.registerForm.user_attribute.practice
                                  .state.maxLength)
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("State is required.")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.registerMethod === "methodRegister" &&
                  _vm.registerType === "clinical"
                    ? _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          {
                            class: {
                              "col-md-6":
                                _vm.registerForm.user_attribute.practice
                                  .location_id !== null &&
                                _vm.specialtyOptions.length > 1,
                              col:
                                _vm.registerForm.user_attribute.practice
                                  .location_id === null ||
                                _vm.specialtyOptions.length <= 1,
                            },
                          },
                          [
                            _c("div", { staticClass: "card bg-light" }, [
                              _c("div", { staticClass: "card-body" }, [
                                _c("div", { staticClass: "required" }, [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [_vm._v("Practice Role")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.registerForm.user_attribute
                                                .practice_role_id,
                                            expression:
                                              "registerForm.user_attribute.practice_role_id",
                                          },
                                        ],
                                        class: {
                                          "form-control": true,
                                          "is-invalid":
                                            _vm.$v.registerForm.user_attribute
                                              .practice_role_id.$dirty &&
                                            _vm.$v.registerForm.user_attribute
                                              .practice_role_id.$error,
                                        },
                                        attrs: {
                                          name: "user_attribute[practice_role_id]",
                                          id: "user-attribute-practice-role-id",
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.registerForm.user_attribute,
                                                "practice_role_id",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            _vm.onChangePracticeRole,
                                          ],
                                        },
                                      },
                                      _vm._l(
                                        _vm.practiceRoleOptions,
                                        function (practiceRoleOption) {
                                          return _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: practiceRoleOption.id,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(practiceRoleOption.label)
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _vm.$v.registerForm.user_attribute
                                      .practice_role_id.$dirty &&
                                    (!_vm.$v.registerForm.user_attribute
                                      .practice_role_id.required ||
                                      !_vm.$v.registerForm.user_attribute
                                        .practice_role_id.numeric)
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [_vm._v("Practice role is required.")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.npiRequired
                                  ? _c("div", { staticClass: "form-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value:
                                              _vm.$v.registerForm.user_attribute
                                                .npi.$model,
                                            expression:
                                              "$v.registerForm.user_attribute.npi.$model",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        class: {
                                          "form-control": true,
                                          "is-invalid":
                                            _vm.$v.registerForm.user_attribute
                                              .npi.$dirty &&
                                            _vm.$v.registerForm.user_attribute
                                              .npi.$error,
                                        },
                                        attrs: {
                                          type: "text",
                                          name: "user_attribute[npi]",
                                          placeholder: "NPI",
                                          id: "npi",
                                          maxlength: "10",
                                        },
                                        domProps: {
                                          value:
                                            _vm.$v.registerForm.user_attribute
                                              .npi.$model,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.$v.registerForm.user_attribute
                                                .npi,
                                              "$model",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.$v.registerForm.user_attribute.npi
                                        .$dirty &&
                                      !_vm.$v.registerForm.user_attribute.npi
                                        .required
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [_vm._v("NPI is required.")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$v.registerForm.user_attribute.npi
                                        .$dirty &&
                                      !_vm.$v.registerForm.user_attribute.npi
                                        .numeric
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "NPI can only contain numbers."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$v.registerForm.user_attribute.npi
                                        .$dirty &&
                                      _vm.$v.registerForm.user_attribute.npi
                                        .numeric &&
                                      (!_vm.$v.registerForm.user_attribute.npi
                                        .minLength ||
                                        !_vm.$v.registerForm.user_attribute.npi
                                          .maxLength)
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [_vm._v("Invalid NPI.")]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.registerForm.user_attribute.practice.location_id !==
                          null && _vm.specialtyOptions.length > 1
                          ? _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "card bg-light" }, [
                                _c("div", { staticClass: "card-body" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "control-label" },
                                        [_vm._v("Your specialties")]
                                      ),
                                      _vm._v(" "),
                                      _c("treeselect", {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.tag_list.$dirty &&
                                            _vm.$v.tag_list.$error,
                                        },
                                        attrs: {
                                          multiple: true,
                                          options: _vm.specialtyOptions,
                                          flat: true,
                                          "value-consists-of":
                                            "ALL_WITH_INDETERMINATE",
                                          normalizer: _vm.normalizer,
                                          placeholder: "Select one or more",
                                        },
                                        model: {
                                          value: _vm.tag_list,
                                          callback: function ($$v) {
                                            _vm.tag_list = $$v
                                          },
                                          expression: "tag_list",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.$v.tag_list.$dirty &&
                                      (!_vm.$v.tag_list.required ||
                                        !_vm.$v.tag_list.minLength)
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "At least one specialty is required."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.registerMethod === "methodRegister" &&
                  _vm.registerType === "corporate"
                    ? [
                        _vm._m(29),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group select col-md-6" },
                            [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Topics of Interest"),
                              ]),
                              _vm._v(" "),
                              _c("multiselect", {
                                attrs: {
                                  options: _vm.topicsOfInterestOptions,
                                  multiple: true,
                                  "close-on-select": false,
                                  "clear-on-select": false,
                                  placeholder: "Select one or more",
                                },
                                model: {
                                  value: _vm.topicsOfInterest,
                                  callback: function ($$v) {
                                    _vm.topicsOfInterest = $$v
                                  },
                                  expression: "topicsOfInterest",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    multiple: "",
                                    name: "topics_of_interest[]",
                                  },
                                },
                                _vm._l(_vm.topicsOfInterest, function (option) {
                                  return _c(
                                    "option",
                                    {
                                      attrs: { selected: "" },
                                      domProps: { value: option },
                                    },
                                    [_vm._v(_vm._s(option))]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.topicsOfInterest.includes("Other")
                            ? _c("div", { staticClass: "form-group text" }, [
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "topics_of_interest[other]",
                                    placeholder: "Other",
                                    maxlength: "255",
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group select col-md-6" },
                            [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Products of Interest"),
                              ]),
                              _vm._v(" "),
                              _c("multiselect", {
                                attrs: {
                                  options: _vm.productsOfInterestOptions,
                                  multiple: true,
                                  "close-on-select": false,
                                  "clear-on-select": false,
                                  placeholder: "Select one or more",
                                },
                                model: {
                                  value: _vm.productsOfInterest,
                                  callback: function ($$v) {
                                    _vm.productsOfInterest = $$v
                                  },
                                  expression: "productsOfInterest",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    multiple: "",
                                    name: "products_of_interest[]",
                                  },
                                },
                                _vm._l(
                                  _vm.productsOfInterest,
                                  function (option) {
                                    return _c(
                                      "option",
                                      {
                                        attrs: { selected: "" },
                                        domProps: { value: option },
                                      },
                                      [_vm._v(_vm._s(option))]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.productsOfInterest.includes("Other")
                            ? _c("div", { staticClass: "form-group text" }, [
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "products_of_interest[other]",
                                    placeholder: "Other",
                                    maxlength: "255",
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center my-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: {
                          type: "submit",
                          disabled: !_vm.isMounted || _vm.isSubmitting,
                        },
                      },
                      [_vm._v("Submit")]
                    ),
                  ]),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("request-sample"),
      _vm._v(" "),
      _c("contact-us"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "market-trends-masthead" }, [
      _c("h1", { staticClass: "section-heading" }, [
        _vm._v("Ophthalmic Market Trends"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Ophthalmic Market Trends")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", [
          _vm._v(
            "Delivering concise, unbiased, expert-reviewed analytics every quarter "
          ),
          _c("span", [
            _vm._v("to drive innovation and competitive intelligence."),
          ]),
          _vm._v(
            " Ideal for companies and investors of all sizes exploring..."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h2", [_vm._v("Four Specialty Offerings")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Bonus coverage of ocular surface disease, ocular plastics, and practice management routinely included. Custom data cuts and cross tabulation packages available."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header border-bottom-0 py-5" }, [
      _c("h3", { staticClass: "card-title text-center mb-0" }, [
        _vm._v("Cataract Edition"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Location of cataract surgeries")]),
      _vm._v(" "),
      _c("li", [_vm._v("Procedure volumes")]),
      _vm._v(" "),
      _c("li", [_vm._v("US premium IOL penetration and surgeon adoption")]),
      _vm._v(" "),
      _c("li", [_vm._v("US IOL market shares by surgery locations...")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header border-bottom-0 py-5" }, [
      _c("h3", { staticClass: "card-title text-center mb-0" }, [
        _vm._v("Refractive Edition"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Laser center ownership by market segment")]),
      _vm._v(" "),
      _c("li", [_vm._v("Average refractive surgery fees")]),
      _vm._v(" "),
      _c("li", [_vm._v("Location of surgery")]),
      _vm._v(" "),
      _c("li", [_vm._v("Procedure market share by technology/device...")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header border-bottom-0 py-5" }, [
      _c("h3", { staticClass: "card-title text-center mb-0" }, [
        _vm._v("Glaucoma Edition"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Primary patient diagnoses")]),
      _vm._v(" "),
      _c("li", [_vm._v("Number of prescribed medications")]),
      _vm._v(" "),
      _c("li", [_vm._v("Patient medication usage and compliance")]),
      _vm._v(" "),
      _c("li", [_vm._v("First and second line therapies...")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header border-bottom-0 py-5" }, [
      _c("h3", { staticClass: "card-title text-center mb-0" }, [
        _vm._v("Retina Edition"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("IVT injections by disease and product")]),
      _vm._v(" "),
      _c("li", [_vm._v("DME treatments")]),
      _vm._v(" "),
      _c("li", [_vm._v("Corticosteroid use")]),
      _vm._v(" "),
      _c("li", [_vm._v("Vitrectomy volume and location")]),
      _vm._v(" "),
      _c("li", [_vm._v("Use of Eylea, Lucentis, Avastin...")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4 mb-5" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h2", [_vm._v("Ophthalmic Market Trends (OMT) Deliverables")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body text-center" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Trend Analysis")]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "Trend analysis based on comparisons with information gathered in previous years."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body text-center" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Two-Year Summary")]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "Two-year historical summary by quarter of procedures by type and product used."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body text-center" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Full-Year Forecast")]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v("A full-year forecast for procedures within each specialty."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4 mb-5" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h2", [_vm._v("OMT Sources")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "card-body d-flex align-items-center text-center pt-3 pb-4",
      },
      [
        _c("h5", { staticClass: "card-title" }, [
          _vm._v("Surveys of Individual Confirmed Ophthalmologists"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "card-body d-flex align-items-center text-center pt-3 pb-4",
      },
      [
        _c("h5", { staticClass: "card-title" }, [
          _vm._v("Proprietary Disease Models"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "card-body d-flex align-items-center text-center pt-3 pb-4",
      },
      [
        _c("h5", { staticClass: "card-title" }, [
          _vm._v("Other Primary Research and Public Data Sources"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-4 mb-5" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h2", [_vm._v("Highlights/Contents of OMT by Specialty")]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-2" }, [
          _vm._v(
            "Delivering quarterly market share analysis & forecasts, ATUs, and other industry trends."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Location of cataract surgeries")]),
      _vm._v(" "),
      _c("li", [_vm._v("Procedure volumes")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "US market shares by model for IOLs: monofocal, presbyopia-correcting-IOLs, and toric IOLs"
        ),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("US premium IOL penetration and surgeon adoption")]),
      _vm._v(" "),
      _c("li", [_vm._v("US IOL market shares by surgery locations")]),
      _vm._v(" "),
      _c("li", [_vm._v("US phaco machines market share and installed base")]),
      _vm._v(" "),
      _c("li", [_vm._v("US market shares for viscoelastic")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Adoption plans for femtosecond laser cataract surgery (FLACS)"),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Adoption plans and use cases for intraoperative and postoperative pharmaceuticals"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Procedure volumes and market share for MIGS stents and canal-based procedures"
        ),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Product purchasing plans")]),
      _vm._v(" "),
      _c("li", [_vm._v("Average premium IOL surgery fees by type")]),
      _vm._v(" "),
      _c("li", [_vm._v("Patient financing")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Laser center ownership by market segment")]),
      _vm._v(" "),
      _c("li", [_vm._v("Average refractive surgery fees by type")]),
      _vm._v(" "),
      _c("li", [_vm._v("Location of surgery")]),
      _vm._v(" "),
      _c("li", [_vm._v("Procedure market share by technology/device")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Femto/flap-maker and excimer lasers used by responding surgeons"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Procedure volume by type: LASIK, surface ablation, SMILE, corneal inlays, phakic IOLs"
        ),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Refractive error distribution by patient candidate")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Ocular surface disease prevalence/screening and treatment"),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Surgeon interest in presbyopia drops")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Primary patient diagnoses")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Glaucoma treatment regimens: medication & surgical intervention rates"
        ),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Number of prescribed medications")]),
      _vm._v(" "),
      _c("li", [_vm._v("Patient medication usage and compliance")]),
      _vm._v(" "),
      _c("li", [_vm._v("First and second line therapies")]),
      _vm._v(" "),
      _c("li", [_vm._v("Glaucoma procedure volumes and share by type")]),
      _vm._v(" "),
      _c("li", [_vm._v("MIGS adoption and procedures by product")]),
      _vm._v(" "),
      _c("li", [_vm._v("Canal-based adoption and procedures by product")]),
      _vm._v(" "),
      _c("li", [_vm._v("Glaucoma tube shunt procedure by product")]),
      _vm._v(" "),
      _c("li", [_vm._v("Laser procedures by type")]),
      _vm._v(" "),
      _c("li", [_vm._v("Interest in investigational glaucoma treatments")]),
      _vm._v(" "),
      _c("li", [_vm._v("Surgical locations")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("IVT injections by disease and product")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Use of Eylea, Lucentis, Avastin, Macugen, and Visudyne PDT"),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("DME treatments")]),
      _vm._v(" "),
      _c("li", [_vm._v("Corticosteroid use")]),
      _vm._v(" "),
      _c("li", [_vm._v("Photocoagulation volume and photocoagulators used")]),
      _vm._v(" "),
      _c("li", [_vm._v("Vitrectomy volume and location")]),
      _vm._v(" "),
      _c("li", [_vm._v("Vitrectomy machine use and market share")]),
      _vm._v(" "),
      _c("li", [_vm._v("Reusable and disposable instruments")]),
      _vm._v(" "),
      _c("li", [_vm._v("Visualization agents")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h2", [_vm._v("Not seeing what you need?")]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-2" }, [
          _vm._v(
            "In addition to our syndicated market trends reports, we also leverage our expert knowledge of the ophthalmic industry and robust panel of US and European ophthalmologists and optometrists to design custom primary research that enhances strategy and operations for our clients. Our unique experience makes us a trusted partner in helping clients with new product design, messaging, pricing and experience with current technology, and expectations from future treatments."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Custom targeted surveys")]),
      _vm._v(" "),
      _c("li", [_vm._v("Surveying and/or interviewing of ophthalmologists")]),
      _vm._v(" "),
      _c("li", [_vm._v("Advisory boards")]),
      _vm._v(" "),
      _c("li", [_vm._v("Territory analyses")]),
      _vm._v(" "),
      _c("li", [_vm._v("Other primary research initiatives")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card d-flex bg-transparent border-0" }, [
      _c(
        "div",
        {
          staticClass:
            "card-body d-flex align-items-center justify-content-center px-5",
        },
        [
          _c("img", {
            staticClass: "img-fluid shadow",
            staticStyle: { "max-height": "554px" },
            attrs: {
              src: "/static/landing/img/product-primary-research-cover.jpg",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center my-3" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Sign In or Register")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center my-3" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Personalized Reports")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }