<template>
	<modal name="careerAnalystModal" height="auto" :scrollable="true" :reset="true" :clickToClose="true" :adaptive="true" :width="800" transition="nice-modal-fade">
		<div class="modal-header">
			<h5 class="modal-title">Seeking Market Analyst/Writer for Market Research Firm in Health Care</h5>
			<button type="button" class="close" @click="$modal.hide('careerAnalystModal')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<h6 class="font-weight-bold">Who We Are:</h6>
			<p>Market Scope is a market research firm that partners with pharmaceutical companies, medical device makers, and physicians to better understand global market trends. Our products and services are used by ophthalmic manufacturers, investors, entrepreneurs, and venture capitalists to measure performance, make investment decisions, and allocate resources. Our data, analysis, and forecasts serve as starting points for many annual planning cycles, strategic plans, acquisition analyses, and return on investment decisions. We offer a casual work environment, company matching retirement plan, health insurance, and competitive salary.</p>
			<h6 class="font-weight-bold">Job Description:</h6>
			<p>The Market Analyst/Writer will be responsible for creating in-depth market reports. This will include researching and analyzing financial documents, medical databases, FDA filings, company websites, and doctor surveys, in addition to attending medical conferences. This research will be used to build analytical models and create a narrative that accurately reflects conditions in a global market.</p>
			<h6 class="font-weight-bold">Key Responsibilities:</h6>
			<ul>
				<li>Build/maintain analytic models and expertise in medical device or pharmaceutical markets</li>
				<li>Develop and update comprehensive market reports</li>
				<li>Author articles for monthly newsletters and website</li>
				<li>Respond to client requests for additional information/explanations</li>
				<li>Contribute to custom research projects</li>
				<li>Attend medical conferences and build industry and doctor relationships</li>
			</ul>
			<h6 class="font-weight-bold">Qualifications:</h6>
			<ul>
				<li>Bachelor’s degree required; MBA or Masters in related field preferred</li>
				<li>3-5 years’ experience, including data analysis and technical writing</li>
				<li>Concentration in Finance, Accounting, Statistics preferred</li>
				<li>Expert skills in Microsoft Word, Excel, and PowerPoint</li>
			</ul>
			<h6 class="font-weight-bold">Skill Requirements:</h6>
			<ul>
				<li>Excellent analytical and technical writing skills</li>
				<li>Strong interpersonal skills—ability to initiate/maintain relationships with industry clients/doctors</li>
				<li>Highly motivated learner with the ability to develop and maintain market expertise</li>
				<li>Good organization and project management skills</li>
				<li>Ability to work independently and collaboratively on long-term projects</li>
				<li>Detail oriented with a passion for continual improvement</li>
			</ul>
			<p class="mb-0 font-weight-bold">If you are interested in the position, please send your resume to: <a href="mailto:resumes@market-scope.com"><strong>resumes@market-scope.com</strong></a>.</p>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-default" @click="$modal.hide('careerAnalystModal')">Close</button>
			<a class="btn btn-primary download-link" target="_blank" :href="'/pdfs/JD-Analyst-Writer.pdf'"><i class="far fa-file-pdf"></i> PDF</a>
		</div>
	</modal>
</template>

<script>
export default {
	name: 'CareerAnalyst'
}
</script>

<style lang="scss" scoped>
.modal-body {
	p {
		color: $gray-light;
		font-size: inherit;
	}
	
	ul {
		color: $gray-light;
	}
	
	a {
		color: $theme-secondary;
	}
}
</style>