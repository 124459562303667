var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "bio-masthead w-100" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-6 d-flex align-items-center justify-content-end bio-headshot",
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: require("@assets/img/about/headshot-23.jpg"),
                    expression: "require('@assets/img/about/headshot-23.jpg')",
                  },
                ],
                staticClass: "img-fluid",
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c("router-link", { attrs: { to: { name: "About" }, exact: "" } }, [
              _vm._v("About"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1),
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("authored-reports", { attrs: { authorId: 11772 } }),
      _vm._v(" "),
      _c("section", { staticClass: "bio managed-products" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "row justify-content-center px-5" }, [
            _c("div", { staticClass: "col-md-6 col-lg-4 p-2" }, [
              _c("div", { staticClass: "card border-0 rounded-0 text-white" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/about/bios/custom-and-primary-research.jpg"),
                      expression:
                        "require('@assets/img/about/bios/custom-and-primary-research.jpg')",
                    },
                  ],
                  staticClass: "card-img py-4 px-5",
                  attrs: { alt: "Custom & Primary Research Solutions" },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-img-overlay text-center" },
                  [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-success py-2 px-3 font-weight-bold",
                        attrs: { to: { name: "PrimaryResearch" } },
                      },
                      [_vm._v("LEARN MORE")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("combined-registration", { attrs: { origin: "bio" } }),
      _vm._v(" "),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-md-6 d-flex align-items-center justify-content-center p-5 bio-info",
      },
      [
        _c("div", [
          _c("h1", [_vm._v("Sharon Gotter")]),
          _vm._v(" "),
          _c("h3", { staticClass: "my-4 font-weight-bold text-uppercase" }, [
            _vm._v("Research Manager"),
          ]),
          _vm._v(" "),
          _c("h4", [
            _vm._v("Phone: "),
            _c("a", { attrs: { href: "tel:+13148350600" } }, [
              _vm._v("314-835-0600"),
            ]),
          ]),
          _vm._v(" "),
          _c("h4", [
            _vm._v("Email: "),
            _c("a", { attrs: { href: "mailto:sharon@market-scope.com" } }, [
              _vm._v("sharon@market-scope.com"),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Sharon Gotter")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bio content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row px-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "mx-md-5" }, [
              _c("h4", { staticClass: "mt-3 mb-4" }, [
                _vm._v(
                  "Sharon Gotter specializes in all types of primary research techniques and focus group moderation."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Sharon joined Market Scope in 2024, bringing over 20 years’ experience in marketing research. She has a master’s degree in marketing research from Southern Illinois University at Edwardsville and has spent her career working in all types of research, such as political polling, financial, and retail research. In her role as research manager, she is responsible for overseeing and executing the development and delivery of primary research projects for corporate clients."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "In her spare time, she is an avid reader, reality show viewer, and bargain hunter/yard saler."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h3", { staticClass: "mt-4 mb-5" }, [
          _vm._v("Managed Products & Services"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "card-title" }, [
      _vm._v("Custom & Primary"),
      _c("br"),
      _vm._v("Research Solutions"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }