<template>
	<modal name="reportVideoModal" height="auto" :scrollable="true" :reset="true" :clickToClose="true" :adaptive="true" transition="nice-modal-fade" @before-open="beforeOpen" @closed="closed">
		<div class="modal-header">
			<h5 class="modal-title">{{ header }}<br /><strong>{{ subheader }}</strong></h5>
			<button type="button" class="close" @click="$modal.hide('reportVideoModal')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="embed-responsive embed-responsive-16by9">
				<iframe v-if="videoId" class="embed-responsive-item" :src="'https://www.youtube.com/embed/' + videoId + '?autoplay=1&modestbranding=1&controls=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
		</div>
	</modal>
</template>

<script>
export default {
	name: 'ReportVideo',
	data() {
		return {
			header: '',
			subheader: '',
			videoId: ''
		}
	},
	methods: {
		beforeOpen(e) {
			this.header = e.params.header
			this.subheader = e.params.subheader
			this.videoId = e.params.videoId
		},
		closed(e) {
			this.header = ''
			this.subheader = ''
			this.videoId = ''
		}
	}
}
</script>