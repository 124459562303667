var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { subpage: true } }),
      _vm._v(" "),
      _c("header", { staticClass: "disease-models-masthead" }),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Landing" }, exact: "" } },
              [_vm._v("Home")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "disease-models", attrs: { id: "disease-models" } },
        [
          _c("div", { staticClass: "container header-row" }, [
            _c("div", [
              _c("div", { staticClass: "mx-auto text-center" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: require("@assets/img/icons/MarketScope-Icons-08.svg"),
                      expression:
                        "require('@assets/img/icons/MarketScope-Icons-08.svg')",
                    },
                  ],
                  staticStyle: {
                    "max-width": "70px",
                    width: "auto",
                    height: "auto",
                  },
                  attrs: { width: "70", height: "70" },
                }),
                _vm._v(" "),
                _c("h1", { staticClass: "section-heading" }, [
                  _vm._v("Ophthalmic Disease Prevalence Models"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Market Scope’s ophthalmic disease models offer extensive country-level prevalence data and five-year forecasts by age group for all countries with a population over a million."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "These proprietary models take into consideration the latest clinical research and demographic projections on a global scale and can be customized by geography and disease category to meet clients’ diverse needs. Disease models are often used to evaluate target markets for clinical-stage and commercial-stage products and gain insights into the volume and demographics of diseased populations."
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-deck w-100 mx-0" }, [
                  _c("div", { staticClass: "card border-0 text-center" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h5", {
                        ref: "flipStudies",
                        staticClass: "card-title mb-0 font-weight-bold",
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "font-weight-bold mb-0" }, [
                        _vm._v("Clinical Studies Researched"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card border-0 text-center" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h5", {
                        ref: "flipDiseases",
                        staticClass: "card-title mb-0 font-weight-bold",
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "font-weight-bold mb-0" }, [
                        _vm._v("Ophthalmic Diseases/Conditions Modeled"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card border-0 text-center" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h5", {
                        ref: "flipCountries",
                        staticClass: "card-title mb-0 font-weight-bold",
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "font-weight-bold mb-0" }, [
                        _vm._v("Countries Forecasted"),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(1),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container mt-5 disease-cards" }, [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-deck w-100 mx-0" },
              _vm._l(_vm.diseaseCards, function (card) {
                return _c(
                  "div",
                  {
                    staticClass: "card text-white shadow mb-4",
                    style: { "background-color": card.color },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-body d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "h5",
                          { staticClass: "card-title text-center mb-0" },
                          [_vm._v(_vm._s(card.title))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-overlay d-flex align-items-center justify-content-center",
                          },
                          [
                            _c("div", [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "card-title font-weight-bold text-center mb-0",
                                  style: { color: card.color },
                                },
                                [_vm._v(_vm._s(card.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "card-text" },
                                _vm._l(card.content, function (line) {
                                  return _c("li", [_vm._v(_vm._s(line))])
                                }),
                                0
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container mt-3" }, [
            _c("div", { staticClass: "row mb-3" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "card border-0" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("LazyYoutube", {
                        attrs: {
                          src: "https://youtu.be/8pAqVyI_DYI",
                          showTitle: false,
                          customThumbnail:
                            "https://img.youtube.com/vi/8pAqVyI_DYI/sddefault.jpg",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.products.length
              ? _c("div", { staticClass: "row mb-3" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "card border-0" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body pt-2" }, [
                        _c("div", { staticClass: "container" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "card-group" },
                              _vm._l(_vm.products, function (product) {
                                return _c(
                                  "div",
                                  { staticClass: "card col p-1 bg-white" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card-body p-0" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Report",
                                                params: {
                                                  id: product.id,
                                                  slug: product.slug,
                                                },
                                              },
                                              title: product.label,
                                            },
                                          },
                                          [
                                            product.document_original_filename ||
                                            product.document_sample_filename
                                              ? _c("img", {
                                                  directives: [
                                                    {
                                                      name: "lazy",
                                                      rawName: "v-lazy",
                                                      value:
                                                        "/files/products/samples/" +
                                                        product.id +
                                                        "/" +
                                                        (product.document_sample_filename
                                                          ? product.document_sample_filename.substr(
                                                              0,
                                                              product.document_sample_filename.lastIndexOf(
                                                                "."
                                                              )
                                                            )
                                                          : product.document_original_filename.substr(
                                                              0,
                                                              product.document_original_filename.lastIndexOf(
                                                                "."
                                                              )
                                                            )) +
                                                        ".thumb.jpg",
                                                      expression:
                                                        "'/files/products/samples/' + product.id + '/' + (product.document_sample_filename ? product.document_sample_filename.substr(0, product.document_sample_filename.lastIndexOf('.')) : product.document_original_filename.substr(0, product.document_original_filename.lastIndexOf('.'))) + '.thumb.jpg'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "img-thumbnail d-block mx-auto border-0",
                                                  attrs: {
                                                    alt: product.cover_img_alt
                                                      ? product.cover_img_alt
                                                      : product.label,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(5),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-center mt-3" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "button" },
                on: { click: _vm.showDiseaseModelsContactUsModal },
              },
              [_vm._v("Contact Us")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("register"),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("disease-models-contact-us"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("span", [_vm._v("Ophthalmic Disease Prevalence Models")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-5" }, [
      _c("div", { attrs: { id: "map" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center mb-3" }, [
        _c("h3", { staticClass: "font-weight-bold" }, [
          _vm._v("Diseases Modeled"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6 my-auto" }, [
      _c("div", { staticClass: "card border-0" }, [
        _c(
          "div",
          { staticClass: "card-header bg-transparent border-bottom-0" },
          [
            _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
              _vm._v("Use cases"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("ul", [
            _c("li", [_vm._v("Evaluation of target markets")]),
            _vm._v(" "),
            _c("li", [_vm._v("Country-level market sizing and potential")]),
            _vm._v(" "),
            _c("li", [_vm._v("Clinical trial planning")]),
            _vm._v(" "),
            _c("li", [_vm._v("Product and company valuation")]),
            _vm._v(" "),
            _c("li", [_vm._v("Allocation of resources")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header bg-transparent border-bottom-0" },
      [
        _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
          _vm._v("Available Models"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card border-0" }, [
          _c(
            "div",
            { staticClass: "card-header bg-transparent border-bottom-0" },
            [
              _c("h3", { staticClass: "card-title font-weight-bold mb-0" }, [
                _vm._v("Learn More"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body pt-2" }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "Contact us using the button below to request more information about these models, request a sample, or schedule a time to discuss live with our team."
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }