<template>
	<modal name="diseaseModelsContactUsModal" height="auto" :scrollable="true" :reset="true" :clickToClose="clickToClose" :adaptive="true" transition="nice-modal-fade" @opened="opened" @closed="closed">
		<form novalidate="novalidate" role="form" v-promise-btn="{ action: 'submit' }" @submit.prevent="send" v-on:keyup.esc="$modal.hide('diseaseModelsContactUsModal')">
			<div class="modal-header">
				<h5 class="modal-title">Contact Us</h5>
				<button type="button" class="close" @click="$modal.hide('diseaseModelsContactUsModal')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="name">Name</label>
						<input v-model="form.name" ref="name" type="text" class="form-control" id="name" placeholder="Name">
					</div>
					<div class="form-group col-md-6">
						<label for="company">Company Name</label>
						<input v-model="form.company" type="text" class="form-control" id="company" placeholder="Company Name">
					</div>
				</div>
				<div class="form-group">
					<label for="contact-email">Email</label>
					<input v-model="form.email" type="email" class="form-control" id="contact-email" placeholder="Email">
				</div>
				<div class="form-group">
					<label for="phone">Phone # <span class="text-muted">(optional)</span></label>
					<input v-model="form.phone" type="tel" class="form-control" id="phone" placeholder="Phone #" />
				</div>
				<div class="form-row">
					<div class="form-group select col-md-6">
						<label for="areas-of-interest">Areas of Interest</label>
						<multiselect v-model="form.areas_of_interest" :options="areasOfInterestOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" placeholder="Select one or more" id="areas-of-interest" />
					</div>
					<div class="form-group select col-md-6">
						<label for="intended-action" class="control-label">I’d like to...</label>
						<select v-model="form.intended_action" class="form-control" name="intended_action" id="intended-action">
							<option disabled value="">Select one</option>
							<option value="Request More Information">Request More Information</option>
							<option value="Request a Sample">Request a Sample</option>
							<option value="Discuss Live">Discuss Live</option>
						</select>
					</div>
				</div>
				<div class="form-group">
					<label for="comments">Comments <span class="text-muted">(optional)</span></label>
					<input v-model="form.comments" type="text" class="form-control" id="comments" placeholder="Comments">
				</div>
				<div class="form-group mb-0">
					<small class="form-notice">Any submitted contact information is confidential and will not be given to any third parties.<br />This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</small>
				</div>
			</div>
			<div class="modal-footer">
				<div v-if="form.status" v-html="form.status" class="w-100"></div>
				<template v-if="!sent">
					<div class="mr-auto">
						<i class="fas fa-phone"></i> Call Us: <a href="tel:+13148350600" style="font-size: 1.1rem; color: #2fbbcc;">314-835-0600</a>
					</div>
					<div class="ml-auto">
						<button type="button" class="btn btn-default" @click="$modal.hide('diseaseModelsContactUsModal')">Cancel</button>
						<button type="submit" class="btn btn-primary">Send</button>
					</div>
				</template>
			</div>
		</form>
	</modal>
</template>

<script>
import Multiselect from 'vue-multiselect'

import { useGoogleRecaptcha } from '../../util/use-google-recaptcha'

export default {
	name: 'DiseaseModelsContactUsModal',
	data() {
		return {
			clickToClose: true,
			sent: false,
			areasOfInterestOptions: ['Glaucoma', 'Retina', 'Dry Eye', 'Refractive Error', 'Cornea', 'Other'],
			form: {
				name: '',
				email: '',
				company: '',
				phone: '',
				areas_of_interest: [],
				intended_action: '',
				comments: '',
				status: ''
			}
		}
	},
	methods: {
		opened(e) {
			this.$refs.name.focus()
		},
		closed(e) {
			Object.assign(this.form, this.$options.data.apply(this).form)
			
			this.clickToClose = true
			this.sent = false
		},
		send() {
			this.clickToClose = false
			
			return useGoogleRecaptcha().getRecaptchaToken('disease_models').then(token => {
				return this.$http.put('func/disease-models-contact-us', {
					name: this.form.name,
					email: this.form.email,
					company: this.form.company,
					phone: this.form.phone,
					areas_of_interest: this.form.areas_of_interest,
					intended_action: this.form.intended_action,
					comments: this.form.comments,
					recaptchaToken: token
				})
					.then(response => {
						this.clickToClose = true
						this.sent = true
						
						this.$set(this.form, 'status', '<h4 class="text-success"><i class="fas fa-check-circle"></i> Email sent.</h4>')
						
						const _paq = window._paq = window._paq || []
						_paq.push(['trackEvent', 'Ophthalmic Disease Prevalence Models', 'Form Submitted', '"Contact Us" Modal'])
						
						setTimeout(() => {
							this.$modal.hide('diseaseModelsContactUsModal')
						}, 1500)
					}, response => {
						this.clickToClose = true
						
						this.$set(this.form, 'status', '<span class="text-danger">Invalid information entered.</span>')
					})
			})
		}
	},
	components: {
		Multiselect
	}
}
</script>