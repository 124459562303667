var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg" }, [
              _c(
                "div",
                { staticClass: "header-content text-center mb-3" },
                [
                  _c("router-link", { attrs: { to: "/", exact: "" } }, [
                    _c("h1", [_vm._v("Market Scope")]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card text-center border-0" }, [
                _c("div", { staticClass: "card-header text-center" }, [
                  _vm.campaign.landing_logo
                    ? _c("img", {
                        staticClass: "img-thumbnail p-3 mb-3",
                        style: {
                          backgroundColor:
                            "#" + _vm.campaign.landing_logo_bg_color,
                        },
                        attrs: {
                          src:
                            "/files/campaigns/logos/" +
                            _vm.campaign.id +
                            "/" +
                            _vm.campaign.landing_logo,
                          alt: "Campaign Logo",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", { staticClass: "card-title mb-0" }, [
                    _vm._v(
                      _vm._s(
                        _vm.campaign.landing_header ||
                          "Register A Clinical Account"
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "p",
                    {
                      class: {
                        "card-text": true,
                        ["text-" + _vm.campaign.landing_subheader_align]: true,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.campaign.landing_subheader ||
                            "A clinical account gives you access to survey participation and results, weekly polls, benchmark reports, a searchable news archive, and more on a personalized dashboard."
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      directives: [
                        {
                          name: "promise-btn",
                          rawName: "v-promise-btn",
                          value: { action: "submit" },
                          expression: "{ action: 'submit' }",
                        },
                      ],
                      staticClass: "text-left",
                      attrs: {
                        "accept-charset": "utf-8",
                        novalidate: "",
                        role: "form",
                      },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.register.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "form-group text required" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.form.full_name.$model,
                                    expression: "$v.form.full_name.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: {
                                  "form-control": true,
                                  "is-invalid":
                                    _vm.$v.form.full_name.$dirty &&
                                    _vm.$v.form.full_name.$error,
                                },
                                attrs: {
                                  type: "text",
                                  name: "full_name",
                                  placeholder: "Full Name",
                                  id: "clinical-full-name",
                                  required: "",
                                  maxlength: "50",
                                },
                                domProps: {
                                  value: _vm.$v.form.full_name.$model,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.form.full_name,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.form.full_name.$dirty &&
                              !_vm.$v.form.full_name.required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Full name is required.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.form.full_name.$dirty &&
                              !_vm.$v.form.full_name.minLength
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "Full name has a minimum of 3 characters."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "form-group email required" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.$v.form.email.$model,
                                    expression: "$v.form.email.$model",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class: {
                                  "form-control": true,
                                  "is-invalid":
                                    _vm.$v.form.email.$dirty &&
                                    _vm.$v.form.email.$error,
                                },
                                attrs: {
                                  type: "email",
                                  name: "email",
                                  placeholder: "Email",
                                  id: "clinical-email",
                                  required: "",
                                  maxlength: "254",
                                },
                                domProps: { value: _vm.$v.form.email.$model },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.form.email,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.form.email.$dirty &&
                              !_vm.$v.form.email.required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Email address is required.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.form.email.$dirty &&
                              !_vm.$v.form.email.email
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Email address has to be valid.")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.form.email.$dirty &&
                              !_vm.$v.form.email.isUnique
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "Sorry, that email address is already in use."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "form-group select required" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: {
                                    for: "user-attribute-practice-location-id",
                                  },
                                },
                                [_vm._v("Country")]
                              ),
                              _vm._v(" "),
                              _c(
                                "multiselect",
                                {
                                  class: {
                                    "is-invalid":
                                      _vm.$v.form.user_attribute.practice
                                        .location_id.$dirty &&
                                      _vm.$v.form.user_attribute.practice
                                        .location_id.$error,
                                  },
                                  attrs: {
                                    options: _vm.countryOptions,
                                    placeholder: "Select your country...",
                                    label: "alias",
                                    "track-by": "id",
                                    "deselect-label": "",
                                    "aria-invalid": "false",
                                    id: "user-attribute-practice-location-id",
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.selectedCountry,
                                    callback: function ($$v) {
                                      _vm.selectedCountry =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "selectedCountry",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult",
                                    },
                                    [_vm._v("No matching countries found.")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                attrs: {
                                  type: "hidden",
                                  name: "user_attribute[practice][location_id]",
                                },
                                domProps: {
                                  value:
                                    _vm.form.user_attribute.practice
                                      .location_id,
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.form.user_attribute.practice.location_id
                                .$dirty &&
                              !_vm.$v.form.user_attribute.practice.location_id
                                .required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Country is required.")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-large btn-success",
                            attrs: { type: "submit", disabled: !_vm.isMounted },
                          },
                          [_vm._v("Register")]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-footer text-center" }, [
                  _c("h5", [_vm._v("Already Have an Account?")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$modal.show("campaignLoginModal", {})
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-lock" }), _vm._v(" Sign In")]
                  ),
                ]),
                _vm._v(" "),
                _vm.campaign.landing_show_qr_code && _vm.campaign.app_share_url
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-none d-md-block card-footer text-center",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src:
                              "/files/campaigns/qr/" +
                              _vm.campaign.slug +
                              "-app-qr-sm.png",
                            alt: "MS+ QR Code",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("footer-section"),
      _vm._v(" "),
      _c("campaign-login"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", [
      _vm._v("Prefer to use your phone/tablet? Try our mobile app "),
      _c("span", { staticClass: "text-nowrap" }, [_vm._v("Market Scope+")]),
      _vm._v(" for an improved experience."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }