<template>
	<div>
		<div class="preloader">
			<div class="preloader_image">
				<div class="sk-double-bounce">
					<div class="sk-child sk-double-bounce1"></div>
					<div class="sk-child sk-double-bounce2"></div>
				</div>
			</div>
		</div>
		<b-navbar toggleable="lg" fixed="top" class="subpage" id="mainNav" ref="mainNav">
			<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
			<b-navbar-brand to="/">Market Scope</b-navbar-brand>
			<b-navbar-nav class="flex-row">
				<b-nav-item href="#" @click.prevent="campaign ? $modal.show('campaignLoginModal', {}) : $modal.show('loginModal')" class="align-self-center login-link"><i class="fa fa-lock"></i> Sign In</b-nav-item>
			</b-navbar-nav>
			<b-collapse is-nav id="nav_collapse">
				<b-navbar-nav class="ml-auto">
					<b-nav-item-dropdown text="Products" @shown="alignNavItems('navDropdown1')" class="full-width" ref="navDropdown1">
						<b-dropdown-item to="/pages/reports?page=1" exact>Global &amp; Regional Market Reports</b-dropdown-item>
						<b-dropdown-item to="/ophthalmic-market-trends" exact>Ophthalmic Market Trends</b-dropdown-item>
						<b-dropdown-item to="/atlases">Atlases</b-dropdown-item>
						<b-dropdown-item to="/disease-models" exact>Disease Models</b-dropdown-item>
						<b-dropdown-item to="/ophthalmic-newsletter" exact>Ophthalmic Newsletter</b-dropdown-item>
						<b-dropdown-item to="/pages/reports/deals?page=1" exact>Ophthalmic Deals Analysis</b-dropdown-item>
					</b-nav-item-dropdown>
					<b-nav-item to="/pages/physicians">Surveys</b-nav-item>
					<b-nav-item to="/pages/primary-research">Primary Research</b-nav-item>
					<b-nav-item to="/pages/news">News</b-nav-item>
					<b-nav-item-dropdown text="Market Scope+" @shown="alignNavItems('navDropdown2')" class="full-width plus-nav-dropdown" ref="navDropdown2">
						<b-dropdown-item href="#features">Features</b-dropdown-item>
						<b-dropdown-item href="#screenshots">Screenshots</b-dropdown-item>
						<b-dropdown-item href="#contact">Contact</b-dropdown-item>
						<b-dropdown-item href="#download" link-class="font-weight-bold">Download</b-dropdown-item>
					</b-nav-item-dropdown>
					<b-nav-item to="/pages/methodology">Methodology</b-nav-item>
					<b-nav-item to="/pages/about">About</b-nav-item>
				</b-navbar-nav>
			</b-collapse>
			<campaign-login v-if="campaign" />
			<login v-else />
		</b-navbar>
		<section id="home" class="no-spacing">
			<div class="circle1"></div>
			<div class="circle2"></div>
			<div class="circle3"></div>
			<div class="circle4"></div>
			<div class="banner-caption">
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="hero-text">
								<h1 class="text-nowrap animated fadeInLeft">Market Scope+</h1>
								<p class="animated fadeInLeft">Market Scope+ is our new robust app that allows ophthalmic and optometric survey participants to easily participate in syndicated and sponsored surveys, review practice benchmark reports, weigh in with weekly polls on new technology adoption, and read the latest trending news in an ad-free environment.</p>
								<a href="https://apps.apple.com/za/app/market-scope/id1642558540" target="_blank" class="d-inline-block mr-0 mr-lg-3 mb-3 animated fadeInLeft"><img v-lazy="require('@assets/img/plus/plus-apple-download.png')" /></a>
								<a v-if="$route.query.referral_code" :href="'https://play.google.com/store/apps/details?id=com.marketscope.app&referrer=' + encodeURIComponent('referral_code=' + $route.query.referral_code)" target="_blank" class="d-inline-block  animated fadeInLeft"><img v-lazy="require('@assets/img/plus/plus-google-download.png')" /></a>
								<a v-else-if="$route.query.access_code" :href="'https://play.google.com/store/apps/details?id=com.marketscope.app&referrer=' + encodeURIComponent('access_code=' + $route.query.access_code)" target="_blank" class="d-inline-block  animated fadeInLeft"><img v-lazy="require('@assets/img/plus/plus-google-download.png')" /></a>
								<a v-else href="https://play.google.com/store/apps/details?id=com.marketscope.app" target="_blank" class="d-inline-block animated fadeInLeft"><img v-lazy="require('@assets/img/plus/plus-google-download.png')" /></a>
							</div>
						</div>
						<div class="col-md-6">
							<div class="phone-mockup animated fadeInUp text-center d-none d-md-block">
								<img v-lazy="require('@assets/img/plus/plus-banner-phone.png')" ref="phoneMockup">
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="features" class="section-spacing">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
						<div class="section-title text-center">
							<h2>Main Features</h2>
							<p>Market Scope+ is designed to offer eye care providers a secure platform to easily participate in surveys, stay informed on industry insights, and earn incentives.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 col-md-6 col-lg-6">
						<div class="features-item text-center wow fadeIn" data-wow-delay="0.2s">
							<div class="icon">
								<img v-lazy="require('@assets/img/plus/plus-feature-1.png')" />
							</div>
							<h3>Complete Surveys</h3>
							<p>Survey participation offers eye care providers a unique chance to measure practice performance against nationwide averages and helps guide companies on the needs of ophthalmologists and optometrists to guide product development, messaging, pricing, and access.</p>
						</div>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-6">
						<div class="features-item text-center wow fadeIn" data-wow-delay="0.3s">
							<div class="icon">
								<img v-lazy="require('@assets/img/plus/plus-feature-2.png')" />
							</div>
							<h3>Stay Informed</h3>
							<p>Gain access to the latest industry insights and stay up to date on market news and trends. We provide instant survey results that help you analyze practice patterns, evaluate treatment choices, and benchmark your practice, as well weekly news coverage to keep you updated on the latest in the ophthalmic industry.</p>
						</div>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-6">
						<div class="features-item text-center wow fadeIn" data-wow-delay="0.4s">
							<div class="icon">
								<img v-lazy="require('@assets/img/plus/plus-feature-3.png')" />
							</div>
							<h3>Earn Incentives</h3>
							<p>Participants in most surveys have the option of an e-gift card sent to them or a charitable donation made in their name. Past recipients include Orbis International, ASCRS Foundation, Glaucoma 360, Doctors Without Borders, and the Houston Food Bank. We realize your time is valuable, and we offer these incentives to show our appreciation for the time you spend participating.</p>
						</div>
					</div>
					<div class="col-sm-6 col-md-6 col-lg-6">
						<div class="features-item text-center wow fadeIn" data-wow-delay="0.5s">
							<div class="icon">
								<img v-lazy="require('@assets/img/plus/plus-feature-4.png')" />
							</div>
							<h3>Secure</h3>
							<p>Our business is built on the privacy and trust of our participants. Market Scope reports only aggregated data in our survey reports that are shared with participants, and your individual responses will always be de-identified before summary analysis to safeguard your anonymity.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="screenshots" class="section-spacing white-bg">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
						<div class="section-title text-center">
							<h2>Take a Look</h2>
							<p>Market Scope+ is designed to be secure, efficient, and easy to use.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 col-md-3 p-3 text-center">
						<a href="#" @click.prevent="showModal(0)"><img v-lazy="require('@assets/img/plus/plus-screenshot-survey.png')" class="img-fluid" /></a>
					</div>
					<div class="col-sm-6 col-md-3 p-3 text-center">
						<a href="#" @click.prevent="showModal(1)"><img v-lazy="require('@assets/img/plus/plus-screenshot-results.png')" class="img-fluid" /></a>
					</div>
					<div class="col-sm-6 col-md-3 p-3 text-center">
						<a href="#" @click.prevent="showModal(2)"><img v-lazy="require('@assets/img/plus/plus-screenshot-polls.png')" class="img-fluid" /></a>
					</div>
					<div class="col-sm-6 col-md-3 p-3 text-center">
						<a href="#" @click.prevent="showModal(3)"><img v-lazy="require('@assets/img/plus/plus-screenshot-news.png')" class="img-fluid" /></a>
					</div>
				</div>
			</div>
		</section>
		<section id="download" class="section-spacing">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="download-img text-md-left text-center wow fadeIn">
							<img v-lazy="require('@assets/img/plus/plus-download.png')" alt="Download" class="img-fluid">
						</div>
					</div>
					<div class="col-md-6 my-auto pl-4">
						<div class="download-text wow fadeIn">
							<h2>Get Started with Market Scope+</h2>
							<p>Market Scope+ is available for iOS and Android users on the App Store and Google Play.</p>
							<a href="https://apps.apple.com/za/app/market-scope/id1642558540" target="_blank" class="d-inline-block mr-0 mr-lg-3 mb-3"><img v-lazy="require('@assets/img/plus/plus-apple-download.png')" /></a>
							<a v-if="$route.query.referral_code" :href="'https://play.google.com/store/apps/details?id=com.marketscope.app&referrer=' + encodeURIComponent('referral_code=' + $route.query.referral_code)" target="_blank" class="d-inline-block"><img v-lazy="require('@assets/img/plus/plus-google-download.png')" /></a>
							<a v-else-if="$route.query.access_code" :href="'https://play.google.com/store/apps/details?id=com.marketscope.app&referrer=' + encodeURIComponent('access_code=' + $route.query.access_code)" target="_blank" class="d-inline-block"><img v-lazy="require('@assets/img/plus/plus-google-download.png')" /></a>
							<a v-else href="https://play.google.com/store/apps/details?id=com.marketscope.app" target="_blank" class="d-inline-block"><img v-lazy="require('@assets/img/plus/plus-google-download.png')" /></a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="contact" class="section-spacing white-bg">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="section-title text-center">
							<h2>Contact Us</h2>
							<p>Contact us using the form below if we can assist with any questions, difficulties, or concerns.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-8 offset-md-2">
						<form class="contact-form wow zoomIn" novalidate="novalidate" role="form" v-promise-btn="{ action: 'submit' }" @submit.prevent="send" >
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input v-model="form.firstName" placeholder="First Name" id="fname" class="form-control" name="fname" type="text" required data-error="Please enter your first name">
										<div class="help-block with-errors"></div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input v-model="form.lastName" placeholder="Last Name" id="lname" class="form-control" name="lname" type="text">
									</div>
								</div>
							</div>
							<div class="form-group">
								<input v-model="form.email" placeholder="Email Address" id="email" class="form-control" name="email" type="email" required data-error="Please enter your valid email address">
								<div class="help-block with-errors"></div>
							</div>
							<div class="form-group">
								<input v-model="form.phone" placeholder="Phone # (optional)" id="phone" class="form-control" name="phone" type="tel">
							</div>
							<div class="form-group">
								<input v-model="form.practiceOrCompany" placeholder="Practice/Company Name" id="cname" class="form-control" name="cname" type="text" required data-error="Please enter your valid practice or copmany name">
								<div class="help-block with-errors"></div>
							</div>
							<div class="form-group">
								<textarea v-model="form.comments" placeholder="Your Comments" id="message" cols="20" rows="5" class="form-control" required data-error="Please enter your comments"></textarea>
								<div class="help-block with-errors"></div>
							</div>
							<div class="form-group mb-3">
								<small class="form-notice">Any submitted contact information is confidential and will not be given to any third parties.<br />This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</small>
							</div>
							<div v-if="status" v-html="status" id="msgSubmit" class="mx-auto mb-3"></div>
							<button type="submit" class="btn btn-primary">Send Message</button>
						</form>
					</div>
				</div>
			</div>
		</section>
		<footer class="footer">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="footer-logo text-center">
							<router-link to="/">Market Scope</router-link>
						</div>
					</div>
					<div class="col-md-12">
						<ul class="social-icons text-center">
							<li><a href="https://www.linkedin.com/company/market-scope-llc/" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
							<li><a href="https://twitter.com/marketscope_llc" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
						</ul>
					</div>
					<div class="col-md-12">
						<div class="copyright-text text-center">
							<p>&copy;{{ new Date().getFullYear() }} Market Scope, LLC. All Rights reserved.</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<div class="back-top">
			<a href="#"><i class="fa fa-angle-up"></i></a>
		</div>
		<b-modal id="screenshots-modal" ref="screenshotsModal">
			<b-carousel
				id="screenshots-carousel"
				v-model="activeSlide"
				:interval="0"
				controls
				background="none"
			>
				<b-carousel-slide :img-src="require('@assets/img/plus/plus-screenshot-survey.png')"></b-carousel-slide>
				<b-carousel-slide :img-src="require('@assets/img/plus/plus-screenshot-results.png')"></b-carousel-slide>
				<b-carousel-slide :img-src="require('@assets/img/plus/plus-screenshot-polls.png')"></b-carousel-slide>
				<b-carousel-slide :img-src="require('@assets/img/plus/plus-screenshot-news.png')"></b-carousel-slide>
			</b-carousel>
			<template #modal-footer="{ close }">
				<b-button variant="light" @click="close()">Close</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'
import { VBScrollspy, BNavbar, BNavbarNav, BNav, BNavItemDropdown, BDropdownItem, BNavItem, BNavbarToggle, BNavbarBrand, BCollapse, VBToggle, BPopover, BModal, BCarousel, BCarouselSlide, BButton } from 'bootstrap-vue'

import VanillaTilt from 'vanilla-tilt'

import Login from './modals/Login'
import CampaignLogin from './modals/CampaignLogin'

import { useGoogleRecaptcha } from '../util/use-google-recaptcha'

export default {
	name: 'Plus',
	metaInfo() {
		return {
			title: 'Market Scope+ Mobile App',
			bodyAttrs: {
				class: 'plus'
			},
			meta: [
				{
					property: 'og:url',
					content: '//www.market-scope.com/plus',
					vmid: 'og:url'
				},
				{
					name: 'image',
					property: 'og:image',
					content: 'https://www.market-scope.com/static/landing/img/plus-promo.jpg',
					vmid: 'og:image'
				}
			],
			link: [
				{
					rel: 'canonical',
					href: 'https://www.market-scope.com/plus',
					vmid: 'canonical'
				}
			]
		}
	},
	data() {
		return {
			form: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				practiceOrCompany: '',
				comments: ''
			},
			activeSlide: 0,
			status: ''
		}
	},
	computed: {
		...mapState({
			campaign: state => state.campaign
		})
	},
	created() {
		this.$_debounce = null
	},
	mounted() {
		$('.preloaderimg').fadeOut()
		$('.preloader').delay(200).fadeOut('slow').delay(200, function () {
			$(this).remove()
		})
		
		$.extend($.easing, {
			easeInOutExpo: function(e,f,a,h,g){if(f==0){return a}if(f==g){return a+h}if((f/=g/2)<1){return h/2*Math.pow(2,10*(f-1))+a}return h/2*(-Math.pow(2,-10*--f)+2)+a}
		})
		
		this.$_debounce = debounce(this.onResize, 250)
		
		this.$_phoneMockup = this.$refs.phoneMockup
		VanillaTilt.init(this.$_phoneMockup, {
			max: 6
		});
		
		// Collapse now if page is not at top
		this.$nextTick(function () {
			this.navbarCollapse()
		})
		
		window.addEventListener('scroll', this.navbarCollapse)
		window.addEventListener('resize', this.$_debounce)
		
		$('.back-top a').on('click', function (event) {
			$('body,html').animate({
				scrollTop: 0
			}, 800)
			return false
		})
		
		$(window).on('scroll', function () {
			if ($(this).scrollTop() > 150) {
				$('.back-top').fadeIn()
			} else {
				$('.back-top').fadeOut()
			}
		})
		
		var headerHeight = $('.navbar').outerHeight()
		$('.plus-nav-dropdown li a').on('click', function (e) {
			var $anchor = $(this)
			
			$('html, body').stop().animate({
				scrollTop: $($anchor.attr('href')).offset().top-headerHeight-30
			}, 1000, 'easeInOutExpo')
			
			e.preventDefault()
		})
		
		window.WOW = require('wow.js')
		
		if ($('.wow').length) {
			var wow = new WOW({
				boxClass:     'wow',      // animated element css class (default is wow)
				animateClass: 'animated', // animation css class (default is animated)
				offset:       0,          // distance to the element when triggering the animation (default is 0)
				mobile:       true,       // trigger animations on mobile devices (default is true)
				live:         true       // act on asynchronously loaded content (default is true)
			})
			wow.init()
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.navbarCollapse)
		window.removeEventListener('resize', this.$_debounce)
		
		this.$_debounce = null
		this.$_phoneMockup.vanillaTilt.destroy()
	},
	methods: {
		scrollIntoView(event) {
			event.preventDefault()
			
			const href = event.target.getAttribute('href')
			const el = href ? document.querySelector(href) : null
			if (el) {
				this.$refs.content.scrollTop = el.offsetTop
			}
		},
		showModal(activeSlide) {
			this.activeSlide = activeSlide
			this.$refs.screenshotsModal.show()
		},
		navbarCollapse() {
			let mainNav = this.$refs.mainNav.$el
			if (mainNav) {
				if (window.pageYOffset > 56) {
					mainNav.classList.add('navbar-shrink')
				} else {
					mainNav.classList.remove('navbar-shrink')
				}
			}
		},
		onResize() {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty('--vh', `${vh}px`)
		},
		alignNavItems(ref) {
			let navDropdown = $(this.$refs[ref].$el)
			navDropdown.children('ul').first().css('padding-left', navDropdown.position().left-16 + 'px')
		},
		send() {
			return useGoogleRecaptcha().getRecaptchaToken('plus_contact_us').then(token => {
				return this.$http.put('func/plus-contact-us', {
					firstName: this.form.firstName,
					lastName: this.form.lastName,
					email: this.form.email,
					phone: this.form.phone,
					practiceOrCompany: this.form.practiceOrCompany,
					comments: this.form.comments,
					recaptchaToken: token
				})
					.then(response => {
						this.status = '<h4 class="text-success"><i class="fas fa-check-circle"></i> Email sent.</h4>'
						
						setTimeout(() => {
							this.status = ''
							Object.assign(this.form, this.$options.data.apply(this).form)
						}, 2000)
					}, response => {
						this.status = '<span class="text-danger">One or more required fields are missing or invalid.</span>'
					})
			})
		}
	},
	components: {
		BNavbar,
		BNavbarNav,
		BNav,
		BNavItemDropdown,
		BDropdownItem,
		BNavItem,
		BNavbarToggle,
		BNavbarBrand,
		BCollapse,
		BPopover,
		BNavbar,
		BModal,
		BCarousel,
		BCarouselSlide,
		BButton,
		Login,
		CampaignLogin
	},
	directives: {
		'b-scrollspy': VBScrollspy
	}
}
</script>

<style lang="scss">
body.plus {
	font-family: 'Poppins', sans-serif;
	background:#f8faff;
	font-size:16px;
	position:relative;
	overflow-x:hidden;
}

@media (max-width: 767.98px) {
	body.plus {
		padding-top:80px;
	}
}
</style>

<style lang="scss" scoped>
/*
Author       	: theme_crazy
Template Name	: AppSinia - App Landing Page
Version      	: 1.0
*/

/****** Table Of Content ***************
=================================================
#1. Typography
#2. Pre Loader
#3. General Design
#4. Header Design
#5. Banner Design
#6. Features
#13. Contact Us
#14. Responsive Design
=================================================
*/

/************* Typography ******************/
a,a:active,a:focus,a:hover{
	outline:none!important;
	text-decoration:none;
}
a{
	transition:all 0.35s ease-in-out;
	color:#333;
}
input:focus,
textarea:focus,
select:focus,
button:focus {
	outline:none!important;
	box-shadow:none;
}
ul,
ol{
	margin:0;
	list-style-type:none;
}
h1,
h2,
h3,
h4,
h5,
h6{
	color:#333;
	font-family: 'Poppins', sans-serif;
}
h1 {
	font-weight: 600;
	font-size:48px !important;
	line-height:56px;
	margin: 0 0 20px;
}
h2{
	font-weight:600;
	font-size:36px !important;
	margin:0 0 15px;
	line-height:40px;
}
h3{
	font-size:20px !important;
	font-weight:500;
	text-transform:capitalize;
	margin:0 0 20px;
}
h4{
	font-size:14px !important;
	color:#929292;
	font-weight:400;
	margin:0 0 20px;
}
h5{
	font-weight:300;
	margin:0 0 10px;
}
h6{
	font-weight:300;
	margin:0 0 10px;
}
p {
	font-size: 16px;
	line-height: 28px;
	color: #929292;
	margin: 0 0 25px;
}

/*================================ Pre Loader ====================================*/
.preloader{
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:#ffffff;
	z-index:13000;
	height:100%;
}
.preloader_image{
	width:130px;
	height:130px;
	position:absolute;
	left:50%;
	top:50%;
	transform:translate(-50%,-50%);
}
.sk-double-bounce {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 40px auto;
}
.sk-double-bounce .sk-child {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #0a5384;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	animation: sk-doubleBounce 2s infinite ease-in-out;
}
.sk-double-bounce .sk-double-bounce2 {
	animation-delay: -1.0s;
}

@keyframes sk-doubleBounce {
	0%, 100% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
}

/*======================================== General Design ========================================*/
.white-bg{
	background-color:#fff;
}
.btn.btn-default{
	line-height: 26px;
	border: 2px solid #2fbbcc;
	border-radius: 27px;
	padding: 12px 30px;
	color: #2fbbcc;
	font-weight: 500;
	text-transform:capitalize;
	box-shadow:none!important;
}
.btn.btn-primary{
	line-height: 26px;
	border: 2px solid #2fbbcc;
	border-radius: 27px;
	padding: 12px 30px;
	color: #fff;
	font-weight: 500;
	background:#2fbbcc;
	text-transform:capitalize;
	opacity:1;
	box-shadow:none!important;
}
.btn-primary:hover{
	color:#2fbbcc;
	border-color:#2fbbcc;
	background:none;
}
.btn.btn-default:hover{
	background:#2fbbcc;
	color:#fff;
	border-color:#2fbbcc;
}
.btn.focus, .btn:focus {
	outline: 0;
	box-shadow: none;
}
section{
	width:100%;
	float:left;
	height:auto;
}
.section-spacing{
	padding:60px 0;
}
.no-spacing{
	padding:0;
}

/*======================================== Banner Design =============================*/
#home{
	background:url('~@assets/img/plus/plus-banner-bg.png') no-repeat center bottom;
	background-size:cover;
	position:relative;
	padding-top: 100px;
}
.banner-caption{
	width:100%;
	float:left;
	height:auto;
	padding:60px 0;
}
.hero-text{
	width:100%;
	float:left;
	height:auto;
	padding: 170px 0 0;
}
.hero-text h1{
	color:#fff;
	font-weight: 200;
	font-family: MistralStd;
	animation-delay:0.2s;
	font-size: 86px !important;
}
.hero-text p{
	color:#fff;
	margin:0 0 30px;
	animation-delay:0.4s;
}
.hero-text .btn.btn-primary,
.hero-text .btn-primary:not(:disabled):not(.disabled):active{
	text-transform:capitalize;
	color:#4776e6;
	background:#fff;
	font-weight:600;
	line-height:26px;
	padding:12px 30px;
	border-radius:27px;
	border:2px solid #fff;
}
.hero-text .btn.btn-default{
	font-size:16px;
	text-transform:capitalize;
	color:#fff;
	font-weight:600;
	line-height:26px;
	padding:12px 30px;
	border-radius:27px;
	border:2px solid #fff;
}
.hero-text a{
	animation-delay:0.6s;
}
.hero-text .btn.btn-default:hover{
	background:#fff;
	color:#4776e6;
}
.hero-text .btn.btn-primary:hover{
	background:none;
	color:#fff;
}
.phone-mockup{
	animation-delay:1s;
}
.circle1 {
	position: absolute;
	left: 40px;
	top: 40px;
	width:100px;
	height:100px;
	border-radius:50%;
	background:rgba(255,255,255,0.1);
	animation: move 2.5s linear infinite;
}
.circle2{
	position: absolute;
	left: 30%;
	top: 60%;
	width:150px;
	height:150px;
	border-radius:50%;
	background:rgba(255,255,255,0.1);
	animation: move 3s linear infinite;
}
.circle3 {
	position: absolute;
	right: 40px;
	top: 140px;
	width:130px;
	height:130px;
	border-radius:50%;
	background:rgba(255,255,255,0.1);
	animation: move 2.5s linear infinite;
}
.circle4 {
	position: absolute;
	left: 45%;
	top: 50px;
	width:160px;
	height:160px;
	border-radius:50%;
	background:rgba(255,255,255,0.1);
	animation: move 3.5s linear infinite;
}

@keyframes move {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(25px);
	}
	100% {
		transform: translateY(0px);
	}
}

/*======================================== Features =============================*/
.section-title{
	width:100%;
	float:left;
	height:auto;
	margin:0 0 70px;
}
.section-title p{
	display: block;
	margin: 0 auto;
}
.features-item{
	width:100%;
	float:left;
	height:auto;
	margin:0 0 65px;
	transition:all 0.3s ease-in-out;
}
.features-item:hover .icon::after{
	background:#2fbbcc;
}
.features-item:hover i:before{
	color:#fff;
}
.features-item h3{
	color:#1d2025;
}
.features-item p{
	margin:0;
}
.features-item .icon{
	display:block;
	position:relative;
	margin:0 0 20px;
}
/*
.features-item .icon::before{
	width: 70px;
	height: 70px;
	background: #e1ecff;
	border-radius: 10px;
	content: '';
	position: absolute;
	left: 50%;
	top: 0px;
	z-index: 1;
	transform:translateX(-50%);
}
.features-item .icon::after{
	width:70px;
	height:70px;
	background:#2fbbcc;
	border-radius:10px;
	content:'';
	position:absolute;
	left:50%;
	top:0;
	z-index:1;
	transform:translateX(-50%) rotate(-45deg);
	transition:all 0.3s ease-in-out;
}
*/
.features-item i{
	width: 88px;
	text-align: center;
	display: inline-block;
	line-height: 72px;
	vertical-align: middle;
	position: relative;
	z-index: 3;
}

/*======================================== Contact Us =============================*/
.form-control{
	font-size:16px;
	color:#929292;
	height:54px;
	border-radius:27px;
	border:1px solid #dcdcdc;
	padding:5px 30px;
	font-weight:300;
}
.form-control:focus{
	box-shadow:none;
	border-color:#2fbbcc;
}
textarea.form-control{
	padding:12px 30px;
}
.form-control::-moz-placeholder{
	color:#a8a8a8;
}
.form-control::-webkit-input-placeholder{
	color:#a8a8a8;
}
.form-control::-ms-input-placeholder{
	color:#a8a8a8;
}
.with-errors li{
	color:#f00;
	padding:10px 0 0;
	font-weight:300;
}
.form-group {
	margin-bottom: 30px;
}
div#msgSubmit {
	font-size: 20px;
}

/*======================================== Footer =============================*/
.footer{
	width:100%;
	float:left;
	height:auto;
	padding:100px 0;
	background:#fff url('~@assets/img/plus/plus-footer-bg.png') no-repeat top center;
	background-size:cover;
}
.footer .social-icons{
	padding:0;
}
.footer .social-icons li{
	display:inline-block;
	margin:0 5px;
}
.footer .social-icons li a{
	display:inline-block;
	font-size:14px;
	border-color:rgba(255,255,255,0.9);
	color:rgba(255,255,255,0.9);
	width:30px;
	line-height:28px;
	border:1px solid #f6f6f6;
	border-radius:2px;
	transition:all 0.3s ease-in-out;
}
.footer .social-icons li a:hover{
	color:#fff;
	border-color:#fff;
}
.footer-logo{
	margin-bottom: 0.5rem;
	
	a {
		color: #fff;
		font-family: MistralStd;
		font-size: 1.5rem;
	}
}
.copyright-text{
	display:block;
	margin:15px 0 0;
}
.copyright-text p{
	color:rgba(255,255,255,0.9);
	font-weight:300;
	margin:0;
}
.back-top{
	position:fixed;
	bottom:15px;
	right:15px;
	z-index:9;
	display:none;
}
.back-top a{
	width:40px;
	line-height:40px;
	background:#2fbbcc;
	color:#fff;
	display:inline-block;
	border-radius:50%;
	text-align:center;
	font-size:24px;
}
.back-top a:hover{
	transform:translateY(-10px);
}

/*======================================== Responsive Design =============================*/
@media (max-width: 374px) {
	.hero-text .btn.btn-primary,
	.hero-text .btn.btn-default,
	.btn.btn-primary,
	.btn.btn-default{
		min-width:unset!important;
		padding:12px 22px!important;
		font-size:14px;
	}
	p{
		font-size:14px;
		line-height:26px;
	}
	.form-control{
		font-size:14px;
	}
}
@media (max-width: 575.98px) {
	.hero-text h1{
		font-size: 48px !important;
	}
	
	#features .row .col-sm-6:nth-last-child(-n+1) .features-item{
		margin-bottom:0;
	}
	.circle1 {
		left: 20px;
		top: 20px;
		width: 60px;
		height: 60px;
	}
	.circle2 {
		left: 50%;
		top: 70%;
		width: 70px;
		height: 70px;
	}
	.circle3 {
		right: 20px;
		top: 50px;
		width: 80px;
		height: 80px;
	}
	.circle4 {
		left: 45%;
		top: 20%;
		width: 60px;
		height: 60px;
	}
	.hero-text .btn.btn-primary,
	.hero-text .btn.btn-default,
	.btn.btn-primary,
	.btn.btn-default{
		padding:12px 25px;
	}
	.input-group .btn.btn-primary {
		padding: 12px 15px;
		min-width: unset!important;
	}
	.form-control{
		padding:5px 20px;
	}
	textarea.form-control {
		padding: 12px 20px;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	#features .row .col-sm-6:nth-last-child(-n+2) .features-item{
		margin-bottom:0;
	}
}

@media (max-width: 767.98px) {
	#home {
		padding-top: 0;
	}
	.container {
		width: 100%;
		max-width: 100%;
		padding: 0 15px!important;
	}
	.section-spacing {
		padding: 80px 0;
	}
	.hero-text{
		padding:20px 0px;
	}
	.footer{
		padding:60px 0;
	}
	h1{
		font-size:30px;
		line-height:40px;
	}
	h2{
		font-size:24px;
		line-height:35px;
	}
	.counter-info{
		margin:0 0 50px;
	}
	.section-title{
		margin:0 0 60px;
	}
	#fun-facts .row .col-md-4:nth-last-child(-n+1) .counter-info,
	#pricing .row .col-md-4:nth-last-child(-n+1) .pricing-table{
		margin-bottom:0;
	}
	.pricing-table{
		margin:0 0 50px;
	}
	.video-img{
		position:relative;
		height:400px;
		top:auto;
		left:auto;
		bottom:-80px;
		width:100%;
	}
	.video-img::after{
		right:auto;
		display:none;
	}
	.subcribe-text{
		padding:0 0 50px;
	}
	.download-img{
		margin: 0 0 50px;
	}
	.contact-thumb{
		margin:0 0 50px;
	}
	.video-content{
		padding:0;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.container {
		width: 768px;
		max-width: 100%;
		padding: 0 15px!important;
	}
	h1{
		font-size:36px;
		line-height:45px;
	}
	h2{
		font-size:26px;
		line-height:32px;
	}
	.hero-text{
		padding:120px 0 0;
	}
	#features .row .col-sm-6:nth-last-child(-n+2) .features-item{
		margin-bottom:0;
	}
	.subcribe-text{
		padding:0;
	}
	.section-spacing {
		padding: 80px 0;
	}
	.footer{
		padding:80px 0;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	#features .row .col-sm-6:nth-last-child(-n+3) .features-item{
		margin-bottom:0;
	}
	h2{
		font-size:28px;
	}
	h1{
		font-size:45px;
	}
}

@media (min-width: 1200px) {
	#features .row .col-sm-6:nth-last-child(-n+3) .features-item{
		margin-bottom:0;
	}
}
</style>