<template>
	<footer>
		<h4>Market Scope</h4>
		<address class="mb-2">13421 Manchester Road, Suite 101 | St. Louis, MO 63131 | Phone: <strong><a href="tel:+13148350600">314-835-0600</a></strong> | Email: <strong><a href="mailto:info@market-scope.com">info@market-scope.com</a></strong></address>
		<h6><router-link :to="{ name: 'Careers' }"><strong>Careers</strong> at Market Scope</router-link> | <a href="https://www.market-scope.com/pages/privacy-policy" target="_blank">Privacy Policy</a></h6>
		<p>&copy;{{ new Date().getFullYear() }} Market Scope, LLC. All Rights reserved.</p>
	</footer>
</template>

<script>
export default {
	name: 'FooterSection'
}
</script>

<style lang="scss" scoped>
footer {
	padding: 25px 0;
	text-align: center;
	color: fade-out(white, .5);
	background-color: $gray-darker;
	
	h4 {
		font-family: MistralStd;
		letter-spacing: normal;
	}
	
	p {
		font-size: 12px;
		margin: 0;
	}
}
</style>