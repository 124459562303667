<template>
	<div>
		<navbar :subpage="true" />
		<header class="careers-masthead"></header>
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<router-link :to="{ name: 'Landing' }" exact>Home</router-link>
			</li>
			<li class="breadcrumb-item">
				<span>Careers</span>
			</li>
		</ol>
		<section class="careers" id="careers">
			<div class="container header-row">
				<div>
					<div class="mx-auto text-center">
						<img v-lazy="require('@assets/img/icons/MarketScope-Icons-28.svg')" width="70" height="70" style="max-width: 70px; width: auto; height: auto;" />
						<h1 class="section-heading mb-4">Careers at Market Scope</h1>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="listings">
					<div class="row">
						<div class="col mb-3">
							<!--
							<h4 class="text-muted text-center">Sorry, but we don't have any available positions at this time. Check back soon!</h4>
							-->
							<div class="card">
								<div class="card-body">
									<h4>Seeking Market Analyst/Writer for Market Research Firm in Health Care</h4>
									<p>The Market Analyst/Writer will be responsible for creating in-depth market reports. This will include researching and analyzing financial documents, medical databases, FDA filings, company websites, and doctor surveys, in addition to attending medical conferences. This research will be used to build analytical models and create a narrative that accurately reflects conditions in a global market.</p>
									<div class="text-center">
										<button type="button" class="btn btn-primary" @click="showModal('careerAnalystModal', 'Market Analyst/Writer')"><i class="fas fa-external-link-alt"></i> Learn More</button>
									</div>
								</div>
							</div>
							<!--
							<div class="card">
								<div class="card-body">
									<h4>Seeking Research Manager for Market Research Firm in Healthcare Industry</h4>
									<p>The Research Manager is a full-time staff position responsible for assisting with the development and completion of primary research projects while also gathering feedback from clients and research participants to further develop a portfolio of product and service offerings.</p>
									<div class="text-center">
										<button type="button" class="btn btn-primary" @click="$modal.show('careerResearchManagerModal')"><i class="fas fa-external-link-alt"></i> Learn More</button>
									</div>
								</div>
							</div>
							-->
						</div>
					</div>
				</div>
			</div>
		</section>
		<register />
		<footer-section />
		<career-analyst />
		<!--<career-research-manager />-->
	</div>
</template>

<script>
import Navbar from './Navbar'
import Register from './Register'
import FooterSection from './FooterSection'
import CareerAnalyst from './modals/careers/Analyst'
//import CareerResearchManager from './modals/careers/ResearchManager'

export default {
	name: 'Careers',
	metaInfo: {
		title: 'Market Scope Careers',
		meta: [
			{
				property: 'og:url',
				content: '//www.market-scope.com/careers',
				vmid: 'og:url'
			},
			{
				name: 'image',
				property: 'og:image',
				content: 'https://www.market-scope.com/static/landing/img/methodology-bg.jpg',
				vmid: 'og:image'
			},
			{
				name: 'description',
				content: 'Learn about current career opportunities at Market Scope, the leading source for market data, independent perspective, and objective analysis in the field of ophthalmology.'
			},
			{
				property: 'og:description',
				content: 'Learn about current career opportunities at Market Scope, the leading source for market data, independent perspective, and objective analysis in the field of ophthalmology.',
				vmid: 'og:description'
			}
		],
		link: [
			{
				rel: 'canonical',
				href: 'https://www.market-scope.com/careers',
				vmid: 'canonical'
			}
		]
	},
	methods: {
		showModal(modalName, title) {
			const _paq = window._paq = window._paq || []
			_paq.push(['trackEvent', 'Careers', 'Opened Modal', 'Career Posting Modal', title])
			
			this.$modal.show(modalName)
		}
	},
	components: {
		Navbar,
		Register,
		FooterSection,
		CareerAnalyst
		//CareerResearchManager
	}
}
</script>

<style lang="scss" scoped>
header.careers-masthead {
	position: relative;
	overflow: hidden;
	
	width: 100%;
	height: 250px;
	
	background: url('~@assets/img/methodology/methodology-bg.jpg'), $theme-secondary;
	background: url('~@assets/img/methodology/methodology-bg.jpg'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
	@include background-cover;
	background-position-x: 70%;
}

section.careers {
	.section-heading {
		color: #0a5384;
	}
	
	p {
		color: $gray-light;
		font-size: inherit;
	}
	
	.card {
		box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
		border: none;
	}
	
	.listings .card {
		border-top: 4px solid #8dc752;
		
		.media h4 > strong {
			color: #0a5384;
		}
	}
}
</style>